import { memo } from 'react';

import LogicalExpressionIcon from '@/assets/logical-expression-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type LogicalExpressionLogoProps = VariantLogoProps;

const ILogicalExpressionLogo = ({ ...rest }: LogicalExpressionLogoProps) => {
  return <FormatterValidatorLogo Icon={LogicalExpressionIcon} iconColoringType="stroke" {...rest} />;
};

export const LogicalExpressionLogo = memo(ILogicalExpressionLogo) as typeof ILogicalExpressionLogo;
