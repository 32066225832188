import type { AppClient, CreateAppClientOptions } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type AppClientId = { appClientId: string };

export const {
  useAutoUpdate: useAutoUpdateAppClient,
  useCreate: useCreateAppClient,
  useDelete: useDeleteAppClient,
  useGet: useGetAppClient,
  useSuspenseBatchGet: useSuspenseBatchGetAppClients,
  useSuspenseGet: useSuspenseGetAppClient,
} = create<AppClient, AppClientId, CreateAppClientOptions>({
  cacheKey: 'appClients',
  equal: (appClient, id) => Boolean(appClient && id?.appClientId && appClient.appClientId === id.appClientId),
  createId: (appClient) => (appClient ? { appClientId: appClient.appClientId } : null),
  create: (client, appClient) => client.createAppClient(appClient),
  delete: (client, id) => client.deleteAppClient(id.appClientId),
  get: (client, id) => client.getAppClient(id.appClientId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listAppClients()
        .then((response: JSONType) => {
          resolve({ data: response.appClients, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateAppClient(id.appClientId, updates),
});

export const toAppClientId = (appClientId?: string | null): AppClientId | null => {
  return appClientId ? { appClientId } : null;
};
