import { memo } from 'react';
import { ModalOverlay as ModalOverlay_, ModalOverlayProps as ModalOverlayProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type ModalOverlayProps = ModalOverlayProps_ & {};

const IModalOverlay = ({ className, children, ...rest }: ModalOverlayProps) => {
  return (
    <ModalOverlay_
      className={({ isEntering, isExiting }) => {
        return merge(
          'fixed inset-0 z-20 flex min-h-full items-center justify-center overflow-y-auto bg-black/25 backdrop-blur-[1px]',
          isEntering && 'animate-in fade-in duration-300 ease-out',
          isExiting && 'animate-out fade-out duration-200 ease-in',
          className
        );
      }}
      isDismissable
      {...rest}
    >
      {children}
    </ModalOverlay_>
  );
};

export const ModalOverlay = memo(IModalOverlay) as typeof IModalOverlay;
