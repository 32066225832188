import { arrayMove } from '@dnd-kit/sortable';
import { ColumnDef } from '@tanstack/react-table';
import { DetailedHTMLProps, memo, TableHTMLAttributes, useMemo } from 'react';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { merge } from '@/components';
import { Button, Table, Text, useComponentStack } from '@/components/core';
import { AddFieldButton, FieldCell } from '@/components/ui';
import { FieldState, useModelConfigStore } from '@/store';

import { FieldDetails } from './FieldDetails';
import { NavigationContainer } from './NavigationContainer';

export type FieldConfigProps = DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;

const IFieldConfig = ({ className, ...rest }: FieldConfigProps) => {
  const fields = useModelConfigStore((state) => state.fields);
  const addField = useModelConfigStore((state) => state.addField);
  const setFields = useModelConfigStore((state) => state.setFields);
  const duplicateField = useModelConfigStore((state) => state.duplicateField);
  const deleteField = useModelConfigStore((state) => state.deleteField);

  const componentStack = useComponentStack();

  const columns = useMemo<ColumnDef<FieldState>[]>(
    () => [
      {
        accessorKey: 'id',
        cell: ({ row }) => (
          <FieldCell
            field={row.original}
            onFieldItemClick={(item) =>
              componentStack?.push(
                <NavigationContainer>
                  <FieldDetails fieldId={item.id} />
                </NavigationContainer>
              )
            }
          />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
    ],
    [componentStack]
  );

  return (
    <>
      <Table
        className={merge('border-separate border-spacing-y-2 pr-4', className)}
        data={fields}
        columns={columns}
        isInnerTable={false}
        onRowIndexChange={(oldIndex, newIndex) => setFields(arrayMove(fields, oldIndex, newIndex))}
        hideHeader
        duplicateRowAction={(row) => duplicateField(row.original.id)}
        deleteRowAction={(row) => deleteField(row.original.id)}
        {...rest}
      />
      <div className="sticky bottom-0 z-10 flex flex-row gap-2 border-t border-gray-200 bg-white p-2">
        <AddFieldButton variant="secondary">
          <PlusIcon className="stroke-gray-500" />
          <Text size="md">Add field</Text>
        </AddFieldButton>
        <Button
          onPress={() => addField({ type: 'table', name: 'new table' })}
          className="text-gray-700 hover:bg-gray-50"
          variant="plain"
        >
          <PlusIcon className="stroke-gray-500" />
          <Text size="md">Add table</Text>
        </Button>
      </div>
    </>
  );
};

export const FieldConfig = memo(IFieldConfig) as typeof IFieldConfig;
