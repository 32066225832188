import { memo } from 'react';

import { ErrorCard } from '@/components/core';
import { SetupProjectProgress } from '@/features/projects';
import { ScaffoldProjectStatus } from '@/hooks';

import { Step } from './Step';

type SetupProjectStepProps = {
  status: ScaffoldProjectStatus;
  hasTrigger: boolean;
  hasExport: boolean;
};

const ISetupProjectStep = ({ status, hasTrigger, hasExport }: SetupProjectStepProps) => {
  return (
    <Step className="w-96">
      <SetupProjectProgress
        inProgress={!status.model.isSuccess || status.model.isPending}
        inProgressMessage="Configuring AI model"
        doneMessage="AI model is ready"
        isError={status.model.errors.length > 0}
      />
      <SetupProjectProgress
        inProgress={!status.validation.isSuccess || status.validation.isPending}
        inProgressMessage="Configuring human in the loop"
        doneMessage="Human in the loop configured"
        isError={status.validation.errors.length > 0}
      />
      {hasTrigger ? (
        <SetupProjectProgress
          inProgress={!status.trigger.isSuccess}
          inProgressMessage="Adding trigger"
          doneMessage="Trigger added"
          isError={status.trigger.errors.length > 0}
        />
      ) : null}
      {hasExport ? (
        <SetupProjectProgress
          inProgress={!status.export.isSuccess}
          inProgressMessage="Adding export"
          doneMessage="Export added"
          isError={status.export.errors.length > 0}
        />
      ) : null}
      {status.errors.length > 0 ? (
        <ErrorCard label="Error encountered while setting up your project" error={status.errors} />
      ) : null}
    </Step>
  );
};

export const SetupProjectStep = memo(ISetupProjectStep) as typeof ISetupProjectStep;
