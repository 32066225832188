import type { FieldFormatter } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Text } from '@/components/core';
import { FormatterLogo } from '@/components/ui';
import { OmitChildren } from '@/utils';

export type FormatterCellProps = OmitChildren<ButtonProps> & {
  formatter: FieldFormatter;
};

const IFormatterCell = ({ formatter, className, ...rest }: FormatterCellProps) => {
  return (
    <Button
      variant="plain"
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-1', className)}
      {...rest}
    >
      <FormatterLogo formatter={formatter} size="md" />
      <Text size="sm">{formatter.name}</Text>
    </Button>
  );
};

export const FormatterCell = memo(IFormatterCell) as typeof IFormatterCell;
