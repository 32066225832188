import { memo, useCallback, useMemo } from 'react';
import { Key } from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import { merge } from '@/components';
import { Button, Hr, ListBox, ListBoxItem, Pills, Popover, Select, SelectValue } from '@/components/core';
import { findField, FormatterValidatorHeader, ValidationFormSettings } from '@/components/ui';
import { FORMATTER_NUMERIC_FUNCTION_ID } from '@/constants';
import { useValidatorMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

interface Item extends Pills.Item {
  isVariable: boolean;
}

type LogicalExpressionProps = {
  onItemsChanged?: (items: Pills.Item[]) => void;
  defaultValue?: Item[];
};

const ILogicalExpression = ({ onItemsChanged, defaultValue }: LogicalExpressionProps) => {
  const getFields = useModelConfigStore((state) => state.getFields);
  const fields = useModelConfigStore((state) => state.fields);

  const variables: Item[] = useMemo(
    () =>
      fields
        .filter((field) => field.formatters?.[0]?.functionId === FORMATTER_NUMERIC_FUNCTION_ID)
        .map((field) => ({
          label: field.name,
          value: field.id,
          isVariable: true,
        })),
    [fields]
  );

  const colorize = useCallback(
    (value: string) => {
      const ok = 'bg-green-100';

      const fields = getFields();
      if (!isNaN(parseFloat(value))) return ok;
      if (['+', '-', '*', '/'].includes(value)) return ok;
      if (fields.map((f) => f.id).includes(value)) return ok;

      return 'bg-red-100';
    },
    [getFields]
  );

  const mapValue = useCallback(
    (key: Key): Item => {
      const fieldId = key.toString();
      const field = getFields().find((v) => v.id === fieldId);
      if (!field) {
        return { value: key.toString(), isVariable: false };
      }

      return { label: field.name, value: field.id, isVariable: true };
    },
    [getFields]
  );

  return (
    <div>
      <Pills.Input className="flex" mapValue={mapValue}>
        <Pills.List
          className="flex grow"
          autoSeparate={['+', '-', '*', '/']}
          onItemsChange={onItemsChanged}
          initialValues={defaultValue}
        >
          {(pill: Pills.Item) => (
            <span className={merge('mx-1 rounded-md border-2 border-gray-200 px-2 py-0', colorize(pill.value))}>
              {pill.label || pill.value}
            </span>
          )}
        </Pills.List>

        <Select className="grow-0">
          <div className="flex">
            <Button variant="secondary">
              Variables
              <ChevronDownIcon className="stroke-gray-900" />
            </Button>
          </div>
          <Popover>
            <ListBox>
              {variables.map((v: Pills.Item) => (
                <ListBoxItem id={v.value}>{v.label}</ListBoxItem>
              ))}
            </ListBox>
          </Popover>
        </Select>
      </Pills.Input>
    </div>
  );
};

const LogicalExpression = memo(ILogicalExpression) as typeof ILogicalExpression;

export type LogexValidatorProps = {
  fieldId: string;
  index: number;
};

const ILogexValidator = ({ fieldId, index }: LogexValidatorProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const getFields = useModelConfigStore((state) => state.getFields);
  const mutate = useValidatorMutation(fieldId, index, field?.parentId);

  const validator = field?.validators?.[index];

  const updateExpression = useCallback(
    (expr: string, items: Pills.Item[]) => {
      mutate((v) => {
        v.config[expr] = items.map((it) => it as object);
        v.config.variables = getFields().map((f) => ({ id: f.id }));
      });
    },
    [getFields, mutate]
  );

  const lhs = useCallback((items: Pills.Item[]) => updateExpression('lhsExpression', items), [updateExpression]);
  const rhs = useCallback((items: Pills.Item[]) => updateExpression('rhsExpression', items), [updateExpression]);

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader validator={validator} />
      <Hr />

      <div className="flex grow flex-col gap-4">
        <LogicalExpression defaultValue={validator?.config?.lhsExpression} onItemsChanged={lhs} />

        <Select
          defaultSelectedKey={validator?.config.operator}
          onSelectionChange={(op) => mutate((v) => (v.config.operator = op.toString()))}
        >
          <div className="flex grow-0">
            <Button variant="secondary">
              <SelectValue />
              <ChevronDownIcon className="stroke-gray-900" />
            </Button>
          </div>
          <Popover>
            <ListBox>
              <ListBoxItem id="eq">=</ListBoxItem>
              <ListBoxItem id="lt">{'<'}</ListBoxItem>
              <ListBoxItem id="gt">{'>'}</ListBoxItem>
              <ListBoxItem id="gte">{'<='}</ListBoxItem>
              <ListBoxItem id="lte">{'>='}</ListBoxItem>
            </ListBox>
          </Popover>
        </Select>

        <LogicalExpression defaultValue={validator?.config?.rhsExpression} onItemsChanged={rhs} />
      </div>

      <Hr />

      <ValidationFormSettings fieldId={fieldId} index={index} />
    </div>
  );
};

export const LogexValidator = memo(ILogexValidator) as typeof ILogexValidator;
