import { Client } from '@lucidtech/las-sdk-browser';
import { createContext } from 'react';

export type GlobalAuthContextInterface = {
  client: Client;
  isLoggedIn: boolean;
  ensureLoggedIn: () => Promise<boolean>;
  login: () => void;
  logout: () => Promise<boolean>;
  setRedirectUrl: (url: string) => void;
  redirectUrl?: string | null;
  updateCredentials: (code: string) => void;
};

export const GlobalAuthContext = createContext<GlobalAuthContextInterface | undefined>(undefined);
