import { AuthorizationCodeCredentials, Client } from '@lucidtech/las-sdk-browser';
import { queryOptions, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { Buffer } from 'buffer';

import { useClient } from '@/hooks';

export type FileId = { fileUrl: string };
const QUERY_KEY = 'files';

const getOptions = (client: Client, fileId?: FileId | null) => {
  return queryOptions({
    queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, QUERY_KEY, fileId],
    queryFn: async () => {
      if (!fileId) throw new Error('fileUrl is not defined');
      return await client.makeFileServerGetRequest(fileId.fileUrl);
    },
    enabled: !!fileId,
  });
};

export const useGetFile = (fileId?: FileId | null) => {
  const { client } = useClient();
  return useQuery(getOptions(client, fileId));
};

export const useBatchGetFile = (fileIds?: FileId[] | null) => {
  const { client } = useClient();

  return useQueries({
    queries: (fileIds ?? []).map((fileId) => ({
      ...getOptions(client, fileId),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((d) => !!d),
        isPending: results.some((result) => result.isPending),
      };
    },
  });
};

export const usePutFile = () => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ fileUrl, file }: { fileUrl: string; file: Blob | File }) => {
      const content = Buffer.from(await file.arrayBuffer());
      const headers = { 'Content-Type': file.type };
      const blob = await client.makeFileServerPutRequest(fileUrl, content, { headers });
      return { fileUrl, blob };
    },
    onSuccess: async ({ fileUrl, blob }) => {
      await queryClient.invalidateQueries({
        queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, QUERY_KEY],
        exact: true,
      });
      await queryClient.setQueryData(
        [(client.credentials as AuthorizationCodeCredentials).clientId, QUERY_KEY, toFileId(fileUrl)],
        blob
      );
    },
  });
};

export function toFileId<V extends FileId>(fileUrl: string): V;
export function toFileId<V extends FileId>(fileUrl?: string): V | null;
export function toFileId<V extends FileId>(fileUrl?: string): V | null {
  return fileUrl ? ({ fileUrl } as V) : null;
}
