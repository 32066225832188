import type { FieldFormatter } from '@lucidtech/las-sdk-browser';
import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo, useRef } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Label, Table, TableRef, Text, useComponentStack } from '@/components/core';
import {
  findField,
  FormatterCell,
  FormatterConfiguration,
  NavigationContainer,
  NewFormatterModal,
} from '@/components/ui';
import { useModelConfigStore } from '@/store';

export type FormattersSectionProps = {
  fieldId: string;
};

const IFormattersSection = ({ fieldId }: FormattersSectionProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const moveFormatter = useModelConfigStore((state) => state.moveFormatter);
  const deleteFormatter = useModelConfigStore((state) => state.deleteFormatter);
  const formatterTableRef = useRef<TableRef<FieldFormatter>>(null);

  const componentStack = useComponentStack();

  const formatterColumns = useMemo<ColumnDef<FieldFormatter>[]>(() => {
    return [
      {
        accessorKey: 'functionId',
        cell: ({ row }) => (
          <FormatterCell
            formatter={row.original}
            onPress={() =>
              componentStack?.push(
                <NavigationContainer>
                  <FormatterConfiguration fieldId={fieldId} index={row.index} />
                </NavigationContainer>
              )
            }
          />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
    ];
  }, [fieldId, componentStack]);

  const formatterData = useMemo(() => field?.formatters ?? [], [field]);

  if (!field) return;

  return (
    <section>
      {/* section should have header instead of label */}
      <Label>Formatters</Label>
      <Table
        ref={formatterTableRef}
        columns={formatterColumns}
        data={formatterData}
        hideHeader
        onRowIndexChange={(fromIndex, toIndex) => {
          moveFormatter(field.id, fromIndex, toIndex, field.parentId);
          formatterTableRef.current?.remountSortableContext();
        }}
        deleteRowAction={(row) => deleteFormatter(field.id, row.index, field.parentId)}
      />
      <DialogTrigger>
        <Button className="text-gray-600 hover:bg-gray-50" variant="plain">
          <PlusIcon className="size-4 stroke-gray-500" />
          <Text size="md">Add formatter</Text>
        </Button>
        <NewFormatterModal fieldId={fieldId} />
      </DialogTrigger>
    </section>
  );
};

export const FormattersSection = memo(IFormattersSection) as typeof IFormattersSection;
