import { memo } from 'react';

import PdfIcon from '@/assets/pdf-icon.svg?react';
import { Text } from '@/components/core';
import { toDocumentId, useGetDocument } from '@/hooks/api';

export type DocumentProps = {
  documentId?: string;
};

const IDocument = ({ documentId }: DocumentProps) => {
  const { data: document_ } = useGetDocument(toDocumentId(documentId));

  if (!document_) {
    return <Text>Loading</Text>;
  }

  return (
    <div className="flex items-center gap-2">
      <PdfIcon className="size-7" />
      <Text>{document_.name}</Text>
    </div>
  );
};

export const DocumentCard = memo(IDocument) as typeof IDocument;
