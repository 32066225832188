import { memo } from 'react';

import SortDescendingIcon from '@/assets/sort-descending-icon.svg?react';
import { merge } from '@/components';
import { Text } from '@/components/core';
import { DivProps } from '@/utils';

const IDescendingCard = ({ className, children, ...rest }: DivProps) => {
  return (
    <div className={merge('flex w-full items-center gap-1', className)} {...rest}>
      <SortDescendingIcon className="size-4 stroke-gray-400" />
      <Text size="sm">Descending</Text>
      {children}
    </div>
  );
};

export const DescendingCard = memo(IDescendingCard) as typeof IDescendingCard;
