import { memo } from 'react';

import CloseIcon from '@/assets/close-icon.svg?react';
import { merge } from '@/components';
import { Button, ButtonProps } from '@/components/core';
import { SVGProps } from '@/utils';

export type CloseButtonProps = ButtonProps & {
  props?: {
    icon?: SVGProps;
  };
};

const ICloseButton = ({ className, props, ...rest }: CloseButtonProps) => {
  const { className: iconClassName, ...iconProps } = props?.icon ?? {};

  return (
    <Button className={className} variant="plain" {...rest}>
      <CloseIcon className={merge('stroke-gray-400', iconClassName)} {...iconProps} />
    </Button>
  );
};

export const CloseButton = memo(ICloseButton) as typeof ICloseButton;
