import { memo } from 'react';
import { DialogTrigger } from 'react-aria-components';

import { Button, ButtonProps } from '@/components/core';
import { NewFieldModal } from '@/components/ui';

export type AddFieldButtonProps = ButtonProps & {
  parentId?: string;
};

const IAddFieldButton = ({ parentId, className, children, ...rest }: AddFieldButtonProps) => {
  return (
    <DialogTrigger>
      <Button className={className} {...rest}>
        {children}
      </Button>
      <NewFieldModal parentId={parentId} />
    </DialogTrigger>
  );
};

export const AddFieldButton = memo(IAddFieldButton) as typeof IAddFieldButton;
