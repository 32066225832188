import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
// @ts-expect-error cannot find module
import { CustomTextRenderer, TextItem } from 'react-pdf/dist/cjs/shared/types';

import { merge } from '@/components';
import { useAttentionMap } from '@/hooks';
import { useDocumentStore } from '@/store';
import { attentionMapToBlocks, DivProps } from '@/utils';

import { useValidation } from './ValidationContext';

export type DocumentPageProps = {
  page: number;
  customTextRenderer?: CustomTextRenderer;
};

const IDocumentPage = ({ page, customTextRenderer }: DocumentPageProps) => {
  const updateContentText = useDocumentStore((state) => state.updateContentText);
  const zoom = useDocumentStore((state) => state.zoom);
  const focusedField = useDocumentStore((state) => state.focusedField);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rotate = useDocumentStore((state) => state.rotation);

  const [canDraw, setCanDraw] = useState(false);

  // When the page zooms or rotates, we want to wait for for everything to become ready before drawing
  useEffect(() => setCanDraw(false), [rotate, zoom]);

  const attentionMap = useMemo(() => attentionMapToBlocks(focusedField?.attentionMap), [focusedField?.attentionMap]);
  useAttentionMap(canvasRef, attentionMap, rotate, canDraw && focusedField?.page === page - 1);

  return (
    <Page
      key={page}
      canvasRef={canvasRef}
      pageNumber={page}
      renderTextLayer={true}
      renderAnnotationLayer={true}
      customTextRenderer={customTextRenderer}
      onGetTextSuccess={({ items }) => {
        updateContentText(
          page,
          items.filter((i): i is TextItem => 'str' in i).map((ti) => ti.str)
        );
      }}
      onRenderSuccess={() => setCanDraw(true)}
      scale={zoom}
    />
  );
};

export const DocumentPage = memo(IDocumentPage) as typeof IDocumentPage;

const ImageViewer = () => {
  const ref = useRef<HTMLCanvasElement>(null);
  const documentBlob = useDocumentStore((state) => state.documentBlob);
  const zoom = useDocumentStore((state) => state.zoom);
  const rotate = useDocumentStore((state) => state.rotation);

  const attention = useDocumentStore((state) => state.attention);

  useAttentionMap(ref, attention);

  useEffect(() => {
    const canvas = ref.current;
    if (canvas && documentBlob) {
      const img = new Image();

      img.onload = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);

        URL.revokeObjectURL(img.src);
      };

      img.src = URL.createObjectURL(documentBlob);
    }
  }, [documentBlob]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `scale(${zoom}) rotate(${rotate}deg)`;
    }
  }, [zoom, rotate]);

  return (
    <>
      <canvas ref={ref} />
    </>
  );
};

export type DocumentViewerProps = Omit<DivProps, 'children'> & {
  customTextRenderer?: CustomTextRenderer;
};

const IDocumentViewer = ({ className, customTextRenderer }: DocumentViewerProps) => {
  const documentBlob = useDocumentStore((state) => state.documentBlob);
  const numPages = useDocumentStore((state) => state.numPages);
  const rotation = useDocumentStore((state) => state.rotation);
  const setCurrentPages = useDocumentStore((state) => state.setCurrentPages);
  const setNumPages = useDocumentStore((state) => state.setNumPages);
  const options = useMemo(() => {
    return {
      cMapUrl: '/cmaps/',
      standardFontDataUrl: '/standard_fonts/',
    };
  }, []);

  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { onScrollToPage } = useValidation();

  useEffect(() => {
    onScrollToPage?.((page: number) => {
      const pageRef = pageRefs.current[page];

      if (pageRef) {
        const pageRect = pageRef.getBoundingClientRect();
        const displacement = Math.abs(pageRect.top / pageRect.height);

        // Only scroll if the calculated displacement is greater than 0.3
        if (displacement > 0.3) {
          pageRef.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }, [onScrollToPage]);

  return (
    <div className={merge('flex justify-center overflow-auto bg-gray-200 p-4', className)}>
      {documentBlob?.type === 'application/pdf' && (
        <Document
          file={documentBlob}
          options={options}
          rotate={rotation}
          onLoadError={(e) => console.log(e)}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            setCurrentPages([0]);
          }}
          externalLinkTarget="_blank"
        >
          <div className="flex flex-col gap-4">
            {Array.from(Array(numPages).keys()).map((page) => (
              <div
                ref={(el) => {
                  pageRefs.current[page] = el;
                }}
              >
                <DocumentPage key={page + 1} page={page + 1} customTextRenderer={customTextRenderer} />
              </div>
            ))}
          </div>
        </Document>
      )}
      {documentBlob && ['image/jpeg', 'image/png', 'image/webp'].includes(documentBlob.type) && <ImageViewer />}
    </div>
  );
};

export const DocumentViewer = memo(IDocumentViewer) as typeof IDocumentViewer;
