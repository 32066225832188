import { memo } from 'react';

import { merge } from '@/components';
import { Text } from '@/components/core';
import { DivProps, OmitChildren } from '@/utils';

export type BadgeProps = OmitChildren<DivProps> & {
  variant: 'success' | 'warning' | 'error' | 'info' | 'default';
  label?: string | null;
};

const IBadge = ({ variant, label, className, ...rest }: BadgeProps) => {
  /* prettier-ignore */
  const successStyles = [
    'bg-green-50',
    'border-green-400',
  ];
  /* prettier-ignore */
  const warningStyles = [
    'bg-amber-50',
    'border-amber-400',
  ];
  /* prettier-ignore */
  const errorStyles = [
    'bg-red-50',
    'border-red-400',
  ];
  /* prettier-ignore */
  const infoStyles = [
    'bg-sky-50',
    'border-sky-400',
  ];
  /* prettier-ignore */
  const defaultStyles = [
    'bg-gray-50',
    'border-gray-400',
  ];
  /* prettier-ignore */
  const successTextStyles = [
    'text-green-600',
  ];
  /* prettier-ignore */
  const warningTextStyles = [
    'text-amber-600',
  ];
  /* prettier-ignore */
  const errorTextStyles = [
    'text-red-600',
  ];
  /* prettier-ignore */
  const infoTextStyles = [
    'text-sky-600',
  ];
  /* prettier-ignore */
  const defaultTextStyles = [
    'text-gray-600',
  ];

  return (
    <div
      className={merge(
        'flex items-center justify-center rounded-full border px-2 py-1',
        variant === 'success' && successStyles,
        variant === 'warning' && warningStyles,
        variant === 'error' && errorStyles,
        variant === 'info' && infoStyles,
        variant === 'default' && defaultStyles,
        className
      )}
      {...rest}
    >
      <Text
        className={merge(
          variant === 'success' && successTextStyles,
          variant === 'warning' && warningTextStyles,
          variant === 'error' && errorTextStyles,
          variant === 'info' && infoTextStyles,
          variant === 'default' && defaultTextStyles
        )}
        size="xs"
      >
        {label}
      </Text>
    </div>
  );
};

export const Badge = memo(IBadge) as typeof IBadge;
