import { memo } from 'react';
import {
  Select as Select_,
  SelectProps as SelectProps_,
  SelectValue as SelectValue_,
  SelectValueProps as SelectValueProps_,
} from 'react-aria-components';

import { merge } from '../utils';

export type SelectProps = SelectProps_;

export const ISelect = ({ className, children, ...rest }: SelectProps) => {
  /* prettier-ignore */
  const styles = [
    'bg-white',
    'data-[disabled]:cursor-not-allowed',
    'outline-none',
    'text-md',
  ];

  /* prettier-ignore */
  const lightStyles = [
    'text-gray-900',
  ];

  return (
    <Select_ className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </Select_>
  );
};

export const Select = memo(ISelect) as typeof ISelect;

const ISelectValue = <T extends object>({ children, className, ...rest }: SelectValueProps<T>) => {
  return (
    <SelectValue_ className={merge('flex grow items-center justify-start gap-3', className)} {...rest}>
      {children}
    </SelectValue_>
  );
};

export type SelectValueProps<T extends object> = SelectValueProps_<T>;
export const SelectValue = memo(ISelectValue) as typeof ISelectValue;
