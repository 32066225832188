import { memo } from 'react';

import SaveIcon from '@/assets/save-icon.svg?react';
import { merge } from '@/components';
import { Text } from '@/components/core';
import { DivProps, OmitChildren } from '@/utils';

export type SaveProps = OmitChildren<DivProps> & {
  message: string;
};

const ISave = ({ message, className, ...rest }: SaveProps) => {
  return (
    <div className={merge('flex items-center gap-2', className)} {...rest}>
      <Text size="sm">{message}</Text>
      <SaveIcon className="size-4 animate-spin stroke-gray-500" />
    </div>
  );
};

export const Save = memo(ISave) as typeof ISave;
