import { memo } from 'react';

import { Text, TextProps } from '@/components/core';
import { formatDate, OmitChildren } from '@/utils';

export type LastUpdatedProps = OmitChildren<TextProps> & {
  createdTime?: Date;
  updatedTime?: Date;
};

const ILastUpdated = ({ createdTime, updatedTime, ...rest }: LastUpdatedProps) => {
  return (
    <Text size="xs" {...rest}>
      Last updated: {formatDate(updatedTime ?? createdTime)}
    </Text>
  );
};

export const LastUpdated = memo(ILastUpdated) as typeof ILastUpdated;
