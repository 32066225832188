import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { guard, merge } from '@/components';
import { Header, Save, Table, Text } from '@/components/core';
import { TriggerCell, TriggerOption, TRIGGERS } from '@/components/flow';
import { DivProps, Id, OmitChildren } from '@/utils';

export type OnTriggerOptionSelected = (triggerOption: TriggerOption) => void;

export type NewTriggerProps = OmitChildren<DivProps> & {
  onTriggerOptionSelected?: OnTriggerOptionSelected;
  isAddingTrigger?: boolean;
};

const INewTrigger = ({ onTriggerOptionSelected, isAddingTrigger, className, ...rest }: NewTriggerProps) => {
  const triggerData: (TriggerOption & Id)[] = useMemo(() => {
    return TRIGGERS.map((t) => ({ ...t, id: t.functionId }));
  }, []);

  const triggerColumns = useMemo<ColumnDef<TriggerOption & Id>[]>(() => {
    return [
      {
        accessorKey: 'functionId',
        cell: ({ row }) => (
          <TriggerCell onPress={() => onTriggerOptionSelected?.(row.original)} option={row.original} />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
    ];
  }, [onTriggerOptionSelected]);

  return (
    <div className={merge('flex flex-col gap-2', className)} {...rest}>
      <Header size="lg">New trigger</Header>
      <Text size="sm">Configure how you will import documents to your project</Text>
      {guard(isAddingTrigger, () => (
        <Save message="Setting up trigger" />
      ))}
      {/* TODO: Maybe use ternary statement instead */}
      {guard(!isAddingTrigger, () => (
        <Table
          className="border-separate border-spacing-2"
          columns={triggerColumns}
          data={triggerData}
          hideHeader
          hideDragHandle
          hideRowOptions
        />
      ))}
    </div>
  );
};

export const NewTrigger = memo(INewTrigger) as typeof INewTrigger;
