import { memo } from 'react';

import SortAscendingIcon from '@/assets/sort-ascending-icon.svg?react';
import { merge } from '@/components';
import { Text } from '@/components/core';
import { DivProps } from '@/utils';

const IAscendingCard = ({ className, children, ...rest }: DivProps) => {
  return (
    <div className={merge('flex w-full items-center gap-1', className)} {...rest}>
      <SortAscendingIcon className="size-4 stroke-gray-400" />
      <Text size="sm">Ascending</Text>
      {children}
    </div>
  );
};

export const AscendingCard = memo(IAscendingCard) as typeof IAscendingCard;
