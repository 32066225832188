export const CRADL_ORGANIZATION = `las:organization:cradl`;
export const CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:create-document-from-email`;
export const CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:create-document-from-power-automate`;
export const CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:create-document-from-zapier`;
export const CREATE_PREDICTION_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:create-prediction`;
export const CREATE_VALIDATION_TASK_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:create-validation-task`;
export const DEFAULT_REDIRECT_URL = `/projects`;
export const EXPORT_TO_EXCEL_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:export-to-excel`;
export const EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:export-to-power-automate`;
export const EXPORT_TO_WEBHOOK_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:export-to-webhook`;
export const EXPORT_TO_ZAPIER_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:export-to-zapier`;
export const FORMATTER_ADDRESS_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-address`;
export const FORMATTER_CLASSIFICATION_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-classification`;
export const FORMATTER_COUNTRY_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-country`;
export const FORMATTER_CURRENCY_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-currency`;
export const FORMATTER_CUSTOM_CODE_FUNCTION_ID = `__CUSTOM_CODE__`;
export const FORMATTER_DATE_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-date`;
export const FORMATTER_DATE_TIME_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-date-time`;
export const FORMATTER_EMAIL_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-email`;
export const FORMATTER_IBAN_NUMBER_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-iban-number`;
export const FORMATTER_NUMERIC_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-numeric`;
export const FORMATTER_PHONE_NUMBER_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-phone-number`;
export const FORMATTER_REGEX_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-regex`;
export const FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-social-security-number`;
export const FORMATTER_TIME_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:formatter-time`;
export const HOOK_EVALUATE_DOCUMENT = `${CRADL_ORGANIZATION}/las:function:hook-evaluate-document`;
export const HOOK_EVALUATE_PREDICTION = `${CRADL_ORGANIZATION}/las:function:hook-evaluate-prediction`;
export const MULTI_VALUE_COLUMN_NAME = `__MULTI_VALUE__`;
export const PROFILE_PICTURE_MIME_TYPES = [`image/jpeg`, `image/png`, `image/webp`];
export const VALIDATOR_CONFIDENCE_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:validator-confidence`;
export const VALIDATOR_CUSTOM_CODE_FUNCTION_ID = `__CUSTOM_CODE__`;
export const VALIDATOR_DATABASE_MATCH_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:validator-database-match`;
export const VALIDATOR_LOGEX_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:validator-logex`;
export const VALIDATOR_REGEX_FUNCTION_ID = `${CRADL_ORGANIZATION}/las:function:validator-regex`;
