import { FieldFormatterWithComingSoon } from '@/components/ui';
import {
  FORMATTER_ADDRESS_FUNCTION_ID,
  FORMATTER_CLASSIFICATION_FUNCTION_ID,
  FORMATTER_COUNTRY_FUNCTION_ID,
  FORMATTER_CURRENCY_FUNCTION_ID,
  FORMATTER_CUSTOM_CODE_FUNCTION_ID,
  FORMATTER_DATE_FUNCTION_ID,
  FORMATTER_DATE_TIME_FUNCTION_ID,
  FORMATTER_EMAIL_FUNCTION_ID,
  FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  FORMATTER_NUMERIC_FUNCTION_ID,
  FORMATTER_PHONE_NUMBER_FUNCTION_ID,
  FORMATTER_REGEX_FUNCTION_ID,
  FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID,
  FORMATTER_TIME_FUNCTION_ID,
} from '@/constants';

const FORMATTER_NUMERIC: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_NUMERIC_FUNCTION_ID,
  name: 'Numeric',
  description: 'Numbers only, with decimals',
  config: {},
};
const FORMATTER_DATE: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_DATE_FUNCTION_ID,
  name: 'Date',
  description: 'Convert to ISO date',
  config: {},
};
const FORMATTER_REGEX: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_REGEX_FUNCTION_ID,
  name: 'RegEx',
  description: 'String replace with RegEx',
  config: {},
};
const FORMATTER_CLASSIFICATION: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_CLASSIFICATION_FUNCTION_ID,
  name: 'Classification',
  description: 'Classify as one of several predetermined values',
  config: {},
};
const FORMATTER_ADDRESS: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_ADDRESS_FUNCTION_ID,
  name: 'Address',
  description: 'Full address',
  config: {},
  comingSoon: true,
};
const FORMATTER_PHONE_NUMBER: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_PHONE_NUMBER_FUNCTION_ID,
  name: 'Phone number',
  description: 'Phone number with land code',
  config: {},
  comingSoon: true,
};
const FORMATTER_IBAN_NUMBER: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  name: 'IBAN number',
  description: 'IBAN with checksum validation',
  config: {},
  comingSoon: true,
};
const FORMATTER_EMAIL: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_EMAIL_FUNCTION_ID,
  name: 'Email address',
  description: 'Convert to email address',
  config: {},
  comingSoon: true,
};
const FORMATTER_COUNTRY: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_COUNTRY_FUNCTION_ID,
  name: 'Country',
  description: 'Convert to country',
  config: {},
  comingSoon: true,
};
const FORMATTER_SOCIAL_SECURITY_NUMBER: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID,
  name: 'Social security number',
  description: 'SSN with checksum validation',
  config: {},
  comingSoon: true,
};
const FORMATTER_DATE_TIME: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_DATE_TIME_FUNCTION_ID,
  name: 'Date & time',
  description: 'Convert to ISO datetime',
  config: {},
  comingSoon: true,
};
const FORMATTER_TIME: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_TIME_FUNCTION_ID,
  name: 'Time',
  description: 'Convert to ISO time',
  config: {},
  comingSoon: true,
};
const FORMATTER_CURRENCY: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_CURRENCY_FUNCTION_ID,
  name: 'Currency',
  description: 'Convert to ISO currency',
  config: {},
  comingSoon: true,
};
const FORMATTER_CUSTOM_CODE: FieldFormatterWithComingSoon = {
  functionId: FORMATTER_CUSTOM_CODE_FUNCTION_ID,
  name: 'Custom JS',
  description: 'Write your own JS formatter',
  config: {},
  comingSoon: true,
};

const POPULAR_FORMATTERS = [FORMATTER_NUMERIC, FORMATTER_DATE, FORMATTER_REGEX, FORMATTER_CLASSIFICATION];
const STRING_FORMATTERS = [
  FORMATTER_REGEX,
  FORMATTER_CLASSIFICATION,
  FORMATTER_EMAIL,
  FORMATTER_CURRENCY,
  FORMATTER_ADDRESS,
  FORMATTER_COUNTRY,
];
const DATE_TIME_FORMATTERS = [FORMATTER_DATE, FORMATTER_DATE_TIME, FORMATTER_TIME];
const NUMBER_FORMATTERS = [
  FORMATTER_NUMERIC,
  FORMATTER_PHONE_NUMBER,
  FORMATTER_IBAN_NUMBER,
  FORMATTER_SOCIAL_SECURITY_NUMBER,
];
const CUSTOM_FORMATTERS = [FORMATTER_CUSTOM_CODE];

export type FormattersType = Record<string, FieldFormatterWithComingSoon[]>;
export const DEFAULT_FORMATTER_CATEGORY = 'Popular';
export const FORMATTERS: FormattersType = {
  [DEFAULT_FORMATTER_CATEGORY]: POPULAR_FORMATTERS,
  Strings: STRING_FORMATTERS,
  Numbers: NUMBER_FORMATTERS,
  'Date & time': DATE_TIME_FORMATTERS,
  Custom: CUSTOM_FORMATTERS,
};
