import { memo } from 'react';
import { Dialog } from 'react-aria-components';

import { Modal, ModalOverlay } from '@/components/core';

import { InviteMembers } from './InviteMembers';

export type InviteMembersModalProps = object;

// eslint-disable-next-line no-empty-pattern
const IInviteMembersModal = ({}: InviteMembersModalProps) => {
  return (
    <ModalOverlay>
      <Modal>
        <Dialog className="outline-none">
          {({ close }) => <InviteMembers className="w-[25rem] p-4" close={close} />}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const InviteMembersModal = memo(IInviteMembersModal) as typeof IInviteMembersModal;
