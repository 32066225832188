import { PropsWithChildren, useRef } from 'react';

import {
  createOrganizationStore,
  OrganizationContext,
  OrganizationProps,
  OrganizationStore,
} from './organizationStore';

type OrganizationProviderProps = PropsWithChildren<OrganizationProps>;

export const OrganizationProvider = ({ children, ...props }: OrganizationProviderProps) => {
  const storeRef = useRef<OrganizationStore>(null);

  if (!storeRef.current) {
    storeRef.current = createOrganizationStore(props);
  }

  return <OrganizationContext.Provider value={storeRef.current}>{children}</OrganizationContext.Provider>;
};
