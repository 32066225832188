import { memo, useState } from 'react';
import { ButtonProps } from 'react-aria-components';

import TableFieldIcon from '@/assets/field-type-table.svg?react';
import MaximizeIcon from '@/assets/maximize-icon.svg?react';
import { merge } from '@/components';
import { Button } from '@/components/core';
import { selectFromFieldConfig, useDocumentStore } from '@/store';
import { toString } from '@/utils';

import { FieldLabel } from './FieldLabel';

type TableRefFieldProps = ButtonProps & {
  name: string;
};

const ITableRefField = ({ name, className, ...rest }: TableRefFieldProps) => {
  const [hover, setHover] = useState(false);
  const config = useDocumentStore(selectFromFieldConfig(name));
  const formState = useDocumentStore((state) => state.form.state);

  const numRows = formState?.values?.[name]?.length;

  return (
    <div className="flex flex-col gap-1">
      <FieldLabel name={config?.name || name} description={toString(config?.description)} hideLabel={false} />

      <Button
        {...rest}
        variant="plain"
        className={merge(className, 'justify-start rounded-md border-1 border-gray-200 bg-gray-100')}
        onHoverChange={setHover}
      >
        <div className="flex w-full items-center gap-2">
          <TableFieldIcon stroke="currentColor" className="text-gray-400" />
          <div className="flex grow items-center gap-2 text-start">
            Rows
            <div className="flex h-6 w-6 items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-sm text-gray-600">
              {numRows}
            </div>
          </div>
          <MaximizeIcon
            stroke="currentColor"
            className="h-5 w-5 text-gray-400 aria-hidden:hidden"
            aria-hidden={!hover}
          />
        </div>
      </Button>
    </div>
  );
};

export const TableRefField = memo(ITableRefField) as typeof ITableRefField;