import { memo } from 'react';

import PowerAutomateIcon from '@/assets/power-automate-logo.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type PowerAutomateLogoProps = LogoProps;

const IPowerAutomateLogo = ({ className, ...rest }: PowerAutomateLogoProps) => {
  return (
    <Logo className={merge('rounded-lg border border-gray-200 bg-white', className)} {...rest}>
      <PowerAutomateIcon className="size-4" />
    </Logo>
  );
};

export const PowerAutomateLogo = memo(IPowerAutomateLogo) as typeof IPowerAutomateLogo;
