import { useReactFlow } from '@xyflow/react';
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { MenuTrigger } from 'react-aria-components';

import DotsIcon from '@/assets/dots-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Menu, MenuItem, MenuItemDelete, Popover, Save, Text } from '@/components/core';
import { ExportOption, NewExportModal } from '@/components/flow';
import { useAddExport } from '@/hooks';
import { toProjectId, useDeleteAction, useSuspenseGetProject, useUpdateProject } from '@/hooks/api';

export type ExportHeaderProps = {
  projectId: string;
  actionId: string;
  children?: ReactNode;
};

const IExportHeader = ({ projectId, actionId, children }: ExportHeaderProps) => {
  const [newExportModelOpened, setNewExportModalOpened] = useState(false);
  const { data: project } = useSuspenseGetProject(toProjectId(projectId));
  const builder = useReactFlow();
  const onSuccess = useCallback(() => {
    builder.setNodes((nodes) => nodes);
    setNewExportModalOpened(false);
  }, [builder]);
  const { addExport, isPending: isAddingExport } = useAddExport({ onSuccess });

  const { mutate: updateProject, isPending: isPendingUpdateProject } = useUpdateProject();
  const { mutate: deleteAction, isPending: isPendingDeleteAction } = useDeleteAction();

  const deleteExport = useCallback(() => {
    const updates = { resourceIds: project.resourceIds.filter((r) => ![actionId].includes(r)) };
    updateProject(
      { id: toProjectId(projectId), updates },
      {
        onSuccess: () => {
          // TODO: Need to also delete hooks
          deleteAction(
            { actionId },
            {
              onSuccess: () => {
                // TODO: Avoid force-triggering rerender of flow
                builder.setNodes((nodes) => nodes);
              },
            }
          );
        },
      }
    );
  }, [project.resourceIds, updateProject, projectId, actionId, deleteAction, builder]);

  const onExportOptionSelected = useCallback(
    async (exportOption: ExportOption) => {
      await addExport({ projectId, exportOption });
    },
    [addExport, projectId]
  );

  const isDeleting = useMemo(
    () => isPendingDeleteAction || isPendingUpdateProject,
    [isPendingDeleteAction, isPendingUpdateProject]
  );

  // TODO: Figure out why uncontrolled doesn't work here
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {children}
      {isDeleting ? (
        <Save message="Deleting" />
      ) : (
        <MenuTrigger>
          <Button className="hover:bg-gray-100" variant="plain" onPress={() => setIsOpen(true)}>
            <DotsIcon className="size-4 rotate-90 fill-gray-400" />
          </Button>
          <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
            <Menu>
              <MenuItemDelete onAction={deleteExport} />
              <MenuItem className="flex items-center gap-2" onAction={() => setNewExportModalOpened(true)}>
                <PlusIcon className="size-4 stroke-gray-500" />
                <Text size="sm">New export</Text>
              </MenuItem>
            </Menu>
          </Popover>
        </MenuTrigger>
      )}
      <NewExportModal
        isAddingExport={isAddingExport}
        isOpen={newExportModelOpened}
        onExportOptionSelected={onExportOptionSelected}
        onOpenChange={() => setNewExportModalOpened(false)}
      />
    </>
  );
};

export const ExportHeader = memo(IExportHeader) as typeof IExportHeader;
