import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Text } from '@/components/core';
import { TEMPLATES } from '@/components/ui';
import { TemplateThumbnail } from '@/features/projects';
import { OmitChildren } from '@/utils';

export type TemplateCardProps = OmitChildren<ButtonProps> & {
  template: keyof typeof TEMPLATES;
  isSelected?: boolean;
};

const ITemplateCard = ({ template, isSelected, className, ...rest }: TemplateCardProps) => {
  return (
    <Button
      className={merge(
        'flex flex-col items-center rounded-lg border border-gray-200 bg-gray-100 py-2 hover:bg-green-50',
        isSelected && 'border-green-300 bg-green-50 hover:bg-green-50',
        className
      )}
      variant="plain"
      {...rest}
    >
      <TemplateThumbnail template={template} />
      <Text className="font-semibold text-gray-500">{template}</Text>
    </Button>
  );
};

export const TemplateCard = memo(ITemplateCard) as typeof ITemplateCard;
