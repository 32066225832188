import { memo } from 'react';
import { NumberField as NumberField_, NumberFieldProps as NumberFieldProps_ } from 'react-aria-components';

import { merge } from '../utils';

export type NumberFieldProps = NumberFieldProps_;

export const INumberField = ({ className, children, ...rest }: NumberFieldProps) => {
  /* prettier-ignore */
  const styles = [
    'grow',
    'bg-white',
    'outline-none',
    'text-md',
  ];

  /* prettier-ignore */
  const lightStyles = [
    'text-gray-900',
  ];

  return (
    <NumberField_ className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </NumberField_>
  );
};

export const NumberField = memo(INumberField) as typeof INumberField;
