import { type Invoice } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type InvoiceId = { invoiceId: string };

export const { useList: useListInvoices } = create<Invoice, InvoiceId, object>({
  cacheKey: 'invoices',
  equal: (invoice, id) => Boolean(invoice && id?.invoiceId && invoice.invoiceId === id.invoiceId),
  createId: (resource) => (resource ? { invoiceId: resource.invoiceId } : null),
  get: (client, id) => client.getInvoice(id.invoiceId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listInvoices()
        .then((response: JSONType) => {
          resolve({ data: response.invoices, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
});

export const toInvoiceId = (invoiceId?: string | null): InvoiceId | null => {
  return invoiceId ? { invoiceId } : null;
};
