import { memo } from 'react';

import { Text } from '@/components/core';
import { ExcelLogo, ExportHeader, SetupRequired } from '@/components/flow';
import { toActionId, useGetAction } from '@/hooks/api';
import { isNotConfiguredExport, JSONType } from '@/utils';

export type ExcelExportNodeContentProps = {
  projectId: string;
  actionId: string;
  config?: JSONType;
};

const IExcelExportNodeContent = ({ projectId, actionId, config }: ExcelExportNodeContentProps) => {
  const { data: action } = useGetAction(toActionId(actionId));

  return (
    <>
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <ExportHeader projectId={projectId} actionId={actionId}>
          <ExcelLogo size="lg" />
          <Text className="flex-1 font-semibold" size="md">
            Export to Excel
          </Text>
        </ExportHeader>
      </div>
      <div className="flex gap-2 pt-2">
        {isNotConfiguredExport(action) ? (
          <SetupRequired />
        ) : (
          <Text className="flex flex-row flex-wrap items-center gap-2 text-gray-600" size="xs">
            Export files to:
            <Text className="flex items-center gap-1 rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
              {config?.documentId}
            </Text>
            <Text className="flex items-center gap-1 rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
              {config?.sheetId}
            </Text>
          </Text>
        )}
      </div>
    </>
  );
};

export const ExcelExportNodeContent = memo(IExcelExportNodeContent) as typeof IExcelExportNodeContent;
