import { memo } from 'react';

import ConfidenceIcon from '@/assets/confidence-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type ConfidenceLogoProps = VariantLogoProps;

const IConfidenceLogo = ({ ...rest }: ConfidenceLogoProps) => {
  return <FormatterValidatorLogo Icon={ConfidenceIcon} iconColoringType="stroke" {...rest} />;
};

export const ConfidenceLogo = memo(IConfidenceLogo) as typeof IConfidenceLogo;
