import { memo } from 'react';

import { Loading } from '@/components/core';
import {
  EmptyExportNodeContent,
  ExcelExportNodeContent,
  PowerAutomateExportNodeContent,
  WebhookExportNodeContent,
  ZapierExportNodeContent,
} from '@/components/flow';
import {
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { useGetProjectFromPath } from '@/hooks';
import { toActionId, useGetAction } from '@/hooks/api';

export type ExportNodeProps = {
  actionId?: string;
};

const IExportNode = ({ actionId }: ExportNodeProps) => {
  const { data: project, isPending: isPendingProject } = useGetProjectFromPath();
  const { data: action } = useGetAction(toActionId(actionId));

  if (isPendingProject) return <Loading label="Loading ..." />;
  if (!project) return null;

  return (
    <div className="w-72">
      {action?.functionId === EXPORT_TO_WEBHOOK_FUNCTION_ID ? (
        <WebhookExportNodeContent projectId={project.projectId} actionId={action.actionId} config={action.config} />
      ) : action?.functionId === EXPORT_TO_EXCEL_FUNCTION_ID ? (
        <ExcelExportNodeContent projectId={project.projectId} actionId={action.actionId} config={action.config} />
      ) : action?.functionId === EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID ? (
        <PowerAutomateExportNodeContent
          projectId={project.projectId}
          actionId={action.actionId}
          config={action.config}
        />
      ) : action?.functionId === EXPORT_TO_ZAPIER_FUNCTION_ID ? (
        <ZapierExportNodeContent projectId={project.projectId} actionId={action.actionId} config={action.config} />
      ) : (
        <EmptyExportNodeContent projectId={project.projectId} />
      )}
    </div>
  );
};

export const ExportNode = memo(IExportNode) as typeof IExportNode;
