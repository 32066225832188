import { createFileRoute, notFound, redirect } from '@tanstack/react-router';

import { listProjectsOptions } from '@/hooks/api';
import { withRetry } from '@/utils';

export const Route = createFileRoute('/_auth/projects/')({
  beforeLoad: async ({ context: { auth, queryClient } }) => {
    if (!auth.client) throw notFound();

    const { data: projects } = await withRetry(async () => {
      return await queryClient.ensureQueryData(listProjectsOptions(auth.client));
    }, 2000);

    const project = projects[0];
    if (project) {
      throw redirect({
        to: '/projects/$projectId',
        params: { projectId: project.projectId },
      });
    } else {
      throw redirect({ to: '/projects/new' });
    }
  },
});
