import { createFileRoute, notFound } from '@tanstack/react-router';

import { Profile } from '@/features/settings';

export const Route = createFileRoute('/_auth/settings/profile')({
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth.client) throw notFound();
  },
  component: Profile,
  notFoundComponent: () => {
    return <p>Profile not found!</p>;
  },
});
