import { useNavigate } from '@tanstack/react-router';
import { memo, useCallback } from 'react';
import { MenuTrigger, Separator } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { merge } from '@/components';
import { Button, ButtonProps, Menu, MenuItem, Popover, Text } from '@/components/core';
import { useGetProjectFromPath } from '@/hooks';
import { useListProjects } from '@/hooks/api';

export type ProjectsMenuProps = ButtonProps;

const IProjectsMenu = ({ className, children, ...rest }: ProjectsMenuProps) => {
  const { data: projects } = useListProjects();
  const navigate = useNavigate();
  const { data: currentProject } = useGetProjectFromPath();

  const onProjectSelected = useCallback(
    async (projectId: string) => {
      // A hacky way of working around a suspected bug in react-aria-components
      requestAnimationFrame(() => {
        navigate({ to: '/projects/$projectId', params: { projectId } });
      });
    },
    [navigate]
  );

  if (!projects) return null;

  return (
    <MenuTrigger>
      <Button variant="plain" className={merge('hover:bg-gray-100 data-[pressed]:bg-gray-100', className)} {...rest}>
        {children}
      </Button>
      <Popover>
        <Menu>
          {projects.data.map((p) => (
            <MenuItem
              className={currentProject?.projectId === p.projectId ? 'bg-brand-50' : ''}
              key={p.projectId}
              onAction={() => onProjectSelected(p.projectId)}
            >
              <Text slot="label" size="sm">
                {p.name}
              </Text>
            </MenuItem>
          ))}
          <Separator className="h-[1px] w-full bg-gray-200" orientation="horizontal" />
          <MenuItem className="flex items-center gap-2" onAction={() => navigate({ to: '/projects/new' })}>
            <PlusIcon className="size-4 stroke-gray-500" />
            <Text slot="label" size="sm">
              New project
            </Text>
          </MenuItem>
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

export const ProjectsMenu = memo(IProjectsMenu) as typeof IProjectsMenu;
