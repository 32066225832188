import { useNavigate } from '@tanstack/react-router';
import { memo, useContext } from 'react';
import { Dialog, OverlayTriggerStateContext, Tabs } from 'react-aria-components';

import ArrowRightIcon from '@/assets/arrow-right-icon.svg?react';
import CloseIcon from '@/assets/close-icon.svg?react';
import EmailIcon from '@/assets/email-icon.svg?react';
import { Button, CopyText, Header, Hr, Modal, ModalOverlay, Tab, TabList, TabPanel, Text } from '@/components/core';
import { ExcelLogo, PowerAutomateLogo, WebhookLogo } from '@/components/flow';
import { CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID } from '@/constants';
import { useProjectTree } from '@/hooks';

import { UploadDocumentsDropzone } from './UploadDocumentsDropzone.tsx';

export type ImportModalProps = {
  projectId: string;
};

const IImportModal = ({ projectId }: ImportModalProps) => {
  const { actions } = useProjectTree({ projectId });
  const overlayTriggerState = useContext(OverlayTriggerStateContext);
  const navigate = useNavigate();
  const email = actions.find((action) => action.functionId === CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID)?.config.email as
    | string
    | undefined;

  return (
    <ModalOverlay>
      <Modal>
        <Dialog className="outline-none">
          {({ close }) => (
            <div className="flex flex-col items-center gap-2 p-4">
              <div className="flex w-full flex-row items-center gap-2">
                <Header size="lg">Upload files</Header>
                <div className="grow" />
                <Button className="hover:bg-gray-100" variant="plain" onPress={close}>
                  <CloseIcon className="stroke-gray-400" />
                </Button>
              </div>
              <UploadDocumentsDropzone
                className="h-64 w-[500px]"
                projectId={projectId}
                onUploadSuccess={() => overlayTriggerState?.close()}
              />
              <div className="flex items-center gap-2">
                <Hr className="my-0 w-[140px]" />
                <Text size="sm">Or import automatically via</Text>
                <Hr className="my-0 w-[140px]" />
              </div>
              <Tabs className="w-full">
                <TabList className="py-2">
                  <Tab className="grow justify-center" id="email">
                    <EmailIcon className="size-4 stroke-gray-600" />
                    Email
                  </Tab>
                  <Tab className="grow justify-center" id="import">
                    <div className="flex -translate-x-6 gap-2">
                      <div className="flex gap-2">
                        <ExcelLogo className="translate-x-8" size="md" />
                        <PowerAutomateLogo className="z-10 translate-x-4" size="md" />
                        <WebhookLogo className="z-20" size="md" />
                      </div>
                      Integrations
                    </div>
                  </Tab>
                </TabList>
                <TabPanel id="email">
                  <div className="flex w-full flex-col items-start gap-2 py-2">
                    <Text size="sm">Send documents to the Cradl AI email inbox</Text>
                    <CopyText value={email} />
                  </div>
                </TabPanel>
                <TabPanel id="import">
                  <div className="flex w-full flex-col items-start gap-2 rounded-lg border border-gray-200 p-3">
                    <Header size="md">Integrate</Header>
                    <Text size="sm">Automatically import files from automation platforms or email</Text>
                    <Button
                      variant="secondary"
                      onPress={() => navigate({ to: '/projects/$projectId/integration', params: { projectId } })}
                    >
                      Go to flow builder
                      <ArrowRightIcon className="stroke-gray-400" />
                    </Button>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const ImportModal = memo(IImportModal) as typeof IImportModal;
