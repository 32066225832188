import { createFileRoute } from '@tanstack/react-router';

import { NavigationBar } from '@/features/projects';

export const Route = createFileRoute('/_auth/projects/$projectId')({
  component: NavigationBar,
  notFoundComponent: () => {
    return <p>Project not found!</p>;
  },
});
