import { useMemo } from 'react';

import { toDocumentId, toProjectRunId, useBatchGetDocument, useGetProjectRun } from '@/hooks/api';
import { isDocumentId } from '@/utils';

export type UseProjectRunTreeOpts = {
  projectId: string;
  runId: string;
};

export const useProjectRunTree = ({ projectId, runId }: UseProjectRunTreeOpts) => {
  const { data: run } = useGetProjectRun(toProjectRunId(projectId, runId), 5000);
  const documentIds = useMemo(() => run?.resourceIds.filter(isDocumentId), [run]);
  const { data: documents } = useBatchGetDocument(documentIds?.map(toDocumentId));

  return {
    emailDocument: documents.find((d) => d.contentType === 'message/rfc822'),
    taskDocument: documents.find((d) => d.contentType !== 'message/rfc822'),
  };
};
