import { memo } from 'react';

import { merge } from '@/components';
import { HrProps as HrProps_ } from '@/utils';

export type HrProps = HrProps_;

const IHr = ({ className, ...rest }: HrProps) => {
  return <hr className={merge('text-gray-200', className)} {...rest} />;
};

export const Hr = memo(IHr) as typeof IHr;
