import { memo, useEffect, useState } from 'react';

import { Button, Header, Input, Label, TextField } from '@/components/core';
import { toProfileId, useGetProfile } from '@/hooks/api';

import { Step } from '../projects';
import { useWizard } from './Wizard';

type ContactDetailsProps = object;

// eslint-disable-next-line no-empty-pattern
const IContactDetails = ({}: ContactDetailsProps) => {
  const wizard = useWizard();

  const [firstName, setFirstName] = useState<string>(wizard.state.firstName || '');
  const [lastName, setLastName] = useState<string>(wizard.state.lastName || '');
  const [organizationName, setOrganizationName] = useState<string>(wizard.state.organizationName || '');
  const [organizationUrl, setOrganizationUrl] = useState<string>(wizard.state.organizationUrl || '');

  const { data: profile } = useGetProfile(toProfileId('me'));

  useEffect(() => {
    if (profile) {
      setFirstName(profile.givenName ?? '');
      setLastName(profile.familyName ?? '');
    }
  }, [profile]);

  const getInputProps = (set: (v: string) => void, defaultValue?: string | null) => ({
    defaultValue: defaultValue ?? undefined,
    onInput: (e: React.FormEvent<HTMLInputElement>) => set(e.currentTarget.value),
  });

  return (
    <Step>
      <Header size="xl">Let's start with the basics</Header>

      {profile && (
        <>
          <div className="flex flex-row gap-2">
            <TextField {...getInputProps(setFirstName, firstName || profile?.givenName)}>
              <Label>First name</Label>
              <Input placeholder="John" />
            </TextField>

            <TextField {...getInputProps(setLastName, lastName || profile?.familyName)}>
              <Label>Last name</Label>
              <Input placeholder="Doe" />
            </TextField>
          </div>
        </>
      )}

      <TextField {...getInputProps(setOrganizationName, organizationName)}>
        <Label>Organization name</Label>
        <Input placeholder="MyCompany Inc" />
      </TextField>

      <TextField {...getInputProps(setOrganizationUrl, organizationUrl)}>
        <Label>Organization URL</Label>
        <Input placeholder="mycompany.com" />
      </TextField>

      <Button
        onPress={() =>
          wizard.next({
            firstName,
            lastName,
            organizationName,
            organizationUrl,
          })
        }
      >
        Continue
      </Button>
    </Step>
  );
};

export const ContactDetails = memo(IContactDetails) as typeof IContactDetails;
