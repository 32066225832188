import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Loading, Text } from '@/components/core';
import { OrganizationLogo } from '@/components/ui';
import { toOrganizationId, useGetOrganization } from '@/hooks/api';
import { OmitChildren } from '@/utils';

export type OrganizationCardProps = OmitChildren<ButtonProps> & {
  organizationId: string;
};

const IOrganizationCard = ({ organizationId, className, ...rest }: OrganizationCardProps) => {
  const { data: organization } = useGetOrganization(toOrganizationId(organizationId));

  if (!organization) return <Loading label="Fetching workspace..." />;

  return (
    <Button className={merge('flex items-center justify-start gap-2', className)} variant="plain" {...rest}>
      <OrganizationLogo size="xl" organizationId={organization.organizationId} />
      <Text>{organization.name}</Text>
    </Button>
  );
};

export const OrganizationCard = memo(IOrganizationCard) as typeof IOrganizationCard;
