import type { FieldFormatter } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { merge } from '@/components';
import { ComingSoon, MenuItem, MenuItemProps, Text } from '@/components/core';
import { FormatterLogo } from '@/components/ui';
import { OmitChildren } from '@/utils';

export type FieldFormatterWithComingSoon = FieldFormatter & { comingSoon?: boolean };

export type FormatterMenuItemProps = OmitChildren<MenuItemProps> & {
  formatter: FieldFormatterWithComingSoon;
};

const IFormatterMenuItem = ({ formatter, className, ...rest }: FormatterMenuItemProps) => {
  return (
    <MenuItem
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-2', className)}
      {...rest}
    >
      <FormatterLogo formatter={formatter} size="xl" />
      <div className="flex flex-1 flex-col">
        <Text>{formatter.name}</Text>
        <Text size="xs">{formatter.description}</Text>
      </div>
      {formatter.comingSoon ? <ComingSoon /> : null}
    </MenuItem>
  );
};

export const FormatterMenuItem = memo(IFormatterMenuItem) as typeof IFormatterMenuItem;
