import type { ProjectRun } from '@lucidtech/las-sdk-browser';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import {
  AccessorKeyColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { DialogTrigger } from 'react-aria-components';

import ImportIcon from '@/assets/import-icon.svg?react';
import ReviewIcon from '@/assets/review-icon.svg?react';
import SortAscendingIcon from '@/assets/sort-ascending-icon.svg?react';
import SortDescendingIcon from '@/assets/sort-descending-icon.svg?react';
import { merge } from '@/components';
import { Button, Checkbox, Search, Text } from '@/components/core';
import { toProjectRunId, useFilteredProjectRunsQuery } from '@/hooks/api';
import { SortingUpdates, useInboxStore } from '@/store';
import { DivProps, TableProps, TableRowProps } from '@/utils';

import { DateFilterCard } from './DateFilterCard.tsx';
import { DateRangeFilterCard } from './DateRangeFilterCard.tsx';
import { ImportModal } from './ImportModal.tsx';
import { InboxFilter } from './InboxFilter';
import { InboxSort } from './InboxSort';
import { ProjectRunsEmpty } from './ProjectRunsEmpty.tsx';
import { RunsRow } from './RunsRow.tsx';
import { SelectedRunId } from './SelectedRunId';
import { StringFilterCard } from './StringFilterCard.tsx';

export type ProjectRunsTableProps = TableProps & {
  projectId: string;
  hideHeaders?: boolean;
  hideSelectionColumn?: boolean;
  columns: AccessorKeyColumnDef<ProjectRun>[];
  props?: {
    outerContainer?: DivProps;
    row?: TableRowProps;
  };
};

const IProjectRunsTable = ({
  className,
  projectId,
  hideHeaders,
  hideSelectionColumn,
  columns,
  props,
  ...rest
}: ProjectRunsTableProps) => {
  const sorting = useInboxStore((state) => state.sorting);
  const updateSorting = useInboxStore((state) => state.updateSorting);
  const filters = useInboxStore((state) => state.filters);
  const setSelectedRunIds = useInboxStore((state) => state.setSelectedRunIds);
  const search = useInboxStore((state) => state.search);
  const setSearch = useInboxStore((state) => state.setSearch);

  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const matchedRoute = matchRoute({ to: '/details/projects/$projectId/runs/$runId' });

  const { data, fetchNextPage, isFetching, isLoading } = useFilteredProjectRunsQuery({ projectId });
  const runs = useMemo(() => data?.pages?.flatMap((page) => page.data) ?? [], [data]);
  const totalWaitingForReview = useMemo(
    () => data?.pages?.flatMap((page) => page.data).filter((r) => r.status === 'Ready for review').length ?? 0,
    [data]
  );
  const firstWaitingForReview = useMemo(
    () => data?.pages?.flatMap((page) => page.data).filter((r) => r.status === 'Ready for review')[0],
    [data]
  );
  const nextToken = useMemo(() => data?.pages?.at(-1)?.nextToken, [data?.pages]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < window.innerHeight * 0.9 && !isFetching && nextToken) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, nextToken]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const columns_ = useMemo<AccessorKeyColumnDef<ProjectRun>[]>(() => {
    const leftColumns: AccessorKeyColumnDef<ProjectRun>[] = hideSelectionColumn
      ? []
      : [
          {
            accessorKey: 'runId',
            header: () => (
              <Checkbox
                onChange={(selected) =>
                  selected ? setSelectedRunIds(runs.map((r) => r.runId)) : setSelectedRunIds([])
                }
              />
            ),
            id: 'selected',
            cell: ({ row }) => <SelectedRunId runId={row.original.runId} />,
            size: 40,
          },
        ];
    return [...leftColumns, ...columns];
  }, [columns, hideSelectionColumn, runs, setSelectedRunIds]);

  const sortingState = useMemo(() => sorting.filter((s) => !s.hidden), [sorting]);

  const table = useReactTable({
    data: runs,
    columns: columns_,
    state: {
      sorting: sortingState,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    isMultiSortEvent: () => true,
    manualSorting: false,
  });

  const { rows } = table.getRowModel();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 40,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  });

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const updates: SortingUpdates = {};
      for (const s of sorting.filter((s) => !s.hidden)) {
        updates[s.id] = { ...s, hidden: true };
      }
      for (const update of updater(sortingState) as SortingState) {
        updates[update.id] = { hidden: false, desc: update.desc };
      }
      updateSorting(updates);
      if (table.getRowModel().rows.length) {
        rowVirtualizer.scrollToIndex?.(0);
      }
    },
    [rowVirtualizer, sorting, sortingState, table, updateSorting]
  );

  const onReview = useCallback(async () => {
    const projectRunId = toProjectRunId(projectId, firstWaitingForReview?.runId);
    if (projectRunId) {
      await navigate({
        to: '/details/projects/$projectId/runs/$runId',
        params: projectRunId,
        search: { showPanel: 'project-runs' },
      });
    }
  }, [firstWaitingForReview?.runId, navigate, projectId]);

  const clearSearch = useCallback(() => setSearch(''), [setSearch]);

  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  const { className: outerContainerClassName, ...outerContainerProps } = props?.outerContainer ?? {};

  return (
    <div
      className={merge('w-full overflow-auto rounded-lg border border-gray-200', outerContainerClassName)}
      ref={tableContainerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      {...outerContainerProps}
    >
      <table className={merge('grid', className)} {...rest}>
        {!hideHeaders ? (
          <thead className="sticky top-0 z-10 grid border-b border-gray-200 bg-gray-50 text-sm text-gray-500">
            <tr className="border-b border-gray-200 bg-white">
              <th className="flex">
                <div className="flex w-full items-center gap-2 p-2">
                  <Search
                    clear={clearSearch}
                    props={{
                      input: {
                        value: search,
                        onChange: (e) => setSearch(e.target.value),
                        placeholder: 'Search...',
                      },
                      searchField: { className: 'h-full' },
                    }}
                  />
                  <InboxFilter />
                  <InboxSort />
                  <div className="p-2 text-gray-300">
                    {isLoading ? 'Refreshing...' : isFetching ? 'Fetching more data...' : null}
                  </div>
                  <div className="grow" />
                  <DialogTrigger>
                    <Button variant="secondary">
                      <ImportIcon className="size-5 stroke-gray-700" />
                      Import
                    </Button>
                    <ImportModal projectId={projectId} />
                  </DialogTrigger>
                  <Button isDisabled={totalWaitingForReview < 1} onPress={onReview}>
                    <ReviewIcon className="stroke-white" />
                    Review
                    {totalWaitingForReview > 0 ? (
                      <div className="bg-brand-500 flex items-center justify-center rounded-lg border border-gray-200 p-1">
                        <Text className="text-white" size="xs">
                          {totalWaitingForReview}
                        </Text>
                      </div>
                    ) : null}
                  </Button>
                </div>
              </th>
            </tr>
            {filters.length ? (
              <tr className="flex items-center border-b border-gray-200 bg-white p-2">
                {filters.map((f) => (
                  <th key={f.id}>
                    {f.type === 'date-range' ? (
                      <DateRangeFilterCard columns={columns.map((c) => c.accessorKey)} filter={f} />
                    ) : null}
                    {f.type === 'date' ? (
                      <DateFilterCard columns={columns.map((c) => c.accessorKey)} filter={f} />
                    ) : null}
                    {f.type === 'string' ? (
                      <StringFilterCard columns={columns.map((c) => c.accessorKey)} filter={f} />
                    ) : null}
                  </th>
                ))}
              </tr>
            ) : null}
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="flex w-full px-8 py-3">
                {headerGroup.headers.map((header) => {
                  return (
                    <th className="flex" key={header.id} style={{ width: header.getSize() }}>
                      <div
                        className={merge(
                          'flex flex-row gap-2',
                          header.column.getCanSort() && 'cursor-pointer select-none'
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortAscendingIcon className="size-5 stroke-gray-400" />,
                          desc: <SortDescendingIcon className="size-5 stroke-gray-400" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        ) : null}
        {rowVirtualizer.getTotalSize() < 1 ? (
          <tbody>
            <ProjectRunsEmpty projectId={projectId} />
          </tbody>
        ) : (
          <tbody className="relative grid" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<ProjectRun>;
              // TODO: Should selected state be a property on row.original ?
              const selected = matchedRoute && matchedRoute.runId === row.original.runId;

              return (
                <RunsRow
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  row={row}
                  selected={selected}
                  runId={row.original.runId}
                  projectId={projectId}
                  measureFn={rowVirtualizer.measureElement}
                  rowProps={props?.row}
                />
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export const ProjectRunsTable = memo(IProjectRunsTable) as typeof IProjectRunsTable;
