import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import * as React from 'react';

import type { AuthContextInterface, GlobalAuthContextInterface } from '@/features/auth';

interface AuthRouterContext {
  auth: AuthContextInterface;
  globalAuth: GlobalAuthContextInterface;
  queryClient: QueryClient;
}

let TanStackRouterDevtools;
if (process.env.NODE_ENV === 'production') {
  TanStackRouterDevtools = () => null;
} else {
  TanStackRouterDevtools = React.lazy(() =>
    import('@tanstack/react-router-devtools').then((res) => ({
      default: res.TanStackRouterDevtools,
    }))
  );
}

export const Route = createRootRouteWithContext<AuthRouterContext>()({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});
