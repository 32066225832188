/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as GlobalAuthCallbackImport } from './routes/globalAuthCallback'
import { Route as AuthCallbackImport } from './routes/authCallback'
import { Route as GlobalAuthImport } from './routes/_globalAuth'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as GlobalAuthOrganizationsImport } from './routes/_globalAuth.organizations'
import { Route as AuthSettingsImport } from './routes/_auth.settings'
import { Route as GlobalAuthOrganizationsIndexImport } from './routes/_globalAuth.organizations.index'
import { Route as GlobalAuthOnboardingIndexImport } from './routes/_globalAuth.onboarding.index'
import { Route as AuthSettingsIndexImport } from './routes/_auth.settings.index'
import { Route as AuthProjectsIndexImport } from './routes/_auth.projects.index'
import { Route as GlobalAuthOrganizationsNewImport } from './routes/_globalAuth.organizations.new'
import { Route as AuthSettingsWorkspaceImport } from './routes/_auth.settings.workspace'
import { Route as AuthSettingsProfileImport } from './routes/_auth.settings.profile'
import { Route as AuthSettingsBillingImport } from './routes/_auth.settings.billing'
import { Route as AuthProjectsNewImport } from './routes/_auth.projects.new'
import { Route as AuthProjectsProjectIdImport } from './routes/_auth.projects.$projectId'
import { Route as AuthProjectsProjectIdIndexImport } from './routes/_auth.projects.$projectId.index'
import { Route as AuthProjectsProjectIdIntegrationImport } from './routes/_auth.projects.$projectId.integration'
import { Route as AuthProjectsProjectIdInboxImport } from './routes/_auth.projects.$projectId.inbox'
import { Route as AuthProjectsProjectIdAnalyticsImport } from './routes/_auth.projects.$projectId.analytics'
import { Route as AuthDetailsProjectsProjectIdImport } from './routes/_auth.details.projects.$projectId'
import { Route as AuthDetailsProjectsProjectIdRunsRunIdImport } from './routes/_auth.details.projects.$projectId.runs.$runId'

// Create/Update Routes

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const GlobalAuthCallbackRoute = GlobalAuthCallbackImport.update({
  id: '/globalAuthCallback',
  path: '/globalAuthCallback',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/authCallback',
  path: '/authCallback',
  getParentRoute: () => rootRoute,
} as any)

const GlobalAuthRoute = GlobalAuthImport.update({
  id: '/_globalAuth',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const GlobalAuthOrganizationsRoute = GlobalAuthOrganizationsImport.update({
  id: '/organizations',
  path: '/organizations',
  getParentRoute: () => GlobalAuthRoute,
} as any)

const AuthSettingsRoute = AuthSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthRoute,
} as any)

const GlobalAuthOrganizationsIndexRoute =
  GlobalAuthOrganizationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => GlobalAuthOrganizationsRoute,
  } as any)

const GlobalAuthOnboardingIndexRoute = GlobalAuthOnboardingIndexImport.update({
  id: '/onboarding/',
  path: '/onboarding/',
  getParentRoute: () => GlobalAuthRoute,
} as any)

const AuthSettingsIndexRoute = AuthSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthProjectsIndexRoute = AuthProjectsIndexImport.update({
  id: '/projects/',
  path: '/projects/',
  getParentRoute: () => AuthRoute,
} as any)

const GlobalAuthOrganizationsNewRoute = GlobalAuthOrganizationsNewImport.update(
  {
    id: '/new',
    path: '/new',
    getParentRoute: () => GlobalAuthOrganizationsRoute,
  } as any,
)

const AuthSettingsWorkspaceRoute = AuthSettingsWorkspaceImport.update({
  id: '/workspace',
  path: '/workspace',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthSettingsProfileRoute = AuthSettingsProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthSettingsBillingRoute = AuthSettingsBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthProjectsNewRoute = AuthProjectsNewImport.update({
  id: '/projects/new',
  path: '/projects/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthProjectsProjectIdRoute = AuthProjectsProjectIdImport.update({
  id: '/projects/$projectId',
  path: '/projects/$projectId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthProjectsProjectIdIndexRoute = AuthProjectsProjectIdIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthProjectsProjectIdRoute,
  } as any,
)

const AuthProjectsProjectIdIntegrationRoute =
  AuthProjectsProjectIdIntegrationImport.update({
    id: '/integration',
    path: '/integration',
    getParentRoute: () => AuthProjectsProjectIdRoute,
  } as any)

const AuthProjectsProjectIdInboxRoute = AuthProjectsProjectIdInboxImport.update(
  {
    id: '/inbox',
    path: '/inbox',
    getParentRoute: () => AuthProjectsProjectIdRoute,
  } as any,
)

const AuthProjectsProjectIdAnalyticsRoute =
  AuthProjectsProjectIdAnalyticsImport.update({
    id: '/analytics',
    path: '/analytics',
    getParentRoute: () => AuthProjectsProjectIdRoute,
  } as any)

const AuthDetailsProjectsProjectIdRoute =
  AuthDetailsProjectsProjectIdImport.update({
    id: '/details/projects/$projectId',
    path: '/details/projects/$projectId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDetailsProjectsProjectIdRunsRunIdRoute =
  AuthDetailsProjectsProjectIdRunsRunIdImport.update({
    id: '/runs/$runId',
    path: '/runs/$runId',
    getParentRoute: () => AuthDetailsProjectsProjectIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_globalAuth': {
      id: '/_globalAuth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GlobalAuthImport
      parentRoute: typeof rootRoute
    }
    '/authCallback': {
      id: '/authCallback'
      path: '/authCallback'
      fullPath: '/authCallback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/globalAuthCallback': {
      id: '/globalAuthCallback'
      path: '/globalAuthCallback'
      fullPath: '/globalAuthCallback'
      preLoaderRoute: typeof GlobalAuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/_auth/settings': {
      id: '/_auth/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSettingsImport
      parentRoute: typeof AuthImport
    }
    '/_globalAuth/organizations': {
      id: '/_globalAuth/organizations'
      path: '/organizations'
      fullPath: '/organizations'
      preLoaderRoute: typeof GlobalAuthOrganizationsImport
      parentRoute: typeof GlobalAuthImport
    }
    '/_auth/projects/$projectId': {
      id: '/_auth/projects/$projectId'
      path: '/projects/$projectId'
      fullPath: '/projects/$projectId'
      preLoaderRoute: typeof AuthProjectsProjectIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/projects/new': {
      id: '/_auth/projects/new'
      path: '/projects/new'
      fullPath: '/projects/new'
      preLoaderRoute: typeof AuthProjectsNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings/billing': {
      id: '/_auth/settings/billing'
      path: '/billing'
      fullPath: '/settings/billing'
      preLoaderRoute: typeof AuthSettingsBillingImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/settings/profile': {
      id: '/_auth/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof AuthSettingsProfileImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/settings/workspace': {
      id: '/_auth/settings/workspace'
      path: '/workspace'
      fullPath: '/settings/workspace'
      preLoaderRoute: typeof AuthSettingsWorkspaceImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_globalAuth/organizations/new': {
      id: '/_globalAuth/organizations/new'
      path: '/new'
      fullPath: '/organizations/new'
      preLoaderRoute: typeof GlobalAuthOrganizationsNewImport
      parentRoute: typeof GlobalAuthOrganizationsImport
    }
    '/_auth/projects/': {
      id: '/_auth/projects/'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AuthProjectsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings/': {
      id: '/_auth/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AuthSettingsIndexImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_globalAuth/onboarding/': {
      id: '/_globalAuth/onboarding/'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof GlobalAuthOnboardingIndexImport
      parentRoute: typeof GlobalAuthImport
    }
    '/_globalAuth/organizations/': {
      id: '/_globalAuth/organizations/'
      path: '/'
      fullPath: '/organizations/'
      preLoaderRoute: typeof GlobalAuthOrganizationsIndexImport
      parentRoute: typeof GlobalAuthOrganizationsImport
    }
    '/_auth/details/projects/$projectId': {
      id: '/_auth/details/projects/$projectId'
      path: '/details/projects/$projectId'
      fullPath: '/details/projects/$projectId'
      preLoaderRoute: typeof AuthDetailsProjectsProjectIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/projects/$projectId/analytics': {
      id: '/_auth/projects/$projectId/analytics'
      path: '/analytics'
      fullPath: '/projects/$projectId/analytics'
      preLoaderRoute: typeof AuthProjectsProjectIdAnalyticsImport
      parentRoute: typeof AuthProjectsProjectIdImport
    }
    '/_auth/projects/$projectId/inbox': {
      id: '/_auth/projects/$projectId/inbox'
      path: '/inbox'
      fullPath: '/projects/$projectId/inbox'
      preLoaderRoute: typeof AuthProjectsProjectIdInboxImport
      parentRoute: typeof AuthProjectsProjectIdImport
    }
    '/_auth/projects/$projectId/integration': {
      id: '/_auth/projects/$projectId/integration'
      path: '/integration'
      fullPath: '/projects/$projectId/integration'
      preLoaderRoute: typeof AuthProjectsProjectIdIntegrationImport
      parentRoute: typeof AuthProjectsProjectIdImport
    }
    '/_auth/projects/$projectId/': {
      id: '/_auth/projects/$projectId/'
      path: '/'
      fullPath: '/projects/$projectId/'
      preLoaderRoute: typeof AuthProjectsProjectIdIndexImport
      parentRoute: typeof AuthProjectsProjectIdImport
    }
    '/_auth/details/projects/$projectId/runs/$runId': {
      id: '/_auth/details/projects/$projectId/runs/$runId'
      path: '/runs/$runId'
      fullPath: '/details/projects/$projectId/runs/$runId'
      preLoaderRoute: typeof AuthDetailsProjectsProjectIdRunsRunIdImport
      parentRoute: typeof AuthDetailsProjectsProjectIdImport
    }
  }
}

// Create and export the route tree

interface AuthSettingsRouteChildren {
  AuthSettingsBillingRoute: typeof AuthSettingsBillingRoute
  AuthSettingsProfileRoute: typeof AuthSettingsProfileRoute
  AuthSettingsWorkspaceRoute: typeof AuthSettingsWorkspaceRoute
  AuthSettingsIndexRoute: typeof AuthSettingsIndexRoute
}

const AuthSettingsRouteChildren: AuthSettingsRouteChildren = {
  AuthSettingsBillingRoute: AuthSettingsBillingRoute,
  AuthSettingsProfileRoute: AuthSettingsProfileRoute,
  AuthSettingsWorkspaceRoute: AuthSettingsWorkspaceRoute,
  AuthSettingsIndexRoute: AuthSettingsIndexRoute,
}

const AuthSettingsRouteWithChildren = AuthSettingsRoute._addFileChildren(
  AuthSettingsRouteChildren,
)

interface AuthProjectsProjectIdRouteChildren {
  AuthProjectsProjectIdAnalyticsRoute: typeof AuthProjectsProjectIdAnalyticsRoute
  AuthProjectsProjectIdInboxRoute: typeof AuthProjectsProjectIdInboxRoute
  AuthProjectsProjectIdIntegrationRoute: typeof AuthProjectsProjectIdIntegrationRoute
  AuthProjectsProjectIdIndexRoute: typeof AuthProjectsProjectIdIndexRoute
}

const AuthProjectsProjectIdRouteChildren: AuthProjectsProjectIdRouteChildren = {
  AuthProjectsProjectIdAnalyticsRoute: AuthProjectsProjectIdAnalyticsRoute,
  AuthProjectsProjectIdInboxRoute: AuthProjectsProjectIdInboxRoute,
  AuthProjectsProjectIdIntegrationRoute: AuthProjectsProjectIdIntegrationRoute,
  AuthProjectsProjectIdIndexRoute: AuthProjectsProjectIdIndexRoute,
}

const AuthProjectsProjectIdRouteWithChildren =
  AuthProjectsProjectIdRoute._addFileChildren(
    AuthProjectsProjectIdRouteChildren,
  )

interface AuthDetailsProjectsProjectIdRouteChildren {
  AuthDetailsProjectsProjectIdRunsRunIdRoute: typeof AuthDetailsProjectsProjectIdRunsRunIdRoute
}

const AuthDetailsProjectsProjectIdRouteChildren: AuthDetailsProjectsProjectIdRouteChildren =
  {
    AuthDetailsProjectsProjectIdRunsRunIdRoute:
      AuthDetailsProjectsProjectIdRunsRunIdRoute,
  }

const AuthDetailsProjectsProjectIdRouteWithChildren =
  AuthDetailsProjectsProjectIdRoute._addFileChildren(
    AuthDetailsProjectsProjectIdRouteChildren,
  )

interface AuthRouteChildren {
  AuthSettingsRoute: typeof AuthSettingsRouteWithChildren
  AuthProjectsProjectIdRoute: typeof AuthProjectsProjectIdRouteWithChildren
  AuthProjectsNewRoute: typeof AuthProjectsNewRoute
  AuthProjectsIndexRoute: typeof AuthProjectsIndexRoute
  AuthDetailsProjectsProjectIdRoute: typeof AuthDetailsProjectsProjectIdRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSettingsRoute: AuthSettingsRouteWithChildren,
  AuthProjectsProjectIdRoute: AuthProjectsProjectIdRouteWithChildren,
  AuthProjectsNewRoute: AuthProjectsNewRoute,
  AuthProjectsIndexRoute: AuthProjectsIndexRoute,
  AuthDetailsProjectsProjectIdRoute:
    AuthDetailsProjectsProjectIdRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface GlobalAuthOrganizationsRouteChildren {
  GlobalAuthOrganizationsNewRoute: typeof GlobalAuthOrganizationsNewRoute
  GlobalAuthOrganizationsIndexRoute: typeof GlobalAuthOrganizationsIndexRoute
}

const GlobalAuthOrganizationsRouteChildren: GlobalAuthOrganizationsRouteChildren =
  {
    GlobalAuthOrganizationsNewRoute: GlobalAuthOrganizationsNewRoute,
    GlobalAuthOrganizationsIndexRoute: GlobalAuthOrganizationsIndexRoute,
  }

const GlobalAuthOrganizationsRouteWithChildren =
  GlobalAuthOrganizationsRoute._addFileChildren(
    GlobalAuthOrganizationsRouteChildren,
  )

interface GlobalAuthRouteChildren {
  GlobalAuthOrganizationsRoute: typeof GlobalAuthOrganizationsRouteWithChildren
  GlobalAuthOnboardingIndexRoute: typeof GlobalAuthOnboardingIndexRoute
}

const GlobalAuthRouteChildren: GlobalAuthRouteChildren = {
  GlobalAuthOrganizationsRoute: GlobalAuthOrganizationsRouteWithChildren,
  GlobalAuthOnboardingIndexRoute: GlobalAuthOnboardingIndexRoute,
}

const GlobalAuthRouteWithChildren = GlobalAuthRoute._addFileChildren(
  GlobalAuthRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof GlobalAuthRouteWithChildren
  '/authCallback': typeof AuthCallbackRoute
  '/globalAuthCallback': typeof GlobalAuthCallbackRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/settings': typeof AuthSettingsRouteWithChildren
  '/organizations': typeof GlobalAuthOrganizationsRouteWithChildren
  '/projects/$projectId': typeof AuthProjectsProjectIdRouteWithChildren
  '/projects/new': typeof AuthProjectsNewRoute
  '/settings/billing': typeof AuthSettingsBillingRoute
  '/settings/profile': typeof AuthSettingsProfileRoute
  '/settings/workspace': typeof AuthSettingsWorkspaceRoute
  '/organizations/new': typeof GlobalAuthOrganizationsNewRoute
  '/projects': typeof AuthProjectsIndexRoute
  '/settings/': typeof AuthSettingsIndexRoute
  '/onboarding': typeof GlobalAuthOnboardingIndexRoute
  '/organizations/': typeof GlobalAuthOrganizationsIndexRoute
  '/details/projects/$projectId': typeof AuthDetailsProjectsProjectIdRouteWithChildren
  '/projects/$projectId/analytics': typeof AuthProjectsProjectIdAnalyticsRoute
  '/projects/$projectId/inbox': typeof AuthProjectsProjectIdInboxRoute
  '/projects/$projectId/integration': typeof AuthProjectsProjectIdIntegrationRoute
  '/projects/$projectId/': typeof AuthProjectsProjectIdIndexRoute
  '/details/projects/$projectId/runs/$runId': typeof AuthDetailsProjectsProjectIdRunsRunIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof GlobalAuthRouteWithChildren
  '/authCallback': typeof AuthCallbackRoute
  '/globalAuthCallback': typeof GlobalAuthCallbackRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/projects/new': typeof AuthProjectsNewRoute
  '/settings/billing': typeof AuthSettingsBillingRoute
  '/settings/profile': typeof AuthSettingsProfileRoute
  '/settings/workspace': typeof AuthSettingsWorkspaceRoute
  '/organizations/new': typeof GlobalAuthOrganizationsNewRoute
  '/projects': typeof AuthProjectsIndexRoute
  '/settings': typeof AuthSettingsIndexRoute
  '/onboarding': typeof GlobalAuthOnboardingIndexRoute
  '/organizations': typeof GlobalAuthOrganizationsIndexRoute
  '/details/projects/$projectId': typeof AuthDetailsProjectsProjectIdRouteWithChildren
  '/projects/$projectId/analytics': typeof AuthProjectsProjectIdAnalyticsRoute
  '/projects/$projectId/inbox': typeof AuthProjectsProjectIdInboxRoute
  '/projects/$projectId/integration': typeof AuthProjectsProjectIdIntegrationRoute
  '/projects/$projectId': typeof AuthProjectsProjectIdIndexRoute
  '/details/projects/$projectId/runs/$runId': typeof AuthDetailsProjectsProjectIdRunsRunIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_globalAuth': typeof GlobalAuthRouteWithChildren
  '/authCallback': typeof AuthCallbackRoute
  '/globalAuthCallback': typeof GlobalAuthCallbackRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/_auth/settings': typeof AuthSettingsRouteWithChildren
  '/_globalAuth/organizations': typeof GlobalAuthOrganizationsRouteWithChildren
  '/_auth/projects/$projectId': typeof AuthProjectsProjectIdRouteWithChildren
  '/_auth/projects/new': typeof AuthProjectsNewRoute
  '/_auth/settings/billing': typeof AuthSettingsBillingRoute
  '/_auth/settings/profile': typeof AuthSettingsProfileRoute
  '/_auth/settings/workspace': typeof AuthSettingsWorkspaceRoute
  '/_globalAuth/organizations/new': typeof GlobalAuthOrganizationsNewRoute
  '/_auth/projects/': typeof AuthProjectsIndexRoute
  '/_auth/settings/': typeof AuthSettingsIndexRoute
  '/_globalAuth/onboarding/': typeof GlobalAuthOnboardingIndexRoute
  '/_globalAuth/organizations/': typeof GlobalAuthOrganizationsIndexRoute
  '/_auth/details/projects/$projectId': typeof AuthDetailsProjectsProjectIdRouteWithChildren
  '/_auth/projects/$projectId/analytics': typeof AuthProjectsProjectIdAnalyticsRoute
  '/_auth/projects/$projectId/inbox': typeof AuthProjectsProjectIdInboxRoute
  '/_auth/projects/$projectId/integration': typeof AuthProjectsProjectIdIntegrationRoute
  '/_auth/projects/$projectId/': typeof AuthProjectsProjectIdIndexRoute
  '/_auth/details/projects/$projectId/runs/$runId': typeof AuthDetailsProjectsProjectIdRunsRunIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/authCallback'
    | '/globalAuthCallback'
    | '/login'
    | '/logout'
    | '/settings'
    | '/organizations'
    | '/projects/$projectId'
    | '/projects/new'
    | '/settings/billing'
    | '/settings/profile'
    | '/settings/workspace'
    | '/organizations/new'
    | '/projects'
    | '/settings/'
    | '/onboarding'
    | '/organizations/'
    | '/details/projects/$projectId'
    | '/projects/$projectId/analytics'
    | '/projects/$projectId/inbox'
    | '/projects/$projectId/integration'
    | '/projects/$projectId/'
    | '/details/projects/$projectId/runs/$runId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/authCallback'
    | '/globalAuthCallback'
    | '/login'
    | '/logout'
    | '/projects/new'
    | '/settings/billing'
    | '/settings/profile'
    | '/settings/workspace'
    | '/organizations/new'
    | '/projects'
    | '/settings'
    | '/onboarding'
    | '/organizations'
    | '/details/projects/$projectId'
    | '/projects/$projectId/analytics'
    | '/projects/$projectId/inbox'
    | '/projects/$projectId/integration'
    | '/projects/$projectId'
    | '/details/projects/$projectId/runs/$runId'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_globalAuth'
    | '/authCallback'
    | '/globalAuthCallback'
    | '/login'
    | '/logout'
    | '/_auth/settings'
    | '/_globalAuth/organizations'
    | '/_auth/projects/$projectId'
    | '/_auth/projects/new'
    | '/_auth/settings/billing'
    | '/_auth/settings/profile'
    | '/_auth/settings/workspace'
    | '/_globalAuth/organizations/new'
    | '/_auth/projects/'
    | '/_auth/settings/'
    | '/_globalAuth/onboarding/'
    | '/_globalAuth/organizations/'
    | '/_auth/details/projects/$projectId'
    | '/_auth/projects/$projectId/analytics'
    | '/_auth/projects/$projectId/inbox'
    | '/_auth/projects/$projectId/integration'
    | '/_auth/projects/$projectId/'
    | '/_auth/details/projects/$projectId/runs/$runId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  GlobalAuthRoute: typeof GlobalAuthRouteWithChildren
  AuthCallbackRoute: typeof AuthCallbackRoute
  GlobalAuthCallbackRoute: typeof GlobalAuthCallbackRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  GlobalAuthRoute: GlobalAuthRouteWithChildren,
  AuthCallbackRoute: AuthCallbackRoute,
  GlobalAuthCallbackRoute: GlobalAuthCallbackRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/_globalAuth",
        "/authCallback",
        "/globalAuthCallback",
        "/login",
        "/logout"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/settings",
        "/_auth/projects/$projectId",
        "/_auth/projects/new",
        "/_auth/projects/",
        "/_auth/details/projects/$projectId"
      ]
    },
    "/_globalAuth": {
      "filePath": "_globalAuth.tsx",
      "children": [
        "/_globalAuth/organizations",
        "/_globalAuth/onboarding/"
      ]
    },
    "/authCallback": {
      "filePath": "authCallback.tsx"
    },
    "/globalAuthCallback": {
      "filePath": "globalAuthCallback.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/_auth/settings": {
      "filePath": "_auth.settings.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/settings/billing",
        "/_auth/settings/profile",
        "/_auth/settings/workspace",
        "/_auth/settings/"
      ]
    },
    "/_globalAuth/organizations": {
      "filePath": "_globalAuth.organizations.tsx",
      "parent": "/_globalAuth",
      "children": [
        "/_globalAuth/organizations/new",
        "/_globalAuth/organizations/"
      ]
    },
    "/_auth/projects/$projectId": {
      "filePath": "_auth.projects.$projectId.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/projects/$projectId/analytics",
        "/_auth/projects/$projectId/inbox",
        "/_auth/projects/$projectId/integration",
        "/_auth/projects/$projectId/"
      ]
    },
    "/_auth/projects/new": {
      "filePath": "_auth.projects.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/billing": {
      "filePath": "_auth.settings.billing.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/settings/profile": {
      "filePath": "_auth.settings.profile.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/settings/workspace": {
      "filePath": "_auth.settings.workspace.tsx",
      "parent": "/_auth/settings"
    },
    "/_globalAuth/organizations/new": {
      "filePath": "_globalAuth.organizations.new.tsx",
      "parent": "/_globalAuth/organizations"
    },
    "/_auth/projects/": {
      "filePath": "_auth.projects.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/": {
      "filePath": "_auth.settings.index.tsx",
      "parent": "/_auth/settings"
    },
    "/_globalAuth/onboarding/": {
      "filePath": "_globalAuth.onboarding.index.tsx",
      "parent": "/_globalAuth"
    },
    "/_globalAuth/organizations/": {
      "filePath": "_globalAuth.organizations.index.tsx",
      "parent": "/_globalAuth/organizations"
    },
    "/_auth/details/projects/$projectId": {
      "filePath": "_auth.details.projects.$projectId.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/details/projects/$projectId/runs/$runId"
      ]
    },
    "/_auth/projects/$projectId/analytics": {
      "filePath": "_auth.projects.$projectId.analytics.tsx",
      "parent": "/_auth/projects/$projectId"
    },
    "/_auth/projects/$projectId/inbox": {
      "filePath": "_auth.projects.$projectId.inbox.tsx",
      "parent": "/_auth/projects/$projectId"
    },
    "/_auth/projects/$projectId/integration": {
      "filePath": "_auth.projects.$projectId.integration.tsx",
      "parent": "/_auth/projects/$projectId"
    },
    "/_auth/projects/$projectId/": {
      "filePath": "_auth.projects.$projectId.index.tsx",
      "parent": "/_auth/projects/$projectId"
    },
    "/_auth/details/projects/$projectId/runs/$runId": {
      "filePath": "_auth.details.projects.$projectId.runs.$runId.tsx",
      "parent": "/_auth/details/projects/$projectId"
    }
  }
}
ROUTE_MANIFEST_END */
