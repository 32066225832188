import SampleDocument from '@/assets/sampleDocuments/Bill of Lading.pdf';
import { TemplateModel } from '@/components/ui';

import { amountFormatter, dateFormatter, highConfidenceValidator } from './common';

export const BILL_OF_LADING: TemplateModel = {
  fields: [
    {
      id: 'consignee_name',
      type: 'single-value',
      name: 'Consignee',
      promptHint: 'The company that issued the purchase order.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'shipper',
      type: 'single-value',
      name: 'Shipper',
      promptHint: 'The company or person the invoice was issued to.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'issue_date',
      type: 'single-value',
      name: 'Issue Date',
      promptHint: 'The issue date.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'bol_number',
      type: 'single-value',
      name: 'BoL Number',
      promptHint: 'The bill of lading number.',
      formatters: [],
      validators: [highConfidenceValidator],
    },
    {
      id: 'total_freight_charge',
      type: 'single-value',
      name: 'Freight Charges',
      promptHint: 'The total freight charge, including taxes.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'goods',
      type: 'table',
      name: 'Goods',
      promptHint: 'The shipped goods.',
      fields: [
        {
          id: 'description',
          type: 'single-value',
          name: 'Description',
          promptHint: 'Description of good/item.',
          formatters: [dateFormatter],
          validators: [highConfidenceValidator],
        },
        {
          id: 'unit',
          type: 'single-value',
          name: 'Unit',
          promptHint: 'The unit of measure, such as kilograms or lbs.',
          formatters: [],
          validators: [highConfidenceValidator],
        },
        {
          id: 'quantity',
          type: 'single-value',
          name: 'Quantity',
          promptHint: 'The quantity.',
          formatters: [],
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
