/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthorizationCodeCredentials, Client } from '@lucidtech/las-sdk-browser';
import {
  type QueryClient,
  queryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';

import { useClient } from '@/hooks';
import { generateId, objectEqual } from '@/utils';

export type IdType = {
  [key: string]: string;
};

export type ModelType = Record<string, any>;
export type ListData<TResource> = { data: TResource[]; nextToken: string };

export interface ApiModel<TData extends ModelType, TId extends IdType, TCreate extends ModelType> {
  cacheKey: string;
  equal: (resource?: TData, id?: TId | null) => boolean;
  createId: (resource?: TData | null) => TId | undefined | null;
  get?: (client: Client, id: TId, ...args: any[]) => Promise<TData>;
  list?: (client: Client, ...args: any[]) => Promise<ListData<TData>>;
  update?: (client: Client, id: TId, updates: Partial<TData>, ...args: any[]) => Promise<TData>;
  delete?: (client: Client, id: TId, ...args: any[]) => Promise<TData>;
  create?: (client: Client, resource: TCreate, ...args: any[]) => Promise<TData>;
}

const isType = <TData extends ModelType, TId extends IdType>(
  resource?: unknown,
  id?: TId | null
): resource is TData => {
  if (!(resource && typeof resource === 'object' && id && typeof id === 'object')) return false;
  return Object.keys(id).reduce((a, b) => a && b in resource, true);
};

const findResource = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  resource?: unknown,
  id?: TId | null
): TData | undefined => {
  if (isType<TData, TId>(resource, id) && apiModel.equal(resource, id)) {
    return resource;
  } else if (Array.isArray(resource)) {
    for (const item of resource) {
      const match = findResource<TData, TId, TCreate>(apiModel, item, id);
      if (match) {
        return match;
      }
    }
  } else if (resource && typeof resource === 'object') {
    for (const item of Object.values(resource)) {
      const match = findResource<TData, TId, TCreate>(apiModel, item, id);
      if (match) {
        return match;
      }
    }
  }
};

export const listOptions = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  client: Client,
  ...args: any[]
) => {
  return queryOptions({
    queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'list'],
    queryFn: async () => {
      if (!apiModel.list) throw new Error(`list() is not defined in for ${apiModel.cacheKey}`);
      return await apiModel.list(client, ...args);
    },
  });
};

export const getOptions = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  client: Client,
  queryClient: QueryClient,
  id?: TId | null,
  refetchInterval?: number,
  ...args: any[]
) => {
  return queryOptions({
    queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'item', id],
    queryFn: async () => {
      if (!apiModel.get) throw new Error(`get() is not defined in for ${apiModel.cacheKey}`);
      if (!id) throw new Error('id must be specified');
      const resource = await apiModel.get(client, id, ...args);
      if (!resource) throw new Error(`${id} does not exist`);
      return resource;
    },
    initialData: () => {
      for (const query of queryClient.getQueriesData({
        queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'list'],
      })) {
        const resource = findResource<TData, TId, TCreate>(apiModel, query[1], id);
        if (resource) {
          return resource;
        }
      }
    },
    initialDataUpdatedAt: () => {
      return queryClient.getQueryState([apiModel.cacheKey, 'list'])?.dataUpdatedAt;
    },
    enabled: !!id,
    staleTime: 5 * 60 * 1000,
    refetchInterval: refetchInterval,
  });
};

export const useGet = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  id?: TId | null,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useQuery(getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id, ...args));
};

export const useSuspenseGet = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  id?: TId | null,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useSuspenseQuery(getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id, ...args));
};

export const useBatchGet = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ids?: (TId | null | undefined)[] | null,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useQueries({
    queries: (ids ?? [])
      .filter((id) => !!id)
      .map((id) => ({
        ...getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id, ...args),
      })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((d) => !!d),
        isPending: results.some((result) => result.isPending),
      };
    },
  });
};

export const useSuspenseBatchGet = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ids?: TId[] | null,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useSuspenseQueries({
    queries: (ids ?? []).map((id) => ({
      ...getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id, ...args),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter((d) => !!d),
        isPending: results.some((result) => result.isPending),
      };
    },
  });
};

export const useList = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ...args: any[]
) => {
  const { client } = useClient();

  return useQuery(listOptions<TData, TId, TCreate>(apiModel, client, ...args));
};

export const useSuspenseList = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  id?: TId | null,
  ...args: any[]
) => {
  const { client } = useClient();

  return useSuspenseQuery(listOptions<TData, TId, TCreate>(apiModel, client, id, ...args));
};

export const useCreate = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (resource: TCreate) => {
      if (!apiModel.create) throw new Error(`create() is not defined in for ${apiModel.cacheKey}`);
      return await apiModel.create(client, resource, ...args);
    },
    onSuccess: async (resource) => {
      await queryClient.invalidateQueries({
        queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'list'],
      });
      await queryClient.setQueryData(
        [
          (client.credentials as AuthorizationCodeCredentials).clientId,
          apiModel.cacheKey,
          'item',
          apiModel.createId(resource),
        ],
        resource
      );
    },
    scope: { id: generateId() },
  });
};

export const useUpdate = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      updates,
    }: {
      id?: TId | null;
      updates?: Partial<TData> | ((resource: TData) => Partial<TData>);
    }) => {
      if (!apiModel.update) throw new Error(`update() is not defined in for ${apiModel.cacheKey}`);
      if (!id) throw new Error('id must be specified');
      if (!updates) throw new Error(`nothing to update for ${id}`);
      if (typeof updates === 'function') {
        const resource = await queryClient.ensureQueryData(
          getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id)
        );
        return await apiModel.update(client, id, updates(resource), ...args);
      } else {
        return await apiModel.update(client, id, updates, ...args);
      }
    },
    onSuccess: async (resource) => {
      await queryClient.invalidateQueries({
        queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'list'],
      });
      await queryClient.setQueryData(
        [
          (client.credentials as AuthorizationCodeCredentials).clientId,
          apiModel.cacheKey,
          'item',
          apiModel.createId(resource),
        ],
        resource
      );
    },
  });
};

export const useAutoUpdate = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  delay?: number,
  id?: TId | null,
  updates?: Partial<TData>,
  ...args: any[]
) => {
  const [previousUpdates, setPreviousUpdates] = useState(updates);
  const debouncedUpdates = useDebounce(updates, delay ?? 2000);

  const updateModelMutation = useUpdate<TData, TId, TCreate>(apiModel, ...args);

  useEffect(() => {
    if (id && debouncedUpdates) {
      if (previousUpdates == null) return;
      if (objectEqual(previousUpdates, debouncedUpdates)) return;
      updateModelMutation.mutate(
        { id, updates: debouncedUpdates },
        {
          onSuccess: () => setPreviousUpdates(updates),
        }
      );
    }
    // Adding id and/or previousUpdates to deps will cause infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdates]);

  return updateModelMutation;
};

export const useDelete = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>,
  ...args: any[]
) => {
  const { client } = useClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id?: TId | null) => {
      if (!apiModel.delete) throw new Error(`delete() is not defined in for ${apiModel.cacheKey}`);
      if (!id) throw new Error('id must be specified');
      return await apiModel.delete(client, id, ...args);
    },
    onSuccess: async (resource) => {
      await queryClient.invalidateQueries({
        queryKey: [(client.credentials as AuthorizationCodeCredentials).clientId, apiModel.cacheKey, 'list'],
      });
      queryClient.removeQueries({
        queryKey: [
          (client.credentials as AuthorizationCodeCredentials).clientId,
          apiModel.cacheKey,
          'item',
          apiModel.createId(resource),
        ],
      });
    },
  });
};

export const create = <TData extends ModelType, TId extends IdType, TCreate extends ModelType>(
  apiModel: ApiModel<TData, TId, TCreate>
) => {
  return {
    getOptions: (client: Client, queryClient: QueryClient, id?: TId | null, ...args: any[]) =>
      getOptions<TData, TId, TCreate>(apiModel, client, queryClient, id, ...args),
    listOptions: (client: Client, ...args: any[]) => listOptions<TData, TId, TCreate>(apiModel, client, ...args),
    useAutoUpdate: (delay?: number, id?: TId | null, updates?: Partial<TData>, ...args: any[]) =>
      useAutoUpdate<TData, TId, TCreate>(apiModel, delay, id, updates, ...args),
    useBatchGet: (ids?: (TId | null | undefined)[] | null, ...args: any[]) =>
      useBatchGet<TData, TId, TCreate>(apiModel, ids, ...args),
    useDelete: (...args: any[]) => useDelete<TData, TId, TCreate>(apiModel, ...args),
    useGet: (id?: TId | null, refetchInterval?: number, ...args: any[]) =>
      useGet<TData, TId, TCreate>(apiModel, id, refetchInterval, ...args),
    useList: (...args: any[]) => useList<TData, TId, TCreate>(apiModel, ...args),
    useSuspenseGet: (id?: TId | null, ...args: any[]) => useSuspenseGet<TData, TId, TCreate>(apiModel, id, ...args),
    useSuspenseBatchGet: (ids?: TId[] | null, ...args: any[]) =>
      useSuspenseBatchGet<TData, TId, TCreate>(apiModel, ids, ...args),
    useSuspenseList: (...args: any[]) => useSuspenseList<TData, TId, TCreate>(apiModel, ...args),
    useUpdate: (...args: any[]) => useUpdate<TData, TId, TCreate>(apiModel, ...args),
    useCreate: (...args: any[]) => useCreate<TData, TId, TCreate>(apiModel, ...args),
  };
};
