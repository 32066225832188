import Lottie from 'lottie-react';
import { memo } from 'react';

import dotsIcon from '@/assets/animated/dots-icon.json';
import DeleteIcon from '@/assets/delete-icon.svg?react';
import { merge } from '@/components';
import { CheckmarkSymbol } from '@/components/core';
import { toProjectRunId, useGetProjectRun } from '@/hooks/api';

export type StatusCompactProps = {
  projectId: string;
  runId: string;
};

const IStatusCompact = ({ projectId, runId }: StatusCompactProps) => {
  const { data: run } = useGetProjectRun(toProjectRunId(projectId, runId));

  if (run?.status === 'Exported') return <CheckmarkSymbol />;
  if (run?.status === 'Pending predictions') return <Lottie animationData={dotsIcon} />;
  if (run?.status === 'Archived') return <DeleteIcon className="size-5 stroke-red-400" />;
  const statusStyles = [run?.status === 'Ready for review' && 'border-gray-200 bg-gray-25'];

  return <div className={merge('size-4 rounded-full border-4', statusStyles)} />;
};

export const StatusCompact = memo(IStatusCompact) as typeof IStatusCompact;
