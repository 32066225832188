import { memo, useMemo } from 'react';

import { ComponentStack, Header, Text } from '@/components/core';
import { ModelPanelHeader } from '@/components/flow';
import { FieldConfig } from '@/components/ui';
import { toModelId, useSuspenseGetModel } from '@/hooks/api';
import { ModelConfigProvider } from '@/store';
import { convertToState } from '@/utils';

export type ModelPanelProps = {
  projectId: string;
  modelId: string;
};

const IModelPanel = ({ modelId }: ModelPanelProps) => {
  const { data: model } = useSuspenseGetModel(toModelId(modelId));
  const fields = useMemo(() => convertToState(model.fieldConfig), [model.fieldConfig]);

  return (
    <ModelConfigProvider fields={fields}>
      <div className="flex h-full flex-col">
        <ModelPanelHeader className="border-b border-gray-200 p-4" modelId={modelId} />

        <ComponentStack>
          <div className="p-2">
            <Header size="md">Data to extract</Header>
            <Text size="sm">Configure the fields and tables the AI model shall extract.</Text>
          </div>
          <div className="grow">
            <FieldConfig />
          </div>
        </ComponentStack>
      </div>
    </ModelConfigProvider>
  );
};

export const ModelPanel = memo(IModelPanel) as typeof IModelPanel;
