import type { CreateRoleOptions, Role } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type RoleId = { roleId: string };

export const { useGet: useGetRole, useList: useListRoles } = create<Role, RoleId, CreateRoleOptions>({
  cacheKey: 'roles',
  equal: (role, id) => Boolean(role && id?.roleId && role.roleId === id.roleId),
  createId: (role) => (role ? { roleId: role.roleId } : null),
  create: (client, role) => client.createRole(role),
  delete: (client, id) => client.deleteRole(id.roleId),
  get: (client, id) => client.getRole(id.roleId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listRoles()
        .then((response: JSONType) => {
          resolve({ data: response.roles, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateRole(id.roleId, updates),
});

export const toRoleId = (roleId?: string | null): RoleId | null => {
  return roleId ? { roleId } : null;
};
