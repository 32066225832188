import { Client } from '@lucidtech/las-sdk-browser';
import { createContext } from 'react';

export type AuthContextInterface = {
  client?: Client | null;
  clientId?: string | null;
  setClientId: (clientId: string) => void;
  ensureLoggedIn: (clientId?: string) => Promise<boolean>;
  isLoggedIn: boolean;
  logout: () => Promise<boolean>;
  setRedirectUrl: (url: string) => void;
  redirectUrl?: string | null;
  getScopes: () => Promise<string[]>;
  updateCredentials: (code: string) => void;
};

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined);
