import { memo } from 'react';

import { TriggerPanel } from '@/components/flow';

import { SlideOut } from './SlideOut.tsx';

export type TriggerPanelSlideOutProps = {
  actionId: string;
  projectId: string;
};

const ITriggerPanelSlideOut = ({ projectId, actionId }: TriggerPanelSlideOutProps) => {
  return (
    <SlideOut>
      <TriggerPanel projectId={projectId} actionId={actionId} />
    </SlideOut>
  );
};

export const TriggerPanelSlideOut = memo(ITriggerPanelSlideOut) as typeof ITriggerPanelSlideOut;
