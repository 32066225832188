import { memo } from 'react';

import { Text } from '@/components/core';
import { ClassificationFormatter, DateFormatter, findField, RegexFormatter } from '@/components/ui';
import {
  FORMATTER_CLASSIFICATION_FUNCTION_ID,
  FORMATTER_DATE_FUNCTION_ID,
  FORMATTER_NUMERIC_FUNCTION_ID,
  FORMATTER_REGEX_FUNCTION_ID,
} from '@/constants';
import { useModelConfigStore } from '@/store';

import { NumericFormatter } from './NumericFormatter';

export type FormatterConfigurationProps = {
  fieldId: string;
  index: number;
};

const IFormatterConfiguration = ({ fieldId, index }: FormatterConfigurationProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const formatter = field?.formatters?.[index];

  if (!formatter) return null;
  switch (formatter.functionId) {
    case FORMATTER_DATE_FUNCTION_ID:
      return <DateFormatter fieldId={fieldId} index={index} />;
    case FORMATTER_REGEX_FUNCTION_ID:
      return <RegexFormatter fieldId={fieldId} index={index} />;
    case FORMATTER_NUMERIC_FUNCTION_ID:
      return <NumericFormatter fieldId={fieldId} index={index} />;
    case FORMATTER_CLASSIFICATION_FUNCTION_ID:
      return <ClassificationFormatter fieldId={fieldId} index={index} />;
  }
  return <Text>Unrecognized formatter</Text>;
};

export const FormatterConfiguration = memo(IFormatterConfiguration) as typeof IFormatterConfiguration;
