import { memo } from 'react';

import { merge } from '@/components';
import { Text } from '@/components/core';
import { useImageSrc } from '@/hooks';
import { toProfileId, useGetProfile } from '@/hooks/api';
import { DivProps, OmitChildren } from '@/utils';

export type ProfileProps = OmitChildren<DivProps> & {
  profileId?: string | null;
  showEmail?: boolean | null;
};

const IProfile = ({ profileId, showEmail, className, ...rest }: ProfileProps) => {
  const { data: profile } = useGetProfile(toProfileId(profileId));
  const { src } = useImageSrc({ source: profile?.pictureUrl });

  if (!profile) {
    return <Text>Loading</Text>;
  }

  return (
    <div className={merge('flex items-center gap-2', className)} {...rest}>
      <img className="size-8 rounded-full" alt={profile.profileId} src={src} />
      <div className="flex flex-col">
        <Text>{profile.givenName}</Text>
        {showEmail ? <Text size="xs">{profile.email}</Text> : null}
      </div>
    </div>
  );
};

export const Profile = memo(IProfile) as typeof IProfile;
