import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import { merge } from '@/components';

export type HeaderProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  size: 'xl' | 'lg' | 'md';
};

export const Header = ({ className, children, size, ...rest }: HeaderProps) => {
  switch (size) {
    case 'xl':
      return (
        <h1 className={merge('text-2xl font-semibold text-gray-900', className)} {...rest}>
          {children}
        </h1>
      );
    case 'lg':
      return (
        <h2 className={merge('text-xl font-semibold text-gray-600', className)} {...rest}>
          {children}
        </h2>
      );
    case 'md':
      return (
        <h2 className={merge('text-lg font-semibold text-gray-600', className)} {...rest}>
          {children}
        </h2>
      );
  }
};
