import type { PaymentMethodDetails } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { CreditCardLogo, Text } from '@/components/core';

export type CreditCardProps = {
  details?: PaymentMethodDetails | null;
};

const ICreditCard = ({ details }: CreditCardProps) => {
  if (!details) return;

  return (
    <div className="flex items-center gap-4 py-2">
      <CreditCardLogo className="size-12" brand={details.brand} />
      <div className="flex flex-col">
        <Text size="sm">Credit card ending in {details.last4}</Text>
        <Text size="xs">
          Expires {details.expMonth}/{details.expYear}
        </Text>
      </div>
    </div>
  );
};

export const CreditCard = memo(ICreditCard) as typeof ICreditCard;
