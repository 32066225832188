import { useCallback, useEffect, useState } from 'react';

import { toDocumentId, toFileId, useGetDocument, useGetFile, usePutFile } from '@/hooks/api';
import { JSONType } from '@/utils';

export type UseDocumentAnnotationsOpts = {
  documentId?: string | null;
};

export const useDocumentAnnotations = ({ documentId }: UseDocumentAnnotationsOpts) => {
  const { data: doc } = useGetDocument(toDocumentId(documentId));
  const { data: blob } = useGetFile(toFileId(doc?.annotationFileUrl));
  const { mutate: updateAnnotations } = usePutFile();
  const [annotations, setAnnotations] = useState<JSONType>({});

  useEffect(() => {
    blob?.text().then((annotationsStr) => {
      setAnnotations(JSON.parse(annotationsStr));
    });
  }, [blob, setAnnotations]);

  const set = useCallback(
    (annotations: JSONType) => {
      return new Promise((resolve, reject) => {
        if (!doc) {
          reject(`${documentId} does not exist`);
          return;
        }
        updateAnnotations(
          {
            fileUrl: doc.annotationFileUrl,
            file: new Blob([JSON.stringify(annotations)], { type: 'application/json' }),
          },
          {
            onSuccess: (file) => resolve(file),
            onError: (error) => reject(error),
          }
        );
      });
    },
    [doc, documentId, updateAnnotations]
  );

  return { annotations, setAnnotations: set };
};
