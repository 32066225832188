import { useNavigate } from '@tanstack/react-router';
import { memo, useEffect } from 'react';

import Logo from '@/assets/logo.svg?react';
import { ErrorCard, Header, WizardStepIndicator } from '@/components/core';
import { createWizard } from '@/components/ui';
import {
  ChooseIntegrationResult,
  ChooseIntegrationsStep,
  ChooseTemplateResult,
  ChooseTemplateStep,
} from '@/features/projects';
import { useScaffoldProject } from '@/hooks';

import { SetupProjectStep } from './SetupProjectStep';

export type NewProject = ChooseTemplateResult & ChooseIntegrationResult;

const { useWizard, Wizard } = createWizard<NewProject>();

export type NewProps = object;

// eslint-disable-next-line no-empty-pattern
const INew = ({}: NewProps) => {
  const { create, projectId, status } = useScaffoldProject();

  const navigate = useNavigate();

  useEffect(() => {
    if (status.isDone && projectId) {
      navigate({ to: '/projects/$projectId', params: { projectId } }).catch(console.error);
    }
  }, [status.isDone, navigate, projectId]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8 bg-gradient-to-b from-white to-slate-200">
      <div className="flex items-center gap-2">
        <Logo />
        <Header size="xl">Cradl AI</Header>
      </div>

      <Wizard>
        <Wizard.Step>
          {(wizard) => <ChooseTemplateStep defaultValue={wizard.state.template} onContinue={wizard.next} />}
        </Wizard.Step>

        <Wizard.Step onNext={create}>
          {(wizard) => (
            <ChooseIntegrationsStep
              defaultOptions={{ trigger: wizard.state.trigger, export: wizard.state.export }}
              isCreatingProject={status.project.isPending}
              onContinue={wizard.next}
              onBack={wizard.previous}
            />
          )}
        </Wizard.Step>

        <Wizard.Step>
          {(wizard) => (
            <SetupProjectStep status={status} hasTrigger={!!wizard.state.trigger} hasExport={!!wizard.state.export} />
          )}
        </Wizard.Step>

        <WizardStepIndicator useWizard={useWizard} hideLast />
      </Wizard>

      {status.project.errors.length > 0 ? (
        <ErrorCard label="Error encountered while setting up your project" error={status.project.errors} />
      ) : null}
    </div>
  );
};

export const New = memo(INew) as typeof INew;
