import { memo } from 'react';

import { ProjectRunStatusBadge } from '@/components/ui';
import { toProjectRunId, useGetProjectRun } from '@/hooks/api';

export type StatusProps = {
  projectId: string;
  runId: string;
};

const IStatus = ({ projectId, runId }: StatusProps) => {
  const { data: run } = useGetProjectRun(toProjectRunId(projectId, runId), 5000);
  return <ProjectRunStatusBadge status={run?.status} />;
};

export const Status = memo(IStatus) as typeof IStatus;
