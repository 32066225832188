import { memo } from 'react';

import PhoneNumberIcon from '@/assets/phone-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type PhoneNumberLogoProps = VariantLogoProps;

const IPhoneNumberLogo = ({ ...rest }: PhoneNumberLogoProps) => {
  return <FormatterValidatorLogo Icon={PhoneNumberIcon} iconColoringType="stroke" {...rest} />;
};

export const PhoneNumberLogo = memo(IPhoneNumberLogo) as typeof IPhoneNumberLogo;
