import { TriggerOption } from '@/components/flow';
import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { generateId } from '@/utils';

export const TRIGGERS: TriggerOption[] = [
  {
    functionId: CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
    config: { email: `${generateId()}@${import.meta.env.VITE_EMAIL_CONNECTORS_DOMAIN}` },
    name: 'Send to Email',
  },
  {
    functionId: CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
    config: { credentials: '********************' },
    name: 'Trigger from Power Automate',
  },
  {
    functionId: CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
    config: { credentials: '********************' },
    name: 'Trigger from Zapier',
  },
];
