import React, { memo, PropsWithChildren, useCallback, useState } from 'react';

import { ComponentStackContext } from '@/components/core';

export type ComponentStackProps = PropsWithChildren;

const IComponentStack = ({ children }: ComponentStackProps) => {
  const [components, setComponents] = useState<React.ReactNode[]>([children]);

  const push = useCallback(
    (node: React.ReactNode) => {
      setComponents((components) => [...components, node]);
    },
    [setComponents]
  );

  const pop = useCallback(() => {
    if (components.length > 1) {
      components.pop();
      setComponents([...components]);
    }
  }, [components, setComponents]);

  return (
    <ComponentStackContext.Provider value={{ push, pop }}>
      {components[components.length - 1]}
    </ComponentStackContext.Provider>
  );
};

export const ComponentStack = memo(IComponentStack) as typeof IComponentStack;
