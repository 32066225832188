import { memo } from 'react';

import { Dialog, Modal, ModalOverlay, ModalOverlayProps } from '@/components/core';
import { NewExport, OnExportOptionSelected } from '@/components/flow';

export type NewExportModalProps = ModalOverlayProps & {
  onExportOptionSelected?: OnExportOptionSelected;
  isAddingExport?: boolean;
};

const INewExportModal = ({ onExportOptionSelected, isAddingExport, ...rest }: NewExportModalProps) => {
  return (
    <ModalOverlay {...rest}>
      <Modal>
        <Dialog className="outline-none">
          <NewExport
            onExportOptionSelected={onExportOptionSelected}
            isAddingExport={isAddingExport}
            className="w-[25rem] p-4"
          />
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const NewExportModal = memo(INewExportModal) as typeof INewExportModal;
