import { memo } from 'react';
import { TabList as TabList_, TabListProps as TabListProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type TabListProps<TTabData extends object> = TabListProps_<TTabData>;

const ITabList = <TTabData extends object>({ className, children, ...rest }: TabListProps<TTabData>) => {
  return (
    <TabList_<TTabData> className={merge('flex gap-4', className)} {...rest}>
      {typeof children === 'function' ? (item) => children(item) : children}
    </TabList_>
  );
};

export const TabList = memo(ITabList) as typeof ITabList;
