import { memo } from 'react';

import { Text } from '@/components/core';
import { toAppClientId, useGetAppClient } from '@/hooks/api';
import { DivProps } from '@/utils';

export type AppClientProps = DivProps & {
  appClientId?: string | null;
};

const IAppClient = ({ appClientId, ...rest }: AppClientProps) => {
  const { data: appClient } = useGetAppClient(toAppClientId(appClientId));
  if (!appClient) return <Text>Loading</Text>;
  return (
    <div {...rest}>
      <Text size="xs">{appClient?.name}</Text>
    </div>
  );
};

export const AppClient = memo(IAppClient) as typeof IAppClient;
