import { memo } from 'react';

import { Dialog, Modal, ModalOverlay, ModalOverlayProps } from '@/components/core';
import { NewValidator } from '@/components/ui';

export type NewValidatorModalProps = ModalOverlayProps & {
  fieldId: string;
};

const INewValidatorModal = ({ fieldId, ...rest }: NewValidatorModalProps) => {
  return (
    <ModalOverlay isDismissable {...rest}>
      <Modal>
        <Dialog>{() => <NewValidator fieldId={fieldId} />}</Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const NewValidatorModal = memo(INewValidatorModal) as typeof INewValidatorModal;
