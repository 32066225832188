import { memo } from 'react';

import { CheckmarkSymbol, ErrorSymbol, Save, Text } from '@/components/core';

export type SetupProjectProgressProps = {
  inProgress: boolean;
  inProgressMessage: string;
  doneMessage: string;
  isError?: boolean;
};

const ISetupProjectProgress = ({ inProgress, inProgressMessage, doneMessage, isError }: SetupProjectProgressProps) => {
  if (isError) {
    return (
      <div className="flex items-center justify-between">
        <Text size="sm">{inProgressMessage}</Text>
        <ErrorSymbol />
      </div>
    );
  }
  if (inProgress) {
    return <Save className="justify-between" message={inProgressMessage} />;
  }
  return (
    <div className="flex items-center justify-between">
      <Text size="sm">{doneMessage}</Text>
      <CheckmarkSymbol />
    </div>
  );
};

export const SetupProjectProgress = memo(ISetupProjectProgress) as typeof ISetupProjectProgress;
