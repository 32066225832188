import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { TextArea as TextArea_, TextAreaProps as TextAreaProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type TextAreaProps = Omit<TextAreaProps_, 'onChange' | 'value'> & {
  value?: string;
  delay?: number;
  onChange?: (value?: string) => void;
};

export const TextArea = ({ id, className, children, value, delay = 1000, onChange, ...rest }: TextAreaProps) => {
  const [value_, setValue_] = useState(value);
  const debouncedValue = useDebounce(value_, delay);

  useEffect(() => {
    if (value) {
      setValue_(value);
    }
  }, [id, value]);

  useEffect(() => {
    onChange?.(debouncedValue);
    // Adding onChange to deps will cause infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  /* prettier-ignore */
  const styles = [
    'bg-white',
    'border',
    'border-gray-200',
    'outline-none',
    'p-2',
    'rounded-lg',
    'text-md',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'text-gray-900',
  ];

  return (
    <TextArea_
      className={merge(styles, lightStyles, className)}
      onChange={(e) => setValue_(e.target.value)}
      value={value_}
      {...rest}
    >
      {children}
    </TextArea_>
  );
};
