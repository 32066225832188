import { memo } from 'react';
import { Focusable } from 'react-aria-components';

import CheckmarkIcon from '@/assets/checkmark-icon.svg?react';
import { Text, Tooltip, TooltipProps } from '@/components/core';

export type ValidatedPredictionTooltipProps = Omit<TooltipProps, 'tooltip'> & {
  formattedValue?: string | null;
  confidence?: number | null;
};

const IValidatedPredictionTooltip = ({
  formattedValue,
  placement = 'left top',
  ...rest
}: ValidatedPredictionTooltipProps) => {
  return (
    <Tooltip
      className="bg-white text-white shadow-lg"
      placement={placement}
      tooltip={
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 border-b border-gray-200 pb-2">
            <CheckmarkIcon className="!rotate-0 stroke-green-500" />
            <Text className="font-semibold">Passed validation</Text>
          </div>
          <Text size="sm">The following value will be exported:</Text>
          <Text className="w-fit rounded-lg border border-gray-200 px-1">{formattedValue}</Text>
        </div>
      }
      {...rest}
    >
      <Focusable>
        <CheckmarkIcon className="fill-green-50 stroke-green-300" />
      </Focusable>
    </Tooltip>
  );
};

export const ValidatedPredictionTooltip = memo(IValidatedPredictionTooltip) as typeof IValidatedPredictionTooltip;
