import { BANK_STATEMENT } from './bank_statement.ts';
import { BILL_OF_LADING } from './bill_of_lading.ts';
import { GENERIC } from './generic.ts';
import { ID_CARD } from './id_card.ts';
import { INVOICE } from './invoice.ts';
import { PAYSLIP } from './payslip.ts';
import { PURCHASE_ORDER } from './purchase_order.ts';
import { RECEIPT } from './receipt.ts';
import { RESUME } from './resume.ts';

export const DEFAULT_TEMPLATE = 'Other';

export const TEMPLATES = {
  Invoices: INVOICE,
  Receipts: RECEIPT,
  'Purchase Orders': PURCHASE_ORDER,
  'Bills of Lading': BILL_OF_LADING,
  'Bank Statements': BANK_STATEMENT,
  'ID Cards': ID_CARD,
  Payslips: PAYSLIP,
  Resumes: RESUME,
  [DEFAULT_TEMPLATE]: GENERIC,
} as const;
