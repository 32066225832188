import { memo } from 'react';

import { DetailsProjectRunsPanel } from './DetailsProjectRunsPanel.tsx';
import { useOpenPanel } from './useOpenPanel.ts';

export type DetailsProjectRunsPanelWrapperProps = {
  projectId: string;
};

const IDetailsProjectRunsPanelWrapper = ({ projectId }: DetailsProjectRunsPanelWrapperProps) => {
  const { projectRunsPanelOpen } = useOpenPanel();

  if (!projectRunsPanelOpen) return null;

  return <DetailsProjectRunsPanel projectId={projectId} />;
};

export const DetailsProjectRunsPanelWrapper = memo(
  IDetailsProjectRunsPanelWrapper
) as typeof IDetailsProjectRunsPanelWrapper;
