import { memo, useCallback, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';

import ChevronSelectorVerticalIcon from '@/assets/chevron-selector-vertical-icon.svg?react';
import SaveIcon from '@/assets/save-icon.svg?react';
import { merge } from '@/components';
import { Button, Header, Text } from '@/components/core';
import {
  ExportLogo,
  ExportOption,
  NewExportModal,
  NewTriggerModal,
  TriggerLogo,
  TriggerOption,
} from '@/components/flow';
import { Step, StepProps } from '@/features/projects';

export type ChooseIntegrationResult = {
  trigger?: TriggerOption;
  export?: ExportOption;
};

export type ChooseIntegrationsStepProps = StepProps & {
  onContinue: (data: ChooseIntegrationResult) => void;
  onBack: (data: ChooseIntegrationResult) => void;
  isCreatingProject?: boolean;
  defaultOptions?: ChooseIntegrationResult;
};

const IChooseIntegrationsStep = ({
  onContinue,
  onBack,
  defaultOptions,
  isCreatingProject,
  ...rest
}: ChooseIntegrationsStepProps) => {
  const [selectedTrigger, setSelectedTrigger] = useState<TriggerOption | undefined>(defaultOptions?.trigger);
  const [triggerModalOpened, setTriggerModalOpened] = useState(false);
  const onTriggerOptionSelected = useCallback((triggerOption: TriggerOption) => {
    setSelectedTrigger(triggerOption);
    setTriggerModalOpened(false);
  }, []);

  const [selectedExport, setSelectedExport] = useState<ExportOption | undefined>(defaultOptions?.export);
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const onExportOptionSelected = useCallback((exportOption: ExportOption) => {
    setSelectedExport(exportOption);
    setExportModalOpened(false);
  }, []);

  return (
    <Step {...rest}>
      <Header size="xl">How do you plan to integrate?</Header>
      <Text>You can change this later.</Text>

      <DialogTrigger>
        <Button
          variant="plain"
          className="flex justify-start gap-3 rounded-lg border border-gray-200 p-3 data-[pressed]:bg-white"
          onPress={() => setTriggerModalOpened(true)}
        >
          <TriggerLogo size="lg" functionId={selectedTrigger?.functionId} />
          <Text className={merge('flex-1 text-start', !selectedTrigger && 'text-gray-400')}>
            {selectedTrigger?.name ?? 'Selected trigger'}
          </Text>
          <ChevronSelectorVerticalIcon className="fill-gray-400" />
        </Button>
        <NewTriggerModal
          isOpen={triggerModalOpened}
          onOpenChange={() => setTriggerModalOpened(false)}
          onTriggerOptionSelected={onTriggerOptionSelected}
        />
      </DialogTrigger>

      <DialogTrigger>
        <Button
          variant="plain"
          className="flex justify-start gap-3 rounded-lg border border-gray-200 p-3 data-[pressed]:bg-white"
          onPress={() => setExportModalOpened(true)}
        >
          <ExportLogo size="lg" functionId={selectedExport?.functionId} />
          <Text className={merge('flex-1 text-start', !selectedExport && 'text-gray-400')}>
            {selectedExport?.name ?? 'Select export'}
          </Text>
          <ChevronSelectorVerticalIcon className="fill-gray-400" />
        </Button>
        <NewExportModal
          isOpen={exportModalOpened}
          onExportOptionSelected={onExportOptionSelected}
          onOpenChange={() => setExportModalOpened(false)}
        />
      </DialogTrigger>

      <Button
        isDisabled={isCreatingProject}
        onPress={() => onContinue({ trigger: selectedTrigger, export: selectedExport })}
      >
        {isCreatingProject ? <SaveIcon className="size-4 animate-spin stroke-gray-500" /> : null}
        Continue
      </Button>
    </Step>
  );
};

export const ChooseIntegrationsStep = memo(IChooseIntegrationsStep) as typeof IChooseIntegrationsStep;
