import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { memo, useCallback } from 'react';

import Logo from '@/assets/logo.svg?react';
import { Header, Text } from '@/components/core';
import { DEFAULT_REDIRECT_URL } from '@/constants';
import { useAuth } from '@/hooks';
import { toProfileId, useGetProfile, useListOrganizations } from '@/hooks/api';

import { OrganizationCard } from './OrganizationCard.tsx';

export type OrganizationsProps = object;

// eslint-disable-next-line no-empty-pattern
const IOrganizations = ({}: OrganizationsProps) => {
  const { data: organizations } = useListOrganizations();
  const { data: profile } = useGetProfile(toProfileId('me'));
  const { ensureLoggedIn, setClientId, redirectUrl } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();

  const onSelectOrganization = useCallback(
    (clientId: string) => {
      queryClient.clear();
      setClientId(clientId);
      ensureLoggedIn().then((value) => {
        if (value) {
          router.history.push(redirectUrl ?? DEFAULT_REDIRECT_URL);
        }
      });
    },
    [ensureLoggedIn, queryClient, redirectUrl, router.history, setClientId]
  );

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8 bg-gradient-to-b from-white to-slate-200">
      <div className="flex items-center gap-2">
        <Logo />
        <Header size="xl">Cradl AI</Header>
      </div>
      <div className="flex w-[35rem] flex-col gap-4 rounded-lg bg-white p-6 shadow">
        <Header size="lg">Choose workspace to login to</Header>
        <Text>
          Your email <span className="font-semibold text-gray-500">{profile?.email}</span> is associated with multiple
          Cradl workspaces
        </Text>

        {organizations?.data.map((o) => (
          <OrganizationCard
            onPress={() => onSelectOrganization(o.clientId)}
            key={o.organizationId}
            organizationId={o.organizationId}
          />
        ))}
      </div>
    </div>
  );
};

export const Organizations = memo(IOrganizations) as typeof IOrganizations;
