import type { Plan } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type PlanId = { planId: string };

export const { useGet: useGetPlan } = create<Plan, PlanId, object>({
  cacheKey: 'plans',
  equal: (plan, id) => Boolean(plan && id?.planId && plan.planId === id.planId),
  createId: (plan) => (plan ? { planId: plan.planId } : null),
  get: (client, id) => client.getPlan(id.planId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listPlans()
        .then((response: JSONType) => {
          resolve({ data: response.plans, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
});

export const toPlanId = (planId?: string | null): PlanId | null => {
  return planId ? { planId } : null;
};
