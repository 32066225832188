import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { merge } from '@/components';

export type LogoProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  size: 'lg' | 'md' | 'xl' | '2xl' | '3xl' | '4xl';
};

export const Logo = ({ size, className, children, ...rest }: LogoProps) => {
  /* prettier-ignore */
  const styles = [
    'flex',
    'items-center',
    'justify-center',
    'p-1',
    'rounded-lg',
    size === 'md' && 'size-6',
    size === 'lg' && 'size-8',
    size === 'xl' && 'size-10',
    size === '2xl' && 'size-12',
    size === '3xl' && 'size-14',
    size === '4xl' && 'size-16',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'text-slate-900',
  ];

  return (
    <div className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </div>
  );
};
