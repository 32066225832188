import type { FieldFormatter, Function } from '@lucidtech/las-sdk-browser';
import { useCallback, useMemo } from 'react';

import { useBatchGetFile, useBatchGetFunctions } from '@/hooks/api';
import { selectFromFieldConfig, useDocumentStore } from '@/store';
import { FieldContextType, FieldStateValueType, FormatterFnReturnType, FormatterFnType, importJS } from '@/utils';

type FormatterResolved = FieldFormatter & {
  function?: Function;
  transform?: FormatterFnType;
};

export type UseFormattersOpts = {
  name: string;
};

export const useFormatters = ({ name }: UseFormattersOpts) => {
  const config = useDocumentStore(selectFromFieldConfig(name));
  const { data: functions } = useBatchGetFunctions(config?.formatters?.map(({ functionId }) => ({ functionId })));
  const { data: formatterBlobs } = useBatchGetFile(functions?.map((f) => ({ fileUrl: f.fileUrl })));

  const formatters = useMemo(async () => {
    const formatters: FormatterResolved[] = config?.formatters?.map((f) => ({ ...f })) ?? [];

    if (formatters.length === functions.length) {
      for (const [i, formatter] of formatters.entries()) {
        formatter.function = functions[i];
        const formatterBlob = formatterBlobs[i];
        if (!formatterBlob) continue;

        const codeModule = await importJS(formatterBlob);
        formatter.transform = codeModule.default;
      }
    }
    return formatters;
  }, [formatterBlobs, config?.formatters, functions]);

  const run = useCallback(
    async (state: FieldStateValueType, context: FieldContextType): Promise<FormatterFnReturnType> => {
      let newState: FieldStateValueType | undefined = state;
      const errors: string[] = [];
      const warnings: string[] = [];

      const fmts = await formatters;

      if (fmts.length > 0) {
        for (const formatter of await formatters) {
          if (!formatter) continue;

          const formatterContext = structuredClone(context);
          formatterContext.config = structuredClone(formatter.config);
          if (!formatter.transform) continue;

          try {
            const {
              result,
              errors: newErrors,
              warnings: newWarnings,
            } = formatter.transform(structuredClone(newState ?? {}), formatterContext);

            newState = result;
            errors.push(...(newErrors ?? []));
            warnings.push(...(newWarnings ?? []));
          } catch (error) {
            if (error instanceof Error) {
              const status = context.config.validationMode === 'error' ? errors : warnings;
              status.push(error.message);
            }

            return { errors, warnings };
          }
        }
      } else {
        // If no formatters have run => value should be = rawValue
        if (!newState.value) {
          newState.value = newState.rawValue;
        }
      }

      return { result: newState, errors, warnings };
    },
    [formatters]
  );

  return { run };
};
