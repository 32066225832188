import { Project } from '@lucidtech/las-sdk-browser';
import { useCallback, useMemo, useState } from 'react';

import { TEMPLATES } from '@/components/ui';
import { NewProject } from '@/features/projects';
import { convertToFieldConfig } from '@/utils';

import { useCreateProject } from './api';
import { useAddExport } from './useAddExport';
import { useAddModel } from './useAddModel';
import { useAddTrigger } from './useAddTrigger';
import { useAddValidation } from './useAddValidation';
import { useProject } from './useProject.ts';

type Status = {
  isSuccess: boolean;
  isPending: boolean;
  errors: Error[];
};

export type ScaffoldProjectStatus = {
  model: Status;
  trigger: Status;
  export: Status;
  validation: Status;
  project: Status;
  errors: Error[];
  isDone: boolean;
};

export const useScaffoldProject = () => {
  const [errors, setErrors] = useState<Error[]>([]);
  const [projectId, setProjectId] = useState<string>();
  const [hasSelectedTrigger, setHasSelectedTrigger] = useState(false);
  const [hasSelectedExport, setHasSelectedExport] = useState(false);
  const { createRun } = useProject();

  const {
    mutate: createProject,
    isSuccess: isSuccessCreateProject,
    error: errorCreateProject,
    isPending: isPendingCreateProject,
  } = useCreateProject();

  const {
    addModel,
    isPending: isPendingAddModel,
    isSuccess: isSuccessAddModel,
    errors: errorsAddModel,
  } = useAddModel();

  const {
    addValidation,
    isPending: isPendingAddValidation,
    isSuccess: isSuccessAddValidation,
    errors: errorsAddValidation,
  } = useAddValidation();

  const {
    addTrigger,
    isSuccess: isSuccessAddTrigger,
    isPending: isPendingAddTrigger,
    errors: errorsAddTrigger,
  } = useAddTrigger();

  const {
    addExport,
    isSuccess: isSuccessAddExport,
    isPending: isPendingAddExport,
    errors: errorsAddExport,
  } = useAddExport();

  const create = useCallback(
    (state?: Partial<NewProject>): Partial<NewProject> => {
      const selectedTemplate = state?.template;
      const selectedTrigger = state?.trigger;
      const selectedExport = state?.export;

      setHasSelectedTrigger(!!selectedTrigger);
      setHasSelectedExport(!!selectedExport);

      createProject(
        { name: selectedTemplate },
        {
          onSuccess: async (project: Project) => {
            const projectId = project.projectId;
            const fields = (selectedTemplate && TEMPLATES[selectedTemplate]?.fields) ?? [];
            const sampleDocuments = (selectedTemplate && TEMPLATES[selectedTemplate]?.sampleDocuments) ?? [];

            setProjectId(project.projectId);

            try {
              const model = await addModel({
                projectId,
                fieldConfig: convertToFieldConfig(fields),
              });

              if (model) {
                await addValidation({ projectId, modelId: model.modelId });

                if (selectedTrigger) await addTrigger({ projectId, triggerOption: selectedTrigger });
                if (selectedExport) await addExport({ projectId, exportOption: selectedExport });
              }

              for (const sampleDocument of sampleDocuments) {
                const blob = await (await fetch(sampleDocument)).blob();
                const name = decodeURI(sampleDocument.split('/').at(-1) ?? 'sample');
                const file = new File([blob], name, { type: blob.type });
                await createRun({ file, projectId: projectId });
              }
            } catch (error) {
              if (error instanceof Error) {
                setErrors((errors) => [...errors, error]);
              }
            }
          },
        }
      );

      return state || {};
    },
    [addExport, addModel, addTrigger, addValidation, createProject, createRun]
  );

  const isDone = useMemo(() => {
    const isDoneAddModel = isSuccessAddModel;
    const isDoneAddValidation = isSuccessAddValidation;
    const isDoneAddTrigger = !hasSelectedTrigger || isSuccessAddTrigger;
    const isDoneAddExport = !hasSelectedExport || isSuccessAddExport;
    return isDoneAddModel && isDoneAddValidation && isDoneAddTrigger && isDoneAddExport;
  }, [
    hasSelectedExport,
    hasSelectedTrigger,
    isSuccessAddExport,
    isSuccessAddModel,
    isSuccessAddTrigger,
    isSuccessAddValidation,
  ]);

  return {
    projectId,
    status: {
      isDone,
      model: {
        isSuccess: isSuccessAddModel,
        isPending: isPendingAddModel,
        errors: errorsAddModel ?? [],
      },
      export: {
        isPending: isPendingAddExport,
        isSuccess: isSuccessAddExport,
        errors: errorsAddExport ?? [],
      },
      trigger: {
        isPending: isPendingAddTrigger,
        isSuccess: isSuccessAddTrigger,
        errors: errorsAddTrigger ?? [],
      },
      validation: {
        isSuccess: isSuccessAddValidation,
        isPending: isPendingAddValidation,
        errors: errorsAddValidation ?? [],
      },
      project: {
        isSuccess: isSuccessCreateProject,
        isPending: isPendingCreateProject,
        errors: errorCreateProject ? [errorCreateProject] : [],
      },
      errors,
    },
    hasSelectedTrigger,
    hasSelectedExport,
    create,
  };
};
