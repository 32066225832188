import { memo, useCallback, useState } from 'react';

import { merge } from '@/components';
import { Button, DebouncedInput, DebouncedSlider, Hr, Text } from '@/components/core';
import { findField, FormatterValidatorHeader, ValidationFormSettings } from '@/components/ui';
import { useValidatorMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';
import { isNumber, parseNumber, toNumber, toString } from '@/utils';

export type ConfidenceValidatorProps = {
  fieldId: string;
  index: number;
};

const IConfidenceValidator = ({ fieldId, index }: ConfidenceValidatorProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const validator = field?.validators?.[index];
  const mutate = useValidatorMutation(fieldId, index, field?.parentId);

  const updateThreshold = useCallback(
    (automate: number | number[] | string) => {
      const parsedAutomate = parseNumber(automate, (value) => value >= 0 && value <= 1);
      if (!isNumber(parsedAutomate)) return;

      mutate((validator) => {
        validator.name = `Confidence > ${(parsedAutomate * 100).toFixed(0)}%`;
        validator.config.thresholds = { automate: parsedAutomate };
      });
    },
    [mutate]
  );

  const choices = [0, 0.65, 0.97, 1.0];
  const currentChoice = choices.findIndex((choice) => validator?.config.thresholds?.automate === choice);
  const [isCustomThreshold, setIsCustomThreshold] = useState(currentChoice === -1);

  if (!validator) return null;

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader validator={validator} />
      <Hr />
      <div className="flex grow flex-col gap-4">
        <Text>Set confidence threshold to</Text>
        <div className="flex shrink">
          <div className="flex rounded-lg border border-gray-200 p-0">
            {choices.map((choice, i) => (
              <Button
                className={merge(currentChoice === i && !isCustomThreshold && 'bg-gray-100')}
                key={choice}
                onPress={() => {
                  setIsCustomThreshold(false);
                  updateThreshold(choice);
                }}
                variant="plain"
              >
                <Text>{Math.ceil(choice * 100)}%</Text>
              </Button>
            ))}
            <Button
              className={merge(isCustomThreshold && 'bg-gray-100')}
              onPress={() => setIsCustomThreshold(true)}
              variant="plain"
            >
              <Text>Custom</Text>
            </Button>
          </div>
        </div>
        {isCustomThreshold ? (
          <div className="flex items-center gap-4">
            <DebouncedSlider
              className="w-full"
              delay={500}
              value={toNumber(validator.config.thresholds?.automate)}
              minValue={0}
              maxValue={1}
              step={0.01}
              onChange={(value) => updateThreshold(value)}
            />
            <div>
              <DebouncedInput
                type="number"
                delay={500}
                step={0.01}
                min={0}
                max={1}
                value={toString(validator.config.thresholds?.automate)}
                onChange={(e) => updateThreshold(e.target.value)}
              />
            </div>
          </div>
        ) : null}
      </div>

      <Hr />

      <ValidationFormSettings fieldId={fieldId} index={index} />
    </div>
  );
};

export const ConfidenceValidator = memo(IConfidenceValidator) as typeof IConfidenceValidator;
