import { useLoaderData } from '@tanstack/react-router';
import { Resizable } from 're-resizable';
import { useCallback } from 'react';

import { Loading } from '@/components/core';
import {
  DocumentForm,
  DocumentTableForm,
  DocumentToolbar,
  DocumentViewer,
  useCreateValidationContext,
  ValidationContext,
} from '@/components/ui';
import { DocumentProvider, useGetProjectFromPath, useKey, useProjectRunTree, useSearch } from '@/hooks';
import { InboxProvider } from '@/store';
import { findModelId, findPredictionId, findValidationId, findValidationTaskId } from '@/utils';

import { DetailsEmailPanelWrapper } from './DetailsEmailPanelWrapper.tsx';
import { DetailsProjectRunsPanelWrapper } from './DetailsProjectRunsPanelWrapper.tsx';
import { ReviewPredictionsTooltip } from './ReviewPredictionsTooltip.tsx';

export type SimpleValidateProps = {
  documentId: string;
  modelId: string;
  predictionId?: string;
  projectId?: string;
  taskId?: string;
  runId?: string;
  validationId?: string;
};

export const Validate = () => {
  const { data: project, isPending: isPendingProject } = useGetProjectFromPath();
  const { run } = useLoaderData({ from: '/_auth/details/projects/$projectId/runs/$runId' });
  // TODO: Need smarter logic here, what if there are multiple documents, predictions and models?
  const { taskDocument } = useProjectRunTree({ projectId: run.projectId, runId: run.runId });
  const predictionId = findPredictionId(run);
  const taskId = findValidationTaskId(run);
  const modelId = findModelId(project);
  const validationId = findValidationId(project);

  const { setSearch } = useSearch({ delay: 100 });
  const { key } = useKey({ value: { predictionId, taskId } });

  const validationContext = useCreateValidationContext();

  const onResize = useCallback(() => {
    if (!validationContext.detachTable) {
      validationContext.setDetachTable(true);
    }
  }, [validationContext]);

  if (isPendingProject) {
    return <Loading label="Loading ..." />;
  }

  if (!taskDocument || !modelId || !project) {
    // TODO: Handle this properly later
    return null;
  }

  const documentProps = {
    documentId: taskDocument.documentId,
    modelId: modelId,
    predictionId: predictionId,
    taskId: taskId,
    validationId: validationId,
    projectId: run.projectId,
    runId: run.runId,
  };

  return (
    <InboxProvider projectId={run.projectId}>
      <div className="flex w-full">
        <div>
          <DetailsProjectRunsPanelWrapper projectId={run.projectId} />
          <DetailsEmailPanelWrapper projectId={run.projectId} runId={run.runId} />
        </div>

        <DocumentProvider {...documentProps} key={key}>
          <Resizable enable={{ right: true }} defaultSize={{ width: '50vw' }}>
            <ValidationContext.Provider value={{ ...validationContext, isTableView: true }}>
              <div className="flex w-full justify-center">
                <div>
                  <DocumentToolbar className="fixed top-0 z-10 -translate-x-[50%] p-2" />
                </div>
              </div>

              <div className="flex h-[calc(100vh-theme(height.14))] flex-col">
                <DocumentViewer className="grow" />

                <Resizable
                  className="shadow-t-md aria-[hidden=true]:animate-slide-down aria-[hidden=false]:animate-slide-up overflow-auto"
                  enable={{ top: true }}
                  onResize={onResize}
                  aria-hidden={validationContext.detachTable ? undefined : validationContext.tableViewIsHidden}
                >
                  <DocumentTableForm setSearch={setSearch} className="w-full p-2" />
                </Resizable>
              </div>
            </ValidationContext.Provider>
          </Resizable>

          <div className="flex-grow">
            <ReviewPredictionsTooltip className="translate-y-64" />

            <ValidationContext.Provider value={{ ...validationContext, isTableView: false }}>
              <DocumentForm className="h-[calc(100vh-theme(height.14))] overflow-auto p-2" setSearch={setSearch} />
            </ValidationContext.Provider>
          </div>
        </DocumentProvider>
      </div>
    </InboxProvider>
  );
};
