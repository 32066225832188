import { memo } from 'react';

import { Checkbox } from '@/components/core';
import { useInboxStore } from '@/store';

const ISelectedRunId = ({ runId }: { runId: string }) => {
  const addSelectedRunId = useInboxStore((state) => state.addSelectedRunId);
  const removeSelectedRunId = useInboxStore((state) => state.removeSelectedRunId);
  const selectedRunIds = useInboxStore((state) => state.selectedRunIds);

  return (
    <Checkbox
      isSelected={selectedRunIds.has(runId)}
      onChange={(selected) => (selected ? addSelectedRunId(runId) : removeSelectedRunId(runId))}
    />
  );
};

export const SelectedRunId = memo(ISelectedRunId) as typeof ISelectedRunId;
