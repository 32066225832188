import { memo } from 'react';

import SocialSecurityNumberIcon from '@/assets/ssn-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type SocialSecurityNumberLogoProps = VariantLogoProps;

const ISocialSecurityNumberLogo = ({ ...rest }: SocialSecurityNumberLogoProps) => {
  return <FormatterValidatorLogo Icon={SocialSecurityNumberIcon} iconColoringType="stroke" {...rest} />;
};

export const SocialSecurityNumberLogo = memo(ISocialSecurityNumberLogo) as typeof ISocialSecurityNumberLogo;
