import CheckIcon from '@/assets/check-icon.svg?react';
import SaveIcon from '@/assets/save-icon.svg?react';
import { Text } from '@/components/core';

export type AutosaveProps = {
  isPending: boolean;
  isSuccess: boolean;
  error: Error | null;
};

export const Autosave = ({ isPending, isSuccess, error }: AutosaveProps) => {
  return (
    <div className="flex flex-col">
      {isPending ? (
        <div className="flex flex-row gap-2">
          <Text size="sm">Saving</Text>
          <SaveIcon className="size-4 animate-spin stroke-gray-500" />
        </div>
      ) : isSuccess ? (
        <div className="flex flex-row gap-2">
          <Text className="animate-fade opacity-0" size="sm">
            Saved!
          </Text>
          <CheckIcon className="animate-fade size-4 stroke-gray-500 opacity-0" />
        </div>
      ) : null}
      <Text size="sm">{error ? error.message : null}</Text>
    </div>
  );
};
