import { memo } from 'react';
import { Group, Separator, Toolbar, ToolbarProps } from 'react-aria-components';

import DownloadIcon from '@/assets/download-icon.svg?react';
import MinusIcon from '@/assets/minus-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import RotateCCWIcon from '@/assets/rotate-ccw-icon.svg?react';
import RotateCWIcon from '@/assets/rotate-cw-icon.svg?react';
import { merge } from '@/components';
import { Button, Text } from '@/components/core';
import { useDocumentStore } from '@/store';

export type DocumentToolbarProps = ToolbarProps;

export const DocumentToolbar = memo(({ className, ...rest }: DocumentToolbarProps) => {
  const zoom = useDocumentStore((state) => state.zoom);
  const setZoom = useDocumentStore((state) => state.setZoom);
  const setRotation = useDocumentStore((state) => state.setRotation);

  return (
    <Toolbar orientation="horizontal" className={merge('flex items-center', className)} {...rest}>
      <Group className="flex flex-row items-center" aria-label="Zoom">
        <Button
          className="hover:bg-gray-100"
          onPress={() => setZoom((previousZoom) => previousZoom / 1.5)}
          variant="plain"
        >
          <MinusIcon className="stroke-gray-400" />
        </Button>
        <Text className="px-1" size="sm">
          {Math.floor(zoom * 100)}%
        </Text>
        <Button
          className="hover:bg-gray-100"
          onPress={() => setZoom((previousZoom) => previousZoom * 1.5)}
          variant="plain"
        >
          <PlusIcon className="stroke-gray-400" />
        </Button>
      </Group>
      <Separator className="mx-4 h-full w-[1px] bg-gray-200" orientation="vertical" />
      <Group className="flex flex-row items-center" aria-label="Rotation">
        <Button
          className="hover:bg-gray-100"
          onPress={() => setRotation((previousRotation) => (previousRotation - 90) % 360)}
          variant="plain"
        >
          <RotateCCWIcon className="stroke-gray-400" />
        </Button>
        <Button
          className="hover:bg-gray-100"
          onPress={() => setRotation((previousRotation) => (previousRotation + 90) % 360)}
          variant="plain"
        >
          <RotateCWIcon className="stroke-gray-400" />
        </Button>
        <Button className="hover:bg-gray-100" variant="plain">
          <DownloadIcon className="stroke-gray-400" />
        </Button>
      </Group>
    </Toolbar>
  );
});
