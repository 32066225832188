import type { FieldFormatter } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { LogoProps } from '@/components/core';
import {
  AddressLogo,
  ClassificationLogo,
  CountryCodeLogo,
  CurrencyLogo,
  CustomCodeLogo,
  DateLogo,
  DateTimeLogo,
  EmailLogo,
  IBANNumberLogo,
  NumericLogo,
  PhoneNumberLogo,
  RegexLogo,
  SocialSecurityNumberLogo,
  StringLogo,
  TimeLogo,
  VariantLogoProps,
} from '@/components/ui';
import {
  FORMATTER_ADDRESS_FUNCTION_ID,
  FORMATTER_CLASSIFICATION_FUNCTION_ID,
  FORMATTER_COUNTRY_FUNCTION_ID,
  FORMATTER_CURRENCY_FUNCTION_ID,
  FORMATTER_CUSTOM_CODE_FUNCTION_ID,
  FORMATTER_DATE_TIME_FUNCTION_ID,
  FORMATTER_EMAIL_FUNCTION_ID,
  FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  FORMATTER_DATE_FUNCTION_ID,
  FORMATTER_NUMERIC_FUNCTION_ID,
  FORMATTER_PHONE_NUMBER_FUNCTION_ID,
  FORMATTER_REGEX_FUNCTION_ID,
  FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID,
  FORMATTER_TIME_FUNCTION_ID,
} from '@/constants';

export type FormatterLogoProps = LogoProps & {
  formatter: FieldFormatter;
};

const IFormatterLogo = ({ formatter, size, ...rest }: FormatterLogoProps) => {
  const logoProps: VariantLogoProps = { size, variant: 'formatter' };

  switch (formatter.functionId) {
    case FORMATTER_CLASSIFICATION_FUNCTION_ID:
      return <ClassificationLogo {...logoProps} {...rest} />;
    case FORMATTER_IBAN_NUMBER_FUNCTION_ID:
      return <IBANNumberLogo {...logoProps} {...rest} />;
    case FORMATTER_NUMERIC_FUNCTION_ID:
      return <NumericLogo {...logoProps} {...rest} />;
    case FORMATTER_PHONE_NUMBER_FUNCTION_ID:
      return <PhoneNumberLogo {...logoProps} {...rest} />;
    case FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID:
      return <SocialSecurityNumberLogo {...logoProps} {...rest} />;
    case FORMATTER_DATE_FUNCTION_ID:
      return <DateLogo {...logoProps} {...rest} />;
    case FORMATTER_DATE_TIME_FUNCTION_ID:
      return <DateTimeLogo {...logoProps} {...rest} />;
    case FORMATTER_TIME_FUNCTION_ID:
      return <TimeLogo {...logoProps} {...rest} />;
    case FORMATTER_REGEX_FUNCTION_ID:
      return <RegexLogo {...logoProps} {...rest} />;
    case FORMATTER_CUSTOM_CODE_FUNCTION_ID:
      return <CustomCodeLogo {...logoProps} {...rest} />;
    case FORMATTER_EMAIL_FUNCTION_ID:
      return <EmailLogo {...logoProps} {...rest} />;
    case FORMATTER_CURRENCY_FUNCTION_ID:
      return <CurrencyLogo {...logoProps} {...rest} />;
    case FORMATTER_ADDRESS_FUNCTION_ID:
      return <AddressLogo {...logoProps} {...rest} />;
    case FORMATTER_COUNTRY_FUNCTION_ID:
      return <CountryCodeLogo {...logoProps} {...rest} />;
    default:
      return <StringLogo {...logoProps} {...rest} />;
  }
};

export const FormatterLogo = memo(IFormatterLogo) as typeof IFormatterLogo;
