import { useDebounce } from '@uidotdev/usehooks';
import { useCallback, useEffect, useState } from 'react';

export const useDebounceCallback = <T extends unknown[]>(fn: (...args: T) => void, delay: number) => {
  const [value, setValue] = useState<T | undefined>(undefined);
  const debouncedValue = useDebounce(value, delay);

  const debouncedOnChange = useCallback(
    (...args: T) => {
      setValue(args);
    },
    [setValue]
  );

  useEffect(() => {
    if (debouncedValue) {
      fn(...debouncedValue);
    }
  }, [debouncedValue, fn]);

  return debouncedOnChange;
};
