import type { CreateValidationOptions, Validation } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type ValidationId = { validationId: string };

export const {
  useCreate: useCreateValidation,
  useGet: useGetValidation,
  useSuspenseBatchGet: useSuspenseBatchGetValidations,
} = create<Validation, ValidationId, CreateValidationOptions>({
  cacheKey: 'validations',
  createId: (validation) => (validation ? { validationId: validation.validationId } : null),
  equal: (validation, id) => Boolean(validation && id?.validationId && validation.validationId === id.validationId),
  create: (client, validation) => client.createValidation(validation),
  get: (client, id) => client.getValidation(id.validationId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listValidations()
        .then((response: JSONType) => {
          resolve({ data: response.validations, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateValidation(id.validationId, updates),
});

export const toValidationId = (validationId?: string | null): ValidationId | null => {
  return validationId ? { validationId } : null;
};
