import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { useComponentStack } from '@/components/core';
import {
  DEFAULT_VALIDATOR_CATEGORY,
  findField,
  NavigationContainer,
  SearchPanel,
  SearchPanelProps,
  ValidatorConfiguration,
  ValidatorMenuItem,
  VALIDATORS,
} from '@/components/ui';
import { useModelConfigStore } from '@/store';

export type NewValidatorProps = Omit<SearchPanelProps<FieldValidator>, 'renderItem' | 'searchableCategorizedData'> & {
  fieldId: string;
};

const INewValidator = ({ fieldId, className, ...rest }: NewValidatorProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const addValidator = useModelConfigStore((state) => state.addValidator);
  const numValidators = useModelConfigStore((state) => findField(state.fields, fieldId)?.validators?.length);
  const componentStack = useComponentStack();

  return (
    <SearchPanel
      className={className}
      defaultCategory={DEFAULT_VALIDATOR_CATEGORY}
      renderItem={(item) => (
        <ValidatorMenuItem
          isDisabled={item.comingSoon}
          onAction={() => {
            // The index _after_ the new validator has been added
            const index = numValidators ?? 0;
            addValidator(fieldId, item, field?.parentId);

            componentStack?.push(
              <NavigationContainer>
                <ValidatorConfiguration fieldId={fieldId} index={index} />
              </NavigationContainer>
            );
          }}
          validator={item}
        />
      )}
      searchableCategorizedData={VALIDATORS}
      {...rest}
    />
  );
};

export const NewValidator = memo(INewValidator) as typeof INewValidator;
