import { Handle, Node as Node_, NodeProps as NodeProps_, Position, useReactFlow } from '@xyflow/react';
import { memo, ReactNode, useEffect } from 'react';

import { merge } from '@/components';

export type ResourceGroupType = 'trigger' | 'model' | 'validation' | 'export' | 'other';

export type NodeProps = {
  children?: () => ReactNode;
  resourceGroupType: ResourceGroupType;
  resourceIds: string[];
};

export type NodeType = Node_<NodeProps, 'node'>;

export const INode = ({ id, data: { children }, selected }: NodeProps_<NodeType>) => {
  const reactFlow = useReactFlow();

  /* prettier-ignore */
  const styles = [
    'border',
    'p-3',
    'rounded-lg',
    selected && 'ring-brand-400',
    selected && 'ring-1',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'border-gray-200',
    'bg-white',
  ];

  useEffect(() => {
    if (selected) {
      reactFlow.fitView({ maxZoom: 1, duration: 400, nodes: [{ id }] }).catch(console.error);
    }
  }, [id, reactFlow, selected]);

  return (
    <div className={merge(styles, lightStyles)}>
      {children?.()}
      <Handle isConnectable={false} type="target" position={Position.Top} />
      <Handle isConnectable={false} type="source" position={Position.Bottom} />
    </div>
  );
};

export const Node = memo(INode) as typeof INode;
