import type { Action } from '@lucidtech/las-sdk-browser';
import { createContext } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type ActionState = Partial<Action> & Pick<Action, 'config'>;

export interface TriggerProps {
  action: ActionState;
}

export interface TriggerState extends TriggerProps {
  updateAction: (updates: Partial<ActionState> | ((state: ActionState) => ActionState)) => void;
  getAction: () => ActionState;
}

export const createTriggerStore = (initProps?: Partial<TriggerProps>) => {
  const defaultProps = {
    action: { config: {} },
  };
  return createStore<TriggerState>()(
    devtools(
      immer((set, get) => ({
        ...defaultProps,
        ...initProps,
        updateAction: (updates) =>
          set((state) => {
            const v = updates instanceof Function ? updates(state.action) : updates;
            Object.assign(state.action, v);
          }),
        getAction: (): ActionState => {
          return get().action;
        },
      }))
    )
  );
};

export type TriggerStore = ReturnType<typeof createTriggerStore>;

export const TriggerContext = createContext<TriggerStore | null>(null);
