import type { Action, Project } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { TriggerOption } from '@/components/flow';
import { toProjectId, useCreateAction, useUpdateProject } from '@/hooks/api';

export type UseAddTriggerOnSuccess = ({ action, project }: { action: Action; project: Project }) => void;
export type UseAddTriggerOnError = ({ error }: { error: Error }) => void;
export type UseAddTriggerCallbackArgs = {
  projectId: string;
  triggerOption: TriggerOption;
  onSuccess?: UseAddTriggerOnSuccess;
  onError?: UseAddTriggerOnError;
};
export type UseAddTriggerCallback = ({
  projectId,
  triggerOption,
  onSuccess,
  onError,
}: UseAddTriggerCallbackArgs) => void;

export type UseAddTriggerOpts = {
  onSuccess?: UseAddTriggerOnSuccess;
  onError?: UseAddTriggerOnError;
};

export const useAddTrigger = ({ onSuccess: onSuccessFnBase, onError: onErrorFnBase }: UseAddTriggerOpts = {}) => {
  const {
    mutateAsync: updateProject,
    isPending: isPendingUpdateProject,
    isSuccess: isSuccessUpdateProject,
    error: errorUpdateProject,
  } = useUpdateProject();
  const {
    mutateAsync: createAction,
    isPending: isPendingCreateAction,
    isSuccess: isSuccessCreateAction,
    error: errorCreateAction,
  } = useCreateAction();

  const addTrigger: UseAddTriggerCallback = useCallback(
    async ({ projectId, triggerOption, onSuccess: onSuccessFn, onError: onErrorFn }: UseAddTriggerCallbackArgs) => {
      const onSuccess: UseAddTriggerOnSuccess = (...args) => {
        onSuccessFnBase?.(...args);
        onSuccessFn?.(...args);
      };
      const onError: UseAddTriggerOnError = (...args) => {
        onErrorFnBase?.(...args);
        onErrorFn?.(...args);
      };

      try {
        const action = await createAction({
          config: triggerOption.config,
          functionId: triggerOption.functionId,
          name: triggerOption.name,
          projectId: projectId,
        });

        const project = await updateProject({
          id: toProjectId(projectId),
          updates: (project: Project) => ({
            resourceIds: [...project.resourceIds, action.actionId],
          }),
        });

        onSuccess?.({ action, project });

        return action;
      } catch (error) {
        if (error instanceof Error) {
          onError?.({ error });
        }
      }
    },
    [createAction, onErrorFnBase, onSuccessFnBase, updateProject]
  );

  return {
    addTrigger,
    isPending: isPendingCreateAction || isPendingUpdateProject,
    isSuccess: isSuccessCreateAction && isSuccessUpdateProject,
    errors: [errorCreateAction, errorUpdateProject].filter((e) => !!e),
  };
};
