import * as React from 'react';

import { GlobalAuthContext } from '@/features/auth';

export function useGlobalAuth() {
  const context = React.useContext(GlobalAuthContext);
  if (!context) {
    throw new Error('useGlobalAuth must be used within an GlobalAuthProvider');
  }
  return context;
}
