import { Document, DOCUMENT_MIME_TYPES, ProjectRun } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { useCreateDocument, useCreateProjectRun, usePutFile } from '@/hooks/api';
import { isInArray } from '@/utils';

export type UseProjectOpts = {
  projectId?: string | null;
};

export const useProject = ({ projectId: projectIdHookOpt }: UseProjectOpts = {}) => {
  const { mutateAsync: createProjectRun } = useCreateProjectRun();
  const { mutateAsync: createDocument } = useCreateDocument();
  const { mutateAsync: putDocumentFile } = usePutFile();

  const createRun = useCallback(
    ({ file, projectId: projectIdCallbackArg }: { file: File; projectId?: string }) => {
      return new Promise<{ run: ProjectRun; document: Document; file: { fileUrl: string; blob: Blob } }>(
        (resolve, reject) => {
          const projectId = projectIdCallbackArg ?? projectIdHookOpt;

          if (!projectId) {
            console.error('You must provide projectId');
            reject('You must provide projectId');
            return;
          }

          if (!isInArray(DOCUMENT_MIME_TYPES, file.type)) {
            console.error(`${file.name} has incompatible mime type: ${file.type}`);
            reject(`${file.name} has incompatible mime type: ${file.type}`);
            return;
          }
          createProjectRun({ projectId })
            .then((run) => {
              createDocument({ name: file.name, projectId: projectId, projectRunId: run.runId })
                .then((document) => {
                  putDocumentFile({ fileUrl: document.fileUrl, file })
                    .then((f) => {
                      resolve({ run, document, file: f });
                    })
                    .catch((error) => reject(error.message));
                })
                .catch((error) => reject(error.message));
            })
            .catch((error) => reject(error.message));
        }
      );
    },
    [createDocument, createProjectRun, projectIdHookOpt, putDocumentFile]
  );

  return { createRun };
};
