import { createFileRoute, notFound } from '@tanstack/react-router';

import { Workspace } from '@/features/settings';

export const Route = createFileRoute('/_auth/settings/workspace')({
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth.client) throw notFound();
  },
  component: Workspace,
  notFoundComponent: () => {
    return <p>Workspace not found!</p>;
  },
});
