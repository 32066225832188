import { memo } from 'react';

import { UploadDocumentsDropzone } from './UploadDocumentsDropzone.tsx';

export type ProjectRunsEmptyProps = {
  projectId: string;
};

const IProjectRunsEmpty = ({ projectId }: ProjectRunsEmptyProps) => {
  return (
    <UploadDocumentsDropzone
      className="m-4 flex h-80 flex-col items-center justify-center rounded-lg border-dashed border-gray-200 bg-white"
      projectId={projectId}
    />
  );
};

export const ProjectRunsEmpty = memo(IProjectRunsEmpty) as typeof IProjectRunsEmpty;
