import { memo, ReactNode } from 'react';
import { Slider as Slider_, SliderProps as SliderProps_, SliderThumb, SliderTrack } from 'react-aria-components';

import { Label } from '@/components/core';
import { OmitChildren } from '@/utils';

export type SliderProps = OmitChildren<SliderProps_> & {
  children?: ReactNode;
};

const ISlider = ({ children, ...rest }: SliderProps) => {
  return (
    <Slider_ {...rest}>
      <Label>Choose confidence</Label>
      {children}
      <SliderTrack className="relative h-8 w-full">
        {({ state }) => (
          <>
            {/* track */}
            <div className="bg-brand-500 absolute top-[50%] h-2 w-full translate-y-[-50%] rounded-full" />
            {/* fill */}
            <div
              className="bg-brand-500 absolute top-[50%] h-2 translate-y-[-50%] rounded-full"
              style={{ width: state.getThumbPercent(0) * 100 + '%' }}
            />
            <SliderThumb className="border-brand-500 top-[50%] h-7 w-7 rounded-full border bg-white transition outline-none" />
          </>
        )}
      </SliderTrack>
    </Slider_>
  );
};

export const Slider = memo(ISlider) as typeof ISlider;
