import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { AuthCallback } from '@/features/auth';

const AuthCallbackSearchSchema = z.object({
  code: z.optional(z.string()),
  client_id: z.optional(z.string()),
  error: z.optional(z.string()),
  error_description: z.optional(z.string()),
});

export const Route = createFileRoute('/authCallback')({
  component: AuthCallback,
  validateSearch: zodValidator(AuthCallbackSearchSchema),
});
