import { memo } from 'react';

import NumericIcon from '@/assets/field-type-numeric.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type NumericLogoProps = VariantLogoProps;

const INumericLogo = ({ ...rest }: NumericLogoProps) => {
  return <FormatterValidatorLogo Icon={NumericIcon} iconColoringType="stroke" {...rest} />;
};

export const NumericLogo = memo(INumericLogo) as typeof INumericLogo;
