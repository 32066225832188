import type { FieldValidator, Function } from '@lucidtech/las-sdk-browser';
import { useCallback, useMemo } from 'react';

import { useBatchGetFile, useBatchGetFunctions } from '@/hooks/api';
import { selectFromFieldConfig, useDocumentStore } from '@/store';
import { FieldContextType, FieldStateValueType, importJS, ValidatorFnReturnType, ValidatorFnType } from '@/utils';

type ValidatorResolved = FieldValidator & {
  function?: Function;
  transform?: ValidatorFnType;
};

export type UseValidatorsOpts = {
  name: string;
};

export enum ValidationType {
  Error,
  Warning,
}

export class ValidationError extends Error {
  type: ValidationType;
  message: string;

  constructor(type: ValidationType, message: string) {
    super(message);
    this.type = type;
    this.message = message;
  }
}

export const useValidators = ({ name }: UseValidatorsOpts) => {
  const config = useDocumentStore(selectFromFieldConfig(name));
  const { data: functions } = useBatchGetFunctions(config?.validators?.map(({ functionId }) => ({ functionId })));
  const { data: validatorBlobs } = useBatchGetFile(functions?.map((f) => ({ fileUrl: f.fileUrl })));
  const validators = useMemo(async () => {
    const validators: ValidatorResolved[] = config?.validators?.map((f) => ({ ...f })) ?? [];
    if (validators.length === functions.length) {
      for (const [i, validator] of validators.entries()) {
        validator.function = functions[i];
        const validatorBlob = validatorBlobs[i];
        if (!validatorBlob) continue;
        const codeModule = await importJS(validatorBlob);
        validator.transform = codeModule.default;
      }
    }
    return validators;
  }, [validatorBlobs, config?.validators, functions]);

  const run = useCallback(
    async (state: FieldStateValueType | undefined, context: FieldContextType): Promise<ValidatorFnReturnType> => {
      let newState = state;
      const errors: string[] = [];
      const warnings: string[] = [];

      if (validators) {
        for (const validator of await validators) {
          if (!validator) continue;

          const validatorContext = structuredClone(context);
          validatorContext.config = structuredClone(validator.config);
          if (!validator.transform) continue;

          try {
            const {
              result,
              errors: newErrors,
              warnings: newWarnings,
            } = validator.transform(structuredClone(newState ?? {}), validatorContext);

            newState = result;
            errors.push(...(newErrors ?? []));
            warnings.push(...(newWarnings ?? []));
          } catch (error) {
            if (error instanceof Error) {
              const status = context.config.validationMode === 'error' ? errors : warnings;
              status.push(error.message);
            }
          }
        }
      }

      return { result: newState, errors, warnings };
    },
    [validators]
  );

  return { run };
};
