import { useNavigate } from '@tanstack/react-router';
import { DetailedHTMLProps, HTMLAttributes, memo } from 'react';
import { MenuTrigger, Separator } from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import InviteMembersIcon from '@/assets/invite-members-icon.svg?react';
import LogoutIcon from '@/assets/logout-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import ProfileSettingsIcon from '@/assets/profile-settings-icon.svg?react';
import WorkspaceSettingsIcon from '@/assets/settings-icon.svg?react';
import { merge } from '@/components';
import { Button, ButtonProps, Checkbox, Header, Menu, MenuItem, Popover } from '@/components/core';
import { OrganizationLogo } from '@/components/ui';
import { useAuth } from '@/hooks';
import { toOrganizationId, useListOrganizations, useListProjects, useSuspenseGetOrganization } from '@/hooks/api';

const IMenuButton = ({ className, children, ...rest }: Omit<ButtonProps, 'variant'>) => {
  return (
    <Button
      className={merge('w-full justify-start p-0 text-gray-500 hover:bg-gray-50', className)}
      variant="plain"
      {...rest}
    >
      {children}
    </Button>
  );
};

const MenuButton = memo(IMenuButton) as typeof IMenuButton;

export type WorkspaceCardProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  organizationId: string;
};

const IWorkspaceCard = ({ organizationId, className }: WorkspaceCardProps) => {
  const { data: organization } = useSuspenseGetOrganization(toOrganizationId(organizationId));
  const { data: organizationList } = useListOrganizations();
  const navigate = useNavigate();

  const { setClientId } = useAuth();

  return (
    <div className={merge('flex flex-row items-center gap-2 px-2', className)}>
      <OrganizationLogo size="lg" organizationId={organizationId} />
      <Header className="text-md shrink truncate font-semibold" size="lg">
        {organization.name || organization.organizationId}
      </Header>
      <MenuTrigger>
        <Button variant="plain" className="group/menu-button">
          <ChevronDownIcon className="stroke-gray-500" />
        </Button>
        <Popover>
          <Menu>
            {organizationList?.data.map((o) => (
              <MenuItem
                key={o.organizationId}
                onAction={async () => {
                  setClientId(o.clientId);
                  await navigate({ to: `/projects` });
                }}
              >
                <MenuButton>
                  <OrganizationLogo size="md" organizationId={o.organizationId} />
                  {o.name}
                  <div className="grow" />
                  <Checkbox isSelected={o.organizationId === organization.organizationId} />
                </MenuButton>
              </MenuItem>
            ))}
            <MenuItem
              onAction={async () => {
                await navigate({ to: '/organizations/new' });
              }}
            >
              <MenuButton>
                <PlusIcon className="size-4 stroke-gray-400" />
                New organization
              </MenuButton>
            </MenuItem>
            <Separator className="h-[1px] w-full bg-gray-200" orientation="horizontal" />
            <MenuItem
              onAction={async () => {
                await navigate({ to: '/settings/workspace' });
              }}
            >
              <MenuButton>
                <InviteMembersIcon className="size-4 stroke-gray-400" />
                Invite members
              </MenuButton>
            </MenuItem>
            <MenuItem
              onAction={async () => {
                await navigate({ to: '/settings/profile' });
              }}
            >
              <MenuButton>
                <ProfileSettingsIcon className="size-4 stroke-gray-400" />
                Profile settings
              </MenuButton>
            </MenuItem>
            <MenuItem
              onAction={async () => {
                await navigate({ to: '/settings/workspace' });
              }}
            >
              <MenuButton>
                <WorkspaceSettingsIcon className="size-4 stroke-gray-400" />
                Account settings
              </MenuButton>
            </MenuItem>
            <Separator className="h-[1px] w-full bg-gray-200" orientation="horizontal" />
            <MenuItem
              onAction={async () => {
                await navigate({ to: '/logout' });
              }}
            >
              <MenuButton>
                <LogoutIcon className="size-4 stroke-gray-400" />
                Logout
              </MenuButton>
            </MenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
};

export const WorkspaceCard = memo(IWorkspaceCard) as typeof IWorkspaceCard;
