import { useMemo } from 'react';

import { JSONType } from '@/utils';

export type UseKeyOpts = {
  value: JSONType;
};

export const useKey = ({ value }: UseKeyOpts) => {
  const key = useMemo(() => JSON.stringify(value), [value]);
  return { key };
};
