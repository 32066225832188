import { memo } from 'react';

import { useProjectRunTree } from '@/hooks';

import { DetailsEmailPanel } from './DetailsEmailPanel.tsx';
import { useOpenPanel } from './useOpenPanel.ts';

export type DetailsEmailPanelWrapperProps = {
  projectId: string;
  runId: string;
};

const IDetailsEmailPanelWrapper = ({ projectId, runId }: DetailsEmailPanelWrapperProps) => {
  const { emailDocument } = useProjectRunTree({ projectId, runId });
  const { emailPanelOpen } = useOpenPanel();

  if (!emailPanelOpen || !emailDocument) return null;

  return <DetailsEmailPanel documentId={emailDocument.documentId} />;
};

export const DetailsEmailPanelWrapper = memo(IDetailsEmailPanelWrapper) as typeof IDetailsEmailPanelWrapper;
