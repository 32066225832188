// Modules available to custom code functions
import './bundle.ts';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider, useLocation } from '@tanstack/react-router';
import { PropsWithChildren, useEffect } from 'react';
import * as React from 'react';

import { useAuth, useGlobalAuth, useRudderAnalytics } from '@/hooks';

import { initSentry } from './instrument';
import { routeTree } from './routeTree.gen';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});

const PageTracker = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const { analytics } = useRudderAnalytics();

  useEffect(() => {
    if (analytics && location.pathname) {
      analytics.page('Loaded a Page');
    }
  }, [location.pathname, analytics]);

  return <>{children}</>;
};

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
    globalAuth: undefined!,
    queryClient: queryClient,
  },
  InnerWrap: ({ children }) => <PageTracker>{children}</PageTracker>,
});

initSentry(Sentry.tanstackRouterBrowserTracingIntegration(router));

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

let TanStackQueryDevtools;
if (process.env.NODE_ENV === 'production') {
  TanStackQueryDevtools = () => null;
} else {
  TanStackQueryDevtools = React.lazy(() =>
    import('@tanstack/react-query-devtools').then((res) => ({
      default: res.ReactQueryDevtools,
    }))
  );
}

export const App = () => {
  const auth = useAuth();
  const globalAuth = useGlobalAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ auth, globalAuth }} />
      <TanStackQueryDevtools />
    </QueryClientProvider>
  );
};
