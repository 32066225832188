import { createFileRoute, notFound } from '@tanstack/react-router';

import { DetailsNavigationBar } from '@/features/projects';
import { getProjectOptions, toProjectId } from '@/hooks/api';

export const Route = createFileRoute('/_auth/details/projects/$projectId')({
  loader: async ({ context: { auth, queryClient }, params: { projectId } }) => {
    if (!auth.client) throw notFound();
    const project = await queryClient.ensureQueryData(
      getProjectOptions(auth.client, queryClient, toProjectId(projectId))
    );
    if (!project) throw notFound();
    return { project };
  },
  component: DetailsNavigationBar,
  notFoundComponent: () => {
    return <p>Project not found!</p>;
  },
});
