import { memo, useMemo } from 'react';

import AIModelLogo from '@/assets/ai-model-icon.svg?react';
import { merge } from '@/components';
import { Autosave, Header, Logo, Text } from '@/components/core';
import { toModelId, useAutoUpdateModel, useSuspenseGetModel } from '@/hooks/api';
import { useModelConfigStore } from '@/store';
import { convertToFieldConfig, DivProps, formatDate } from '@/utils';

export type ModelPanelHeaderProps = DivProps & {
  modelId: string;
};

const IModelPanelHeader = ({ modelId, className, ...rest }: ModelPanelHeaderProps) => {
  const { data: model } = useSuspenseGetModel(toModelId(modelId));
  const fields = useModelConfigStore((state) => state.fields);

  const updates = useMemo(() => {
    return { fieldConfig: convertToFieldConfig(fields) };
  }, [fields]);

  const { isPending, isSuccess, error } = useAutoUpdateModel(1000, toModelId(modelId), updates);

  return (
    <div className={merge('flex items-center gap-4', className)} {...rest}>
      <Logo className="border-none bg-blue-500" size="xl">
        <AIModelLogo className="text-white" />
      </Logo>
      <div>
        <Header size="lg">AI Model</Header>
        <Text size="xs">Last updated: {formatDate(model.updatedTime ?? model.createdTime)}</Text>
      </div>
      <div className="grow" />
      <Autosave isPending={isPending} isSuccess={isSuccess} error={error} />
    </div>
  );
};

export const ModelPanelHeader = memo(IModelPanelHeader) as typeof IModelPanelHeader;
