import { memo } from 'react';

import StringIcon from '@/assets/field-type-string.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type StringLogoProps = VariantLogoProps;

const IStringLogo = ({ ...rest }: StringLogoProps) => {
  return <FormatterValidatorLogo Icon={StringIcon} iconColoringType="stroke" {...rest} />;
};

export const StringLogo = memo(IStringLogo) as typeof IStringLogo;
