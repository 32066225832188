import { memo } from 'react';

import { merge } from '@/components';
import { DivProps } from '@/utils';

export type StepProps = DivProps;

const IStep = ({ className, children, ...rest }: StepProps) => {
  return (
    <div className={merge('flex flex-col gap-4 bg-white p-6', className)} {...rest}>
      {children}
    </div>
  );
};

export const Step = memo(IStep) as typeof IStep;
