import type { Field } from '@lucidtech/las-sdk-browser';

import {
  FORMATTER_ADDRESS_FUNCTION_ID,
  FORMATTER_CLASSIFICATION_FUNCTION_ID,
  FORMATTER_COUNTRY_FUNCTION_ID,
  FORMATTER_CURRENCY_FUNCTION_ID,
  FORMATTER_CUSTOM_CODE_FUNCTION_ID,
  FORMATTER_DATE_TIME_FUNCTION_ID,
  FORMATTER_EMAIL_FUNCTION_ID,
  FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  FORMATTER_DATE_FUNCTION_ID,
  FORMATTER_NUMERIC_FUNCTION_ID,
  FORMATTER_PHONE_NUMBER_FUNCTION_ID,
  FORMATTER_REGEX_FUNCTION_ID,
  FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID,
  FORMATTER_TIME_FUNCTION_ID,
} from '@/constants';
import { FieldState } from '@/store';

export const findField = (fields: FieldState[], id: string) => {
  for (const field of fields) {
    if (field.id === id) {
      return field;
    }
    if (field.fields) {
      for (const subField of field.fields) {
        if (subField.id === id) {
          return subField;
        }
      }
    }
  }
};

export type FieldOrFieldState = FieldState | Field;

export const isDateField = (field: FieldOrFieldState) => {
  return Boolean(
    field.formatters?.find((f) =>
      [FORMATTER_DATE_FUNCTION_ID, FORMATTER_DATE_TIME_FUNCTION_ID].includes(f.functionId)
    )
  );
};

export const isNumericField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_NUMERIC_FUNCTION_ID].includes(f.functionId)));
};

export const isClassificationField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_CLASSIFICATION_FUNCTION_ID].includes(f.functionId)));
};

export const isIBANNumberField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_IBAN_NUMBER_FUNCTION_ID].includes(f.functionId)));
};

export const isRegexField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_REGEX_FUNCTION_ID].includes(f.functionId)));
};

export const isEmailField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_EMAIL_FUNCTION_ID].includes(f.functionId)));
};

export const isCustomCodeField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_CUSTOM_CODE_FUNCTION_ID].includes(f.functionId)));
};

export const isTimeField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_TIME_FUNCTION_ID].includes(f.functionId)));
};

export const isSocialSecurityNumberField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_SOCIAL_SECURITY_NUMBER_FUNCTION_ID].includes(f.functionId)));
};

export const isDateTimeField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_DATE_TIME_FUNCTION_ID].includes(f.functionId)));
};

export const isCurrencyField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_CURRENCY_FUNCTION_ID].includes(f.functionId)));
};

export const isAddressField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_ADDRESS_FUNCTION_ID].includes(f.functionId)));
};

export const isCountryCodeField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_COUNTRY_FUNCTION_ID].includes(f.functionId)));
};

export const isPhoneNumberField = (field: FieldOrFieldState) => {
  return Boolean(field.formatters?.find((f) => [FORMATTER_PHONE_NUMBER_FUNCTION_ID].includes(f.functionId)));
};
