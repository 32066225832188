import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/projects/$projectId/')({
  beforeLoad: async ({ params }) => {
    throw redirect({
      to: '/projects/$projectId/inbox',
      params: { projectId: params.projectId },
    });
  },
});
