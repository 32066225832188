import { ColumnDef, Row } from '@tanstack/react-table';
import { memo, useCallback, useEffect, useMemo } from 'react';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import { merge } from '@/components';
import {
  Button,
  DebouncedInput,
  Input,
  ListBox,
  ListBoxItem,
  MenuItem,
  MenuItemProps,
  Popover,
  Select,
  SelectValue,
  Table,
  TableProps,
  Text,
} from '@/components/core';
import { useTriggerStore } from '@/store';
import { toString } from '@/utils';
import { generateId, jsonToList } from '@/utils';

type HTTPMethodItemProps = MenuItemProps & {
  httpMethod: string;
};

const IHTTPMethodItem = ({ className, httpMethod, ...rest }: HTTPMethodItemProps) => {
  const action = useTriggerStore((state) => state.action);
  const updateAction = useTriggerStore((state) => state.updateAction);
  return (
    <MenuItem
      onAction={() => updateAction({ config: { ...action.config, httpMethod } })}
      className={merge('rounded-lg p-2 hover:bg-gray-50', className)}
      {...rest}
    >
      <Text>{httpMethod}</Text>
    </MenuItem>
  );
};

export const HTTPMethodItem = memo(IHTTPMethodItem) as typeof IHTTPMethodItem;

type HttpHeader = {
  id: string;
  key?: string;
  value?: string;
};

type IHttpHeaderProps = Omit<TableProps<HttpHeader>, 'columns' | 'data'>;

const IHttpHeaders = ({ ...rest }: IHttpHeaderProps) => {
  const { getAction, updateAction } = useTriggerStore((state) => state);
  const action = useTriggerStore((state) => state.action);

  const headers = useMemo(() => jsonToList<HttpHeader>(action.config?.headers), [action.config]);

  useEffect(() => {
    updateAction((action) => ({ config: { ...action.config, headers } }));
  }, [headers, updateAction]);

  const updateHeader = useCallback(
    (id: string, prop: keyof HttpHeader, val?: string) => {
      updateAction((action) => {
        const headers = jsonToList<HttpHeader>(action.config?.headers);
        const header = headers.find((h: HttpHeader) => h.id === id);
        if (header && val) {
          header[prop] = val;
        }

        const oldConfig = action?.config;
        return { ...action, config: { ...oldConfig, headers } };
      });
    },
    [updateAction]
  );

  const onDeleteRow = useCallback(
    (row: Row<HttpHeader>) => {
      updateAction((action) => {
        const headers = jsonToList<HttpHeader>(action.config?.headers).filter((h: HttpHeader) => h.id !== row.id);
        return { ...action, config: { ...action?.config, headers } };
      });
    },
    [updateAction]
  );

  const onAddRow = useCallback(() => {
    updateAction((action) => {
      const headers = jsonToList<HttpHeader>(action.config?.headers);
      const oldConfig = action?.config;
      return { ...action, config: { ...oldConfig, headers: [...headers, { id: generateId() }] } };
    });
  }, [updateAction]);

  const columns = useMemo<ColumnDef<HttpHeader>[]>(() => {
    const headers = jsonToList<HttpHeader>(getAction().config?.headers);

    return [
      {
        accessorKey: 'key',
        header: 'Header',
        cell: ({ row }) => (
          <DebouncedInput
            defaultValue={headers[row.index]?.key}
            onChange={(e) => updateHeader(row.id, 'key', e.target.value)}
          />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        accessorKey: 'value',
        header: 'Value',
        cell: ({ row }) => (
          <DebouncedInput
            defaultValue={headers[row.index]?.value}
            onChange={(e) => updateHeader(row.id, 'value', e.target.value)}
          />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
    ];
  }, [getAction, updateHeader]);

  return (
    <>
      <Table columns={columns} data={headers} deleteRowAction={onDeleteRow} {...rest} />
      <Button onPress={onAddRow} variant="secondary">
        Add new header
      </Button>
    </>
  );
};

export const HttpHeaders = memo(IHttpHeaders) as typeof IHttpHeaders;

export type ExportWebhookConfigurationProps = object;

// eslint-disable-next-line no-empty-pattern
const IExportWebhookConfiguration = ({}: ExportWebhookConfigurationProps) => {
  const action = useTriggerStore((state) => state.action);
  const updateAction = useTriggerStore((state) => state.updateAction);

  return (
    <div className="flex flex-col gap-2 p-4">
      <Text size="sm">Send files to:</Text>
      <Input
        onChange={(e) => updateAction({ config: { ...action.config, url: e.target.value } })}
        className="text-xs text-gray-600"
        value={toString(action.config?.url)}
      />
      <div className="flex flex-col gap-2">
        <Text size="sm">HTTP method:</Text>
        <Select
          defaultSelectedKey={toString(action.config?.httpMethod) || 'post'}
          onSelectionChange={(key) => updateAction({ config: { ...action.config, httpMethod: key.toString() } })}
        >
          <div className="flex grow-0">
            <Button variant="secondary">
              <SelectValue />
              <ChevronDownIcon className="stroke-gray-900" />
            </Button>
          </div>
          <Popover>
            <ListBox>
              <ListBoxItem id="post">POST</ListBoxItem>
              <ListBoxItem id="get">GET</ListBoxItem>
              <ListBoxItem id="put">PUT</ListBoxItem>
            </ListBox>
          </Popover>
        </Select>
      </div>

      <div>
        <Text size="sm">Headers</Text>
        <HttpHeaders />
      </div>
    </div>
  );
};

export const ExportWebhookConfiguration = memo(IExportWebhookConfiguration) as typeof IExportWebhookConfiguration;
