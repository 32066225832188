import { Resizable } from 're-resizable';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { merge } from '@/components';

export type PanelProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Panel = ({ className, children, ...rest }: PanelProps) => {
  return (
    // FIXME (h-[calc(...)]): Why do we need to calc instead of just using h-full
    <div className={merge('flex h-[calc(100vh-theme(height.14))] bg-white', className)} {...rest}>
      <Resizable className="shadow-r-md h-full" defaultSize={{ width: 400 }} enable={{ right: true }}>
        <div className="h-full w-full overflow-y-auto">{children}</div>
      </Resizable>
    </div>
  );
};
