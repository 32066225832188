import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { LogoProps } from '@/components/core';
import {
  ConfidenceLogo,
  CustomCodeLogo,
  DatabaseMatchLogo,
  LogicalExpressionLogo,
  RegexLogo,
  StringLogo,
  VariantLogoProps,
} from '@/components/ui';
import {
  VALIDATOR_CONFIDENCE_FUNCTION_ID,
  VALIDATOR_CUSTOM_CODE_FUNCTION_ID,
  VALIDATOR_DATABASE_MATCH_FUNCTION_ID,
  VALIDATOR_LOGEX_FUNCTION_ID,
  VALIDATOR_REGEX_FUNCTION_ID,
} from '@/constants';

export type ValidatorLogoProps = LogoProps & {
  validator: FieldValidator;
};

const IValidatorLogo = ({ validator, size, ...rest }: ValidatorLogoProps) => {
  const logoProps: VariantLogoProps = { size, variant: 'validator' };

  switch (validator.functionId) {
    case VALIDATOR_CONFIDENCE_FUNCTION_ID:
      return <ConfidenceLogo {...logoProps} {...rest} />;
    case VALIDATOR_LOGEX_FUNCTION_ID:
      return <LogicalExpressionLogo {...logoProps} {...rest} />;
    case VALIDATOR_REGEX_FUNCTION_ID:
      return <RegexLogo {...logoProps} {...rest} />;
    case VALIDATOR_CUSTOM_CODE_FUNCTION_ID:
      return <CustomCodeLogo {...logoProps} {...rest} />;
    case VALIDATOR_DATABASE_MATCH_FUNCTION_ID:
      return <DatabaseMatchLogo {...logoProps} {...rest} />;
    default:
      return <StringLogo {...logoProps} {...rest} />;
  }
};

export const ValidatorLogo = memo(IValidatorLogo) as typeof IValidatorLogo;
