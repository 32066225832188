import { PropsWithChildren, useRef } from 'react';

import { createModelConfigStore, ModelConfigContext, ModelConfigProps, ModelConfigStore } from './modelConfigStore';

type ModelConfigProviderProps = PropsWithChildren<ModelConfigProps>;

export const ModelConfigProvider = ({ children, ...props }: ModelConfigProviderProps) => {
  const storeRef = useRef<ModelConfigStore>(null);

  if (!storeRef.current) {
    storeRef.current = createModelConfigStore(props);
  }

  return <ModelConfigContext.Provider value={storeRef.current}>{children}</ModelConfigContext.Provider>;
};
