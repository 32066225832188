import { memo } from 'react';

import ZapierIcon from '@/assets/zapier-logo.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type ZapierLogoProps = LogoProps;

const IZapierLogo = ({ className, ...rest }: ZapierLogoProps) => {
  return (
    <Logo className={merge('rounded-lg border border-gray-200 bg-white', className)} {...rest}>
      <ZapierIcon className="size-5" />
    </Logo>
  );
};

export const ZapierLogo = memo(IZapierLogo) as typeof IZapierLogo;
