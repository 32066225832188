import { memo } from 'react';
import { Link as Link_, LinkProps as LinkProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type LinkProps = LinkProps_ & {};

const ILink = ({ className, children, ...rest }: LinkProps) => {
  return (
    <Link_ className={merge('underline', className)} target="_blank" {...rest}>
      {children}
    </Link_>
  );
};

export const Link = memo(ILink) as typeof ILink;
