import { HTMLAttributes, memo } from 'react';

import { WizardState } from '../ui';

export type StepIndicatorProps = {
  max: number;
  current: number;
  startStep?: number;
  setCurrent: (p: number) => void;
  hideLast?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const IStepIndicator = ({ max, current, startStep, setCurrent, hideLast, ...rest }: StepIndicatorProps) => {
  const start = startStep ?? 0;

  return (
    <div {...rest} className="mt-5 flex justify-center gap-1">
      {Array.from({ length: max - (hideLast ? 1 : 0) }).map((_, index) => {
        return (
          <div
            key={index}
            aria-selected={index + start === current}
            onClick={() => setCurrent(start + index)}
            className="h-[0.7rem] w-[0.7rem] rounded-full aria-[selected=false]:bg-gray-200 aria-[selected=true]:bg-blue-400"
          />
        );
      })}
    </div>
  );
};

export const StepIndicator = memo(IStepIndicator) as typeof IStepIndicator;

export interface WizardStepIndicatorProps<T> {
  useWizard: () => WizardState<T>;
  hideLast?: boolean;
}

const IWizardStepIndicator = <T extends object>({ useWizard }: WizardStepIndicatorProps<T>) => {
  const wizard = useWizard();
  return <StepIndicator current={wizard.step} setCurrent={wizard.setStep} max={wizard.maxSteps} hideLast />;
};

export const WizardStepIndicator = memo(IWizardStepIndicator) as typeof IWizardStepIndicator;
