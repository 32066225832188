import { memo } from 'react';

import BankStatementThumbnail from '@/assets/bank-statement-thumbnail.svg?react';
import BillOfLadingThumbnail from '@/assets/bill-of-lading-thumbnail.svg?react';
import InvoiceThumbnail from '@/assets/invoice-thumbnail.svg?react';
import OtherThumbnail from '@/assets/other-thumbnail.svg?react';
import PurchaseOrderThumbnail from '@/assets/purchase-order-thumbnail.svg?react';
import ReceiptThumbnail from '@/assets/receipt-thumbnail.svg?react';
import { TEMPLATES } from '@/components/ui';

export type TemplateThumbnailProps = {
  template: keyof typeof TEMPLATES;
};

const ITemplateThumbnail = ({ template }: TemplateThumbnailProps) => {
  switch (template) {
    case 'Other':
      return <OtherThumbnail />;
    case 'Bills of Lading':
      return <BillOfLadingThumbnail />;
    case 'Invoices':
      return <InvoiceThumbnail />;
    case 'Bank Statements':
      return <BankStatementThumbnail />;
    case 'Purchase Orders':
      return <PurchaseOrderThumbnail />;
    case 'Receipts':
      return <ReceiptThumbnail />;
    default:
      return <InvoiceThumbnail />;
  }
};

export const TemplateThumbnail = memo(ITemplateThumbnail) as typeof ITemplateThumbnail;
