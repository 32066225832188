import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { memo } from 'react';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import EmailIcon from '@/assets/email-icon.svg?react';
import ListCheckIcon from '@/assets/list-check-icon.svg?react';
import CradlLogo from '@/assets/logo.svg?react';
import { merge } from '@/components';
import { Button, Text } from '@/components/core';
import { NavigationBar } from '@/components/ui';
import { toDocumentId, useGetDocument } from '@/hooks/api';

import { useOpenPanel } from './useOpenPanel.ts';

export type DetailsNavigationBarProps = object;

// eslint-disable-next-line no-empty-pattern
const IDetailsNavigationBar = ({}: DetailsNavigationBarProps = {}) => {
  const { run } = useLoaderData({ from: '/_auth/details/projects/$projectId/runs/$runId' });
  const navigate = useNavigate();
  const { projectRunsPanelOpen, emailPanelOpen } = useOpenPanel();
  const documentId = run.resourceIds.find((r) => r.startsWith('las:document:'));
  const { data: document } = useGetDocument(toDocumentId(documentId));

  return (
    <div className="h-full w-full">
      <div className="flex h-14 w-full items-center gap-3 border-b border-gray-200 bg-white p-2">
        <Button
          onPress={() => navigate({ to: '/projects/$projectId', params: { projectId: run.projectId } })}
          className="px-3 hover:bg-gray-100"
          variant="plain"
        >
          <CradlLogo className="size-6" />
        </Button>
        <Button
          onPress={() => navigate({ to: '/projects/$projectId/inbox', params: { projectId: run.projectId } })}
          className="hover:bg-gray-100"
          variant="plain"
        >
          <Text>Inbox</Text>
        </Button>
        <ChevronDownIcon className="-rotate-90 stroke-gray-400" />
        <Text>{document?.name}</Text>
      </div>
      <NavigationBar
        className="h-[calc(100vh-theme(height.14))] items-center py-2"
        initialWidth={60}
        enableResizing={false}
      >
        <Button
          onPress={() =>
            navigate({
              to: '.',
              search: projectRunsPanelOpen ? undefined : { showPanel: 'project-runs' },
            })
          }
          className={merge('hover:bg-gray-100', projectRunsPanelOpen && 'bg-gray-100')}
          variant="plain"
        >
          <ListCheckIcon className={merge('size-6 fill-gray-400', projectRunsPanelOpen && 'fill-gray-800')} />
        </Button>
        <Button
          onPress={() =>
            navigate({
              to: '.',
              search: emailPanelOpen ? undefined : { showPanel: 'email' },
            })
          }
          className={merge('hover:bg-gray-100', emailPanelOpen && 'bg-gray-100')}
          variant="plain"
        >
          <EmailIcon className={merge('size-6 stroke-gray-400', emailPanelOpen && 'stroke-gray-800')} />
        </Button>
      </NavigationBar>
    </div>
  );
};

export const DetailsNavigationBar = memo(IDetailsNavigationBar) as typeof IDetailsNavigationBar;
