export const ProjectRunStatusValues = [
    'Archived',
    'Exported',
    'Pending export',
    'Pending predictions',
    'Ready for review',
    'Review completed',
    'Succeeded predictions',
];
export const toListProjectRunsQueryParams = (options) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const params = {};
    for (const [key, val] of Object.entries(options)) {
        switch (key) {
            case 'sort':
                {
                    const sorting = [];
                    for (const sort of val) {
                        sorting.push(`${sort.column}:${sort.order}`);
                    }
                    params[key] = sorting;
                }
                break;
            case 'createdTimeAfter':
            case 'createdTimeBefore':
            case 'updatedTimeAfter':
            case 'updatedTimeBefore':
                params[key] = val === null || val === void 0 ? void 0 : val.toISOString();
                break;
            default:
                params[key] = val;
                break;
        }
    }
    return params;
};
