import { memo } from 'react';

import { Dialog, Modal, ModalOverlay, ModalOverlayProps } from '@/components/core';
import { NewTrigger, OnTriggerOptionSelected } from '@/components/flow';

export type NewTriggerModalProps = ModalOverlayProps & {
  onTriggerOptionSelected?: OnTriggerOptionSelected;
  isAddingTrigger?: boolean;
};

const INewTriggerModal = ({ onTriggerOptionSelected, isAddingTrigger, ...rest }: NewTriggerModalProps) => {
  return (
    <ModalOverlay {...rest}>
      <Modal>
        <Dialog aria-label="NewTriggerModal" className="outline-none">
          <NewTrigger
            onTriggerOptionSelected={onTriggerOptionSelected}
            isAddingTrigger={isAddingTrigger}
            className="w-[25rem] p-4"
          />
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const NewTriggerModal = memo(INewTriggerModal) as typeof INewTriggerModal;
