import { memo } from 'react';

import AddressIcon from '@/assets/address-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type AddressLogoProps = VariantLogoProps;

const IAddressLogo = ({ ...rest }: AddressLogoProps) => {
  return <FormatterValidatorLogo Icon={AddressIcon} iconColoringType="stroke" {...rest} />;
};

export const AddressLogo = memo(IAddressLogo) as typeof IAddressLogo;
