import { memo } from 'react';

import { ProfileBadge } from './ProfileBadge';

export type MultiProfileBadgeProps = {
  profileIds?: string[];
  maxShowing?: number;
};

const IMultiProfileBadge = ({ profileIds, maxShowing = 3 }: MultiProfileBadgeProps) => {
  if (!profileIds) return null;

  const visibleProfileIds = profileIds.slice(0, maxShowing);
  const restProfileIds = profileIds.length - visibleProfileIds.length;

  return (
    <>
      {visibleProfileIds.map((profileId) => (
        <ProfileBadge key={profileId} profileId={profileId} />
      ))}
      {restProfileIds > 0 ? (
        <div className="rounded-lg border border-gray-300 bg-gray-50 px-2 py-1">+{restProfileIds}</div>
      ) : null}
    </>
  );
};

export const MultiProfileBadge = memo(IMultiProfileBadge) as typeof IMultiProfileBadge;
