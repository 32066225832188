import SampleDocument from '@/assets/sampleDocuments/Receipt.jpeg';
import { TemplateModel } from '@/components/ui';

import { amountFormatter, currencyFormatter, dateFormatter, highConfidenceValidator } from './common';

export const RECEIPT: TemplateModel = {
  fields: [
    {
      id: 'merchant_name',
      type: 'single-value',
      name: 'Merchant',
      promptHint: 'The merchant name.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'purchase_date',
      type: 'single-value',
      name: 'Purchase Date',
      promptHint: 'The date of the purchase.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'total_amount',
      type: 'single-value',
      name: 'Total Amount',
      promptHint: 'The total amount, including taxes.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'currency',
      type: 'single-value',
      name: 'Currency',
      promptHint: 'The currency of the amount.',
      formatters: [currencyFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'receipt_lines',
      type: 'table',
      name: 'Line items',
      promptHint: 'The line items on the receipt.',
      fields: [
        {
          id: 'description',
          type: 'single-value',
          name: 'Description',
          promptHint: 'Description of the purchased item or service.',
          validators: [highConfidenceValidator],
        },
        {
          id: 'line_total',
          type: 'single-value',
          name: 'Line Total',
          promptHint: 'The line total.',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
