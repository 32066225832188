import { memo } from 'react';

import { LogoProps } from '@/components/core';
import {
  AddressLogo,
  ClassificationLogo,
  CountryCodeLogo,
  CurrencyLogo,
  CustomCodeLogo,
  DateLogo,
  DateTimeLogo,
  EmailLogo,
  FieldOrFieldState,
  IBANNumberLogo,
  isAddressField,
  isClassificationField,
  isCountryCodeField,
  isCurrencyField,
  isCustomCodeField,
  isDateField,
  isDateTimeField,
  isEmailField,
  isIBANNumberField,
  isNumericField,
  isPhoneNumberField,
  isRegexField,
  isSocialSecurityNumberField,
  isTimeField,
  NumericLogo,
  PhoneNumberLogo,
  RegexLogo,
  SocialSecurityNumberLogo,
  StringLogo,
  TimeLogo,
  VariantLogoProps,
} from '@/components/ui';

export type FieldLogoProps = LogoProps & {
  field: FieldOrFieldState;
};

const IFieldLogo = ({ field, size, ...rest }: FieldLogoProps) => {
  const logoProps: VariantLogoProps = { size, variant: 'field-type' };

  if (isDateField(field)) {
    return <DateLogo {...logoProps} {...rest} />;
  } else if (isNumericField(field)) {
    return <NumericLogo {...logoProps} {...rest} />;
  } else if (isClassificationField(field)) {
    return <ClassificationLogo {...logoProps} {...rest} />;
  } else if (isIBANNumberField(field)) {
    return <IBANNumberLogo {...logoProps} {...rest} />;
  } else if (isSocialSecurityNumberField(field)) {
    return <SocialSecurityNumberLogo {...logoProps} {...rest} />;
  } else if (isAddressField(field)) {
    return <AddressLogo {...logoProps} {...rest} />;
  } else if (isDateTimeField(field)) {
    return <DateTimeLogo {...logoProps} {...rest} />;
  } else if (isTimeField(field)) {
    return <TimeLogo {...logoProps} {...rest} />;
  } else if (isPhoneNumberField(field)) {
    return <PhoneNumberLogo {...logoProps} {...rest} />;
  } else if (isCountryCodeField(field)) {
    return <CountryCodeLogo {...logoProps} {...rest} />;
  } else if (isRegexField(field)) {
    return <RegexLogo {...logoProps} {...rest} />;
  } else if (isCurrencyField(field)) {
    return <CurrencyLogo {...logoProps} {...rest} />;
  } else if (isCustomCodeField(field)) {
    return <CustomCodeLogo {...logoProps} {...rest} />;
  } else if (isEmailField(field)) {
    return <EmailLogo {...logoProps} {...rest} />;
  }

  return <StringLogo {...logoProps} {...rest} />;
};

export const FieldLogo = memo(IFieldLogo) as typeof IFieldLogo;
