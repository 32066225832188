import { memo, useState } from 'react';

import { Button, Header, Text } from '@/components/core';
import { TEMPLATES } from '@/components/ui';
import { Step, StepProps, TemplateCard } from '@/features/projects';
import { keys } from '@/utils';

type TemplateValue = keyof typeof TEMPLATES | undefined;
export type ChooseTemplateResult = { template: TemplateValue };

export type ChooseTemplateStepProps = StepProps & {
  onContinue: (result: ChooseTemplateResult) => void;
  defaultValue?: TemplateValue;
};

const IChooseTemplateStep = ({ onContinue, defaultValue, ...rest }: ChooseTemplateStepProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateValue>(defaultValue);

  return (
    <Step {...rest} className="w-150">
      <Header size="xl">What are you looking to automate?</Header>
      <Text>Select 1 for now. You can add more later</Text>
      <div className="grid grid-cols-4 gap-5">
        {keys(TEMPLATES).map((template) => (
          <TemplateCard
            key={template}
            template={template}
            isSelected={template === selectedTemplate}
            onPress={() => setSelectedTemplate(template)}
          />
        ))}
      </div>
      <Button isDisabled={!selectedTemplate} onPress={() => onContinue({ template: selectedTemplate })}>
        Continue
      </Button>
    </Step>
  );
};

export const ChooseTemplateStep = memo(IChooseTemplateStep) as typeof IChooseTemplateStep;
