import type { Action } from '@lucidtech/las-sdk-browser';
import { MarkerType } from '@xyflow/react';

import {
  DefaultNode,
  Edge,
  ExportNode,
  ModelNode,
  NodeType,
  ResourceGroupType,
  TriggerNode,
  ValidationNode,
} from '@/components/flow';
import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
  CREATE_PREDICTION_FUNCTION_ID,
  CREATE_VALIDATION_TASK_FUNCTION_ID,
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { useSuspenseBatchGetActions } from '@/hooks/api';

const calculateXPosition = (n: number, total: number) => {
  const width = 350;
  const offset = (total * width - width) / 2;
  return n * width - offset;
};

const adjustPosition = (nodes: NodeType[]) => {
  const xOffset: Record<ResourceGroupType, number> = {
    trigger: 0,
    model: 0,
    validation: 200,
    export: 0,
    other: 0,
  };
  const yPosition: Record<ResourceGroupType, number> = {
    trigger: 0,
    model: 225,
    validation: 450,
    export: 675,
    other: 900,
  };
  for (const [i, node] of nodes.entries()) {
    node.position.x = xOffset[node.data.resourceGroupType] + calculateXPosition(i, nodes.length);
    node.position.y = yPosition[node.data.resourceGroupType];
  }
};

export type UseNodesAndEdgesOpts = {
  connections: boolean[][];
  resources: string[][];
};

export const useNodesAndEdges = ({ connections, resources }: UseNodesAndEdgesOpts) => {
  const resourceIds = resources.flatMap((resourceId) => resourceId);
  const { data: actions } = useSuspenseBatchGetActions(
    resourceIds.filter((r) => r.startsWith('las:action')).map((actionId) => ({ actionId }))
  );

  const getResourceGroupType = (
    resourceIds: string[]
  ): { action: Action; type: ResourceGroupType; props: Record<string, string | undefined> } => {
    // TODO: handle errors
    const actionId = resourceIds[0];
    const action = actions.find((a) => a.actionId === actionId) as Action;
    const props: Record<string, string | undefined> = { actionId };
    let type: ResourceGroupType = 'other';

    if (
      [
        CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
        CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
        CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
      ].includes(action.functionId)
    ) {
      type = 'trigger';
      props.hookId = resourceIds.find((ri) => ri.startsWith('las:hook:'));
    } else if (action.functionId === CREATE_PREDICTION_FUNCTION_ID) {
      props.modelId = resourceIds.find((ri) => ri.startsWith('las:model:'));
      type = 'model';
    } else if (action.functionId === CREATE_VALIDATION_TASK_FUNCTION_ID) {
      props.validationId = resourceIds.find((ri) => ri.startsWith('las:validation:'));
      type = 'validation';
    } else if (
      [
        EXPORT_TO_WEBHOOK_FUNCTION_ID,
        EXPORT_TO_EXCEL_FUNCTION_ID,
        EXPORT_TO_ZAPIER_FUNCTION_ID,
        EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
      ].includes(action.functionId)
    ) {
      type = 'export';
    }

    return { action, props, type };
  };

  const nodes: NodeType[] = [];
  for (const resourceIds of resources) {
    const { action, type, props } = getResourceGroupType(resourceIds);
    let children = () => <DefaultNode resourceIds={resourceIds} />;
    if (type === 'trigger') {
      children = () => <TriggerNode {...props} />;
    } else if (type === 'model') {
      children = () => <ModelNode {...props} />;
    } else if (type === 'validation') {
      children = () => <ValidationNode {...props} />;
    } else if (type === 'export') {
      children = () => <ExportNode {...props} />;
    }
    nodes.push({
      id: action.actionId,
      position: { x: 0, y: 0 },
      data: { children, resourceGroupType: type, resourceIds },
      type: 'node',
    });
  }

  // TODO: Ugly hack to add empty state, should be handled by a zustand state
  if (!nodes.find((node) => node.data.resourceGroupType === 'trigger')) {
    nodes.push({
      id: 'newTrigger',
      position: { x: 0, y: 0 },
      data: {
        children: () => <TriggerNode />,
        resourceGroupType: 'trigger',
        resourceIds: [],
      },
      type: 'node',
    });
  }

  // TODO: Ugly hack to add empty state, should be handled by a zustand state
  if (!nodes.find((node) => node.data.resourceGroupType === 'export')) {
    nodes.push({
      id: 'newExport',
      position: { x: 0, y: 0 },
      data: {
        children: () => <ExportNode />,
        resourceGroupType: 'export',
        resourceIds: [],
      },
      type: 'node',
    });
  }

  adjustPosition(nodes.filter((n) => n.data.resourceGroupType === 'trigger'));
  adjustPosition(nodes.filter((n) => n.data.resourceGroupType === 'model'));
  adjustPosition(nodes.filter((n) => n.data.resourceGroupType === 'validation'));
  adjustPosition(nodes.filter((n) => n.data.resourceGroupType === 'export'));

  const edges: Edge[] = [];
  for (let i = 0; i < resources.length; ++i) {
    for (let j = 0; j < resources.length; ++j) {
      if (connections[i][j]) {
        const from_action_id = resources[i][0];
        const to_action_id = resources[j][0];
        edges.push({
          id: `${from_action_id}-${to_action_id}`,
          source: from_action_id,
          target: to_action_id,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
          },
          type: 'edge',
        });
      }
    }
  }

  return { nodes, edges };
};
