import { Switch as Switch_, SwitchProps as SwitchProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type SwitchProps = SwitchProps_;

export const Switch = ({ className, children, ...rest }: SwitchProps) => {
  const styles = ['flex gap-2 group/control cursor-pointer items-center'];
  const lightStyles = ['text-gray-900'];
  return (
    <Switch_ className={merge(styles, lightStyles, className)} {...rest}>
      <>
        <div className="flex h-5 w-8 items-center rounded-full bg-gray-300 px-0.5 group-data-[selected]/control:bg-slate-700">
          <span className="h-[0.95rem] w-[0.95rem] rounded-full bg-white transition duration-200 ease-in-out group-data-[selected]/control:translate-x-[85%]" />
        </div>
        {children}
      </>
    </Switch_>
  );
};
