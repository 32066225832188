import axios from 'axios';
import { SHA256 } from 'crypto-js';
import * as base64 from 'crypto-js/enc-base64';
import { Credentials, Token } from '@lucidtech/las-sdk-core';
const utils = {
    randomString(alphabet, length) {
        const buffer = new Uint8Array(length);
        window.crypto.getRandomValues(buffer);
        const chars = [];
        buffer.forEach((v) => chars.push(alphabet[v % alphabet.length]));
        return chars.join('');
    },
};
export default utils;
export class PKCE {
    constructor(verifier, challenge) {
        this.verifier = verifier || PKCE.generateVerifier();
        this.challenge = challenge || PKCE.generateChallenge(this.verifier);
        window.sessionStorage.setItem(PKCE.VERIFIER_KEY, this.verifier);
        window.sessionStorage.setItem(PKCE.CHALLENGE_KEY, this.challenge);
    }
    static generateVerifier() {
        return utils.randomString(PKCE.CODE_CHALLENGE_ALPHABET, PKCE.CODE_CHALLENGE_LENGTH);
    }
    static generateChallenge(verifier) {
        return SHA256(verifier).toString(base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
}
PKCE.CODE_CHALLENGE_ALPHABET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789._~-';
PKCE.CODE_CHALLENGE_LENGTH = 64;
PKCE.VERIFIER_KEY = 'pkceVerifier';
PKCE.CHALLENGE_KEY = 'pkceChallenge';
export class PKCEDerived extends PKCE {
    constructor(verifier, challenge, code) {
        super(verifier, challenge);
        this.code = code;
    }
    static createFromCode(code) {
        const verifier = window.sessionStorage.getItem(PKCE.VERIFIER_KEY);
        const challenge = window.sessionStorage.getItem(PKCE.CHALLENGE_KEY);
        if (!!code && !!verifier && !!challenge) {
            return new PKCEDerived(verifier, challenge, code);
        }
        return null;
    }
}
export class AuthorizationCodeCredentials extends Credentials {
    constructor(apiEndpoint, clientId, authEndpoint, redirectUri, launchUriFn, pkce, storage, logoutRedirectUri) {
        super(apiEndpoint, storage);
        this.clientId = clientId;
        this.authEndpoint = authEndpoint;
        this.redirectUri = redirectUri;
        this.logoutRedirectUri = logoutRedirectUri;
        this.launchUriFn = launchUriFn;
        this.pkce = pkce;
    }
    getToken() {
        return new Promise((resolve, reject) => {
            this.refreshToken()
                .then((token) => {
                resolve(token);
            })
                .catch((_error) => {
                this.getTokenFromCode()
                    .then((token) => {
                    resolve(token);
                })
                    .catch((error) => {
                    this.initiateOAuthFlow();
                    reject(error);
                });
            });
        });
    }
    refreshToken() {
        return new Promise((resolve, reject) => {
            let { token } = this;
            if (!token && !!this.storage) {
                token = this.storage.getPersistentToken() || undefined;
            }
            if (!(token && token.refreshToken)) {
                return reject({ message: 'No refresh token available' });
            }
            console.debug('Getting accessToken using refreshToken');
            const params = {
                grant_type: 'refresh_token',
                client_id: this.clientId,
                refresh_token: token.refreshToken,
            };
            this.postToTokenEndpoint(params)
                .then((newToken) => {
                resolve(new Token(newToken.accessToken, newToken.expiration, newToken.refreshToken || token.refreshToken));
            })
                .catch((error) => {
                console.debug(`Error getting accessToken using refreshToken: ${error.message}`);
                reject(error);
            });
        });
    }
    getTokenFromCode() {
        return new Promise((resolve, reject) => {
            console.debug('Getting accessToken using PKCE code');
            if (this.pkce) {
                const params = {
                    grant_type: 'authorization_code',
                    code: this.pkce.code,
                    client_id: this.clientId,
                    redirect_uri: this.redirectUri,
                    code_verifier: this.pkce.verifier,
                };
                this.postToTokenEndpoint(params)
                    .then((token) => {
                    resolve(token);
                })
                    .catch((error) => {
                    console.debug(`Error getting accessToken using PKCE code ${error.message}`);
                    reject(error);
                });
            }
            else {
                reject({ message: 'No PKCE code available' });
            }
        });
    }
    postToTokenEndpoint(params) {
        return new Promise((resolve, reject) => {
            const endpoint = `https://${this.authEndpoint}/oauth2/token`;
            const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            const config = { headers, params };
            axios
                .post(endpoint, null, config)
                .then((response) => {
                const token = new Token(response.data.access_token, Date.now() + 1000 * response.data.expires_in, response.data.refresh_token);
                resolve(token);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    initiateOAuthFlow(csrfToken) {
        const pkce = new PKCE();
        const params = {
            response_type: 'code',
            client_id: this.clientId,
            redirect_uri: this.redirectUri,
            code_challenge_method: 'S256',
            code_challenge: pkce.challenge,
        };
        if (csrfToken) {
            params.state = csrfToken;
        }
        const endpoint = `https://${this.authEndpoint}/oauth2/authorize`;
        const config = { url: endpoint, params };
        const uri = axios.getUri(config);
        this.launchUriFn(uri);
    }
    initiateLogoutFlow() {
        const params = {
            client_id: this.clientId,
            logout_uri: this.logoutRedirectUri || this.redirectUri,
        };
        const endpoint = `https://${this.authEndpoint}/logout`;
        const config = { url: endpoint, params };
        const uri = axios.getUri(config);
        this.token = undefined;
        this.pkce = undefined;
        this.launchUriFn(uri);
    }
    isLoggedIn() {
        return !!this.token || !!this.pkce;
    }
}
