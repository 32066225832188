import { memo } from 'react';

import { ExportPanel } from '@/components/flow';

import { SlideOut } from './SlideOut.tsx';

export type ExportPanelSlideOutProps = {
  actionId: string;
  hookId: string;
  projectId: string;
};

const IExportPanelSlideOut = ({ projectId, actionId }: ExportPanelSlideOutProps) => {
  return (
    <SlideOut>
      <ExportPanel projectId={projectId} actionId={actionId} />
    </SlideOut>
  );
};

export const ExportPanelSlideOut = memo(IExportPanelSlideOut) as typeof IExportPanelSlideOut;
