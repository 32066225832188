import { PaymentMethod } from '@lucidtech/las-sdk-browser';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useSearch } from '@tanstack/react-router';
import { useSessionStorage } from '@uidotdev/usehooks';
import { memo, useCallback, useEffect, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Dialog, Header, Modal, ModalOverlay } from '@/components/core';
import { useCreatePaymentMethod, useDeletePaymentMethod, useUpdatePaymentMethod } from '@/hooks/api';

const PaymentMethodModal = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${import.meta.env.VITE_APP_URL}/settings/billing`,
        },
      })
      .then(console.log);
  };

  return (
    <form onSubmit={handleSubmit} className="min-h-[28rem] w-[30rem] p-6">
      <Header size="xl">Add payment details</Header>

      <div className="flex flex-col gap-4">
        <PaymentElement />
        <Button isDisabled={!stripe} type="submit" variant="primary">
          Save
        </Button>
      </div>
    </form>
  );
};

const IAddPaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useSessionStorage<(PaymentMethod & { isUpdated?: boolean }) | undefined>(
    'pendingPaymentMethod',
    undefined
  );
  const [isOpen, setIsOpen] = useState(false);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethod();
  const { mutateAsync: updatePaymentMethod } = useUpdatePaymentMethod();
  const { mutateAsync: deletePaymentMethod } = useDeletePaymentMethod();
  const { redirectStatus, setupIntentSecret } = useSearch({ from: '/_auth/settings/billing' });

  const setupPaymentMethod = useCallback(async () => {
    const paymentMethod = await createPaymentMethod({});
    setPaymentMethod(paymentMethod);

    if (!stripePromise && paymentMethod?.stripePublishableKey) {
      setStripePromise(loadStripe(paymentMethod.stripePublishableKey));
    }

    setIsOpen(true);
  }, [createPaymentMethod, setPaymentMethod, stripePromise]);

  useEffect(() => {
    if (!paymentMethod || redirectStatus !== 'succeeded') return;

    if (paymentMethod.stripeSetupIntentSecret === setupIntentSecret) {
      updatePaymentMethod({ id: { paymentMethodId: paymentMethod.paymentMethodId }, updates: paymentMethod });
      setPaymentMethod(undefined);
    }
  }, [paymentMethod, redirectStatus, setPaymentMethod, setupIntentSecret, updatePaymentMethod]);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        if (paymentMethod && redirectStatus !== 'succeedd') {
          deletePaymentMethod({ paymentMethodId: paymentMethod.paymentMethodId });
          setPaymentMethod(undefined);
        }
      }

      setIsOpen(isOpen);
    },
    [deletePaymentMethod, paymentMethod, redirectStatus, setPaymentMethod]
  );

  return (
    <DialogTrigger>
      <ModalOverlay isOpen={isOpen} onOpenChange={onOpenChange}>
        <Modal>
          <Dialog>
            {paymentMethod?.stripeSetupIntentSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret: paymentMethod.stripeSetupIntentSecret }}>
                <PaymentMethodModal />
              </Elements>
            )}
          </Dialog>
        </Modal>
      </ModalOverlay>

      <Button variant="secondary" onPress={setupPaymentMethod}>
        <PlusIcon stroke="black" />
        Add payment method
      </Button>
    </DialogTrigger>
  );
};

export const AddPaymentMethod = memo(IAddPaymentMethod);
