import { memo, useMemo } from 'react';

import { useComponentStack } from '@/components/core';
import { FieldDetails, FieldMenuItem, NavigationContainer, SearchPanel, SearchPanelProps } from '@/components/ui';
import { FieldState, useModelConfigStore } from '@/store';
import { generateId } from '@/utils';

import { DEFAULT_TEMPLATE, TEMPLATES } from './templates';

export type NewFieldProps = Omit<SearchPanelProps<FieldState>, 'renderItem' | 'searchableCategorizedData'> & {
  parentId?: string;
};

const INewField = ({ parentId, className, ...rest }: NewFieldProps) => {
  const addField = useModelConfigStore((state) => state.addField);
  const componentStack = useComponentStack();
  const searchableCategorizedFields = useMemo(() => {
    return Object.fromEntries(Object.entries(TEMPLATES).map(([category, template]) => [category, template.fields]));
  }, []);

  return (
    <SearchPanel
      className={className}
      defaultCategory={DEFAULT_TEMPLATE}
      renderItem={(item) => {
        const newField = { ...item, parentId, id: generateId() };

        return (
          <FieldMenuItem
            isDisabled={item.comingSoon || (!!parentId && !!item.fields)}
            onAction={() => {
              addField(newField);

              componentStack?.push(
                <NavigationContainer>
                  <FieldDetails fieldId={newField.id} />
                </NavigationContainer>
              );
            }}
            field={item}
          />
        );
      }}
      searchableCategorizedData={searchableCategorizedFields}
      {...rest}
    />
  );
};

export const NewField = memo(INewField) as typeof INewField;
