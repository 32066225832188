import { memo } from 'react';
import {
  Disclosure,
  DisclosureGroup,
  DisclosureGroupProps,
  DisclosurePanel,
  Heading,
  Text,
  TextField,
} from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import {
  Button,
  ErrorSymbol,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  TextArea,
  WarningSymbol,
} from '@/components/core';
import { useValidatorMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

import { findField } from './utils';

type ValidationFormSettingsProps = DisclosureGroupProps & {
  fieldId: string;
  index: number;
};

const IValidationFormSettings = ({ fieldId, index, ...rest }: ValidationFormSettingsProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const mutate = useValidatorMutation(fieldId, index, field?.parentId);
  const validator = field?.validators?.[index];

  return (
    <DisclosureGroup {...rest}>
      <Disclosure id="form-settings">
        <Heading>
          <Button slot="trigger" variant="plain" className="w-full">
            <strong className="grow text-start">Validation UI Settings</strong>
            <ChevronDownIcon className="stroke-gray-600" />
          </Button>
        </Heading>
        <DisclosurePanel className="flex flex-col gap-4">
          <Select
            defaultSelectedKey={validator?.config?.validationMode ?? 'warning'}
            onSelectionChange={(key) => mutate((f) => (f.config.validationMode = key.toString()))}
          >
            <Label>If validation does not pass</Label>
            <Button variant="secondary" className="w-48 justify-start">
              <SelectValue />
              <ChevronDownIcon className="stroke-gray-600 text-sm" />
            </Button>

            <Text slot="description">Warning allows users to ignore errors.</Text>

            <Popover>
              <ListBox>
                <ListBoxItem id="warning" className="flex">
                  <WarningSymbol />
                  Warning
                </ListBoxItem>
                <ListBoxItem id="error" className="flex flex-row">
                  <ErrorSymbol />
                  Error
                </ListBoxItem>
              </ListBox>
            </Popover>
          </Select>

          <TextField
            className="flex w-full flex-col"
            onChange={(value: string) => mutate((f) => (f.config.errorMessage = value))}
            defaultValue={validator?.config?.errorMessage}
          >
            <Label>Customize the error message</Label>
            <TextArea />
            <Text slot="description">The error message the user will see in the validation interface.</Text>
          </TextField>
        </DisclosurePanel>
      </Disclosure>
    </DisclosureGroup>
  );
};

export const ValidationFormSettings = memo(IValidationFormSettings) as typeof IValidationFormSettings;
