import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { merge } from '@/components';
import { ComingSoon, MenuItem, MenuItemProps, Text } from '@/components/core';
import { OmitChildren } from '@/utils';

import { ValidatorLogo } from './ValidatorLogo.tsx';

export type FieldValidatorWithComingSoon = FieldValidator & { comingSoon?: boolean };

export type ValidatorMenuItemProps = OmitChildren<MenuItemProps> & {
  validator: FieldValidatorWithComingSoon;
};

const IValidatorMenuItem = ({ validator, className, ...rest }: ValidatorMenuItemProps) => {
  return (
    <MenuItem
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-2', className)}
      {...rest}
    >
      <ValidatorLogo validator={validator} size="xl" />
      <div className="flex flex-1 flex-col">
        <Text>{validator.name}</Text>
        <Text size="xs">{validator.description}</Text>
      </div>
      {validator.comingSoon ? <ComingSoon /> : null}
    </MenuItem>
  );
};

export const ValidatorMenuItem = memo(IValidatorMenuItem) as typeof IValidatorMenuItem;
