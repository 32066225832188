import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_globalAuth')({
  beforeLoad: async ({ context, location }) => {
    const { globalAuth } = context;
    if (!(await globalAuth.ensureLoggedIn())) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
