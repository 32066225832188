var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import { toListProjectRunsQueryParams } from './types';
import { buildURL } from './utils';
const maybeParseDate = (val) => {
    if (typeof val === 'string') {
        const re = /2\d{3}-[0-1]\d-[0-3]\dT[0-2]\d:[0-6]\d:[0-6]\d\.\d+(\+0000|Z)/;
        if (val.match(re)) {
            return new Date(val);
        }
    }
    return val;
};
/**
 * A high-level http client for communicating with the Cradl REST API
 */
export class Client {
    constructor(credentials) {
        this.credentials = credentials;
    }
    /**
     * Action section
     * Endpoint: /actions
     *
     * Methods:
     * - listActions
     * - getAction
     * - createAction
     * - updateAction
     * - deleteAction
     */
    /**
     * List actions, calls the GET /actions endpoint.
     * @param options Object with list options
     * @returns ActionList response from REST API
     */
    listActions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/actions', options);
        });
    }
    /**
     * Get an action, calls the GET /actions/:id endpoint.
     * @param actionId Id of the action
     * @param options Object with get options
     * @returns Action response from REST API
     */
    getAction(actionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/actions/${actionId}`, options);
        });
    }
    /**
     * Creates an action, calls the POST /actions endpoint.
     *
     * @param options Object with create options
     * @returns Action response from REST API
     */
    createAction(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/actions', options);
        });
    }
    /**
     * Updates an action, calls the PATCH /actions/:id endpoint.
     *
     * @param actionId Id of the action
     * @param options Object with update options
     */
    updateAction(actionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/actions/${actionId}`, options);
        });
    }
    /**
     * Delete an action, calls the DELETE /actions/:id endpoint.
     *
     * @param actionId Id of the action
     * @param options Object with delete options
     * @returns Action response from REST API
     */
    deleteAction(actionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/actions/${actionId}`, options);
        });
    }
    /**
     * ActionRun section
     * Endpoint: /actions/:id/runs
     *
     * Methods:
     * - listActionRuns
     * - getActionRun
     * - createActionRun
     * - updateActionRun
     * - deleteActionRun
     */
    /**
     * List action runs, calls the GET /actions/:id/runs endpoint.
     * @param actionId Id of the action
     * @param options Object with list options
     * @returns ActionRunList response from REST API
     */
    listActionRuns(actionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/actions/${actionId}/runs`, options);
        });
    }
    /**
     * Get an action run, calls the GET /actions/:id/runs/:id endpoint.
     * @param actionId Id of the action
     * @param runId Id of the run
     * @param options Object with get options
     * @returns ActionRun response from REST API
     */
    getActionRun(actionId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/actions/${actionId}/runs/${runId}`, options);
        });
    }
    /**
     * Creates an action run, calls the POST /actions/:id/runs endpoint.
     *
     * @param options Object with create options
     * @param actionId Id of the action
     * @returns ActionRun response from REST API
     */
    createActionRun(actionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest(`/actions/${actionId}/runs`, options);
        });
    }
    /**
     * Updates an action run, calls the PATCH /actions/:id/runs/:id endpoint.
     *
     * @param actionId Id of the action
     * @param runId Id of the run
     * @param options Object with update options
     */
    updateActionRun(actionId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/actions/${actionId}/runs/${runId}`, options);
        });
    }
    /**
     * Delete an action run, calls the DELETE /actions/:id/runs/:id endpoint.
     *
     * @param actionId Id of the action
     * @param runId Id of the run
     * @param options Object with delete options
     * @returns ActionRun response from REST API
     */
    deleteActionRun(actionId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/actions/${actionId}/runs/${runId}`, options);
        });
    }
    /**
     * AppClient section
     * Endpoint: /appClients
     *
     * Methods:
     * - listAppClients
     * - getAppClient
     * - createAppClient
     * - updateAppClient
     * - deleteAppClient
     */
    /**
     * List appClients, calls the GET /appClients endpoint.
     * @param options Object with list options
     * @returns AppClientList response from REST API
     */
    listAppClients(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/appClients', options);
        });
    }
    /**
     * Get a appClient, calls the GET /appClients/:id endpoint.
     * @param appClientId Id of the appClient
     * @param options Object with get options
     * @returns AppClient response from REST API
     */
    getAppClient(appClientId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/appClients/${appClientId}`, options);
        });
    }
    /**
     * Creates a appClient, calls the POST /appClients endpoint.
     *
     * @param options Object with create options
     * @returns AppClient response from REST API
     */
    createAppClient(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/appClients', options);
        });
    }
    /**
     * Updates a appClient, calls the PATCH /appClients/:id endpoint.
     *
     * @param appClientId Id of the appClient
     * @param options Object with update options
     */
    updateAppClient(appClientId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/appClients/${appClientId}`, options);
        });
    }
    /**
     * Delete a appClient, calls the DELETE /appClients/:id endpoint.
     *
     * @param appClientId Id of the appClient
     * @param options Object with delete options
     * @returns AppClient response from REST API
     */
    deleteAppClient(appClientId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/appClients/${appClientId}`, options);
        });
    }
    /**
     * Document section
     * Endpoint: /documents
     *
     * Methods:
     * - listDocuments
     * - getDocument
     * - createDocument
     * - updateDocument
     * - deleteDocument
     */
    /**
     * List documents, calls the GET /documents endpoint.
     * @param options Object with list options
     * @returns DocumentList response from REST API
     */
    listDocuments(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/documents', options);
        });
    }
    /**
     * Get a document, calls the GET /documents/:id endpoint.
     * @param documentId Id of the document
     * @param options Object with get options
     * @returns Document response from REST API
     */
    getDocument(documentId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/documents/${documentId}`, options);
        });
    }
    /**
     * Creates a document, calls the POST /documents endpoint.
     *
     * @param options Object with create options
     * @returns Document response from REST API
     */
    createDocument(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/documents', options);
        });
    }
    /**
     * Updates a document, calls the PATCH /documents/:id endpoint.
     *
     * @param documentId Id of the document
     * @param options Object with update options
     */
    updateDocument(documentId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/documents/${documentId}`, options);
        });
    }
    /**
     * Delete a document, calls the DELETE /documents/:id endpoint.
     *
     * @param documentId Id of the document
     * @param options Object with delete options
     * @returns Document response from REST API
     */
    deleteDocument(documentId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/documents/${documentId}`, options);
        });
    }
    /**
     * Function section
     * Endpoint: /functions
     *
     * Methods:
     * - listFunctions
     * - getFunction
     * - createFunction
     * - updateFunction
     * - deleteFunction
     */
    /**
     * List functions, calls the GET /functions endpoint.
     * @param options Object with list options
     * @returns FunctionList response from REST API
     */
    listFunctions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/functions', options);
        });
    }
    /**
     * Get a function, calls the GET /functions/:id endpoint.
     * @param functionId Id of the function
     * @param options Object with get options
     * @returns Function response from REST API
     */
    getFunction(functionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/functions/${encodeURIComponent(functionId)}`, options);
        });
    }
    /**
     * Creates a function, calls the POST /functions endpoint.
     *
     * @param options Object with create options
     * @returns Function response from REST API
     */
    createFunction(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/functions', options);
        });
    }
    /**
     * Updates a function, calls the PATCH /functions/:id endpoint.
     *
     * @param functionId Id of the function
     * @param options Object with update options
     */
    updateFunction(functionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/functions/${functionId}`, options);
        });
    }
    /**
     * Delete a function, calls the DELETE /functions/:id endpoint.
     *
     * @param functionId Id of the function
     * @param options Object with delete options
     * @returns Function response from REST API
     */
    deleteFunction(functionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/functions/${functionId}`, options);
        });
    }
    /**
     * Hook section
     * Endpoint: /hooks
     *
     * Methods:
     * - listHooks
     * - getHook
     * - createHook
     * - updateHook
     * - deleteHook
     */
    /**
     * List hooks, calls the GET /hooks endpoint.
     * @param options Object with list options
     * @returns HookList response from REST API
     */
    listHooks(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/hooks', options);
        });
    }
    /**
     * Get a hook, calls the GET /hooks/:id endpoint.
     * @param hookId Id of the hook
     * @param options Object with get options
     * @returns Hook response from REST API
     */
    getHook(hookId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/hooks/${hookId}`, options);
        });
    }
    /**
     * Creates a hook, calls the POST /hooks endpoint.
     *
     * @param options Object with create options
     * @returns Hook response from REST API
     */
    createHook(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/hooks', options);
        });
    }
    /**
     * Updates a hook, calls the PATCH /hooks/:id endpoint.
     *
     * @param hookId Id of the hook
     * @param options Object with update options
     */
    updateHook(hookId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/hooks/${hookId}`, options);
        });
    }
    /**
     * Delete a hook, calls the DELETE /hooks/:id endpoint.
     *
     * @param hookId Id of the hook
     * @param options Object with delete options
     * @returns Hook response from REST API
     */
    deleteHook(hookId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/hooks/${hookId}`, options);
        });
    }
    /**
     * HookRun section
     * Endpoint: /hooks/:id/runs
     *
     * Methods:
     * - listHookRuns
     * - getHookRun
     * - createHookRun
     * - updateHookRun
     * - deleteHookRun
     */
    /**
     * List hook runs, calls the GET /hooks/:id/runs endpoint.
     * @param hookId Id of the hook
     * @param options Object with list options
     * @returns HookRunList response from REST API
     */
    listHookRuns(hookId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/hooks/${hookId}/runs`, options);
        });
    }
    /**
     * Get a hook run, calls the GET /hooks/:id/runs/:id endpoint.
     * @param hookId Id of the hook
     * @param runId Id of the run
     * @param options Object with get options
     * @returns HookRun response from REST API
     */
    getHookRun(hookId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/hooks/${hookId}/runs/${runId}`, options);
        });
    }
    /**
     * Creates a hook run, calls the POST /hooks/:id/runs endpoint.
     *
     * @param options Object with create options
     * @param hookId Id of the hook
     * @returns HookRun response from REST API
     */
    createHookRun(hookId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest(`/hooks/${hookId}/runs`, options);
        });
    }
    /**
     * Updates a hook run, calls the PATCH /hooks/:id/runs/:id endpoint.
     *
     * @param hookId Id of the hook
     * @param runId Id of the run
     * @param options Object with update options
     */
    updateHookRun(hookId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/hooks/${hookId}/runs/${runId}`, options);
        });
    }
    /**
     * Delete a hook run, calls the DELETE /hooks/:id/runs/:id endpoint.
     *
     * @param hookId Id of the hook
     * @param runId Id of the run
     * @param options Object with delete options
     * @returns HookRun response from REST API
     */
    deleteHookRun(hookId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/hooks/${hookId}/runs/${runId}`, options);
        });
    }
    /**
     * Invoice section
     * Endpoint: /invoices
     *
     * Methods:
     * - listInvoices
     * - getInvoice
     */
    /**
     * List invoices, calls the GET /invoices endpoint.
     * @param options Object with list options
     * @returns InvoiceList response from REST API
     */
    listInvoices(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/invoices', options);
        });
    }
    /**
     * Get a invoice, calls the GET /invoices/:id endpoint.
     * @param invoiceId Id of the invoice
     * @param options Object with get options
     * @returns Invoice response from REST API
     */
    getInvoice(invoiceId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/invoices/${invoiceId}`, options);
        });
    }
    /**
     * Log section
     * Endpoint: /logs
     *
     * Methods:
     * - listLogs
     * - getLog
     */
    /**
     * List logs, calls the GET /logs endpoint.
     * @param options Object with list options
     * @returns LogList response from REST API
     */
    listLogs(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/logs', options);
        });
    }
    /**
     * Get a log, calls the GET /logs/:id endpoint.
     * @param logId Id of the log
     * @param options Object with get options
     * @returns Log response from REST API
     */
    getLog(logId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/logs/${logId}`, options);
        });
    }
    /**
     * Model section
     * Endpoint: /models
     *
     * Methods:
     * - listModels
     * - getModel
     * - createModel
     * - updateModel
     * - deleteModel
     */
    /**
     * List models, calls the GET /models endpoint.
     * @param options Object with list options
     * @returns ModelList response from REST API
     */
    listModels(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/models', options);
        });
    }
    /**
     * Get a model, calls the GET /models/:id endpoint.
     * @param modelId Id of the model
     * @param options Object with get options
     * @returns Model response from REST API
     */
    getModel(modelId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/models/${modelId}`, options);
        });
    }
    /**
     * Creates a model, calls the POST /models endpoint.
     *
     * @param options Object with create options
     * @returns Model response from REST API
     */
    createModel(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/models', options);
        });
    }
    /**
     * Updates a model, calls the PATCH /models/:id endpoint.
     *
     * @param modelId Id of the model
     * @param options Object with update options
     */
    updateModel(modelId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/models/${modelId}`, options);
        });
    }
    /**
     * Delete a model, calls the DELETE /models/:id endpoint.
     *
     * @param modelId Id of the model
     * @param options Object with delete options
     * @returns Model response from REST API
     */
    deleteModel(modelId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/models/${modelId}`, options);
        });
    }
    /**
     * Organization section
     * Endpoint: /organizations
     *
     * Methods:
     * - listOrganizations
     * - getOrganization
     * - createOrganization
     * - updateOrganization
     */
    /**
     * List organizations, calls the GET /organizations endpoint.
     * @param options Object with list options
     * @returns OrganizationList response from REST API
     */
    listOrganizations(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/organizations', options);
        });
    }
    /**
     * Get an organization, calls the GET /organizations/:id endpoint.
     * @param organizationId Id of the organization
     * @param options Object with get options
     * @returns Organization response from REST API
     */
    getOrganization(organizationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/organizations/${organizationId}`, options);
        });
    }
    /**
     * Creates an organization, calls the POST /organizations endpoint.
     *
     * @param options Object with create options
     * @returns Organization response from REST API
     */
    createOrganization(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/organizations', options);
        });
    }
    /**
     * Updates an organization, calls the PATCH /organizations/:id endpoint.
     *
     * @param organizationId Id of the organization
     * @param options Object with update options
     */
    updateOrganization(organizationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/organizations/${organizationId}`, options);
        });
    }
    /**
     * PaymentMethod section
     * Endpoint: /paymentMethods
     *
     * Methods:
     * - listPaymentMethods
     * - getPaymentMethod
     * - createPaymentMethod
     * - updatePaymentMethod
     * - deletePaymentMethod
     */
    /**
     * List paymentMethods, calls the GET /paymentMethods endpoint.
     * @param options Object with list options
     * @returns PaymentMethodList response from REST API
     */
    listPaymentMethods(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/paymentMethods', options);
        });
    }
    /**
     * Get a paymentMethod, calls the GET /paymentMethods/:id endpoint.
     * @param paymentMethodId Id of the paymentMethod
     * @param options Object with get options
     * @returns PaymentMethod response from REST API
     */
    getPaymentMethod(paymentMethodId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/paymentMethods/${paymentMethodId}`, options);
        });
    }
    /**
     * Creates a paymentMethod, calls the POST /paymentMethods endpoint.
     *
     * @param options Object with create options
     * @returns PaymentMethod response from REST API
     */
    createPaymentMethod(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/paymentMethods', options);
        });
    }
    /**
     * Updates a paymentMethod, calls the PATCH /paymentMethods/:id endpoint.
     *
     * @param paymentMethodId Id of the paymentMethod
     * @param options Object with update options
     */
    updatePaymentMethod(paymentMethodId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/paymentMethods/${paymentMethodId}`, options);
        });
    }
    /**
     * Delete a paymentMethod, calls the DELETE /paymentMethods/:id endpoint.
     *
     * @param paymentMethodId Id of the paymentMethod
     * @param options Object with delete options
     * @returns PaymentMethod response from REST API
     */
    deletePaymentMethod(paymentMethodId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/paymentMethods/${paymentMethodId}`, options);
        });
    }
    /**
     * Plan section
     * Endpoint: /plans
     *
     * Methods:
     * - listPlans
     * - getPlan
     */
    /**
     * List plans, calls the GET /plans endpoint.
     * @param options Object with list options
     * @returns PlanList response from REST API
     */
    listPlans(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/plans', options);
        });
    }
    /**
     * Get a plan, calls the GET /plans/:id endpoint.
     * @param planId Id of the plan
     * @param options Object with get options
     * @returns Plan response from REST API
     */
    getPlan(planId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/plans/${encodeURIComponent(planId)}`, options);
        });
    }
    /**
     * Prediction section
     * Endpoint: /predictions
     *
     * Methods:
     * - listPredictions
     * - getPrediction
     * - createPrediction
     * - deletePrediction
     */
    /**
     * List predictions, calls the GET /predictions endpoint.
     * @param options Object with list options
     * @returns PredictionList response from REST API
     */
    listPredictions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/predictions', options);
        });
    }
    /**
     * Get a prediction, calls the GET /predictions/:id endpoint.
     * @param predictionId Id of the prediction
     * @param options Object with get options
     * @returns Prediction response from REST API
     */
    getPrediction(predictionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/predictions/${predictionId}`, options);
        });
    }
    /**
     * Creates a prediction, calls the POST /predictions endpoint.
     *
     * @param options Object with create options
     * @returns Prediction response from REST API
     */
    createPrediction(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/predictions', options);
        });
    }
    /**
     * Delete a prediction, calls the DELETE /predictions/:id endpoint.
     *
     * @param predictionId Id of the prediction
     * @param options Object with delete options
     * @returns Prediction response from REST API
     */
    deletePrediction(predictionId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/predictions/${predictionId}`, options);
        });
    }
    /**
     * Profile section
     * Endpoint: /profiles
     *
     * Methods:
     * - getProfile
     * - updateProfile
     */
    /**
     * Get a profile, calls the GET /profiles/:id endpoint.
     * @param profileId Id of the profile
     * @param options Object with get options
     * @returns Profile response from REST API
     */
    getProfile(profileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/profiles/${profileId}`, options);
        });
    }
    /**
     * Updates a profile, calls the PATCH /profiles/:id endpoint.
     *
     * @param profileId Id of the profile
     * @param options Object with update options
     */
    updateProfile(profileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/profiles/${profileId}`, options);
        });
    }
    /**
     * Project section
     * Endpoint: /projects
     *
     * Methods:
     * - listProjects
     * - getProject
     * - createProject
     * - updateProject
     * - deleteProject
     */
    /**
     * List projects, calls the GET /projects endpoint.
     * @param options Object with list options
     * @returns ProjectList response from REST API
     */
    listProjects(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/projects', options);
        });
    }
    /**
     * Get a project, calls the GET /projects/:id endpoint.
     * @param projectId Id of the project
     * @param options Object with get options
     * @returns Project response from REST API
     */
    getProject(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/projects/${projectId}`, options);
        });
    }
    /**
     * Creates a project, calls the POST /projects endpoint.
     *
     * @param options Object with create options
     * @returns Project response from REST API
     */
    createProject(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/projects', options);
        });
    }
    /**
     * Updates a project, calls the PATCH /projects/:id endpoint.
     *
     * @param projectId Id of the project
     * @param options Object with update options
     */
    updateProject(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/projects/${projectId}`, options);
        });
    }
    /**
     * Delete a project, calls the DELETE /projects/:id endpoint.
     *
     * @param projectId Id of the project
     * @param options Object with delete options
     * @returns Project response from REST API
     */
    deleteProject(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/projects/${projectId}`, options);
        });
    }
    /**
     * ProjectRun section
     * Endpoint: /projects/:id/runs
     *
     * Methods:
     * - listProjectRuns
     * - getProjectRun
     * - createProjectRun
     * - updateProjectRun
     * - deleteProjectRun
     */
    /**
     * List project runs, calls the GET /projects/:id/runs endpoint.
     * @param projectId Id of the project
     * @param options Object with list options
     * @returns ProjectRunList response from REST API
     */
    listProjectRuns(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/projects/${projectId}/runs`, toListProjectRunsQueryParams(options));
        });
    }
    /**
     * Get a project run, calls the GET /projects/:id/runs/:id endpoint.
     * @param projectId Id of the project
     * @param runId Id of the run
     * @param options Object with get options
     * @returns ProjectRun response from REST API
     */
    getProjectRun(projectId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/projects/${projectId}/runs/${runId}`, options);
        });
    }
    /**
     * Creates a project run, calls the POST /projects/:id/runs endpoint.
     *
     * @param options Object with create options
     * @param projectId Id of the project
     * @returns ProjectRun response from REST API
     */
    createProjectRun(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest(`/projects/${projectId}/runs`, options);
        });
    }
    /**
     * Updates a project run, calls the PATCH /projects/:id/runs/:id endpoint.
     *
     * @param projectId Id of the project
     * @param runId Id of the run
     * @param options Object with update options
     */
    updateProjectRun(projectId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/projects/${projectId}/runs/${runId}`, options);
        });
    }
    /**
     * Delete a project run, calls the DELETE /projects/:id/runs/:id endpoint.
     *
     * @param projectId Id of the project
     * @param runId Id of the run
     * @param options Object with delete options
     * @returns ProjectRun response from REST API
     */
    deleteProjectRun(projectId, runId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/projects/${projectId}/runs/${runId}`, options);
        });
    }
    /**
     * Role section
     * Endpoint: /roles
     *
     * Methods:
     * - listRoles
     * - getRole
     * - createRole
     * - updateRole
     * - deleteRole
     */
    /**
     * List roles, calls the GET /roles endpoint.
     * @param options Object with list options
     * @returns RoleList response from REST API
     */
    listRoles(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/roles', options);
        });
    }
    /**
     * Get a role, calls the GET /roles/:id endpoint.
     * @param roleId Id of the role
     * @param options Object with get options
     * @returns Role response from REST API
     */
    getRole(roleId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/roles/${roleId}`, options);
        });
    }
    /**
     * Creates a role, calls the POST /roles endpoint.
     *
     * @param options Object with create options
     * @returns Role response from REST API
     */
    createRole(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/roles', options);
        });
    }
    /**
     * Updates a role, calls the PATCH /roles/:id endpoint.
     *
     * @param roleId Id of the role
     * @param options Object with update options
     */
    updateRole(roleId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/roles/${roleId}`, options);
        });
    }
    /**
     * Delete a role, calls the DELETE /roles/:id endpoint.
     *
     * @param roleId Id of the role
     * @param options Object with delete options
     * @returns Role response from REST API
     */
    deleteRole(roleId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/roles/${roleId}`, options);
        });
    }
    /**
     * Secret section
     * Endpoint: /secrets
     *
     * Methods:
     * - listSecrets
     * - getSecret
     * - createSecret
     * - updateSecret
     * - deleteSecret
     */
    /**
     * List secrets, calls the GET /secrets endpoint.
     * @param options Object with list options
     * @returns SecretList response from REST API
     */
    listSecrets(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/secrets', options);
        });
    }
    /**
     * Get a secret, calls the GET /secrets/:id endpoint.
     * @param secretId Id of the secret
     * @param options Object with get options
     * @returns Secret response from REST API
     */
    getSecret(secretId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/secrets/${secretId}`, options);
        });
    }
    /**
     * Creates a secret, calls the POST /secrets endpoint.
     *
     * @param options Object with create options
     * @returns Secret response from REST API
     */
    createSecret(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/secrets', options);
        });
    }
    /**
     * Updates a secret, calls the PATCH /secrets/:id endpoint.
     *
     * @param secretId Id of the secret
     * @param options Object with update options
     */
    updateSecret(secretId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/secrets/${secretId}`, options);
        });
    }
    /**
     * Delete a secret, calls the DELETE /secrets/:id endpoint.
     *
     * @param secretId Id of the secret
     * @param options Object with delete options
     * @returns Secret response from REST API
     */
    deleteSecret(secretId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/secrets/${secretId}`, options);
        });
    }
    /**
     * User section
     * Endpoint: /users
     *
     * Methods:
     * - listUsers
     * - getUser
     * - createUser
     * - updateUser
     * - deleteUser
     */
    /**
     * List users, calls the GET /users endpoint.
     * @param options Object with list options
     * @returns UserList response from REST API
     */
    listUsers(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/users', options);
        });
    }
    /**
     * Get a user, calls the GET /users/:id endpoint.
     * @param userId Id of the user
     * @param options Object with get options
     * @returns User response from REST API
     */
    getUser(userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/users/${userId}`, options);
        });
    }
    /**
     * Creates a user, calls the POST /users endpoint.
     *
     * @param options Object with create options
     * @returns User response from REST API
     */
    createUser(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/users', options);
        });
    }
    /**
     * Updates a user, calls the PATCH /users/:id endpoint.
     *
     * @param userId Id of the user
     * @param options Object with update options
     */
    updateUser(userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/users/${userId}`, options);
        });
    }
    /**
     * Delete a user, calls the DELETE /users/:id endpoint.
     *
     * @param userId Id of the user
     * @param options Object with delete options
     * @returns User response from REST API
     */
    deleteUser(userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/users/${userId}`, options);
        });
    }
    /**
     * Validation section
     * Endpoint: /validations
     *
     * Methods:
     * - listValidations
     * - getValidation
     * - createValidation
     * - updateValidation
     * - deleteValidation
     */
    /**
     * List validations, calls the GET /validations endpoint.
     * @param options Object with list options
     * @returns ValidationList response from REST API
     */
    listValidations(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest('/validations', options);
        });
    }
    /**
     * Get a validation, calls the GET /validations/:id endpoint.
     * @param validationId Id of the validation
     * @param options Object with get options
     * @returns Validation response from REST API
     */
    getValidation(validationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/validations/${validationId}`, options);
        });
    }
    /**
     * Creates a validation, calls the POST /validations endpoint.
     *
     * @param options Object with create options
     * @returns Validation response from REST API
     */
    createValidation(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest('/validations', options);
        });
    }
    /**
     * Updates a validation, calls the PATCH /validations/:id endpoint.
     *
     * @param validationId Id of the validation
     * @param options Object with update options
     */
    updateValidation(validationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/validations/${validationId}`, options);
        });
    }
    /**
     * Delete a validation, calls the DELETE /validations/:id endpoint.
     *
     * @param validationId Id of the validation
     * @param options Object with delete options
     * @returns Validation response from REST API
     */
    deleteValidation(validationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/validations/${validationId}`, options);
        });
    }
    /**
     * ValidationTask section
     * Endpoint: /validations/:id/tasks
     *
     * Methods:
     * - listValidationTasks
     * - getValidationTask
     * - createValidationTask
     * - updateValidationTask
     * - deleteValidationTask
     */
    /**
     * List validation tasks, calls the GET /validations/:id/tasks endpoint.
     * @param validationId Id of the validation
     * @param options Object with list options
     * @returns ValidationTaskList response from REST API
     */
    listValidationTasks(validationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/validations/${validationId}/tasks`, options);
        });
    }
    /**
     * Get a validation task, calls the GET /validations/:id/tasks/:id endpoint.
     * @param validationId Id of the validation
     * @param taskId Id of the task
     * @param options Object with get options
     * @returns ValidationTask response from REST API
     */
    getValidationTask(validationId, taskId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeGetRequest(`/validations/${validationId}/tasks/${taskId}`, options);
        });
    }
    /**
     * Creates a validation task, calls the POST /validations/:id/tasks endpoint.
     *
     * @param options Object with create options
     * @param validationId Id of the validation
     * @returns ValidationTask response from REST API
     */
    createValidationTask(validationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePostRequest(`/validations/${validationId}/tasks`, options);
        });
    }
    /**
     * Updates a validation task, calls the PATCH /validations/:id/tasks/:id endpoint.
     *
     * @param validationId Id of the validation
     * @param taskId Id of the task
     * @param options Object with update options
     */
    updateValidationTask(validationId, taskId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makePatchRequest(`/validations/${validationId}/tasks/${taskId}`, options);
        });
    }
    /**
     * Delete a validation task, calls the DELETE /validations/:id/tasks/:id endpoint.
     *
     * @param validationId Id of the validation
     * @param taskId Id of the task
     * @param options Object with delete options
     * @returns ValidationTask response from REST API
     */
    deleteValidationTask(validationId, taskId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeDeleteRequest(`/validations/${validationId}/tasks/${taskId}`, options);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeGetRequest(path_1) {
        return __awaiter(this, arguments, void 0, function* (path, options = {}) {
            const { requestConfig } = options, query = __rest(options, ["requestConfig"]);
            return this.makeAuthorizedRequest(axios.get, buildURL(path, query), requestConfig);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeDeleteRequest(path_1) {
        return __awaiter(this, arguments, void 0, function* (path, options = {}) {
            const { requestConfig } = options, query = __rest(options, ["requestConfig"]);
            return this.makeAuthorizedRequest(axios.delete, buildURL(path, query), requestConfig);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makePostRequest(path, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeAuthorizedBodyRequest(axios.post, path, options);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makePatchRequest(path, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.makeAuthorizedBodyRequest(axios.patch, path, options);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeFileServerGetRequest(fileUrl_1) {
        return __awaiter(this, arguments, void 0, function* (fileUrl, options = {}) {
            const { requestConfig } = options, query = __rest(options, ["requestConfig"]);
            const constructedRequestConfig = Object.assign({ responseType: 'arraybuffer' }, requestConfig);
            return this.makeAuthorizedFileServerRequest(axios.get, buildURL(fileUrl, query), constructedRequestConfig);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeFileServerPutRequest(fileUrl_1, content_1) {
        return __awaiter(this, arguments, void 0, function* (fileUrl, content, options = {}) {
            options.data = content;
            yield this.makeAuthorizedFileServerBodyRequest(axios.put, fileUrl, options);
            return new Blob([content], { type: options.headers['Content-Type'] });
        });
    }
    makeAuthorizedFileServerRequest(axiosFn_1, fileUrl_1) {
        return __awaiter(this, arguments, void 0, function* (axiosFn, fileUrl, 
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        requestConfig = {}) {
            const headers = yield this.getAuthorizationHeaders();
            let config = { headers };
            if (requestConfig) {
                config = Object.assign(Object.assign({}, config), requestConfig);
            }
            const result = yield axiosFn(fileUrl, config);
            return new Blob([result.data], { type: result.headers['content-type'] });
        });
    }
    makeAuthorizedFileServerBodyRequest(axiosFn_1, fileUrl_1) {
        return __awaiter(this, arguments, void 0, function* (axiosFn, fileUrl, 
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        options = {}) {
            const authHeaders = yield this.getAuthorizationHeaders();
            const { requestConfig, data, headers } = options;
            let config = { headers: Object.assign(Object.assign({}, authHeaders), headers) };
            if (requestConfig) {
                config = Object.assign(Object.assign({}, config), requestConfig);
            }
            const result = yield axiosFn(fileUrl, data, config);
            return result.data;
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeAuthorizedRequest(axiosFn_1, path_1) {
        return __awaiter(this, arguments, void 0, function* (axiosFn, path, requestConfig = {}) {
            const endpoint = `${this.credentials.apiEndpoint}${path}`;
            const headers = yield this.getAuthorizationHeaders();
            let config = { headers };
            if (requestConfig) {
                config = Object.assign(Object.assign({}, config), requestConfig);
            }
            const result = yield axiosFn(endpoint, config);
            return JSON.parse(JSON.stringify(result.data), (key, val) => maybeParseDate(val));
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    makeAuthorizedBodyRequest(axiosFn_1, path_1) {
        return __awaiter(this, arguments, void 0, function* (axiosFn, path, options = {}) {
            const endpoint = `${this.credentials.apiEndpoint}${path}`;
            const headers = yield this.getAuthorizationHeaders();
            const { requestConfig } = options, body = __rest(options, ["requestConfig"]);
            let config = { headers };
            if (requestConfig) {
                config = Object.assign(Object.assign({}, config), requestConfig);
            }
            const result = yield axiosFn(endpoint, body, config);
            return JSON.parse(JSON.stringify(result.data), (key, val) => maybeParseDate(val));
        });
    }
    getAuthorizationHeaders() {
        return __awaiter(this, void 0, void 0, function* () {
            const accessToken = yield this.credentials.getAccessToken();
            return {
                Authorization: `Bearer ${accessToken}`,
            };
        });
    }
}
export default Client;
