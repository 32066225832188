import { memo, useCallback, useMemo } from 'react';
import { Focusable } from 'react-aria-components';

import CloseIcon from '@/assets/close-icon.svg?react';
import { Text, Tooltip, TooltipProps } from '@/components/core';
import { useTour } from '@/hooks';
import { useFilteredProjectRunsQuery } from '@/hooks/api';

export type SampleDocumentTooltipProps = Omit<TooltipProps, 'tooltip' | 'children'> & {
  projectId: string;
};

const ISampleDocumentTooltip = ({ projectId, placement = 'bottom left', ...rest }: SampleDocumentTooltipProps) => {
  const { showSampleDocumentTooltip, setShowSampleDocumentTooltip } = useTour({ prefixes: [projectId] });
  const close = useCallback(() => setShowSampleDocumentTooltip(false), [setShowSampleDocumentTooltip]);
  const { data } = useFilteredProjectRunsQuery({ projectId });
  const runs = useMemo(() => data?.pages?.flatMap((page) => page.data) ?? [], [data]);
  if (runs.length < 1) return null;

  return (
    <Tooltip
      placement={placement}
      isDisabled={!showSampleDocumentTooltip}
      isOpen={showSampleDocumentTooltip}
      tooltip={
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Text className="font-semibold text-white">✨ We added a sample document for you</Text>
            <div className="grow" />
            <button className="cursor-pointer text-white hover:[&_svg]:stroke-gray-100" onClick={close}>
              <CloseIcon className="stroke-white" />
            </button>
          </div>
          <Text className="text-white">
            The file has been processed by AI and has been flagged for human review. Click review to see predictions.
          </Text>
          <button
            onClick={close}
            className="w-fit cursor-pointer self-end rounded-lg bg-white px-2 py-1 text-black hover:bg-gray-100"
          >
            Got it!
          </button>
        </div>
      }
      {...rest}
    >
      <Focusable>
        <div className="w-full" />
      </Focusable>
    </Tooltip>
  );
};

export const SampleDocumentTooltip = memo(ISampleDocumentTooltip) as typeof ISampleDocumentTooltip;
