import { DOCUMENT_MAX_SIZE, DOCUMENT_MIME_TYPES } from '@lucidtech/las-sdk-browser';
import { memo, useCallback, useState } from 'react';

import SaveIcon from '@/assets/save-icon.svg?react';
import { DropZone, DropZoneButton, DropZoneProps, Text } from '@/components/core';
import { useProject } from '@/hooks';

type UploadProgressProps = {
  uploadProgress: number;
};

const IUploadProgress = ({ uploadProgress }: UploadProgressProps) => {
  return (
    <>
      <Text className="flex gap-2">
        <SaveIcon className="size-4 animate-spin stroke-gray-500" />
        Uploading files...
      </Text>
      <Text size="xs">({uploadProgress} completed)</Text>
    </>
  );
};

const UploadProgress = memo(IUploadProgress) as typeof IUploadProgress;

export type UploadDocumentsDropzoneProps = Omit<DropZoneProps, 'children'> & {
  projectId: string;
  onUploadSuccess?: () => void;
};

const IUploadDocumentsDropzone = ({
  className,
  projectId,
  onUploadSuccess,
  ...rest
}: UploadDocumentsDropzoneProps) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [isUploading, setIsUploading] = useState(false);
  const { createRun } = useProject({ projectId });

  const onUploadFiles = useCallback(
    (files: File[]) => {
      setUploadProgress(0);
      setUploadError(undefined);
      setIsUploading(true);

      const uploads: Promise<unknown>[] = [];
      for (const file of files ?? []) {
        uploads.push(
          createRun({ file }).then(() => {
            setUploadProgress((x) => x + 1);
          })
        );
      }

      Promise.all(uploads)
        .then(onUploadSuccess)
        .catch((error) => {
          setUploadError(error.message);
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [createRun]
  );

  return (
    <DropZone
      className={className}
      acceptedMimeTypes={[...DOCUMENT_MIME_TYPES]}
      maxFileSize={DOCUMENT_MAX_SIZE}
      onDropFiles={onUploadFiles}
      isDisabled={isUploading}
      {...rest}
    >
      {isUploading ? <UploadProgress uploadProgress={uploadProgress} /> : <DropZoneButton />}
      {uploadError ? <Text className="text-red-500">{uploadError}</Text> : null}
    </DropZone>
  );
};

export const UploadDocumentsDropzone = memo(IUploadDocumentsDropzone) as typeof IUploadDocumentsDropzone;
