import { memo } from 'react';

import DateTimeIcon from '@/assets/date-change-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type DateTimeLogoProps = VariantLogoProps;

const IDateTimeLogo = ({ ...rest }: DateTimeLogoProps) => {
  return <FormatterValidatorLogo Icon={DateTimeIcon} iconColoringType="stroke" {...rest} />;
};

export const DateTimeLogo = memo(IDateTimeLogo) as typeof IDateTimeLogo;
