import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Text } from '@/components/core';
import { ExportLogo } from '@/components/flow';
import { JSONType, OmitChildren } from '@/utils';

export type ExportOption = {
  functionId: string;
  config: JSONType;
  name: string;
};

export type ExportCellProps = OmitChildren<ButtonProps> & {
  option: ExportOption;
};

const IExportCell = ({ option, className, ...rest }: ExportCellProps) => {
  return (
    <Button
      variant="plain"
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-2', className)}
      {...rest}
    >
      <ExportLogo size="lg" functionId={option.functionId} />
      <Text>{option.name}</Text>
    </Button>
  );
};

export const ExportCell = memo(IExportCell) as typeof IExportCell;
