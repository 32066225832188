import { memo, useCallback, useMemo } from 'react';
import { FileTrigger } from 'react-aria-components';

import ImportIcon from '@/assets/import-icon.svg?react';
import { Autosave, Button, DebouncedInput, Header, Input, Label, Text, TextField } from '@/components/core';
import { PROFILE_PICTURE_MIME_TYPES } from '@/constants';
import { useImageSrc } from '@/hooks';
import { toProfileId, useAutoUpdateProfile, usePutFile } from '@/hooks/api';
import { useProfileStore } from '@/store';

export type ProfileDetailsProps = {
  profileId: string;
};

const IProfileDetails = ({ profileId }: ProfileDetailsProps) => {
  const givenName = useProfileStore((state) => state.givenName);
  const familyName = useProfileStore((state) => state.familyName);
  const email = useProfileStore((state) => state.email);
  const pictureUrl = useProfileStore((state) => state.pictureUrl);
  const { src } = useImageSrc({ source: pictureUrl });
  const updateProfile = useProfileStore((state) => state.updateProfile);
  const { mutate: putFile } = usePutFile();

  const updates = useMemo(() => {
    return { familyName, givenName };
  }, [givenName, familyName]);
  const { isPending, isSuccess, error } = useAutoUpdateProfile(1000, toProfileId(profileId), updates);

  const onSelect = useCallback(
    (fileList: FileList | null) => {
      if (!fileList || !pictureUrl) return;
      const files = Array.from(fileList);
      if (!files) return;
      putFile({ fileUrl: pictureUrl, file: files[0] });
    },
    [pictureUrl, putFile]
  );

  return (
    <>
      <div className="flex items-center">
        <Header size="xl">Profile</Header>
        <div className="grow" />
        <Autosave isPending={isPending} isSuccess={isSuccess} error={error} />
      </div>
      <Text size="md">Manage your personal details</Text>
      <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
        <div className="flex items-center gap-4">
          <img className="size-16 rounded-full" alt={profileId} src={src} />
          <div className="flex flex-col gap-1">
            <Label>Profile picture</Label>
            <FileTrigger allowsMultiple={false} acceptedFileTypes={PROFILE_PICTURE_MIME_TYPES} onSelect={onSelect}>
              <div>
                <Button>
                  <ImportIcon className="size-5 stroke-gray-200" />
                  Upload
                </Button>
              </div>
            </FileTrigger>
            <Text size="xs">{PROFILE_PICTURE_MIME_TYPES.join(', ')}</Text>
          </div>
        </div>
        <div className="flex gap-2">
          <TextField className="flex w-1/2 flex-col gap-1" defaultValue={givenName ?? undefined}>
            <Label>First name</Label>
            <DebouncedInput onChange={(e) => updateProfile({ givenName: e.target.value })} />
          </TextField>
          <TextField className="flex w-1/2 flex-col gap-1" defaultValue={familyName ?? undefined}>
            <Label>Last name</Label>
            <DebouncedInput onChange={(e) => updateProfile({ familyName: e.target.value })} />
          </TextField>
        </div>
        <TextField className="flex flex-col gap-1" type="email" defaultValue={email ?? undefined} isDisabled>
          <Label>Email</Label>
          <Input />
        </TextField>
      </div>
    </>
  );
};

export const ProfileDetails = memo(IProfileDetails) as typeof IProfileDetails;
