import type { CreatePredictionOptions, Prediction } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type PredictionId = { predictionId: string };

export const { useBatchGet: useBatchGetPrediction, useGet: useGetPrediction } = create<
  Prediction,
  PredictionId,
  CreatePredictionOptions
>({
  cacheKey: 'predictions',
  equal: (prediction, id) => Boolean(prediction && id?.predictionId && prediction.predictionId === id.predictionId),
  createId: (prediction) => (prediction ? { predictionId: prediction.predictionId } : null),
  create: (client, prediction) => client.createPrediction(prediction),
  delete: (client, id) => client.deletePrediction(id.predictionId),
  get: (client, id) => client.getPrediction(id.predictionId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listPredictions()
        .then((response: JSONType) => {
          resolve({ data: response.predictions, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
});

export const toPredictionId = (predictionId?: string | null): PredictionId | null => {
  return predictionId ? { predictionId } : null;
};
