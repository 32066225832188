import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { ModelConfigContext, ModelConfigState } from './modelConfigStore';

export function useModelConfigStore<T>(selector: (state: ModelConfigState) => T): T {
  const store = useContext(ModelConfigContext);
  if (!store) throw new Error('Missing ModelConfigContext.Provider in the tree');
  return useStore(store, selector);
}
