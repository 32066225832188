import { memo, useCallback } from 'react';
import { PillListActions } from 'src/components/core/PillsInput';

import { Button, CopyText, Pills, Text } from '@/components/core';
import { useTriggerStore } from '@/store';
import { jsonToList, toString } from '@/utils';

export type TriggerEmailConfigurationProps = object;

// eslint-disable-next-line no-empty-pattern
const ITriggerEmailConfiguration = ({}: TriggerEmailConfigurationProps) => {
  const updateAction = useTriggerStore((state) => state.updateAction);
  const action = useTriggerStore((state) => state.action);

  const onWhitelistChange = useCallback(
    (whitelist: Pills.Item[]) => {
      updateAction((action) => {
        action.config.whitelist = whitelist.map((it) => it.value);
        return action;
      });
    },
    [updateAction]
  );

  const initialValues = jsonToList(action?.config?.whitelist).map((v) => ({ value: toString(v) }));

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-2">
        <Text size="sm">Send emails to:</Text>
        <CopyText value={toString(action.config?.email)} />
      </div>

      <div className="flex flex-col gap-1">
        <Text size="sm">Whitelist</Text>
        <Pills.Input>
          <Pills.List className="flex grow" onItemsChange={onWhitelistChange} initialValues={initialValues}>
            {(pill: Pills.Item, handle: PillListActions) => (
              <Button variant="secondary" className="flex gap-1 py-0">
                {pill.value}
                <span className="ml-2 cursor-pointer" onClick={() => handle.removeItem(pill)}>
                  x
                </span>
              </Button>
            )}
          </Pills.List>
        </Pills.Input>
      </div>
    </div>
  );
};

export const TriggerEmailConfiguration = memo(ITriggerEmailConfiguration) as typeof ITriggerEmailConfiguration;
