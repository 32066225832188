import { JSONType } from './types';

export const stripKeys = (obj: JSONType, keysToRemove: string[]): JSONType => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((it) => stripKeys(it, keysToRemove));
  }

  return Object.entries(obj).reduce((res, [key, value]) => {
    if (!keysToRemove.includes(key)) {
      res[key] = stripKeys(value, keysToRemove);
    }

    return res;
  }, {} as JSONType);
};
