import { Resizable } from 're-resizable';
import { DetailedHTMLProps, HTMLAttributes, memo } from 'react';

import { merge } from '@/components';

export type PanelProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ISlideOut = ({ className, children, ...rest }: PanelProps) => {
  return (
    <div className={merge('animate-slide-in-from-right flex h-full bg-white', className)} {...rest}>
      <Resizable className="shadow-l-md h-full" defaultSize={{ width: 500 }} enable={{ left: true }}>
        <div className={merge('h-full w-full overflow-y-auto', className)} {...rest}>
          {children}
        </div>
      </Resizable>
    </div>
  );
};

export const SlideOut = memo(ISlideOut) as typeof ISlideOut;
