import { AuthorizationCodeCredentials, Client, PKCEDerived } from '@lucidtech/las-sdk-browser';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ReactNode, useCallback, useMemo } from 'react';

import { createGlobalCredentials } from './auth';
import { GlobalAuthContext, GlobalAuthContextInterface } from './GlobalAuthContext';

export interface GlobalAuthProviderProps {
  children?: ReactNode | null;
}

export const GlobalAuthProvider = ({ children }: GlobalAuthProviderProps) => {
  const [redirectUrl, setRedirectUrl] = useLocalStorage<string | null>('cradl:auth:redirectUrl');

  const client = useMemo(() => {
    const credentials = createGlobalCredentials();
    return new Client(credentials);
  }, []);

  const updateCredentials = useCallback(
    (code: string) => {
      (client.credentials as AuthorizationCodeCredentials).pkce = PKCEDerived.createFromCode(code);
    },
    [client]
  );

  const login = useCallback(() => {
    (client.credentials as AuthorizationCodeCredentials).initiateOAuthFlow();
  }, [client]);

  const logout = useCallback(async () => {
    // Delete all auth-related values in local storage
    for (const [key, _] of Object.entries(window.localStorage).filter(([key]) => key.includes('cradl:auth'))) {
      window.localStorage.removeItem(key);
    }

    if (client) {
      (client.credentials as AuthorizationCodeCredentials).initiateLogoutFlow();
    }
    return true;
  }, []);

  const ensureLoggedIn = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      const credentials = client.credentials as AuthorizationCodeCredentials;

      if (credentials.isLoggedIn()) {
        resolve(true);
        return;
      }

      credentials
        .getAccessToken()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }, [client]);

  const isLoggedIn = (client?.credentials as AuthorizationCodeCredentials).isLoggedIn();

  const context: GlobalAuthContextInterface = useMemo(
    () => ({
      isLoggedIn,
      ensureLoggedIn,
      client,
      setRedirectUrl,
      redirectUrl,
      login,
      logout,
      updateCredentials,
    }),
    [isLoggedIn, ensureLoggedIn, client, setRedirectUrl, redirectUrl, login, logout, updateCredentials]
  );

  return <GlobalAuthContext.Provider value={context}>{children}</GlobalAuthContext.Provider>;
};
