import type { Role } from '@lucidtech/las-sdk-browser';
import { useForm } from '@tanstack/react-form';
import { memo } from 'react';
import { FieldError, MenuTrigger, TextField } from 'react-aria-components';

import CloseIcon from '@/assets/close-icon.svg?react';
import { merge } from '@/components';
import { Button, Header, Input, Label, Menu, MenuItem, Popover, Text } from '@/components/core';
import { useCreateUser, useListRoles } from '@/hooks/api';
import { DivProps } from '@/utils';

type FormType = {
  email?: string;
  role?: Role;
};

export type InviteMembersProps = DivProps & {
  close?: () => void;
};

const IInviteMembers = ({ close, className, children, ...rest }: InviteMembersProps) => {
  const { data: roles } = useListRoles();
  const { mutate: createUser } = useCreateUser();

  const form = useForm<FormType>({
    defaultValues: {
      role: roles?.data[0],
    },
    onSubmit: async ({ value }) => {
      if (!value.email || !value.role) return;
      createUser({ email: value.email, roleIds: [value.role.roleId] });
      close?.();
    },
  });

  return (
    <div className={merge('flex flex-col gap-2', className)} {...rest}>
      <div className="flex w-full flex-row items-center gap-2">
        <Header size="xl">Invite members</Header>
        <div className="grow" />
        <Button className="hover:bg-gray-100" variant="plain" onPress={close}>
          <CloseIcon className="stroke-gray-400" />
        </Button>
      </div>
      <Text>Invoice co-workers to your workspace</Text>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await form.handleSubmit();
        }}
        className="space-y-4"
      >
        <form.Field
          name="email"
          children={(field) => (
            <TextField className="flex flex-col" name="email" type="email" isRequired>
              <Label>Send invite to:</Label>
              <Input
                onChange={(e) => field.handleChange(e.target.value)}
                onBlur={field.handleBlur}
                value={field.state.value}
              />
              <FieldError />
            </TextField>
          )}
        />
        <form.Field
          name="role"
          children={(field) => (
            <TextField className="flex flex-col" name="name" type="name">
              <Label>Invite as:</Label>
              <MenuTrigger>
                <Button className="justify-start" variant="secondary">
                  {field.state.value?.name}
                </Button>
                <Popover>
                  <Menu>
                    {roles?.data.map((role) => (
                      <MenuItem onAction={() => field.handleChange(role)}>{role.name}</MenuItem>
                    ))}
                  </Menu>
                </Popover>
              </MenuTrigger>
              <FieldError />
            </TextField>
          )}
        />
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isDirty]}
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          children={([canSubmit, _]) => {
            if (canSubmit) {
              return (
                <Button isDisabled={!canSubmit} type="submit">
                  Send invite
                </Button>
              );
            }
          }}
        />
      </form>
      {children}
    </div>
  );
};

export const InviteMembers = memo(IInviteMembers) as typeof IInviteMembers;
