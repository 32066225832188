import { Integration } from '@sentry/core';
import * as Sentry from '@sentry/react';

const sentryIntegrations: Integration[] = [
  Sentry.browserTracingIntegration(),
  Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }),
];

if (import.meta.env.VITE_SENTRY_PROFILING === 'true') {
  console.log('Enabling Sentry profiling');
  sentryIntegrations.push(Sentry.browserProfilingIntegration());
}

export const initSentry = (...integrations: Integration[]) => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [...sentryIntegrations, ...integrations],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: import.meta.env.VITE_SENTRY_TARGETS?.split(','),
    tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
    // Session Replay
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: 1.0,
    // Profiling
    profilesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE),
  });
};
