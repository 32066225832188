import { Button as Button_, ButtonProps as ButtonProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type ButtonProps = ButtonProps_ & {
  variant?: 'primary' | 'secondary' | 'plain';
};

export const Button = ({ className, children, variant = 'primary', ...rest }: ButtonProps) => {
  /* prettier-ignore */
  const primaryStyles = variant === 'primary' && [
    'bg-brand-600',
    'data-[pressed]:bg-brand-700',
    'data-[disabled]:bg-gray-300',
    'hover:bg-brand-500',
  ];
  /* prettier-ignore */
  const secondaryStyles = variant === 'secondary' && [
    'bg-white',
    'border',
    'border-gray-200',
    'data-[pressed]:bg-gray-100',
    'hover:bg-gray-50',
    'text-gray-900',
  ];
  /* prettier-ignore */
  const plainStyles = variant === 'plain' && [
    'data-[pressed]:bg-gray-100',
    'hover:bg-gray-50',
    'text-gray-900',
  ];
  return (
    <Button_
      className={merge(
        'flex cursor-pointer flex-row items-center justify-center gap-2 rounded-lg p-2 text-white outline-none data-[disabled]:cursor-not-allowed',
        primaryStyles,
        secondaryStyles,
        plainStyles,
        className
      )}
      {...rest}
    >
      {children}
    </Button_>
  );
};
