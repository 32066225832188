import { memo } from 'react';

import { merge } from '@/components';
import { Text, TextProps, Tooltip } from '@/components/core';
import { formatDate, formatDateFull } from '@/utils';

export type DateTimeProps = TextProps & {
  date?: Date;
};

const IDateTime = ({ date, className, children, ...rest }: DateTimeProps) => {
  if (!date) {
    return <Text>Loading</Text>;
  }

  return (
    <Tooltip
      delay={500}
      tooltip={
        <Text className="text-white" size="sm">
          {formatDateFull(date)}
        </Text>
      }
    >
      <Text className={merge('', className)} {...rest}>
        {formatDate(date)}
        {children}
      </Text>
    </Tooltip>
  );
};

export const DateTime = memo(IDateTime) as typeof IDateTime;
