import { memo } from 'react';

import MinimizeIcon from '@/assets/minimize-icon.svg?react';
import { Label, Text, Tooltip } from '@/components/core';

import { useValidation } from './ValidationContext';

export type FieldLabelProps = {
  description?: string;
  name: string;
  hideLabel: boolean;
};

const IFieldLabel = ({ name, description, hideLabel = false }: FieldLabelProps) => {
  const toggleProps = {
    className: 'group-hover/field-label:stroke-gray-400 rounded-lg size-5 cursor-pointer',
  };

  const { isTableView, hideTableView } = useValidation();

  return (
    <div className="group/field-label flex items-center gap-2">
      <Tooltip
        isDisabled={!description}
        placement="left"
        tooltip={
          <Text className="text-white" size="xs">
            {description}
          </Text>
        }
      >
        <Label hidden={hideLabel} htmlFor={name}>
          {name}
        </Label>
      </Tooltip>

      {isTableView && <MinimizeIcon onClick={hideTableView} {...toggleProps} />}
    </div>
  );
};

export const FieldLabel = memo(IFieldLabel) as typeof IFieldLabel;
