import { memo } from 'react';

import { merge } from '@/components';
import { Header, Text } from '@/components/core';
import { ValidationLogo } from '@/components/flow';
import { toActionId, useSuspenseGetAction } from '@/hooks/api';
import { DivProps, formatDate } from '@/utils';

export type ValidationPanelHeaderProps = DivProps & {
  actionId: string;
};

const IValidationPanelHeader = ({ actionId, className, ...rest }: ValidationPanelHeaderProps) => {
  const { data: action } = useSuspenseGetAction(toActionId(actionId));

  return (
    <div className={merge('flex items-center gap-4', className)} {...rest}>
      <ValidationLogo size="xl" />
      <div>
        <Header size="lg">Validation</Header>
        <Text size="xs">Last updated: {formatDate(action.updatedTime ?? action.createdTime)}</Text>
      </div>
      <div className="grow" />
    </div>
  );
};

export const ValidationPanelHeader = memo(IValidationPanelHeader) as typeof IValidationPanelHeader;
