import { memo } from 'react';

import { merge } from '@/components';
import { Autosave, Header, Text } from '@/components/core';
import { ExportLogo } from '@/components/flow';
import { useAutoUpdateAction } from '@/hooks/api';
import { useTriggerStore } from '@/store';
import { DivProps, formatDate, OmitChildren } from '@/utils';

export type ExportPanelHeaderProps = OmitChildren<DivProps> & {
  actionId: string;
};

const IExportPanelHeader = ({ className, actionId, ...rest }: ExportPanelHeaderProps) => {
  const action = useTriggerStore((state) => state.action);
  const { isPending, isSuccess, error } = useAutoUpdateAction(
    1000,
    { actionId },
    {
      config: action.config,
      enabled: action.enabled,
      functionId: action.functionId,
      metadata: action.metadata,
      name: action.name,
    }
  );

  return (
    <div className={merge('flex items-center gap-4', className)} {...rest}>
      <ExportLogo size="xl" functionId={action.functionId} />
      <div className="flex-1">
        <Header size="lg">{action.name}</Header>
        <Text size="xs">Last updated: {formatDate(action.updatedTime ?? action.createdTime)}</Text>
      </div>
      <Autosave isPending={isPending} isSuccess={isSuccess} error={error} />
    </div>
  );
};

export const ExportPanelHeader = memo(IExportPanelHeader) as typeof IExportPanelHeader;
