import { useNodes } from '@xyflow/react';
import { memo } from 'react';

import { NodeType } from '@/components/flow';

import { ModelPanelSlideOut } from './ModelPanelSlideOut.tsx';

export type ModelPanelWrapperProps = {
  projectId: string;
};

const IModelPanelWrapper = ({ projectId }: ModelPanelWrapperProps) => {
  const nodes = useNodes() as NodeType[];
  const selectedNode = nodes.find((node) => node.selected);
  const modelId = selectedNode?.data.resourceIds.find((r) => r.startsWith('las:model'));

  if (!modelId || !selectedNode || selectedNode.data.resourceGroupType !== 'model') {
    return null;
  }

  return <ModelPanelSlideOut projectId={projectId} modelId={modelId} />;
};

export const ModelPanelSlideOutWrapper = memo(IModelPanelWrapper) as typeof IModelPanelWrapper;
