import { memo } from 'react';

import { merge } from '@/components';
import { ComingSoon, MenuItem, MenuItemProps, Text } from '@/components/core';
import { DateLogo, CurrencyLogo, IBANNumberLogo, NumericLogo, StringLogo, ClassificationLogo } from '@/components/ui';
import { FieldState } from '@/store';
import { OmitChildren } from '@/utils';

export type FieldWithComingSoon = FieldState & { comingSoon?: boolean };
export type TemplateModel = { fields: FieldWithComingSoon[]; sampleDocuments: string[] };

export type FieldMenuItemProps = OmitChildren<MenuItemProps> & {
  field: FieldWithComingSoon;
};

const IFieldMenuItem = ({ field, className, ...rest }: FieldMenuItemProps) => {
  let logo;
  switch (true) {
    case /iban/i.test(field.name):
      logo = <IBANNumberLogo size="xl" variant="formatter" />;
      break;
    case /currency/i.test(field.name):
      logo = <CurrencyLogo size="xl" variant="formatter" />;
      break;
    case /classification/i.test(field.name):
      logo = <ClassificationLogo size="xl" variant="formatter" />;
      break;
    case /date/i.test(field.name):
      logo = <DateLogo size="xl" variant="formatter" />;
      break;
    case /amount/i.test(field.name):
    case /number/i.test(field.name):
    case /numeric/i.test(field.name):
      logo = <NumericLogo size="xl" variant="formatter" />;
      break;
    default:
      logo = <StringLogo size="xl" variant="formatter" />;
      break;
  }

  return (
    <MenuItem
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-2', className)}
      {...rest}
    >
      {logo}
      <div className="flex flex-1 flex-col">
        <Text>{field.name}</Text>
        <Text size="xs">{field.promptHint}</Text>
      </div>
      {field.comingSoon ? <ComingSoon /> : null}
    </MenuItem>
  );
};

export const FieldMenuItem = memo(IFieldMenuItem) as typeof IFieldMenuItem;
