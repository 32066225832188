import { useReactFlow } from '@xyflow/react';
import { memo, useCallback, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Text } from '@/components/core';
import { NewTriggerModal, TriggerOption } from '@/components/flow';
import { useAddTrigger } from '@/hooks';

export type EmptyTriggerNodeContentProps = {
  projectId: string;
};

const IEmptyTriggerNodeContent = ({ projectId }: EmptyTriggerNodeContentProps) => {
  const [newTriggerModelOpened, setNewTriggerModalOpened] = useState(false);
  const builder = useReactFlow();
  const onSuccess = useCallback(() => {
    builder.setNodes((nodes) => nodes);
    setNewTriggerModalOpened(false);
  }, [builder]);
  const { addTrigger, isPending: isAddingTrigger } = useAddTrigger({ onSuccess });

  const onTriggerOptionSelected = useCallback(
    async (triggerOption: TriggerOption) => {
      await addTrigger({ projectId, triggerOption });
    },
    [addTrigger, projectId]
  );

  return (
    <DialogTrigger>
      <Button
        variant="plain"
        className="flex h-24 w-full items-center justify-center gap-2 rounded-lg border-2 border-dashed"
        onPress={() => setNewTriggerModalOpened(true)}
      >
        <PlusIcon className="stroke-gray-500" />
        <Text>Add trigger</Text>
      </Button>
      <NewTriggerModal
        onTriggerOptionSelected={onTriggerOptionSelected}
        isAddingTrigger={isAddingTrigger}
        isOpen={newTriggerModelOpened}
        onOpenChange={() => setNewTriggerModalOpened(false)}
      />
    </DialogTrigger>
  );
};

export const EmptyTriggerNodeContent = memo(IEmptyTriggerNodeContent) as typeof IEmptyTriggerNodeContent;
