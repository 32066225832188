import type { CreateUserOptions, User } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type UserId = { userId: string };

export const {
  useCreate: useCreateUser,
  useGet: useGetUser,
  useDelete: useDeleteUser,
  useList: useListUsers,
} = create<User, UserId, CreateUserOptions>({
  cacheKey: 'users',
  equal: (user, id) => Boolean(user && id?.userId && user.userId === id.userId),
  createId: (user) => (user ? { userId: user.userId } : null),
  create: (client, user) => client.createUser(user),
  delete: (client, id) => client.deleteUser(id.userId),
  get: (client, id) => client.getUser(id.userId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listUsers()
        .then((response: JSONType) => {
          resolve({ data: response.users, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateUser(id.userId, updates),
});

export const toUserId = (userId?: string | null): UserId | null => {
  return userId ? { userId } : null;
};
