import type { Action, Hook, Project, Validation } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { CREATE_VALIDATION_TASK_FUNCTION_ID, HOOK_EVALUATE_PREDICTION } from '@/constants';
import { toProjectId, useCreateAction, useCreateHook, useCreateValidation, useUpdateProject } from '@/hooks/api';

export type UseAddValidationOnSuccess = ({
  validation,
  action,
  hook,
  project,
}: {
  validation: Validation;
  action: Action;
  hook: Hook;
  project: Project;
}) => void;

export type UseAddValidationOnError = ({ error }: { error: Error }) => void;

export type UseAddValidationOpts = {
  onSuccess?: UseAddValidationOnSuccess;
  onError?: UseAddValidationOnError;
};

export const useAddValidation = (opts?: UseAddValidationOpts) => {
  const { onSuccess: onSuccessFnBase, onError: onErrorFnBase } = opts ?? {};

  const {
    mutateAsync: createValidation,
    isPending: isPendingCreateValidation,
    isSuccess: isSuccessCreateValidation,
    error: errorCreateValidation,
  } = useCreateValidation();

  const {
    mutateAsync: createAction,
    isPending: isPendingCreateAction,
    isSuccess: isSuccessCreateAction,
    error: errorCreateAction,
  } = useCreateAction();

  const {
    mutateAsync: createHook,
    isPending: isPendingCreateHook,
    isSuccess: isSuccessCreateHook,
    error: errorCreateHook,
  } = useCreateHook();

  const {
    mutateAsync: updateProject,
    isPending: isPendingUpdateProject,
    isSuccess: isSuccessUpdateProject,
    error: errorUpdateProject,
  } = useUpdateProject();

  const addValidation = useCallback(
    async ({
      projectId,
      modelId,
      onSuccess: onSuccessFn,
      onError: onErrorFn,
    }: {
      projectId: string;
      modelId: string;
      onSuccess?: UseAddValidationOnSuccess;
      onError?: UseAddValidationOnError;
    }) => {
      const onSuccess: UseAddValidationOnSuccess = (...args) => {
        onSuccessFnBase?.(...args);
        onSuccessFn?.(...args);
      };
      const onError: UseAddValidationOnError = (...args) => {
        onErrorFnBase?.(...args);
        onErrorFn?.(...args);
      };

      try {
        const validation = await createValidation({ config: {}, projectId: projectId });

        const action = await createAction({
          config: { validationId: validation.validationId },
          functionId: CREATE_VALIDATION_TASK_FUNCTION_ID,
          projectId: projectId,
        });

        const hook = await createHook({
          functionId: HOOK_EVALUATE_PREDICTION,
          config: { modelId },
          projectId: projectId,
          trigger: 'Prediction is Created',
          falseActionId: action.actionId,
        });

        const project = await updateProject({
          id: toProjectId(projectId),
          updates: (project: Project) => ({
            resourceIds: [...project.resourceIds, validation.validationId, action.actionId, hook.hookId],
          }),
        });

        onSuccess?.({ validation, action, hook, project });
        return validation;
      } catch (error) {
        if (error instanceof Error) {
          onError?.({ error });
        }
      }
    },
    [createAction, createHook, createValidation, onErrorFnBase, onSuccessFnBase, updateProject]
  );

  return {
    addValidation,
    isPending: isPendingCreateValidation || isPendingCreateAction || isPendingCreateHook || isPendingUpdateProject,
    isSuccess: isSuccessCreateValidation && isSuccessCreateAction && isSuccessCreateHook && isSuccessUpdateProject,
    errors: [errorCreateValidation, errorCreateAction, errorCreateHook, errorUpdateProject].filter((e) => !!e),
  };
};
