import { memo, useCallback } from 'react';

import Logo from '@/assets/logo.svg?react';
import { Header, Loading, WizardStepIndicator } from '@/components/core';
import { useAuth } from '@/hooks';
import { useCreateOrganization } from '@/hooks/api';
import { OrganizationProvider } from '@/store';

import { NewProject } from '../projects';
import { ContactDetails } from './ContactDetails';
import { useWizard, Wizard } from './Wizard';

export type OnboardingState = {
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationUrl: string;
} & NewProject;

export const IOnboarding = () => {
  const { mutateAsync: createOrganization, isPending: isPendingCreateOrganization } = useCreateOrganization();
  const { setRedirectUrl, ensureLoggedIn } = useAuth();

  const onCreate = useCallback(
    async (result?: Partial<OnboardingState>): Promise<Partial<OnboardingState>> => {
      if (result) {
        createOrganization({ name: result.organizationName, url: result.organizationUrl }).then((organization) => {
          setRedirectUrl('/projects');
          ensureLoggedIn(organization.clientId);
        });
      }

      return {};
    },
    [createOrganization, ensureLoggedIn, setRedirectUrl]
  );

  return (
    <>
      {isPendingCreateOrganization ? (
        <Loading label="Setting up organization ..." />
      ) : (
        <div className="flex h-screen flex-col items-center justify-center gap-8 bg-gradient-to-b from-white to-slate-200">
          <div className="flex items-center gap-2">
            <Logo />
            <Header size="xl">Cradl AI</Header>
          </div>

          <Wizard>
            <Wizard.Step onNext={onCreate}>{() => <ContactDetails />}</Wizard.Step>
            <WizardStepIndicator useWizard={useWizard} hideLast />
          </Wizard>
        </div>
      )}
    </>
  );
};

export const Onboarding = memo(IOnboarding);

export const IOnboardingWrapper = () => {
  return (
    <OrganizationProvider>
      <Onboarding />
    </OrganizationProvider>
  );
};

export const OnboardingWrapper = memo(IOnboardingWrapper);
