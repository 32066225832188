import { memo } from 'react';

import EmailIcon from '@/assets/email-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type EmailLogoProps = VariantLogoProps;

const IEmailLogo = ({ ...rest }: EmailLogoProps) => {
  return <FormatterValidatorLogo Icon={EmailIcon} iconColoringType="stroke" {...rest} />;
};

export const EmailLogo = memo(IEmailLogo) as typeof IEmailLogo;
