import SampleDocument from '@/assets/sampleDocuments/Purchase Order.pdf';
import { TemplateModel } from '@/components/ui';

import { amountFormatter, dateFormatter, highConfidenceValidator, mediumConfidenceValidator } from './common';

export const PURCHASE_ORDER: TemplateModel = {
  fields: [
    {
      id: 'supplier_name',
      type: 'single-value',
      name: 'Supplier',
      description: 'The company that issued the purchase order.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'buyer_name',
      type: 'single-value',
      name: 'Buyer',
      description: 'The company or person the invoice was issued to.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'issue_date',
      type: 'single-value',
      name: 'Issue Date',
      description: 'The issue date.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'total_amount',
      type: 'single-value',
      name: 'Total Amount',
      description: 'The total amount, including taxes.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'po_lines',
      type: 'table',
      name: 'Purchase Order Line Items',
      description: 'The purchase order line items.',
      fields: [
        {
          id: 'delivery_date',
          type: 'single-value',
          name: 'Delivery Date',
          promptHint: 'The delivery date for this particular item, if available, or for the whole order.',
          formatters: [dateFormatter],
          validators: [highConfidenceValidator],
        },
        {
          id: 'po_number',
          type: 'single-value',
          name: 'PO number',
          promptHint: 'The PO number for this item, if available, or for the whole order.',
          validators: [highConfidenceValidator],
        },
        {
          id: 'description',
          type: 'single-value',
          name: 'Line Description',
          promptHint: 'A description of the purchased product or service.',
          validators: [mediumConfidenceValidator],
        },
        {
          id: 'quantity',
          type: 'single-value',
          name: 'Quantity',
          promptHint: 'The quantity.',
          formatters: [],
          validators: [highConfidenceValidator],
        },
        {
          id: 'unit',
          type: 'single-value',
          name: 'Unit',
          promptHint: 'The unit of measure.',
          formatters: [],
          validators: [highConfidenceValidator],
        },
        {
          id: 'unit_price',
          type: 'single-value',
          name: 'Unit Price',
          promptHint: 'The price per unit.',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
        {
          id: 'line_total',
          type: 'single-value',
          name: 'Line Total',
          promptHint: 'The line total amount.',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
