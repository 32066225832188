import { memo, useEffect, useState } from 'react';

import { Hr, Text } from '@/components/core';
import { TextArea } from '@/components/core';
import { findField, FormatterValidatorHeader } from '@/components/ui';
import { useFormatterMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

import { FormatterTest } from './FormatterTest';

export type RegexFormatterProps = {
  fieldId: string;
  index: number;
};

const IRegexFormatter = ({ fieldId, index }: RegexFormatterProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const formatter = field?.formatters?.[index];
  const [matchPattern, setMatchPattern] = useState(formatter?.config.matchPattern);
  const [replacePattern, setReplacePattern] = useState(formatter?.config.replacePattern);

  const mutate = useFormatterMutation(fieldId, index, field?.parentId);

  useEffect(() => mutate((f) => (f.config.matchPattern = matchPattern)), [matchPattern, mutate]);
  useEffect(() => mutate((f) => (f.config.replacePattern = replacePattern)), [replacePattern, mutate]);

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader formatter={formatter} />

      <Hr />
      <div className="flex grow flex-col gap-4">
        <Text>Match pattern</Text>
        <TextArea delay={500} value={formatter?.config.matchPattern} onChange={setMatchPattern} />

        <Text>Replace pattern</Text>
        <TextArea delay={500} value={formatter?.config.replacePattern} onChange={setReplacePattern} />
      </div>

      <FormatterTest fieldId={fieldId} index={index} />
    </div>
  );
};

export const RegexFormatter = memo(IRegexFormatter) as typeof IRegexFormatter;
