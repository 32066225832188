import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { guard, merge } from '@/components';
import { Header, Save, Table, Text } from '@/components/core';
import { ExportCell, ExportOption, EXPORTS } from '@/components/flow';
import { DivProps, Id, OmitChildren } from '@/utils';

export type OnExportOptionSelected = (exportOption: ExportOption) => void;

export type NewExportProps = OmitChildren<DivProps> & {
  onExportOptionSelected?: OnExportOptionSelected;
  isAddingExport?: boolean;
};

const INewExport = ({ onExportOptionSelected, isAddingExport, className, ...rest }: NewExportProps) => {
  const exportData: (ExportOption & Id)[] = useMemo(() => {
    return EXPORTS.map((e) => ({ ...e, id: e.functionId }));
  }, []);

  const exportColumns = useMemo<ColumnDef<ExportOption & Id>[]>(() => {
    return [
      {
        accessorKey: 'functionId',
        cell: ({ row }) => <ExportCell onPress={() => onExportOptionSelected?.(row.original)} option={row.original} />,
        size: Number.MAX_SAFE_INTEGER,
      },
    ];
  }, [onExportOptionSelected]);

  return (
    <div className={merge('flex flex-col gap-2', className)} {...rest}>
      <Header size="lg">Add export</Header>
      <Text size="sm">Configure how you will export documents from Cradl</Text>
      {guard(isAddingExport, () => (
        <Save message="Setting up export" />
      ))}
      {/* TODO: Maybe use ternary statement instead */}
      {guard(!isAddingExport, () => (
        <Table
          className="border-separate border-spacing-2"
          columns={exportColumns}
          data={exportData}
          hideHeader
          hideDragHandle
          hideRowOptions
        />
      ))}
    </div>
  );
};

export const NewExport = memo(INewExport) as typeof INewExport;
