import { memo } from 'react';
import { TextField } from 'react-aria-components';

import { Checkbox, DebouncedInput, Input, Label, Text, TextArea } from '@/components/core';
import { findField, FormattersSection, ValidatorsSection } from '@/components/ui';
import { useModelConfigStore } from '@/store';
import { toBoolean, toString } from '@/utils';

export type FieldDetailsProps = {
  fieldId: string;
};

const IFieldDetails = ({ fieldId }: FieldDetailsProps) => {
  const updateField = useModelConfigStore((state) => state.updateField);
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));

  if (!field) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-3 p-4">
        <TextField
          className="flex flex-col gap-1"
          defaultValue={field.name}
          onChange={(name) => updateField(field.id, { name }, field.parentId)}
        >
          <Label>Field name</Label>
          <Input />
        </TextField>
        <TextField
          className="flex flex-col gap-1"
          defaultValue={toString(field.promptHint)}
          onChange={(promptHint) => updateField(field.id, { promptHint }, field.parentId)}
        >
          <Label>AI prompt</Label>
          <TextArea />
        </TextField>
        <section>
          <Label>Field settings</Label>
          <div className="flex gap-2 py-2">
            <Checkbox
              isSelected={field.type === 'multi-value'}
              onChange={(isMultiValue) =>
                updateField(field.id, { type: isMultiValue ? 'multi-value' : 'single-value' }, field.parentId)
              }
            />
            <div className="flex flex-col gap-1">
              <Text>Multi value</Text>
              <Text size="xs">If enabled, field can have multiple values</Text>
            </div>
          </div>
          <div className="flex gap-2 py-2">
            <Checkbox
              isSelected={toBoolean(field.isNullable)}
              onChange={(isNullable) => updateField(field.id, { isNullable }, field.parentId)}
            />
            <div className="flex flex-col gap-1">
              <Text>Can be empty</Text>
              <Text size="xs">If enabled, field can have no values</Text>
            </div>
          </div>
        </section>
        <FormattersSection fieldId={fieldId} />
        <ValidatorsSection fieldId={fieldId} />
      </div>
    </>
  );
};

export const FieldDetails = memo(IFieldDetails) as typeof IFieldDetails;
