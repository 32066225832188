import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  type Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table';
import {
  CSSProperties,
  DetailedHTMLProps,
  memo,
  ReactNode,
  RefAttributes,
  TableHTMLAttributes,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Menu, MenuTrigger } from 'react-aria-components';

import CopyIcon from '@/assets/copy-icon.svg?react';
import DndHandleIcon from '@/assets/dnd-handle-icon.svg?react';
import DotsIcon from '@/assets/dots-icon.svg?react';
import { merge } from '@/components';
import { Button, Label, MenuItem, MenuItemDelete, Popover, Text } from '@/components/core';
import { Id, TableRowProps } from '@/utils';

export type RowCellProps<TRow> = {
  row: Row<TRow>;
  isInnerTable?: boolean;
};

const IRowDragHandleCell = <TRow,>({ row, isInnerTable }: RowCellProps<TRow>) => {
  const { attributes, listeners } = useSortable({ id: row.id });
  return (
    // TODO: Figure out how to use Button instead of vanilla button
    <button {...attributes} {...listeners} className="flex cursor-pointer items-center px-1" tabIndex={-1}>
      <DndHandleIcon
        className={merge(
          'size-4 text-transparent',
          isInnerTable ? 'group-hover/row-inner:fill-gray-400' : 'group-hover/row-outer:fill-gray-400'
        )}
      />
    </button>
  );
};

export const RowDragHandleCell = memo(IRowDragHandleCell) as typeof IRowDragHandleCell;

export type RowOptions<TRow> = {
  options?: {
    onAction: (row: Row<TRow>) => void;
    children: ReactNode;
  }[];
  duplicateRowAction?: (row: Row<TRow>) => void;
  deleteRowAction?: (row: Row<TRow>) => void;
};

const IRowOptionsCell = <TRow,>({
  row,
  isInnerTable,
  options,
  duplicateRowAction,
  deleteRowAction,
}: RowOptions<TRow> & RowCellProps<TRow>) => {
  return (
    <MenuTrigger>
      <Button variant="plain" excludeFromTabOrder>
        <DotsIcon
          className={merge(
            'text-transparent',
            isInnerTable ? 'group-hover/row-inner:fill-gray-500' : 'group-hover/row-outer:fill-gray-500'
          )}
        />
      </Button>
      <Popover>
        <Menu className="outline-none">
          {options?.map((option, i) => (
            <MenuItem key={i} className="flex items-center p-3" onAction={() => option.onAction(row)}>
              {option.children}
            </MenuItem>
          ))}
          {duplicateRowAction ? (
            <MenuItem className="flex items-center p-3" onAction={() => duplicateRowAction(row)}>
              <CopyIcon className="size-4 stroke-gray-500" />
              <Text size="sm">Duplicate</Text>
            </MenuItem>
          ) : null}
          {deleteRowAction ? <MenuItemDelete onAction={() => deleteRowAction(row)} /> : null}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

export const RowOptionsCell = memo(IRowOptionsCell) as typeof IRowOptionsCell;

type DraggableRowProps<TRow> = TableRowProps & {
  row: Row<TRow>;
  isInnerTable?: boolean;
};

const IDraggableRow = <TRow extends Id>({ row, isInnerTable, className, ...rest }: DraggableRowProps<TRow>) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  return (
    <tr
      className={merge(
        'relative',
        isDragging ? 'z-10 opacity-80' : 'z-0 opacity-100',
        isInnerTable ? 'group/row-inner' : 'group/row-outer',
        className
      )}
      ref={setNodeRef}
      style={style}
      {...rest}
    >
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} style={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

export const DraggableRow = memo(IDraggableRow) as typeof IDraggableRow;

export type TableRef<TRow> = {
  table: ReactTable<TRow>;
  remountSortableContext: () => void;
};

export type TableProps<TRow> = Omit<DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>, 'ref'> &
  RowOptions<TRow> &
  RefAttributes<TableRef<TRow>> & {
    columns: ColumnDef<TRow>[];
    data: TRow[];
    isInnerTable?: boolean;
    onRowIndexChange?: (oldIndex: number, newIndex: number) => void;
    hideHeader?: boolean;
    hideRowOptions?: boolean;
    hideDragHandle?: boolean;
    props?: {
      row?: TableRowProps;
    };
  };

const ITable = <TRow extends Id>({
  data,
  isInnerTable,
  columns,
  onRowIndexChange,
  hideHeader,
  hideRowOptions,
  hideDragHandle,
  options,
  duplicateRowAction,
  deleteRowAction,
  className,
  props,
  ref,
  ...rest
}: TableProps<TRow>) => {
  const [sortableContextKey, setSortableContextKey] = useState(0);
  const columns_ = useMemo<ColumnDef<TRow>[]>(() => {
    const leftColumns: ColumnDef<TRow>[] = hideDragHandle
      ? []
      : [
          {
            cell: ({ row }) => <RowDragHandleCell row={row} isInnerTable={isInnerTable} />,
            id: 'drag-handle',
            size: 0,
          },
        ];

    const rightColumns: ColumnDef<TRow>[] = hideRowOptions
      ? []
      : [
          {
            cell: ({ row }) => (
              <RowOptionsCell
                row={row}
                isInnerTable={isInnerTable}
                options={options}
                duplicateRowAction={duplicateRowAction}
                deleteRowAction={deleteRowAction}
              />
            ),
            id: 'row-options',
            size: 0,
          },
        ];

    return [...leftColumns, ...columns, ...rightColumns];
  }, [columns, deleteRowAction, duplicateRowAction, hideDragHandle, hideRowOptions, isInnerTable, options]);

  const dataIds = useMemo<UniqueIdentifier[]>(() => data?.map(({ id }) => id), [data]);

  const table = useReactTable({
    columns: columns_,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        onRowIndexChange?.(oldIndex, newIndex);
      }
    },
    [dataIds, onRowIndexChange]
  );

  useImperativeHandle(ref, () => {
    return {
      remountSortableContext() {
        setSortableContextKey(sortableContextKey + 1);
      },
      table,
    };
  }, [sortableContextKey, table]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
  const { className: rowClassName, ...rowProps } = props?.row ?? {};

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      onDragStart={() => setSortableContextKey((prev) => prev + 1)}
      sensors={sensors}
    >
      <table className={merge('w-full', className)} {...rest}>
        {!hideHeader ? (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="text-left" key={header.id}>
                    <Label>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </Label>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody>
          {/* https://github.com/clauderic/dnd-kit/issues/1513 */}
          {/* https://github.com/clauderic/dnd-kit/issues/1417 */}
          <SortableContext key={sortableContextKey} items={dataIds} strategy={verticalListSortingStrategy}>
            {table
              .getRowModel()
              .rows.filter((row) => !row.original.hidden)
              .map((row) => (
                <DraggableRow
                  className={rowClassName}
                  key={row.id}
                  row={row}
                  isInnerTable={isInnerTable}
                  {...rowProps}
                />
              ))}
          </SortableContext>
        </tbody>
      </table>
    </DndContext>
  );
};

export const Table = memo(ITable) as typeof ITable;
