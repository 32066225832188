import { memo } from 'react';

import CustomCodeIcon from '@/assets/custom-code-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type CustomCodeLogoProps = VariantLogoProps;

const ICustomCodeLogo = ({ ...rest }: CustomCodeLogoProps) => {
  return <FormatterValidatorLogo Icon={CustomCodeIcon} iconColoringType="stroke" {...rest} />;
};

export const CustomCodeLogo = memo(ICustomCodeLogo) as typeof ICustomCodeLogo;
