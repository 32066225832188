import SampleDocument from '@/assets/sampleDocuments/Invoice.pdf';
import { TemplateModel } from '@/components/ui';

import {
  amountFormatter,
  currencyFormatter,
  dateFormatter,
  highConfidenceValidator,
  mediumConfidenceValidator,
} from './common';

export const INVOICE: TemplateModel = {
  fields: [
    {
      id: 'supplier_name',
      type: 'single-value',
      name: 'Supplier',
      promptHint: 'The company that issued the invoice.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'buyer_name',
      type: 'single-value',
      name: 'Buyer',
      promptHint: 'The company or person the invoice was issued to.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'invoice_id',
      type: 'single-value',
      name: 'Invoice Number',
      promptHint: 'The invoice number.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'invoice_date',
      type: 'single-value',
      name: 'Invoice Date',
      promptHint: 'The invoice date.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'due_date',
      type: 'single-value',
      name: 'Due Date',
      promptHint: 'The invoice due date. Can also be a relative date, such as "30 days".',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'iban',
      type: 'single-value',
      name: 'IBAN',
      promptHint: 'The IBAN number',
      validators: [highConfidenceValidator],
    },
    {
      id: 'total_amount',
      type: 'single-value',
      name: 'Total Amount',
      promptHint: 'The total amount, including taxes.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'currency',
      type: 'single-value',
      name: 'Currency',
      promptHint: 'The ISO currency of the total amount.',
      formatters: [currencyFormatter],
    },
    {
      id: 'invoice_lines',
      type: 'table',
      name: 'Invoice Line Items',
      promptHint: 'The invoice line items.',
      fields: [
        {
          id: 'description',
          type: 'single-value',
          name: 'Line Description',
          promptHint: 'A description of the purchased product or service',
          validators: [mediumConfidenceValidator],
        },
        {
          id: 'line_total',
          type: 'single-value',
          name: 'Line Total',
          promptHint: 'The line total amount',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
