import { memo } from 'react';
import {
  ListBox as ListBox_,
  ListBoxItem as ListBoxItem_,
  ListBoxItemProps as ListBoxItemProps_,
  ListBoxProps as ListBoxProps_,
} from 'react-aria-components';

import { merge } from '../utils';

export type ListBoxProps<T> = ListBoxProps_<T>;
export type ListBoxItemProps = ListBoxItemProps_;

const IListBox = <T extends object>({ className, children, ...rest }: ListBoxProps<T>) => {
  return (
    <ListBox_ className={merge('rounded-lg outline-none', className)} {...rest}>
      {children}
    </ListBox_>
  );
};

const IListBoxItem = ({ className, children, ...rest }: ListBoxItemProps) => {
  return (
    <ListBoxItem_
      className={merge(
        'cursor-pointer items-center gap-2 rounded-lg bg-white p-2 outline-none hover:bg-gray-50',
        className
      )}
      {...rest}
    >
      {children}
    </ListBoxItem_>
  );
};

export const ListBox = memo(IListBox) as typeof IListBox;
export const ListBoxItem = memo(IListBoxItem) as typeof IListBoxItem;
