import { memo } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Header, Text } from '@/components/core';
import { ValidationPanelHeader } from '@/components/flow';
import { InviteMembersModal, MembersTable } from '@/components/ui';
import { toActionId, useSuspenseGetAction } from '@/hooks/api';
import { TriggerProvider } from '@/store';

export type ValidationPanelProps = {
  actionId: string;
  projectId: string;
  validationId: string;
};

const IValidationPanel = ({ projectId, actionId, validationId }: ValidationPanelProps) => {
  const { data: action } = useSuspenseGetAction(toActionId(actionId));

  return (
    /* Force remount of component when projectId, actionId or validationId changes */
    <div key={`${projectId}/${actionId}/${validationId}`}>
      <TriggerProvider action={action}>
        <ValidationPanelHeader className="border-b border-gray-200 p-4" actionId={actionId} />
        <div className="flex flex-col gap-4 p-4">
          <div>
            <Header size="md">Reviewers</Header>
            <Text>You can invite your team to review model predictions</Text>
          </div>
          <MembersTable />
          <div>
            <DialogTrigger>
              <Button variant="secondary">
                <PlusIcon className="stroke-gray-500" />
                Add reviewer
              </Button>
              <InviteMembersModal />
            </DialogTrigger>
          </div>
        </div>
      </TriggerProvider>
    </div>
  );
};

export const ValidationPanel = memo(IValidationPanel) as typeof IValidationPanel;
