import { memo, useCallback } from 'react';
import { Focusable } from 'react-aria-components';

import CloseIcon from '@/assets/close-icon.svg?react';
import { Text, Tooltip, TooltipProps } from '@/components/core';
import { useTour } from '@/hooks';

export type ReviewPredictionsTooltipProps = Omit<TooltipProps, 'tooltip' | 'children'>;

const IReviewPredictionsTooltip = ({ placement = 'left', ...rest }: ReviewPredictionsTooltipProps) => {
  const { showReviewPredictionsTooltip, setShowReviewPredictionsTooltip } = useTour();
  const close = useCallback(() => setShowReviewPredictionsTooltip(false), [setShowReviewPredictionsTooltip]);

  return (
    <Tooltip
      placement={placement}
      isDisabled={!showReviewPredictionsTooltip}
      isOpen={showReviewPredictionsTooltip}
      tooltip={
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Text className="font-semibold text-white">✅ Review AI Predictions</Text>
            <div className="grow" />
            <button className="cursor-pointer text-white hover:[&_svg]:stroke-gray-100" onClick={close}>
              <CloseIcon className="stroke-white" />
            </button>
          </div>
          <Text className="text-white">
            When you hit confirm, the file will be marked as completed in your runs. You can then download the processed
            data manually or setup automatic export.
          </Text>
          <button
            onClick={close}
            className="w-fit cursor-pointer self-end rounded-lg bg-white px-2 py-1 text-black hover:bg-gray-100"
          >
            Got it!
          </button>
        </div>
      }
      {...rest}
    >
      <Focusable>
        <div />
      </Focusable>
    </Tooltip>
  );
};

export const ReviewPredictionsTooltip = memo(IReviewPredictionsTooltip) as typeof IReviewPredictionsTooltip;
