import { createContext } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface ProfileProps {
  givenName?: string | null;
  familyName?: string | null;
  email?: string | null;
  pictureUrl?: string | null;
}

export interface ProfileState extends ProfileProps {
  updateProfile: (updates: Partial<ProfileProps>) => void;
}

export const createProfileStore = (initProps?: Partial<ProfileProps>) => {
  return createStore<ProfileState>()(
    devtools(
      immer((set) => ({
        ...initProps,
        updateProfile: (updates) =>
          set((state) => {
            if (updates.givenName) {
              state.givenName = updates.givenName;
            }
            if (updates.familyName) {
              state.familyName = updates.familyName;
            }
          }),
      }))
    )
  );
};

export type ProfileStore = ReturnType<typeof createProfileStore>;

export const ProfileContext = createContext<ProfileStore | null>(null);
