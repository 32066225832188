import { ReactNode, SVGProps } from 'react';

import CheckmarkIcon from '@/assets/checkmark-icon.svg?react';
import { merge } from '@/components';
import { Tooltip } from '@/components/core';

export type CheckmarkSymbolProps = SVGProps<SVGSVGElement> & {
  tooltip?: ReactNode;
};

export const CheckmarkSymbol = ({ className, tooltip, ...rest }: CheckmarkSymbolProps) => {
  /* prettier-ignore */
  const styles  = [
    'rounded-full',
    'size-5',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'stroke-green-300',
    'fill-green-50'
  ];
  /* prettier-ignore */
  const darkStyles = [
    'stroke-green-300',
    'fill-green-50'
  ];

  return (
    <Tooltip tooltip={tooltip}>
      <CheckmarkIcon className={merge(styles, lightStyles, darkStyles, className)} {...rest} />
    </Tooltip>
  );
};
