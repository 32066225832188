import { BaseEdge, type Edge as Edge_, type EdgeProps as EdgeProps_, getBezierPath } from '@xyflow/react';

export type EdgeProps = {
  value: number;
};

export type Edge = Edge_<EdgeProps, 'edge'>;

export const Edge = ({ id, sourceX, sourceY, targetX, targetY }: EdgeProps_<Edge>) => {
  const [edgePath] = getBezierPath({ sourceX, sourceY, targetX, targetY });
  return <BaseEdge id={id} path={edgePath} />;
};
