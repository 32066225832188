import { useCallback } from 'react';

export type UseDownloadFileOpts = {
  fetchData: () => Promise<Blob>;
  downloadedFileName: string;
  onError?: (error: unknown) => void;
};

export const useDownloadFile = ({ fetchData, downloadedFileName, onError }: UseDownloadFileOpts) => {
  const download = useCallback(async () => {
    try {
      const data = await fetchData();
      const url = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.hidden = true;
      link.setAttribute('download', downloadedFileName);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      console.log('Error downloading file', error);
      onError?.(error);
    }
  }, [downloadedFileName, fetchData, onError]);

  return { download };
};
