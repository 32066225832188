import { memo } from 'react';

import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';
import { useImageSrc } from '@/hooks';
import { toOrganizationId, useGetOrganization } from '@/hooks/api';
import { OmitChildren } from '@/utils';

export type OrganizationLogoProps = OmitChildren<LogoProps> & {
  organizationId: string;
};

const IOrganizationLogo = ({ organizationId, ...rest }: OrganizationLogoProps) => {
  const { data: organization } = useGetOrganization(toOrganizationId(organizationId));
  const { src } = useImageSrc({ source: organization?.pictureUrl });

  return (
    <Logo {...rest}>
      <img
        className={merge(
          rest.size === 'md' && 'rounded-sm',
          rest.size === 'lg' && 'rounded-lg',
          rest.size === 'xl' && 'rounded-lg'
        )}
        alt={organization?.name ?? ''}
        src={src}
      />
    </Logo>
  );
};

export const OrganizationLogo = memo(IOrganizationLogo) as typeof IOrganizationLogo;
