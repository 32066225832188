import { PropsWithChildren, useRef } from 'react';

import { createTriggerStore, TriggerContext, TriggerProps, TriggerStore } from './triggerStore';

type TriggerProviderProps = PropsWithChildren<TriggerProps>;

export const TriggerProvider = ({ children, ...props }: TriggerProviderProps) => {
  const storeRef = useRef<TriggerStore>(null);

  // TODO: Check out using API hooks here and create new store when objects change
  if (!storeRef.current) {
    storeRef.current = createTriggerStore(props);
  }

  return <TriggerContext.Provider value={storeRef.current}>{children}</TriggerContext.Provider>;
};
