import { Text, WarningSymbol } from '@/components/core';

export const SetupRequired = () => {
  return (
    <div className="flex w-full items-center gap-1 rounded-lg border-2 border-amber-200 bg-amber-50 p-1">
      <WarningSymbol className="fill-amber-500 stroke-white" />
      <Text className="text-amber-800" size="xs">
        Setup required
      </Text>
    </div>
  );
};
