import { useEffect, useMemo, useState } from 'react';

import { toFileId, useGetFile } from '@/hooks/api';
import { toDataUrl } from '@/utils';

export type UseImageSrcOpts = {
  source?: string | Blob | null;
};

export const useImageSrc = ({ source }: UseImageSrcOpts) => {
  const [src, setSrc] = useState<string | undefined>();

  const fileserverUrl = useMemo(() => {
    if (typeof source === 'string' && source.startsWith(import.meta.env.VITE_FILES_ENDPOINT)) {
      return source;
    }
  }, [source]);

  const { data: blob } = useGetFile(toFileId(fileserverUrl));

  useEffect(() => {
    if (blob) {
      toDataUrl(blob).then((dataUrl) => {
        setSrc(dataUrl);
      });
    } else if (source instanceof Blob) {
      toDataUrl(source).then((dataUrl) => {
        setSrc(dataUrl);
      });
    } else if (typeof source === 'string' && !source.startsWith(import.meta.env.VITE_FILES_ENDPOINT)) {
      setSrc(source);
    }
  }, [blob, source]);

  return { src };
};
