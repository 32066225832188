import type { Field } from '@lucidtech/las-sdk-browser';
import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { DocumentContext, DocumentState } from './documentStore';

export const selectFromFieldConfig = (name: string) => {
  let fieldName: string | undefined;
  let subFieldName: string | undefined;
  for (const match of name.matchAll(/(?<fieldName>.+)\[\w+]\.columns\.(?<subFieldName>.+)/g)) {
    fieldName = match.groups?.fieldName;
    subFieldName = match.groups?.subFieldName;
  }

  if (!fieldName) {
    fieldName = name;
  }

  return (state: DocumentState): Field | undefined => {
    if (subFieldName) {
      const config = state.fieldConfig?.[fieldName]?.fields?.[subFieldName];
      if (config) {
        return config;
      }
    }
    return state.fieldConfig?.[fieldName];
  };
};

export function useDocumentStore<T>(selector: (state: DocumentState) => T): T {
  const store = useContext(DocumentContext);
  if (!store) throw new Error('Missing DocumentContext.Provider in the tree');
  return useStore(store, selector);
}
