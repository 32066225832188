import type { Action, Hook, Project } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { ExportOption } from '@/components/flow';
import { HOOK_EVALUATE_PREDICTION } from '@/constants';
import { toProjectId, useCreateAction, useCreateHook, useUpdateProject } from '@/hooks/api';

export type UseAddExportOnSuccess = ({
  action,
  autoHook,
  reviewHook,
  project,
}: {
  action: Action;
  autoHook: Hook;
  reviewHook: Hook;
  project: Project;
}) => void;

export type UseAddExportOnError = ({ error }: { error: Error }) => void;
export type UseAddExportCallbackArgs = {
  projectId: string;
  exportOption: ExportOption;
  onSuccess?: UseAddExportOnSuccess;
  onError?: UseAddExportOnError;
};
export type UseAddExportCallback = ({ projectId, exportOption, onSuccess, onError }: UseAddExportCallbackArgs) => void;

export type UseAddExportOpts = {
  onSuccess?: UseAddExportOnSuccess;
  onError?: UseAddExportOnError;
};

export const useAddExport = ({ onSuccess: onSuccessFnBase, onError: onErrorFnBase }: UseAddExportOpts = {}) => {
  const {
    mutateAsync: updateProject,
    isPending: isPendingUpdateProject,
    isSuccess: isSuccessUpdateProject,
    error: errorUpdateProject,
  } = useUpdateProject();
  const {
    mutateAsync: createAction,
    isPending: isPendingCreateAction,
    isSuccess: isSuccessCreateAction,
    error: errorCreateAction,
  } = useCreateAction();
  const {
    mutateAsync: createHook,
    isPending: isPendingCreateHook,
    isSuccess: isSuccessCreateHook,
    error: errorCreateHook,
  } = useCreateHook();

  const addExport: UseAddExportCallback = useCallback(
    async ({ projectId, exportOption, onSuccess: onSuccessFn, onError: onErrorFn }: UseAddExportCallbackArgs) => {
      const onSuccess: UseAddExportOnSuccess = (...args) => {
        onSuccessFnBase?.(...args);
        onSuccessFn?.(...args);
      };

      const onError: UseAddExportOnError = (...args) => {
        onErrorFnBase?.(...args);
        onErrorFn?.(...args);
      };

      try {
        const action = await createAction({
          config: exportOption.config,
          functionId: exportOption.functionId,
          name: exportOption.name,
          projectId: projectId,
        });

        const [reviewHook, autoHook] = await Promise.all([
          // Hook triggering when validation task has succeeded
          createHook({
            config: {},
            projectId: projectId,
            trigger: 'ValidationTask has Completed',
            trueActionId: action.actionId,
          }),

          // Hook triggering when prediction is created and passed auto validation
          createHook({
            functionId: HOOK_EVALUATE_PREDICTION,
            config: {},
            projectId: projectId,
            trigger: 'Prediction is Created',
            trueActionId: action.actionId,
          }),
        ]);

        const project = await updateProject({
          id: toProjectId(projectId),
          updates: (project: Project) => ({
            resourceIds: [...project.resourceIds, action.actionId, reviewHook.hookId, autoHook.hookId],
          }),
        });

        onSuccess?.({ action, reviewHook, autoHook, project });

        return action;
      } catch (error) {
        if (error instanceof Error) {
          onError?.({ error });
        }
      }
    },
    [createAction, createHook, onErrorFnBase, onSuccessFnBase, updateProject]
  );

  return {
    addExport,
    isPending: isPendingCreateAction || isPendingCreateHook || isPendingUpdateProject,
    isSuccess: isSuccessCreateAction && isSuccessCreateHook && isSuccessUpdateProject,
    errors: [errorCreateAction, errorCreateHook, errorUpdateProject].filter((e) => !!e),
  };
};
