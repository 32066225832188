import { memo, SVGProps } from 'react';

import AmexCardLogo from '@/assets/amex-card-logo.svg?react';
import DinersClubCardLogo from '@/assets/diners-club-card-logo.svg?react';
import DiscoverCardLogo from '@/assets/discover-card-logo.svg?react';
import JCBCardLogo from '@/assets/jcb-card-logo.svg?react';
import MasterCardLogo from '@/assets/mastercard-card-logo.svg?react';
import UnionPayCardLogo from '@/assets/unionpay-card-logo.svg?react';
import VisaCardLogo from '@/assets/visa-card-logo.svg?react';

export type CreditCardLogoProps = SVGProps<SVGSVGElement> & {
  brand: 'amex' | 'diners_club' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa';
};

const ICreditCardLogo = ({ brand, ...rest }: CreditCardLogoProps) => {
  let Logo;
  switch (brand) {
    case 'amex':
      Logo = AmexCardLogo;
      break;
    case 'diners_club':
      Logo = DinersClubCardLogo;
      break;
    case 'discover':
      Logo = DiscoverCardLogo;
      break;
    case 'jcb':
      Logo = JCBCardLogo;
      break;
    case 'mastercard':
      Logo = MasterCardLogo;
      break;
    case 'unionpay':
      Logo = UnionPayCardLogo;
      break;
    case 'visa':
      Logo = VisaCardLogo;
      break;
    default:
      break;
  }

  return Logo ? <Logo {...rest} /> : null;
};

export const CreditCardLogo = memo(ICreditCardLogo) as typeof ICreditCardLogo;
