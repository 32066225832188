import { memo } from 'react';

import DatabaseMatchIcon from '@/assets/database-match-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type DatabaseMatchLogoProps = VariantLogoProps;

const IDatabaseMatchLogo = ({ ...rest }: DatabaseMatchLogoProps) => {
  return <FormatterValidatorLogo Icon={DatabaseMatchIcon} iconColoringType="fill" {...rest} />;
};

export const DatabaseMatchLogo = memo(IDatabaseMatchLogo) as typeof IDatabaseMatchLogo;
