import { memo } from 'react';

import ExcelIcon from '@/assets/excel-icon.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type ExcelLogoProps = LogoProps;

const IExcelLogo = ({ className, ...rest }: ExcelLogoProps) => {
  return (
    <Logo className={merge('rounded-lg border border-gray-200 bg-white', className)} {...rest}>
      <ExcelIcon className="size-5" />
    </Logo>
  );
};

export const ExcelLogo = memo(IExcelLogo) as typeof IExcelLogo;
