import { memo } from 'react';

import { Text } from '@/components/core';
import { ConfidenceValidator, findField, RegexValidator } from '@/components/ui';
import {
  VALIDATOR_CONFIDENCE_FUNCTION_ID,
  VALIDATOR_LOGEX_FUNCTION_ID,
  VALIDATOR_REGEX_FUNCTION_ID,
} from '@/constants';
import { useModelConfigStore } from '@/store';

import { LogexValidator } from './LogexValidator';

export type ValidatorConfigurationProps = {
  fieldId: string;
  index: number;
};

const IValidatorConfiguration = ({ fieldId, index }: ValidatorConfigurationProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const validator = field?.validators?.[index];

  if (!validator) return null;
  switch (validator.functionId) {
    case VALIDATOR_CONFIDENCE_FUNCTION_ID:
      return <ConfidenceValidator fieldId={fieldId} index={index} />;
    case VALIDATOR_REGEX_FUNCTION_ID:
      return <RegexValidator fieldId={fieldId} index={index} />;
    case VALIDATOR_LOGEX_FUNCTION_ID:
      return <LogexValidator fieldId={fieldId} index={index} />;
  }
  return <Text>Unrecognized validator</Text>;
};

export const ValidatorConfiguration = memo(IValidatorConfiguration) as typeof IValidatorConfiguration;
