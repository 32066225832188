import type { CreatePaymentMethodOptions, PaymentMethod } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type PaymentMethodId = { paymentMethodId: string };

export const {
  useDelete: useDeletePaymentMethod,
  useList: useListPaymentMethods,
  useCreate: useCreatePaymentMethod,
  useUpdate: useUpdatePaymentMethod,
} = create<PaymentMethod, PaymentMethodId, CreatePaymentMethodOptions>({
  cacheKey: 'paymentMethods',
  equal: (paymentMethod, id) =>
    Boolean(paymentMethod && id?.paymentMethodId && paymentMethod.paymentMethodId === id.paymentMethodId),
  createId: (paymentMethod) => (paymentMethod ? { paymentMethodId: paymentMethod.paymentMethodId } : null),
  create: (client, paymentMethod) => client.createPaymentMethod(paymentMethod),
  get: (client, id) => client.getPaymentMethod(id.paymentMethodId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listPaymentMethods()
        .then((response: JSONType) => {
          resolve({ data: response.paymentMethods, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updatePaymentMethod(id.paymentMethodId, updates),
  delete: (client, id) => client.deletePaymentMethod(id.paymentMethodId),
});

export const toPaymentMethodId = (paymentMethodId?: string | null): PaymentMethodId | null => {
  return paymentMethodId ? { paymentMethodId } : null;
};
