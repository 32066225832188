import { useCopyToClipboard } from '@uidotdev/usehooks';
import { memo } from 'react';

import CheckmarkIcon from '@/assets/checkmark-simple-icon.svg?react';
import CopyIcon from '@/assets/copy-icon.svg?react';
import { Button, Logo, Text, TextProps } from '@/components/core';

export type CopyTextProps = Omit<TextProps, 'children'> & {
  value?: string;
};

const ICopyText = ({ value }: CopyTextProps) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="flex items-center gap-2 rounded-lg bg-gray-50 px-2 py-1">
      <Text className="truncate text-gray-600" size="xs">
        {value}
      </Text>
      <div className="grow" />
      {copiedText ? (
        <Text className="animate-fade opacity-0" size="xs">
          Copied!
        </Text>
      ) : null}
      <Button
        isDisabled={!!copiedText}
        className="p-0 hover:bg-gray-100"
        variant="plain"
        onPress={async () => {
          if (value) {
            await copyToClipboard(value);
          }
        }}
      >
        <Logo size="md">
          {copiedText ? (
            <CheckmarkIcon className="size-4 stroke-gray-500" />
          ) : (
            <CopyIcon className="size-4 stroke-gray-500" />
          )}
        </Logo>
      </Button>
    </div>
  );
};

export const CopyText = memo(ICopyText) as typeof ICopyText;
