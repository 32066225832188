import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo, useRef } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Label, Table, TableRef, Text, useComponentStack } from '@/components/core';
import {
  findField,
  NavigationContainer,
  NewValidatorModal,
  ValidatorCell,
  ValidatorConfiguration,
} from '@/components/ui';
import { useModelConfigStore } from '@/store';

export type ValidatorsSectionProps = {
  fieldId: string;
};

const IValidatorsSection = ({ fieldId }: ValidatorsSectionProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const moveValidator = useModelConfigStore((state) => state.moveValidator);
  const deleteValidator = useModelConfigStore((state) => state.deleteValidator);
  const validatorTableRef = useRef<TableRef<FieldValidator>>(null);

  const componentStack = useComponentStack();

  const validatorColumns = useMemo<ColumnDef<FieldValidator>[]>(() => {
    return [
      {
        accessorKey: 'functionId',
        cell: ({ row }) => (
          <ValidatorCell
            validator={row.original}
            onPress={() =>
              componentStack?.push(
                <NavigationContainer>
                  <ValidatorConfiguration fieldId={fieldId} index={row.index} />
                </NavigationContainer>
              )
            }
          />
        ),
        size: Number.MAX_SAFE_INTEGER,
      },
    ];
  }, [componentStack, fieldId]);

  const validatorData = useMemo(() => field?.validators ?? [], [field]);

  if (!field) return null;

  return (
    <section>
      {/* section should have header instead of label */}
      <Label>Validators</Label>
      <Table
        ref={validatorTableRef}
        columns={validatorColumns}
        data={validatorData}
        hideHeader
        onRowIndexChange={(fromIndex, toIndex) => {
          moveValidator(field.id, fromIndex, toIndex, field.parentId);
          validatorTableRef.current?.remountSortableContext();
        }}
        deleteRowAction={(row) => deleteValidator(field.id, row.index, field.parentId)}
      />
      <DialogTrigger>
        <Button className="text-gray-600 hover:bg-gray-50" variant="plain">
          <PlusIcon className="size-4 stroke-gray-500" />
          <Text size="md">Add validator</Text>
        </Button>
        <NewValidatorModal fieldId={fieldId} />
      </DialogTrigger>
    </section>
  );
};

export const ValidatorsSection = memo(IValidatorsSection) as typeof IValidatorsSection;
