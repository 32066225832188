import { memo, useEffect, useMemo } from 'react';

import { merge } from '@/components';
import { Autosave } from '@/components/core';
import { toOrganizationId, useAutoUpdateOrganization, usePutFile } from '@/hooks/api';
import { useOrganizationStore } from '@/store';
import { DivProps } from '@/utils';

export type WorkspaceHeaderProps = DivProps;

const IWorkspaceHeader = ({ className, children, ...rest }: WorkspaceHeaderProps) => {
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const name = useOrganizationStore((state) => state.name);
  const planId = useOrganizationStore((state) => state.planId);
  const paymentMethodId = useOrganizationStore((state) => state.paymentMethodId);
  const pictureUrl = useOrganizationStore((state) => state.pictureUrl);
  const picture = useOrganizationStore((state) => state.picture);

  const updates = useMemo(() => {
    return { name, planId, paymentMethodId };
  }, [name, planId, paymentMethodId]);
  const {
    isPending: isPendingUpdateOrganization,
    isSuccess: isSuccessUpdateOrganization,
    error: errorUpdateOrganization,
  } = useAutoUpdateOrganization(1000, toOrganizationId(organizationId), updates);
  const {
    mutate: updatePicture,
    isPending: isPendingUpdatePicture,
    isSuccess: isSuccessUpdatePicture,
    error: errorUpdatePicture,
  } = usePutFile();

  useEffect(() => {
    if (!pictureUrl || !picture) return;
    updatePicture({ fileUrl: pictureUrl, file: picture });
  }, [pictureUrl, picture, updatePicture]);

  return (
    <div className={merge('flex items-center', className)} {...rest}>
      {children}
      <div className="grow" />
      <Autosave
        isPending={isPendingUpdateOrganization || isPendingUpdatePicture}
        isSuccess={isSuccessUpdateOrganization || isSuccessUpdatePicture}
        error={errorUpdateOrganization ?? errorUpdatePicture}
      />
    </div>
  );
};

export const WorkspaceHeader = memo(IWorkspaceHeader) as typeof IWorkspaceHeader;
