import { memo } from 'react';

import { Button, CopyText, Header, Link, Text } from '@/components/core';
import { useTriggerStore } from '@/store';
import { toString } from '@/utils';

export type TriggerPowerAutomateConfigurationProps = object;

// eslint-disable-next-line no-empty-pattern
const ITriggerPowerAutomateConfiguration = ({}: TriggerPowerAutomateConfigurationProps) => {
  const action = useTriggerStore((state) => state.action);

  return (
    <div className="flex flex-col gap-4 p-4">
      <Header size="md">Connect using Power Automate</Header>
      <Text size="sm">
        Read how-to in our <Link href="https://docs.cradl.ai/integrations/powerAutomate">documentation</Link>
      </Text>
      <Button variant="secondary" onPress={() => window.open('https://make.powerautomate.com')}>
        Open Power Automate
      </Button>
      <div className="flex flex-col gap-2">
        <Text size="sm">Client credentials:</Text>
        <CopyText value={toString(action.config?.credentials)} />
      </div>
    </div>
  );
};

export const TriggerPowerAutomateConfiguration = memo(
  ITriggerPowerAutomateConfiguration
) as typeof ITriggerPowerAutomateConfiguration;
