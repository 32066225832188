import { AuthorizationCodeCredentials } from '@lucidtech/las-sdk-browser';
import { Token, TokenStorage } from '@lucidtech/las-sdk-browser';

export class LocalStorage implements TokenStorage<Token> {
  readonly keyName: string;

  constructor(keyName: string) {
    this.keyName = keyName;
  }

  getPersistentToken(): Token | null {
    const tokenString = window.localStorage.getItem(this.keyName);

    if (!tokenString) {
      return null;
    }

    const { accessToken, expiration, refreshToken } = JSON.parse(tokenString);

    if (!(accessToken && expiration && refreshToken)) {
      return null;
    }

    return new Token(accessToken, expiration, refreshToken);
  }

  setPersistentToken(t: Token): void {
    let token = t;

    const oldToken = window.localStorage.getItem(this.keyName);
    if (oldToken) {
      const refreshToken = JSON.parse(oldToken).refreshToken;
      token = new Token(t.accessToken, t.expiration, refreshToken);
    }

    const tokenString = JSON.stringify(token);
    window.localStorage.setItem(this.keyName, tokenString);
  }
}

export function createCredentials(clientId: string): AuthorizationCodeCredentials {
  const storage = new LocalStorage(`cradl:auth:token/${clientId}`);

  const launchUri = (uri: string): void => {
    window.open(uri, '_self');
  };

  return new AuthorizationCodeCredentials(
    import.meta.env.VITE_API_ENDPOINT,
    clientId,
    import.meta.env.VITE_AUTH_ENDPOINT,
    import.meta.env.VITE_REDIRECT_URI,
    launchUri,
    undefined,
    storage,
    import.meta.env.VITE_LOGOUT_REDIRECT_URI
  );
}

export function createGlobalCredentials(): AuthorizationCodeCredentials {
  const storage = new LocalStorage('cradl:auth:globalToken');

  const launchUri = (uri: string): void => {
    window.open(uri, '_self');
  };

  return new AuthorizationCodeCredentials(
    import.meta.env.VITE_API_ENDPOINT,
    import.meta.env.VITE_GLOBAL_CLIENT_ID,
    import.meta.env.VITE_AUTH_ENDPOINT,
    import.meta.env.VITE_GLOBAL_REDIRECT_URI,
    launchUri,
    undefined,
    storage,
    import.meta.env.VITE_LOGOUT_REDIRECT_URI
  );
}
