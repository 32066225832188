import { type ProjectRunStatus, ProjectRunStatusValues } from '@lucidtech/las-sdk-browser';
import { memo, useCallback, useMemo } from 'react';
import { DialogTrigger, MenuTrigger } from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import StringIcon from '@/assets/field-type-string.svg?react';
import { merge } from '@/components';
import {
  Button,
  CloseButton,
  ListBox,
  ListBoxItem,
  Menu,
  MenuItem,
  Pills,
  Popover,
  Select,
  Text,
} from '@/components/core';
import { ProjectRunStatusBadge } from '@/components/ui';
import { StringColumnFilter, StringConditions, useInboxStore } from '@/store';

import { FilterCard, FilterCardSubType } from './FilterCard.tsx';

export type StringFilterCardProps = FilterCardSubType & {
  columns: string[];
  filter: StringColumnFilter;
};

const IStringFilterCard = ({ columns, filter, className, ...rest }: StringFilterCardProps) => {
  const updateFilter = useInboxStore((state) => state.updateFilter);
  const deleteFilter = useInboxStore((state) => state.deleteFilter);

  const selectValues: string[] = useMemo(() => {
    if (filter.columnName === 'status') {
      return [...ProjectRunStatusValues];
    }
    return [];
  }, [filter.columnName]);

  const items: Pills.Item[] | undefined = useMemo(() => {
    return filter.value?.map((v) => ({ label: v, value: v }));
  }, [filter.value]);

  const onItemsChanged = useCallback(
    (items: Pills.Item[]) => {
      updateFilter(filter.id, { value: items.map((i) => i.value) });
    },
    [filter.id, updateFilter]
  );

  return (
    <FilterCard onDelete={() => deleteFilter(filter.id)} className={merge('', className)} {...rest}>
      <MenuTrigger>
        <Button className="p-1 hover:bg-gray-100" variant="plain">
          {filter.columnName ? (
            <>
              <StringIcon className="size-5 stroke-gray-500" />
              <Text className="text-gray-500" size="sm">
                {filter.columnName}
              </Text>
            </>
          ) : (
            <Text className="pl-1 text-gray-300" size="sm">
              Select column
            </Text>
          )}
        </Button>
        <Popover>
          <Menu>
            {columns
              .filter((c) => ['runId', 'status'].includes(c))
              .map((c) => (
                <MenuItem key={filter.id} onAction={() => updateFilter(filter.id, { columnName: c })}>
                  <Text size="sm">{c}</Text>
                </MenuItem>
              ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      <MenuTrigger>
        <Button className="p-1 hover:bg-gray-100" variant="plain">
          {filter.condition ? (
            <Text size="sm">{filter.condition}</Text>
          ) : (
            <Text className="text-gray-300" size="sm">
              Select condition
            </Text>
          )}
        </Button>
        <Popover>
          <Menu>
            {StringConditions.map((condition, i) => (
              <MenuItem key={i} onAction={() => updateFilter(filter.id, { condition })}>
                <Text size="sm">{condition}</Text>
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      <DialogTrigger>
        <Button variant="plain">
          {filter.value?.length === 1 ? filter.value[0] : `one of ${filter.value?.length}`}
        </Button>
        <Popover>
          <Pills.Input className="flex">
            <Pills.List className="flex grow" isInputDisabled initialValues={items} onItemsChange={onItemsChanged}>
              {(pill: Pills.Item, handle: Pills.PillListActions) => (
                <ProjectRunStatusBadge status={pill.value as ProjectRunStatus}>
                  <CloseButton className="size-3 p-0" onPress={() => handle.removeItem(pill)} />
                </ProjectRunStatusBadge>
              )}
            </Pills.List>

            <Select className="grow-0">
              <div className="flex">
                <Button variant="secondary">
                  Status
                  <ChevronDownIcon className="stroke-gray-900" />
                </Button>
              </div>
              <Popover>
                <ListBox>
                  {selectValues.map((v) => (
                    <ListBoxItem key={v} id={v}>
                      {v}
                    </ListBoxItem>
                  ))}
                </ListBox>
              </Popover>
            </Select>
          </Pills.Input>
        </Popover>
      </DialogTrigger>
    </FilterCard>
  );
};

export const StringFilterCard = memo(IStringFilterCard) as typeof IStringFilterCard;
