import { memo } from 'react';

import { Input, Text } from '@/components/core';
import { useTriggerStore } from '@/store';
import { toString } from '@/utils';

export type ExportExcelConfigurationProps = object;

// eslint-disable-next-line no-empty-pattern
const IExportExcelConfiguration = ({}: ExportExcelConfigurationProps) => {
  const action = useTriggerStore((state) => state.action);
  const updateAction = useTriggerStore((state) => state.updateAction);

  return (
    <div className="flex flex-col gap-2 p-4">
      <Text size="sm">Send files to:</Text>
      <Input
        onChange={(e) => updateAction({ config: { ...action.config, documentId: e.target.value } })}
        className="text-xs text-gray-600"
        value={toString(action.config?.documentId)}
      />
      <Input
        onChange={(e) => updateAction({ config: { ...action.config, sheetId: e.target.value } })}
        className="text-xs text-gray-600"
        value={toString(action.config?.sheetId)}
      />
    </div>
  );
};

export const ExportExcelConfiguration = memo(IExportExcelConfiguration) as typeof IExportExcelConfiguration;
