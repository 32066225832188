import { useNavigate } from '@tanstack/react-router';
import { memo, useCallback } from 'react';

import Logo from '@/assets/logo.svg?react';
import SaveIcon from '@/assets/save-icon.svg?react';
import { Button, ErrorCard, Header } from '@/components/core';
import { WorkspaceDetails } from '@/features/settings';
import { useCreateOrganization, usePutFile } from '@/hooks/api';
import { useOrganizationStore } from '@/store';

export type NewProps = object;

// eslint-disable-next-line no-empty-pattern
const INew = ({}: NewProps) => {
  const name = useOrganizationStore((state) => state.name);
  const picture = useOrganizationStore((state) => state.picture);
  const { mutate: createOrganization, isPending: isPendingCreateOrganization, error } = useCreateOrganization();
  const { mutate: updatePicture, isPending: isPendingUpdatePicture } = usePutFile();
  const navigate = useNavigate();

  const onCreateOrganization = useCallback(() => {
    createOrganization(
      { name },
      {
        onSuccess: (organization) => {
          if (picture) {
            updatePicture(
              { fileUrl: organization.pictureUrl, file: picture },
              {
                onSuccess: () => navigate({ to: '/projects' }).catch(console.error),
              }
            );
          } else {
            navigate({ to: '/projects' }).catch(console.error);
          }
        },
      }
    );
  }, [createOrganization, name, navigate, picture, updatePicture]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8 bg-gradient-to-b from-white to-slate-200">
      <div className="flex items-center gap-2">
        <Logo />
        <Header size="xl">Cradl AI</Header>
      </div>
      <div className="flex w-[35rem] flex-col gap-4 rounded-lg bg-white p-6 shadow">
        <WorkspaceDetails />
        <Button isDisabled={isPendingCreateOrganization || isPendingUpdatePicture} onPress={onCreateOrganization}>
          {isPendingCreateOrganization || isPendingUpdatePicture ? (
            <SaveIcon className="size-4 animate-spin stroke-gray-500" />
          ) : null}
          Create
        </Button>
        {error ? <ErrorCard label="There was a problem creating your workspace" error={error} /> : null}
      </div>
    </div>
  );
};

export const New = memo(INew) as typeof INew;
