import { AuthorizationCodeCredentials, Client, PKCEDerived } from '@lucidtech/las-sdk-browser';
import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useCallback, useMemo } from 'react';

import { JWT } from '@/utils';

import { createCredentials } from './auth';
import { AuthContext, AuthContextInterface } from './AuthContext';

export interface AuthProviderProps {
  children?: ReactNode | null;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [redirectUrl, setRedirectUrl] = useSessionStorage<string | null>('cradl:auth:redirectUrl', null);
  const [clientId, setClientId] = useLocalStorage<string | undefined>('cradl:auth:clientId');

  const client = useMemo(() => {
    if (clientId) {
      const credentials = createCredentials(clientId);
      return new Client(credentials);
    }
  }, [clientId]);

  const updateCredentials = useCallback(
    (code: string) => {
      if (client) {
        (client.credentials as AuthorizationCodeCredentials).pkce = PKCEDerived.createFromCode(code);
      }
    },
    [client]
  );

  const logout = useCallback(async () => {
    for (const [key, _] of Object.entries(window.localStorage).filter(
      ([key, clientId]) => key.includes('cradl:auth:token') && typeof clientId === 'string'
    )) {
      const [_, clientId] = key.split('/');
      const credentials = createCredentials(clientId);
      window.localStorage.removeItem(key);
      setClientId(undefined);
      credentials.initiateLogoutFlow();
    }
    return true;
  }, [setClientId]);

  const ensureLoggedIn = useCallback(
    (clientId?: string): Promise<boolean> => {
      if (clientId) {
        setClientId(clientId);
      }

      return new Promise((resolve) => {
        if (!client && !clientId) {
          resolve(false);
          return;
        }

        const credentials = clientId
          ? createCredentials(clientId)
          : (client?.credentials as AuthorizationCodeCredentials);

        if (credentials.isLoggedIn()) {
          resolve(true);
          return;
        }
        credentials
          .getAccessToken()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    },
    [client, setClientId]
  );

  const getScopes = useCallback(async () => {
    if (!client) return [];
    const token = await client.credentials.getAccessToken();
    if (!token) return [];
    const jwt = jwtDecode<JWT>(token);
    return jwt.scope.split(' ').map((scope) => (scope.includes('/') ? scope.split('/')[1] : scope)) || [];
  }, [client]);

  const isLoggedIn = (client?.credentials as AuthorizationCodeCredentials)?.isLoggedIn() ?? false;

  const context: AuthContextInterface = useMemo(
    () => ({
      ensureLoggedIn,
      isLoggedIn,
      client,
      setRedirectUrl,
      redirectUrl,
      logout,
      getScopes,
      setClientId,
      clientId,
      updateCredentials,
    }),
    [
      ensureLoggedIn,
      isLoggedIn,
      client,
      setRedirectUrl,
      redirectUrl,
      logout,
      getScopes,
      setClientId,
      clientId,
      updateCredentials,
    ]
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
