import { useEffect, useState } from 'react';

import { toFileId, toPredictionId, useGetFile, useGetPrediction } from '@/hooks/api';
import { JSONType } from '@/utils';

export type UsePredictionAnnotationsOpts = {
  predictionId?: string | null;
};

export const usePredictionAnnotations = ({ predictionId }: UsePredictionAnnotationsOpts) => {
  const { data: prediction } = useGetPrediction(toPredictionId(predictionId));
  const { data: blob } = useGetFile(toFileId(prediction?.fileUrl));
  const [annotations, setAnnotations] = useState<JSONType>({});

  useEffect(() => {
    blob?.text().then((annotationsStr) => {
      setAnnotations(JSON.parse(annotationsStr));
    });
  }, [blob, setAnnotations]);

  return { annotations };
};
