import { createFileRoute, notFound } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { Validate } from '@/features/projects';
import { getProjectRunOptions, toProjectRunId } from '@/hooks/api';

export type ShowPanelType = 'project-runs' | 'email';

const ProjectRunsDetailsSearchSchema = z.object({
  showPanel: fallback(z.optional(z.enum(['project-runs', 'email'])), undefined),
});

export const Route = createFileRoute('/_auth/details/projects/$projectId/runs/$runId')({
  loader: async ({ context: { auth, queryClient }, params: { projectId, runId } }) => {
    if (!auth.client) throw notFound();
    const run = await queryClient.ensureQueryData(
      getProjectRunOptions(auth.client, queryClient, toProjectRunId(projectId, runId))
    );
    if (!run) throw notFound();
    return { run };
  },
  component: Validate,
  notFoundComponent: () => {
    return <p>Project Run not found!</p>;
  },
  validateSearch: zodValidator(ProjectRunsDetailsSearchSchema),
});
