import React, { useContext } from 'react';

type ComponentStackActions = {
  push: (node: React.ReactNode) => void;
  pop: () => void;
};

export const ComponentStackContext = React.createContext<ComponentStackActions | null>(null);

export const useComponentStack = () => {
  return useContext(ComponentStackContext);
};
