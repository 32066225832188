import { memo } from 'react';

import { EmailLogo, Text } from '@/components/core';
import { TriggerHeader } from '@/components/flow';
import { toActionId, useGetAction } from '@/hooks/api';
import { toString } from '@/utils';

export type EmailTriggerNodeContentProps = {
  projectId: string;
  actionId: string;
};

const IEmailTriggerNodeContent = ({ projectId, actionId }: EmailTriggerNodeContentProps) => {
  const { data: action } = useGetAction(toActionId(actionId));

  return (
    <>
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <TriggerHeader projectId={projectId} actionId={actionId}>
          <EmailLogo size="lg" />
          <Text className="flex-1 font-semibold" size="md">
            Send to Email
          </Text>
        </TriggerHeader>
      </div>
      <div className="flex gap-2 pt-2">
        <Text className="flex items-center gap-1 rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
          {toString(action?.config?.email)}
        </Text>
      </div>
    </>
  );
};

export const EmailTriggerNodeContent = memo(IEmailTriggerNodeContent) as typeof IEmailTriggerNodeContent;
