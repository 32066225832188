import type { FieldConfig, ProjectRunStatus } from '@lucidtech/las-sdk-browser';
import { FormApi, ReactFormApi } from '@tanstack/react-form';
import { createContext } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type FieldValueState = {
  confidence?: number;
  value?: string | null;
  rawValue?: string | null;
  isTouched?: boolean;
  attentionMap?: number[][];
  suppressFormattersAndValidators?: boolean;
  page?: number;
};

export type FormValue = {
  current: FieldValueState;
  candidates: FieldValueState[];
};

export type FormValueRow = {
  id: string;
  columns: { [key: string]: FormValue };
};

export type FormValues = {
  [key: string]: FormValueRow[] | FormValue;
};

export type FormType = FormApi<FormValues, undefined> & ReactFormApi<FormValues, undefined>;
export type CreateDocumentStoreProps = Partial<DocumentProps> & { form: FormType };
export type Point = {
  x: number;
  y: number;
};
export type AttentionBlock = {
  intensity: number;
  points: Point[];
};

export interface DocumentProps {
  documentId?: string;
  documentBlob?: Blob;
  zoom: number;
  rotation: number;
  numPages?: number;
  currentPages: number[];
  focusedField?: FieldValueState;
  attention?: AttentionBlock[];
  fieldConfig?: FieldConfig;
  form: FormType;
  contentText: { [key: number]: string[] };
  maximizedFields: string[];
  status?: ProjectRunStatus;
}

export interface DocumentState extends DocumentProps {
  setZoom: (change: (prev: number) => number) => void;
  setRotation: (change: (prev: number) => number) => void;
  setNumPages: (numPages?: number) => void;
  setCurrentPages: (currentPages: number[]) => void;
  updateContentText: (page: number, text: string[]) => void;
  getAutocomplete: () => string[];
  setMaximizedFields: (maximizedFields: string[]) => void;
  setFocusedField: (fieldValue?: FieldValueState) => void;
  setAttention: (attention?: AttentionBlock[]) => void;
}

export const createDocumentStore = (initProps: CreateDocumentStoreProps) => {
  const props: DocumentProps = {
    zoom: 1,
    rotation: 0,
    currentPages: [],
    maximizedFields: [],
    contentText: {},
    ...initProps,
  };
  return createStore<DocumentState>()(
    // use persist when we figure a way to serialize the form state
    devtools(
      immer((set, get) => ({
        ...props,
        setZoom: (change: (prev: number) => number) =>
          set((state) => {
            state.zoom = change(state.zoom);
          }),
        setRotation: (change: (prev: number) => number) =>
          set((state) => {
            state.rotation = change(state.rotation);
          }),
        setNumPages: (numPages?: number) =>
          set((state) => {
            state.numPages = numPages;
          }),
        setCurrentPages: (currentPages: number[]) =>
          set((state) => {
            state.currentPages = currentPages;
          }),
        updateContentText: (page: number, text: string[]) =>
          set((state) => {
            state.contentText[page] = text;
          }),
        getAutocomplete: () => {
          let autocomplete = Object.values(get().contentText).reduce((a, b) => a.concat(b), []);
          autocomplete = [...new Set(autocomplete)];
          autocomplete.sort();
          return autocomplete;
        },
        setMaximizedFields: (maximizedFields: string[]) =>
          set((state) => {
            state.maximizedFields = maximizedFields;
          }),
        setFocusedField: (focusedField?: FieldValueState) =>
          set((state) => {
            state.focusedField = focusedField;
          }),
        setAttention: (attention?: AttentionBlock[]) =>
          set((state) => {
            console.log('(documentStore) Updating attention to:', attention);
            state.attention = attention;
          }),
      }))
    )
  );
};

export type DocumentStore = ReturnType<typeof createDocumentStore>;

export const DocumentContext = createContext<DocumentStore | null>(null);
