import Lottie from 'lottie-react';
import { memo } from 'react';

import loadingIcon from '@/assets/animated/loading-icon.json';
import { merge } from '@/components';
import { Header, Text } from '@/components/core';
import { DivProps } from '@/utils';

export type LoadingProps = DivProps & {
  label: string;
  message?: string;
};

const ILoading = ({ className, label, message, ...rest }: LoadingProps) => {
  return (
    <div className={merge('flex h-full w-full flex-col items-center justify-center gap-4', className)} {...rest}>
      <Lottie animationData={loadingIcon} />
      <div className="flex flex-col items-center gap-1">
        <Header size="lg">{label}</Header>
        {message ? <Text size="sm">{message}</Text> : null}
      </div>
    </div>
  );
};

export const Loading = memo(ILoading) as typeof ILoading;
