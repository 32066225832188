import { memo } from 'react';

import SaveIcon from '@/assets/save-icon.svg?react';
import { Text } from '@/components/core';
import { FormatterError, FormatterWarning } from '@/utils';

export type FieldInfoProps = {
  warnings: FormatterWarning[];
  errors: FormatterError[];
  isValidating?: boolean;
};

const IFieldInfo = ({ warnings, errors, isValidating }: FieldInfoProps) => {
  return (
    <>
      {errors.length ? (
        <Text className="text-red-600" size="xs">
          {errors.map((e) => e.errors.join(', ')).join(', ')}
        </Text>
      ) : null}
      {warnings.length ? (
        <Text className="text-yellow-600" size="xs">
          {warnings.map((w) => w.warnings.join(', ')).join(', ')}
        </Text>
      ) : null}
      {isValidating ? (
        <Text className="flex gap-1" size="xs">
          <SaveIcon className="size-4 animate-spin stroke-gray-400" />
          Validating...
        </Text>
      ) : null}
    </>
  );
};

export const FieldInfo = memo(IFieldInfo) as typeof IFieldInfo;
