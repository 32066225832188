import { DeepKeys } from '@tanstack/react-form';
import type { ColumnDef } from '@tanstack/react-table';
import { memo, useCallback, useMemo, useRef, useState } from 'react';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Loading, Table, TableRef } from '@/components/core';
import { Field, FieldLabel } from '@/components/ui';
import { MULTI_VALUE_COLUMN_NAME } from '@/constants';
import { FormValueRow, selectFromFieldConfig, useDocumentStore } from '@/store';
import { generateId, toString } from '@/utils';

export type TableFieldProps = {
  name: string;
  setSearch: (search?: string) => void;
  rowId: string;
};

const ITableField = ({ name, setSearch, rowId }: TableFieldProps) => {
  const form = useDocumentStore((state) => state.form);
  const config = useDocumentStore(selectFromFieldConfig(name));
  // TODO: Remove the need for columnsVersion
  const [columnsVersion, setColumnsVersion] = useState(0);
  const ref = useRef<TableRef<FormValueRow>>(null);
  const status = useDocumentStore((state) => state.status);

  const columns = useMemo<ColumnDef<FormValueRow>[]>(
    () => {
      if (config?.type === 'table') {
        return Object.entries(config?.fields ?? {}).map(([columnName, columnConfig]) => ({
          accessorKey: `columns.${columnName}`,
          header: columnConfig.name,
          cell: ({ row }) => (
            <Field
              rowId={row.id}
              hideLabel
              name={`${name}[${row.index}].columns.${columnName}`}
              setSearch={setSearch}
            />
          ),
        }));
      } else {
        return [
          {
            accessorKey: `columns.${MULTI_VALUE_COLUMN_NAME}`,
            header: MULTI_VALUE_COLUMN_NAME,
            cell: ({ row }) => (
              <Field
                rowId={row.id}
                hideLabel
                name={`${name}[${row.index}].columns.${MULTI_VALUE_COLUMN_NAME}`}
                setSearch={setSearch}
              />
            ),
            size: Number.MAX_SAFE_INTEGER,
          },
        ];
      }
    },
    // TODO: Remove the need for columnsVersion
    [config?.type, config?.fields, name, setSearch, columnsVersion]
  );

  const createRow = useCallback(() => {
    const row: FormValueRow = { id: generateId(), columns: {} };
    for (const fieldName of Object.keys(config?.fields ?? {})) {
      row.columns[fieldName] = {
        current: { value: '' },
        candidates: [],
      };
    }
    return row;
  }, [config?.fields]);

  const createValue = useCallback((): FormValueRow => {
    return {
      id: generateId(),
      columns: {
        [MULTI_VALUE_COLUMN_NAME]: {
          current: { value: '' },
          candidates: [],
        },
      },
    };
  }, []);

  const createCopy = useCallback((value: FormValueRow) => {
    return { ...structuredClone(value), id: generateId() };
  }, []);

  return (
    <form.Field key={rowId} name={name} mode="array">
      {(field) => {
        if (!field.state.value) {
          return <Loading label="table" />;
        }
        return (
          <>
            <FieldLabel name={config?.name || name} description={toString(config?.description)} hideLabel={false} />
            <Table
              ref={ref}
              data={field.state.value as FormValueRow[]}
              columns={columns}
              isInnerTable={false}
              hideHeader={config?.type === 'multi-value'}
              hideDragHandle={status !== 'Ready for review'}
              onRowIndexChange={(oldIndex, newIndex) => {
                field.moveValue(oldIndex, newIndex);
                for (const columnName of Object.keys(config?.fields ?? {})) {
                  const oldFieldName = `${name}[${oldIndex}].columns.${columnName}`;
                  form.validateField(oldFieldName as DeepKeys<FormValueRow>, 'change');
                  const newFieldName = `${name}[${newIndex}].columns.${columnName}`;
                  form.validateField(newFieldName as DeepKeys<FormValueRow>, 'change');
                }
                setColumnsVersion((prev) => prev + 1);
                ref.current?.remountSortableContext();
              }}
              duplicateRowAction={async (row) => {
                await field.insertValue(row.index + 1, createCopy(row.original));
                setColumnsVersion((prev) => prev + 1);
                ref.current?.remountSortableContext();
              }}
              deleteRowAction={async (row) => {
                await field.removeValue(row.index);
                setColumnsVersion((prev) => prev + 1);
                ref.current?.remountSortableContext();
              }}
            />
            {status === 'Ready for review' ? (
              /* TODO: Button uses full width. Remove unnecessary div when possible */
              <div>
                <Button
                  className="flex flex-row text-sm text-gray-500 hover:bg-gray-100"
                  variant="plain"
                  onPress={() => field.pushValue(config?.type === 'multi-value' ? createValue() : createRow())}
                  excludeFromTabOrder
                >
                  <PlusIcon className="size-4 stroke-gray-400" />
                  Add {config?.type === 'multi-value' ? 'value' : 'row'}
                </Button>
              </div>
            ) : null}
          </>
        );
      }}
    </form.Field>
  );
};

export const TableField = memo(ITableField) as typeof ITableField;
