import { memo } from 'react';

import { Loading } from '@/components/core';
import {
  EmailTriggerNodeContent,
  EmptyTriggerNodeContent,
  PowerAutomateTriggerNodeContent,
  ZapierTriggerNodeContent,
} from '@/components/flow';
import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { useGetProjectFromPath } from '@/hooks';
import { toActionId, useGetAction } from '@/hooks/api';

export type TriggerNodeProps = {
  actionId?: string;
  hookId?: string;
};

const ITriggerNode = ({ actionId }: TriggerNodeProps) => {
  const { data: project, isPending: isPendingProject } = useGetProjectFromPath();
  const { data: action } = useGetAction(toActionId(actionId));

  if (isPendingProject) return <Loading label="Loading ..." />;
  if (!project) return null;

  return (
    <div className="w-72">
      {action?.functionId === CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID ? (
        <EmailTriggerNodeContent projectId={project.projectId} actionId={action?.actionId} />
      ) : action?.functionId === CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID ? (
        <PowerAutomateTriggerNodeContent projectId={project.projectId} actionId={action?.actionId} />
      ) : action?.functionId === CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID ? (
        <ZapierTriggerNodeContent projectId={project.projectId} actionId={action?.actionId} />
      ) : (
        <EmptyTriggerNodeContent projectId={project.projectId} />
      )}
    </div>
  );
};

export const TriggerNode = memo(ITriggerNode) as typeof ITriggerNode;
