import { FormApi, useForm } from '@tanstack/react-form';
import { useCallback, useEffect, useMemo } from 'react';

import { Loading } from '@/components/core';
import { useDocumentAnnotations, usePredictionAnnotations } from '@/hooks';
import {
  toDocumentId,
  toFileId,
  toModelId,
  toProjectRunId,
  toValidationTaskId,
  useGetDocument,
  useGetFile,
  useGetModel,
  useGetProjectRun,
  useGetValidationTask,
  useUpdateValidationTask,
} from '@/hooks/api';
import { DocumentProvider as DocumentProvider_, FormValues } from '@/store';
import { Annotations, DivProps, toAnnotations, toFormValues } from '@/utils';

import { useKey } from './useKey.ts';

type OnSubmit = { value: FormValues; formApi: FormApi<FormValues, undefined> };
export type DocumentProviderProps = DivProps & {
  documentId: string;
  modelId: string;
  predictionId?: string;
  projectId?: string;
  validationId?: string;
  taskId?: string;
  runId?: string;
};

export const DocumentProvider = ({
  children,
  documentId,
  modelId,
  predictionId,
  projectId,
  validationId,
  taskId,
  runId,
}: DocumentProviderProps) => {
  const { data: doc } = useGetDocument(toDocumentId(documentId));
  const { data: documentBlob } = useGetFile(toFileId(doc?.fileUrl));
  const { annotations: documentAnnotations, setAnnotations: setDocumentAnnotations } = useDocumentAnnotations({
    documentId,
  });
  const { annotations: predictionAnnotations } = usePredictionAnnotations({ predictionId });
  const { data: model } = useGetModel(toModelId(modelId));
  const { data: task } = useGetValidationTask(toValidationTaskId(validationId, taskId));
  const { data: run } = useGetProjectRun(toProjectRunId(projectId, runId));
  const { mutate: updateTask } = useUpdateValidationTask();

  const { values, meta } = useMemo(() => {
    if (!model) return {};
    const annotationsArgs: Annotations[] = [predictionAnnotations, documentAnnotations];
    return toFormValues(model.fieldConfig, ...annotationsArgs);
  }, [documentAnnotations, model, predictionAnnotations]);

  const onSubmit = useCallback(
    async ({ value }: OnSubmit) => {
      if (!model || !doc) return;
      const annotations = toAnnotations(model.fieldConfig, value);
      console.log('Update task with:', annotations);
      updateTask({
        id: toValidationTaskId(validationId, taskId),
        updates: { output: annotations },
      });
      console.log('Update document with:', annotations);
      await setDocumentAnnotations(annotations);
    },
    [doc, model, setDocumentAnnotations, taskId, updateTask, validationId]
  );

  const form = useForm({
    defaultState: {
      values,
      fieldMetaBase: meta,
    },
    onSubmit,
  });
  const { key } = useKey({ value: { task } });

  const isReadyModel = Boolean(!modelId || model);
  const isReadyTask = Boolean(!taskId || task);
  const isReadyRun = Boolean(!runId || run);
  if (!(doc && documentBlob && isReadyModel && isReadyTask && isReadyRun)) {
    return <Loading label="document" />;
  }

  return (
    <DocumentProvider_
      documentId={doc.documentId}
      documentBlob={documentBlob}
      form={form}
      fieldConfig={model?.fieldConfig}
      status={run?.status}
      key={key}
    >
      {children}
    </DocumentProvider_>
  );
};
