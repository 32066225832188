import { memo, ReactNode, useEffect, useState } from 'react';

import CheckmarkIcon from '@/assets/checkmark-icon.svg?react';
import DeleteIcon from '@/assets/delete-icon.svg?react';
import InboxIcon from '@/assets/inbox-icon.svg?react';
import ReviewIcon from '@/assets/review-icon.svg?react';
import { merge } from '@/components';
import { Button, Text } from '@/components/core';
import { useInboxStore } from '@/store';

type FilterGroupProps = {
  name: string;
  isSelected?: boolean;
  onPress?: () => void;
};

const IFilterGroup = ({ name, isSelected, onPress }: FilterGroupProps) => {
  const icons: { [key: string]: ReactNode } = {
    All: <InboxIcon className={merge('size-4 fill-gray-400', isSelected && 'fill-brand-600')} />,
    Review: <ReviewIcon className={merge('size-4 stroke-gray-400', isSelected && 'stroke-brand-600')} />,
    Exported: <CheckmarkIcon className={merge('size-4 stroke-gray-400', isSelected && 'stroke-brand-600')} />,
    Archived: <DeleteIcon className={merge('size-4 stroke-gray-400', isSelected && 'stroke-brand-600')} />,
  };

  return (
    <div className={merge('border-b border-gray-200 py-2', isSelected && 'border-brand-600 border-b-2')}>
      <Button className="py-1 hover:bg-gray-100" variant="plain" onPress={onPress}>
        {icons[name]}
        <Text className={merge(isSelected && 'text-brand-600')}>{name}</Text>
      </Button>
    </div>
  );
};

const FilterGroup = memo(IFilterGroup) as typeof IFilterGroup;

export type FilterGroupsBarProps = object;

// eslint-disable-next-line no-empty-pattern
const IFilterGroupsBar = ({}: FilterGroupsBarProps) => {
  const filterGroups = useInboxStore((state) => state.filterGroups);
  const addFilter = useInboxStore((state) => state.addFilter);
  const clearFilters = useInboxStore((state) => state.clearFilters);
  const [selectedFilterGroup, setSelectedFilterGroup] = useState(0);

  useEffect(() => {
    const filterGroup = filterGroups[selectedFilterGroup];
    if (!filterGroup) return;
    clearFilters();
    for (const filter of filterGroup.filters) {
      addFilter(filter);
    }
  }, [addFilter, clearFilters, filterGroups, selectedFilterGroup]);

  return (
    <div className="flex h-16 w-full py-2">
      {filterGroups.map((filterGroup, i) => (
        <FilterGroup
          key={i}
          name={filterGroup.name}
          isSelected={i === selectedFilterGroup}
          onPress={() => setSelectedFilterGroup(i)}
        />
      ))}
    </div>
  );
};

export const FilterGroupsBar = memo(IFilterGroupsBar) as typeof IFilterGroupsBar;
