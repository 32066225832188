import { Outlet } from '@tanstack/react-router';
import { Resizable } from 're-resizable';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { merge } from '@/components';

export type NavigationBarProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  initialWidth?: number;
  enableResizing?: boolean;
};

export const NavigationBar = ({ initialWidth, enableResizing, className, children, ...rest }: NavigationBarProps) => {
  /* prettier-ignore */
  const styles = [
    'border-r',
    'flex',
    'flex-col',
    'h-screen',
    'space-y-2',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'border-gray-200',
    'bg-white',
  ];
  return (
    <div className="flex overflow-hidden bg-gray-50">
      <Resizable defaultSize={{ width: initialWidth ?? 300 }} enable={{ right: enableResizing ?? true }}>
        <nav className={merge(styles, lightStyles, className)} {...rest}>
          {children}
        </nav>
      </Resizable>
      <Outlet />
    </div>
  );
};
