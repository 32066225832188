import { memo } from 'react';

import CurrencyIcon from '@/assets/currency-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type CurrencyLogoProps = VariantLogoProps;

const ICurrencyLogo = ({ ...rest }: CurrencyLogoProps) => {
  return <FormatterValidatorLogo Icon={CurrencyIcon} iconColoringType="stroke" {...rest} />;
};

export const CurrencyLogo = memo(ICurrencyLogo) as typeof ICurrencyLogo;
