import { ProjectRun } from '@lucidtech/las-sdk-browser';
import { useEffect, useMemo, useState } from 'react';

import { findPredictionId, findValidationId, findValidationTaskId, JSONType, stripKeys } from '@/utils';

import {
  toFileId,
  toPredictionId,
  toProjectId,
  toProjectRunId,
  useBatchGetFile,
  useBatchGetPrediction,
  useBatchGetProjectRun,
  useBatchGetValidationTask,
  useGetProject,
} from './api';

const KEYS_TO_EXCLUDE = ['errors', 'warnings', 'location', 'attentionMap', 'source', 'page'];

type ExportReady = {
  run: ProjectRun;
  result: JSONType | undefined;
};

const useGetRunPredictions = (runs: ProjectRun[]) => {
  const [decodedBlobs, setDecodedBlobs] = useState<JSONType[]>();

  const predIds = useMemo(() => runs.map((run) => toPredictionId(findPredictionId(run))), [runs]);

  const { data: predictions } = useBatchGetPrediction(predIds);
  const { data: predictionBlobs } = useBatchGetFile(predictions?.map((p) => toFileId(p.fileUrl)));

  useEffect(() => {
    Promise.all(
      predictionBlobs.map((blob, idx) =>
        blob
          .text()
          .then(JSON.parse)
          .then((decoded) => ({ ...predIds[idx], prediction: decoded }))
      )
    ).then(setDecodedBlobs);
  }, [predIds, predictionBlobs]);

  return { data: decodedBlobs };
};

export type UseCreateExportOpts = {
  projectId: string;
  runIds?: string[] | null;
};

export const useCreateExport = ({ projectId, runIds }: UseCreateExportOpts) => {
  const { data: project } = useGetProject(toProjectId(projectId));
  const { data: runs } = useBatchGetProjectRun(runIds?.map((runId) => toProjectRunId(projectId, runId)));
  const { data: predictions } = useGetRunPredictions(runs);

  const validationId = findValidationId(project);

  const { data: validationTasks } = useBatchGetValidationTask(
    runs.map((run) => ({ validationId, taskId: findValidationTaskId(run) }))
  );

  const exports: ExportReady[] = useMemo(() => {
    const exports: ExportReady[] = [];
    for (const run of runs) {
      const taskId = findValidationTaskId(run);
      const validationTask = validationTasks.find((t) => t.taskId === taskId);

      let output: JSONType | undefined;
      if (validationTask?.output) {
        output = validationTask.output;
      } else {
        const predictionId = findPredictionId(run);
        output = predictions?.find((p) => p.predictionId === predictionId)?.prediction as JSONType | undefined;
      }

      exports.push({
        run,
        result: { taskId, predictions: stripKeys(output, KEYS_TO_EXCLUDE) },
      });
    }

    return exports;
  }, [predictions, runs, validationTasks]);

  return exports;
};
