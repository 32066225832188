import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { memo, useCallback, useEffect } from 'react';

import Logo from '@/assets/logo.svg?react';
import { Button, Header, Hr, Text } from '@/components/core';
import { DEFAULT_REDIRECT_URL } from '@/constants';
import { useGlobalAuth } from '@/hooks';

const routeApi = getRouteApi('/login');

export type LoginProps = object;

// eslint-disable-next-line no-empty-pattern
const ILogin = ({}: LoginProps) => {
  const { setRedirectUrl, login } = useGlobalAuth();
  const search = routeApi.useSearch();

  const navigate = useNavigate();

  useEffect(() => {
    setRedirectUrl(search.redirect ?? DEFAULT_REDIRECT_URL);
  }, [search.redirect, setRedirectUrl]);

  const onSignup = useCallback(() => {
    const authEndpoint = import.meta.env.VITE_AUTH_ENDPOINT;
    const clientId = import.meta.env.VITE_GLOBAL_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_GLOBAL_REDIRECT_URI;
    const url = `https://${authEndpoint}/signup?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

    navigate({ href: url, reloadDocument: true });
  }, [navigate]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-gradient-to-b from-white to-slate-200">
      <Logo />
      <Header size="xl">Welcome to Cradl AI</Header>
      <Text size="md">Sign up or log in with your account</Text>
      <Button className="w-[300px]" onPress={onSignup}>Sign up</Button>
      <div className="flex items-center gap-2">
        <Hr className="my-0 w-[60px]" />
        <Text size="sm">Already have an account?</Text>
        <Hr className="my-0 w-[60px]" />
      </div>
      <Button onPress={login} variant="secondary" className="w-[300px]">
        Log in
      </Button>
    </div>
  );
};

export const Login = memo(ILogin) as typeof ILogin;
