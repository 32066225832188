import { memo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import DotsIcon from '@/assets/dots-icon.svg?react';
import { merge } from '@/components';
import { Button, Menu, MenuItemDelete, Popover } from '@/components/core';
import { DivProps, OmitChildren } from '@/utils';

export type FilterCardProps = DivProps & {
  onDelete: () => void;
};

const IFilterCard = ({ onDelete, className, children, ...rest }: FilterCardProps) => {
  return (
    <div
      className={merge('group/filter-card flex items-center rounded-lg border border-gray-200 bg-white', className)}
      {...rest}
    >
      {children}
      <MenuTrigger>
        <Button className="p-1 hover:bg-gray-100" variant="plain">
          <DotsIcon className="rotate-90 fill-gray-500" />
        </Button>
        <Popover>
          <Menu>
            <MenuItemDelete onAction={onDelete} />
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
};

export const FilterCard = memo(IFilterCard) as typeof IFilterCard;
export type FilterCardSubType = Omit<OmitChildren<FilterCardProps>, 'onDelete'>;
