import { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { Menu } from 'react-aria-components';

import { merge } from '@/components';
import { Button, Search, Tag, Text } from '@/components/core';
import { useSearch } from '@/hooks';
import { DivProps, JSONType, OmitChildren, searchMatch } from '@/utils';

export type SearchPanelProps<TItem extends JSONType> = OmitChildren<DivProps> & {
  renderItem: (item: TItem) => ReactNode;
  searchableCategorizedData: Record<string, TItem[]>;
  defaultCategory?: string;
  searchDelay?: number;
};

const ISearchPanel = <TItem,>({
  searchableCategorizedData,
  defaultCategory,
  searchDelay,
  renderItem,
  className,
  ...rest
}: SearchPanelProps<TItem>) => {
  const { search, setSearch, debouncedSearch } = useSearch({ delay: searchDelay ?? 100 });
  const [selectedCategory, setSelectedCategory] = useState(
    defaultCategory ?? Object.keys(searchableCategorizedData)[0] ?? ''
  );

  const filteredData: Record<string, TItem[]> = useMemo(() => {
    const filtered: Record<string, TItem[]> = {};
    for (const [category, items] of Object.entries(searchableCategorizedData)) {
      filtered[category] = items.filter((item) => searchMatch(item, debouncedSearch));
    }
    return filtered;
  }, [debouncedSearch, searchableCategorizedData]);

  const filteredItems: TItem[] = useMemo(() => {
    return filteredData[selectedCategory] ?? [];
  }, [filteredData, selectedCategory]);

  useEffect(() => {
    if (filteredItems.length > 0) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newCategory = Object.entries(filteredData).find(([_, items]) => items.length > 0)?.[0];
    if (newCategory) {
      setSelectedCategory(newCategory);
    }
  }, [filteredData, filteredItems.length, selectedCategory]);

  return (
    <div className={merge('flex h-[30rem] w-[50rem] flex-col', className)} {...rest}>
      <div className="flex gap-2 border-b border-gray-200 p-4">
        <Search
          clear={() => setSearch(undefined)}
          props={{
            input: {
              className: 'w-full p-2',
              value: search,
              onChange: (e) => setSearch(e.target.value),
              placeholder: 'Search fields',
            },
            searchField: {
              className: 'w-full',
            },
          }}
        />
      </div>
      <div className="flex">
        <div className="flex h-[25rem] w-[30%] flex-col gap-2 overflow-y-auto border-r border-gray-200 p-4">
          {Object.entries(filteredData).map(([category, items]) => {
            if (items.length < 1) return null;
            return (
              <Button
                key={category}
                variant="plain"
                className={merge('flex gap-1', category === selectedCategory && 'bg-gray-50')}
                onPress={() => setSelectedCategory(category)}
              >
                <Text>{category}</Text>
                <div className="grow" />
                <Tag className="flex">{items.length}</Tag>
              </Button>
            );
          })}
        </div>
        <Menu
          aria-label="SelectPanelItems"
          className="flex h-[25rem] w-[70%] flex-col gap-2 overflow-y-auto p-2 outline-none"
        >
          {filteredItems.map(renderItem)}
        </Menu>
      </div>
    </div>
  );
};

export const SearchPanel = memo(ISearchPanel) as typeof ISearchPanel;
