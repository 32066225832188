import type { Profile } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';

export type ProfileId = { profileId: string };

export const {
  getOptions: getProfileOptions,
  useAutoUpdate: useAutoUpdateProfile,
  useCreate: useCreateProfile,
  useGet: useGetProfile,
  useSuspenseGet: useSuspenseGetProfile,
} = create<Profile, ProfileId, object>({
  cacheKey: 'profiles',
  equal: (profile, id) => Boolean(profile && id?.profileId && profile.profileId === id.profileId),
  createId: (profile) => (profile ? { profileId: profile.profileId } : null),
  get: (client, id) => client.getProfile(id.profileId),
  update: (client, id, updates) => client.updateProfile(id.profileId, updates),
});

export const toProfileId = (profileId?: string | null): ProfileId | null => {
  return profileId ? { profileId } : null;
};
