import { memo } from 'react';

import { Text } from '@/components/core';
import { PowerAutomateLogo, TriggerHeader } from '@/components/flow';

export type PowerAutomateTriggerNodeContentProps = {
  projectId: string;
  actionId: string;
};

const IPowerAutomateTriggerNodeContent = ({ projectId, actionId }: PowerAutomateTriggerNodeContentProps) => {
  return (
    <>
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <TriggerHeader projectId={projectId} actionId={actionId}>
          <PowerAutomateLogo size="lg" />
          <Text className="flex-1 font-semibold" size="md">
            Trigger from Power Automate
          </Text>
        </TriggerHeader>
      </div>
      <div className="flex gap-2 pt-2">
        <Text className="flex items-center gap-1 rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
          Power Automate
        </Text>
      </div>
    </>
  );
};

export const PowerAutomateTriggerNodeContent = memo(
  IPowerAutomateTriggerNodeContent
) as typeof IPowerAutomateTriggerNodeContent;
