import { memo } from 'react';

import { Dialog, Modal, ModalOverlay, ModalOverlayProps } from '@/components/core';
import { NewField } from '@/components/ui';

export type NewFieldModalProps = ModalOverlayProps & {
  parentId?: string;
};

const INewFieldModal = ({ parentId, ...rest }: NewFieldModalProps) => {
  return (
    <ModalOverlay {...rest}>
      <Modal>
        <Dialog>{() => <NewField parentId={parentId} />}</Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const NewFieldModal = memo(INewFieldModal) as typeof INewFieldModal;
