import { useNavigate, useParams } from '@tanstack/react-router';
import { DetailedHTMLProps, FormHTMLAttributes, memo, useMemo } from 'react';

import { merge } from '@/components';
import { Button, CheckmarkSymbol, Loading } from '@/components/core';
import { Field, TableField, TableRefField } from '@/components/ui';
import { useNextRunId } from '@/hooks';
import { toProjectRunId } from '@/hooks/api';
import { useDocumentStore } from '@/store';

import { useValidation } from './ValidationContext.ts';

export type DocumentFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  setSearch: (search?: string) => void;
  hideFormActions?: boolean;
};

const IDocumentForm = ({ className, setSearch, hideFormActions, ...rest }: DocumentFormProps) => {
  const form = useDocumentStore((state) => state.form);
  const fieldConfig = useDocumentStore((state) => state.fieldConfig);
  const status = useDocumentStore((state) => state.status);
  const { projectId, runId } = useParams({ from: '/_auth/details/projects/$projectId/runs/$runId' });

  const nextRunId = useNextRunId(projectId, runId);
  const navigate = useNavigate();

  const { currentTable } = useValidation();

  const fields = useMemo(() => {
    return Object.entries(fieldConfig ?? {}).map(([label, field]) => {
      if (field.type === 'table') {
        return <TableRefField name={label} onPress={() => currentTable?.setCurrent?.(label, field)} />;
      } else if (field.type === 'multi-value') {
        return <TableField key={label} rowId={label} name={label} setSearch={setSearch} />;
      } else {
        return <Field key={label} rowId={label} name={label} setSearch={setSearch} />;
      }
    });
  }, [currentTable, fieldConfig, setSearch]);

  if (status === 'Pending predictions') {
    return <Loading label="AI is working" message="The AI model is parsing your document" />;
  }

  return (
    <form
      className={merge('flex flex-col gap-4', className)}
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await form.handleSubmit();

        const projectRunId = toProjectRunId(projectId, nextRunId);
        if (projectRunId) {
          await navigate({
            to: '/details/projects/$projectId/runs/$runId',
            params: projectRunId,
            search: { showPanel: 'project-runs' },
          });
        }
      }}
      {...rest}
    >
      {fields}
      {!hideFormActions ? (
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]: boolean[]) => (
            <div className="flex w-full gap-2">
              {status === 'Ready for review' ? (
                <Button className="min-w-32" type="submit" isDisabled={!canSubmit}>
                  {isSubmitting ? '...' : 'Confirm'}
                </Button>
              ) : (
                <div className="flex items-center gap-2 rounded-lg border border-gray-200 p-2">
                  <CheckmarkSymbol />
                  {status}
                </div>
              )}
            </div>
          )}
        />
      ) : null}
    </form>
  );
};

export const DocumentForm = memo(IDocumentForm) as typeof IDocumentForm;
