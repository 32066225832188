import SampleDocument from '@/assets/sampleDocuments/ID Card.pdf';
import { TemplateModel } from '@/components/ui';

import {
  countryCodeFormatter,
  dateFormatter,
  genderFormatter,
  highConfidenceValidator,
  numericFormatter,
} from './common.ts';

export const ID_CARD: TemplateModel = {
  fields: [
    {
      id: 'surname',
      type: 'single-value',
      name: 'Surname',
      promptHint: '',
      validators: [highConfidenceValidator],
    },
    {
      id: 'first_name',
      type: 'single-value',
      name: 'First Name',
      promptHint: '',
      validators: [highConfidenceValidator],
    },
    {
      id: 'gender',
      type: 'single-value',
      name: 'Gender',
      promptHint: "The person's gender",
      formatters: [genderFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'document_number',
      type: 'single-value',
      name: 'Document Number',
      promptHint: '',
      validators: [highConfidenceValidator],
    },
    {
      id: 'date_of_expiry',
      type: 'single-value',
      name: 'Date Of Expiry',
      promptHint: 'Return ISO formatted date of expiry.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'nationality',
      type: 'single-value',
      name: 'Nationality',
      promptHint: 'ISO formatted nationality.',
      formatters: [countryCodeFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'height',
      type: 'single-value',
      name: 'Height',
      promptHint: 'Height of the person, without units',
      formatters: [numericFormatter],
      validators: [highConfidenceValidator],
    },
  ],
  sampleDocuments: [SampleDocument],
};
