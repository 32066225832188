import { memo } from 'react';
import { Modal as Modal_, ModalOverlayProps } from 'react-aria-components';

import { merge } from '@/components';

export type ModalProps = ModalOverlayProps & React.RefAttributes<HTMLDivElement>;

const IModal = ({ className, children, ...rest }: ModalProps) => {
  return (
    <Modal_
      className={({ isEntering, isExiting }) => {
        return merge(
          'rounded-lg bg-white',
          isEntering && 'animate-in zoom-in-95 duration-300 ease-out',
          isExiting && 'animate-out zoom-out-95 duration-200 ease-in',
          className
        );
      }}
      {...rest}
    >
      {children}
    </Modal_>
  );
};

export const Modal = memo(IModal) as typeof IModal;
