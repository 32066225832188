import { memo } from 'react';

import { Hr, Text } from '@/components/core';
import { TextArea } from '@/components/core';
import { findField, FormatterValidatorHeader, ValidationFormSettings } from '@/components/ui';
import { useValidatorMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

export type RegexValidatorProps = {
  fieldId: string;
  index: number;
};

const IRegexValidator = ({ fieldId, index }: RegexValidatorProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const validator = field?.validators?.[index];
  const mutate = useValidatorMutation(fieldId, index, field?.parentId);

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader validator={validator} />
      <Hr />
      <div className="flex grow flex-col gap-4">
        <Text>Match pattern</Text>

        <TextArea
          delay={500}
          value={validator?.config.matchPattern}
          onChange={(value: string) => mutate((f) => (f.config.matchPattern = value))}
        />
      </div>

      <ValidationFormSettings fieldId={fieldId} index={index} />
    </div>
  );
};

export const RegexValidator = memo(IRegexValidator) as typeof IRegexValidator;
