import { ProjectRun } from '@lucidtech/las-sdk-browser';
import { useMemo } from 'react';

import { useFilteredProjectRunsQuery } from './api';

export const useNextRunId = (projectId: string, runId: string) => {
  const { data } = useFilteredProjectRunsQuery({ projectId });

  return useMemo(() => {
    const pages = data?.pages || [];

    let startIndex = -1;
    for (const page of pages) {
      startIndex = page.data.findIndex((run: ProjectRun) => run?.runId === runId);
      const run = page.data.find((r: ProjectRun, idx: number) => {
        return startIndex < idx && r.status === 'Ready for review';
      });

      if (run) {
        return run?.runId;
      }
    }

    const nextTask = pages[0]?.data?.find((r: ProjectRun) => r.status === 'Ready for review');
    return nextTask?.runId;
  }, [data, runId]);
};
