import { memo } from 'react';

import {
  ExportExcelConfiguration,
  ExportPanelHeader,
  ExportPowerAutomateConfiguration,
  ExportWebhookConfiguration,
  ExportZapierConfiguration,
} from '@/components/flow';
import {
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { toActionId, toProjectId, useSuspenseGetAction, useSuspenseGetProject } from '@/hooks/api';
import { TriggerProvider } from '@/store';

export type ExportPanelProps = {
  projectId: string;
  actionId: string;
};

const IExportPanel = ({ projectId, actionId }: ExportPanelProps) => {
  const { data: action } = useSuspenseGetAction(toActionId(actionId));
  const { data: project } = useSuspenseGetProject(toProjectId(projectId));
  const modelId = project.resourceIds.find((resourceId) => resourceId.startsWith('las:model'));

  return (
    /* Force remount of component when projectId or actionId changes */
    <div key={`${projectId}/${actionId}`}>
      <TriggerProvider action={action}>
        <ExportPanelHeader className="border-b border-gray-200 p-4" actionId={actionId} />
        {action.functionId === EXPORT_TO_EXCEL_FUNCTION_ID ? <ExportExcelConfiguration /> : null}
        {action.functionId === EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID && modelId ? (
          <ExportPowerAutomateConfiguration modelId={modelId} />
        ) : null}
        {action.functionId === EXPORT_TO_WEBHOOK_FUNCTION_ID ? <ExportWebhookConfiguration /> : null}
        {action.functionId === EXPORT_TO_ZAPIER_FUNCTION_ID ? <ExportZapierConfiguration /> : null}
      </TriggerProvider>
    </div>
  );
};

export const ExportPanel = memo(IExportPanel) as typeof IExportPanel;
