import { arrayMove } from '@dnd-kit/sortable';
import type { Field, FieldFormatter, FieldValidator } from '@lucidtech/las-sdk-browser';
import { createContext } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { generateId } from '@/utils';

export type FieldState = Omit<Field, 'fields'> & {
  id: string;
  fields?: FieldState[] | null;
  parentId?: string | null;
};

export interface ModelConfigProps {
  fields: FieldState[];
}

export interface ModelConfigState extends ModelConfigProps {
  getFields: () => FieldState[];
  addField: (field: Partial<FieldState>) => void;
  deleteField: (id: string, parentId?: string | null) => void;
  duplicateField: (id: string, parentId?: string | null) => void;
  setFields: (fields: FieldState[], parentId?: string | null) => void;
  updateField: (id: string, updates: Partial<FieldState>, parentId?: string | null) => void;
  addFormatter: (id: string, formatter: FieldFormatter, parentId?: string | null) => void;
  moveFormatter: (id: string, fromIndex: number, toIndex: number, parentId?: string | null) => void;
  deleteFormatter: (id: string, index: number, parentId?: string | null) => void;
  updateFormatter: (
    id: string,
    index: number,
    fn: (formatter: FieldFormatter) => FieldFormatter,
    parentId?: string | null
  ) => void;
  addValidator: (id: string, formatter: FieldValidator, parentId?: string | null) => void;
  moveValidator: (id: string, fromIndex: number, toIndex: number, parentId?: string | null) => void;
  deleteValidator: (id: string, index: number, parentId?: string | null) => void;
  updateValidator: (
    id: string,
    index: number,
    fn: (validator: FieldValidator) => FieldValidator,
    parentId?: string | null
  ) => void;
}

export const createModelConfigStore = (initProps?: Partial<ModelConfigProps>) => {
  const defaultProps = {
    fields: [],
  };
  const findField = (fields: FieldState[], id: string) => {
    return fields.find((field) => field.id === id);
  };
  const getField = (fields: FieldState[], id: string, parentId?: string | null) => {
    if (parentId) {
      const parent = findField(fields, parentId);
      if (parent && parent.fields?.constructor === Array) {
        return findField(parent.fields, id);
      }
    } else {
      return findField(fields, id);
    }
  };
  const createCopy = (field: FieldState) => {
    const newField = structuredClone(field);
    newField.id = generateId();
    newField.name += ' (Copy)';
    return newField;
  };
  return createStore<ModelConfigState>()(
    devtools(
      immer((set, get) => ({
        ...defaultProps,
        ...initProps,
        getFields: () => {
          const { fields } = get();
          return fields;
        },
        addField: (newField) =>
          set((state) => {
            const field: FieldState = {
              ...newField,
              id: newField.id ?? generateId(),
              name: newField.name ?? 'New field',
              type: newField.type ?? 'single-value',
            };
            if (field.type === 'table') {
              field.fields ||= [];
            }
            if (field.parentId) {
              // TODO: Fix this warning
              const parent = findField(state.fields, field.parentId);
              if (parent && parent.fields?.constructor === Array) {
                parent.fields.push(field);
              }
            } else {
              state.fields.push(field);
            }
          }),
        deleteField: (id, parentId) =>
          set((state) => {
            if (parentId) {
              const parent = findField(state.fields, parentId);
              if (parent && parent.fields?.constructor === Array) {
                const index = parent.fields.findIndex((f) => f.id === id);
                if (index > -1) {
                  parent.fields.splice(index, 1);
                }
              }
            } else {
              const index = state.fields.findIndex((f) => f.id === id);
              if (index > -1) {
                state.fields.splice(index, 1);
              }
            }
          }),
        duplicateField: (id, parentId) =>
          set((state) => {
            if (parentId) {
              const parent = findField(state.fields, parentId);
              if (parent && parent.fields?.constructor === Array) {
                const field = findField(parent.fields, id);
                if (field) {
                  const index = parent.fields.indexOf(field);
                  parent.fields.splice(index + 1, 0, createCopy(field));
                }
              }
            } else {
              const field = findField(state.fields, id);
              if (field) {
                const index = state.fields.indexOf(field);
                state.fields.splice(index + 1, 0, createCopy(field));
              }
            }
          }),
        setFields: (fields, parentId) =>
          set((state) => {
            if (parentId) {
              const parent = findField(state.fields, parentId);
              if (parent) {
                parent.fields = fields;
              }
            } else {
              state.fields = fields;
            }
          }),
        updateField: (id, updates, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (field) Object.assign(field, updates);
          }),
        addFormatter: (id, formatter, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (!field) return;
            if (field.formatters) {
              field.formatters.push(formatter);
            } else {
              field.formatters = [formatter];
            }
          }),
        moveFormatter: (id, fromIndex, toIndex, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (field && field.formatters) {
              field.formatters = arrayMove(field.formatters, fromIndex, toIndex);
            }
          }),
        deleteFormatter: (id, index, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (field && field.formatters) {
              field.formatters.splice(index, 1);
            }
          }),
        updateFormatter: (id, index, fn: (formatter: FieldFormatter) => FieldFormatter, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            const formatter = field?.formatters?.[index];
            if (formatter) Object.assign(formatter, fn(formatter));
          }),
        moveValidator: (id, fromIndex, toIndex, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (field && field.validators) {
              field.validators = arrayMove(field.validators, fromIndex, toIndex);
            }
          }),
        deleteValidator: (id, index, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (field && field.validators) {
              field.validators.splice(index, 1);
            }
          }),
        updateValidator: (id, index, fn: (validator: FieldValidator) => FieldValidator, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            const validator = field?.validators?.[index];
            if (validator) Object.assign(validator, fn(validator));
          }),
        addValidator: (id, validator, parentId) =>
          set((state) => {
            const field = getField(state.fields, id, parentId);
            if (!field) return;
            if (field.validators) {
              field.validators.push(validator);
            } else {
              field.validators = [validator];
            }
          }),
      }))
    )
  );
};

export type ModelConfigStore = ReturnType<typeof createModelConfigStore>;

export const ModelConfigContext = createContext<ModelConfigStore | null>(null);
