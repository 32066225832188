import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { merge } from '@/components';

export type PageProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Page = ({ className, children, ...rest }: PageProps) => {
  return (
    <div className="flex h-screen w-full flex-row justify-center overflow-auto pt-14">
      <div className={merge('w-[35rem] space-y-2', className)} {...rest}>
        {children}
      </div>
    </div>
  );
};
