import axios from 'axios';
import { memo, useMemo } from 'react';

import { Text } from '@/components/core';

const getErrorMessage = (error: Error): string | undefined => {
  if (axios.isAxiosError(error)) {
    return error.response?.data.message;
  }
  return error.message;
};

export type ErrorCardProps = {
  label: string;
  message?: string;
  error?: Error | Error[] | null;
};

const IErrorCard = ({ label, message, error }: ErrorCardProps) => {
  const errorMessages = useMemo(() => {
    let errors: (string | undefined)[] = [];
    if (Array.isArray(error)) {
      errors = error.map((e) => getErrorMessage(e));
    } else if (error instanceof Error) {
      errors = [getErrorMessage(error)];
    }
    return errors.filter((e) => typeof e === 'string');
  }, [error]);

  return (
    <>
      <div className="flex h-full w-full flex-col border border-red-300 p-2">
        <Text className="font-semibold text-red-400" size="md">
          {label}
        </Text>
        {message ? <Text className="text-red-400">{message}</Text> : null}
        {errorMessages.map((e, i) => (
          <Text key={i} className="text-red-400">
            {e}
          </Text>
        ))}
      </div>
      <Text>
        Please contact our{' '}
        <span onClick={() => window._chatlio?.open()} className="cursor-pointer font-semibold underline">
          chat
        </span>{' '}
        or <span className="font-semibold">support@cradl.ai</span> if the problem persists
      </Text>
    </>
  );
};

export const ErrorCard = memo(IErrorCard) as typeof IErrorCard;
