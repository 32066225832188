import { ReactNode, SVGProps } from 'react';

import InfoIcon from '@/assets/info-icon.svg?react';
import { merge } from '@/components';
import { Tooltip } from '@/components/core';

export type ErrorSymbolProps = SVGProps<SVGSVGElement> & {
  tooltip?: ReactNode;
};

export const ErrorSymbol = ({ className, tooltip, ...rest }: ErrorSymbolProps) => {
  /* prettier-ignore */
  const styles  = [
    'rounded-full',
    'size-5',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'stroke-red-300',
    'fill-red-50'
  ];
  /* prettier-ignore */
  const darkStyles = [
    'stroke-red-300',
    'fill-red-50'
  ];

  return (
    <Tooltip tooltip={tooltip}>
      <InfoIcon className={merge(styles, lightStyles, darkStyles, className)} {...rest} />
    </Tooltip>
  );
};
