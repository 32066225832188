import { memo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import CalendarIcon from '@/assets/calendar-icon.svg?react';
import StringIcon from '@/assets/field-type-string.svg?react';
import FilterIcon from '@/assets/filter-icon.svg?react';
import { Button, Menu, MenuItem, Popover, Text } from '@/components/core';
import { useInboxStore } from '@/store';

export type InboxFilterProps = object;

// eslint-disable-next-line no-empty-pattern
const IInboxFilter = ({}: InboxFilterProps) => {
  const addFilter = useInboxStore((state) => state.addFilter);
  return (
    <MenuTrigger>
      <Button className="text-gray-400" variant="secondary">
        <FilterIcon className="size-5 stroke-gray-400" />
        Filter
      </Button>
      <Popover className="flex flex-col gap-2 p-2">
        <Menu>
          <MenuItem className="flex items-center" onAction={() => addFilter({ type: 'date-range' })}>
            <CalendarIcon className="size-5 stroke-gray-400" />
            <Text>Add date range filter</Text>
          </MenuItem>
          <MenuItem className="flex items-center" onAction={() => addFilter({ type: 'date' })}>
            <CalendarIcon className="size-5 stroke-gray-400" />
            <Text>Add date filter</Text>
          </MenuItem>
          <MenuItem className="flex items-center" onAction={() => addFilter({ type: 'string' })}>
            <StringIcon className="size-5 stroke-gray-400" />
            <Text>Add string filter</Text>
          </MenuItem>
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

export const InboxFilter = memo(IInboxFilter) as typeof IInboxFilter;
