import { type ClassValue, clsx } from 'clsx';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { isNumber } from '@/utils';

export function merge(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export const numberGuard = (
  value: unknown,
  render?: (value: number) => ReactNode,
  predicate?: (value: number) => boolean
) => {
  if (isNumber(value, predicate)) {
    return <>{render?.(value)}</>;
  }
  return null;
};

export const guard = (
  value: unknown,
  render?: (value: unknown) => ReactNode,
  predicate?: (value: unknown) => boolean
) => {
  if (!!value && (!predicate || (predicate && predicate(value)))) {
    return <>{render?.(value)}</>;
  }
  return null;
};
