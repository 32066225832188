import { memo } from 'react';

import WebhookIcon from '@/assets/webhook-icon.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type WebhookLogoProps = LogoProps;

const IWebhookLogo = ({ className, ...rest }: WebhookLogoProps) => {
  return (
    <Logo className={merge('rounded-lg border border-gray-200 bg-white', className)} {...rest}>
      <WebhookIcon className="size-min-5" />
    </Logo>
  );
};

export const WebhookLogo = memo(IWebhookLogo) as typeof IWebhookLogo;
