import { memo } from 'react';

import TimeIcon from '@/assets/time-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type TimeLogoProps = VariantLogoProps;

const ITimeLogo = ({ ...rest }: TimeLogoProps) => {
  return <FormatterValidatorLogo Icon={TimeIcon} iconColoringType="stroke" {...rest} />;
};

export const TimeLogo = memo(ITimeLogo) as typeof ITimeLogo;
