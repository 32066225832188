import { memo } from 'react';

import { Header, Text } from '@/components/core';
import { toOrganizationId, useSuspenseGetOrganization } from '@/hooks/api';
import { OrganizationProvider } from '@/store';

import { BillingDetails } from './BillingDetails.tsx';
import { Page } from './Page';
import { WorkspaceHeader } from './WorkspaceHeader.tsx';

export type BillingProps = object;

// eslint-disable-next-line no-empty-pattern
const IBilling = ({}: BillingProps) => {
  const { data: organization } = useSuspenseGetOrganization(toOrganizationId('me'));
  organization.organizationId = 'me';

  return (
    // Force re-creation of provider if organizationId changes
    <Page key={organization.organizationId}>
      <OrganizationProvider {...organization}>
        <WorkspaceHeader>
          <div className="flex flex-col">
            <Header size="xl">Billing</Header>
            <Text size="md">Update your payment information or switch plans according to your needs</Text>
          </div>
        </WorkspaceHeader>

        <BillingDetails organizationId={organization.organizationId} />
      </OrganizationProvider>
    </Page>
  );
};

export const Billing = memo(IBilling) as typeof IBilling;
