import { memo, PropsWithChildren } from 'react';

import ChevronIcon from '@/assets/arrow-left-icon.svg?react';
import { Button, Text, useComponentStack } from '@/components/core';

type NewType = PropsWithChildren;

export type NavigationContainerProps = NewType;

const INavigationContainer = ({ children }: NavigationContainerProps) => {
  const componentStack = useComponentStack();

  return (
    <>
      <div className="border-b border-gray-200 p-2">
        <Button variant="plain" onPress={() => componentStack?.pop()}>
          <ChevronIcon className="stroke-gray-500" />
          <Text>Back</Text>
        </Button>
      </div>

      <div className="grow">{children}</div>
    </>
  );
};

export const NavigationContainer = memo(INavigationContainer) as typeof INavigationContainer;
