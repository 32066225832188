import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { TriggerContext, TriggerState } from './triggerStore';

export function useTriggerStore<T>(selector: (state: TriggerState) => T): T {
  const store = useContext(TriggerContext);
  if (!store) throw new Error('Missing TriggerContext.Provider in the tree');
  return useStore(store, selector);
}
