import { memo } from 'react';

import { ValidationPanel } from '@/components/flow';

import { SlideOut } from './SlideOut.tsx';

export type ValidationPanelSlideOutProps = {
  actionId: string;
  projectId: string;
  validationId: string;
};

const IValidationPanelSlideOut = ({ projectId, actionId, validationId }: ValidationPanelSlideOutProps) => {
  return (
    <SlideOut>
      <ValidationPanel actionId={actionId} projectId={projectId} validationId={validationId} />
    </SlideOut>
  );
};

export const ValidationPanelSlideOut = memo(IValidationPanelSlideOut) as typeof IValidationPanelSlideOut;
