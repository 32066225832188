import { memo } from 'react';

import ClassificationIcon from '@/assets/classification-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type ClassificationLogoProps = VariantLogoProps;

const IClassificationLogo = ({ ...rest }: ClassificationLogoProps) => {
  return <FormatterValidatorLogo Icon={ClassificationIcon} iconColoringType="stroke" {...rest} />;
};

export const ClassificationLogo = memo(IClassificationLogo) as typeof IClassificationLogo;
