import {
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker as DatePicker_,
  DatePickerProps as DatePickerProps_,
  DateSegment,
  DateValue,
  Dialog,
  Group,
  Heading,
} from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import { merge } from '@/components';
import { Button, Popover } from '@/components/core';

export type DatePickerProps = DatePickerProps_<DateValue> & {
  label: string;
  date?: DateValue | null;
  onDateChange?: (value: DateValue | null) => void;
};

export const DatePicker = ({ label, date, onDateChange, className, ...rest }: DatePickerProps) => {
  return (
    <DatePicker_ aria-label={label} className={merge('', className)} defaultValue={date} {...rest}>
      <Group className="flex items-center">
        <Button className="gap-1 hover:bg-gray-50" variant="plain">
          <DateInput className="pointer-events-none flex text-xs text-gray-500">
            {(segment) => {
              segment.isEditable = false;
              return <DateSegment segment={segment} />;
            }}
          </DateInput>
        </Button>
      </Group>
      <Popover>
        <Dialog>
          <Calendar onChange={onDateChange}>
            <header className="flex items-center justify-center gap-4 p-2">
              <Button variant="plain" slot="previous">
                <ChevronDownIcon className="rotate-90 stroke-gray-400" />
              </Button>
              <Heading className="font-semibold text-gray-600" />
              <Button variant="plain" slot="next">
                <ChevronDownIcon className="-rotate-90 stroke-gray-400" />
              </Button>
            </header>
            <CalendarGrid>
              {(date) => (
                <CalendarCell
                  className="data-[selected]:bg-brand-100 data-[selection-end]:bg-brand-300 data-[selection-start]:bg-brand-300 rounded-lg p-3 data-[disabled]:bg-gray-100 data-[disabled]:text-gray-400 data-[selection-end]:outline-none data-[selection-start]:outline-none"
                  date={date}
                />
              )}
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </DatePicker_>
  );
};
