import { memo } from 'react';

import { Dialog, Modal, ModalOverlay, ModalOverlayProps } from '@/components/core';
import { NewFormatter } from '@/components/ui';

export type NewFormatterModalProps = ModalOverlayProps & {
  fieldId: string;
};

const INewFormatterModal = ({ fieldId, ...rest }: NewFormatterModalProps) => {
  return (
    <ModalOverlay {...rest}>
      <Modal>
        <Dialog>{() => <NewFormatter fieldId={fieldId} />}</Dialog>
      </Modal>
    </ModalOverlay>
  );
};

export const NewFormatterModal = memo(INewFormatterModal) as typeof INewFormatterModal;
