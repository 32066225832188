export default [
  { value: 'AED', description: 'AED' },
  { value: 'AFN', description: 'AFN' },
  { value: 'ALL', description: 'ALL' },
  { value: 'AMD', description: 'AMD' },
  { value: 'ANG', description: 'ANG' },
  { value: 'AOA', description: 'AOA' },
  { value: 'ARS', description: 'ARS' },
  { value: 'AUD', description: 'AUD' },
  { value: 'AWG', description: 'AWG' },
  { value: 'AZN', description: 'AZN' },
  { value: 'BAM', description: 'BAM' },
  { value: 'BBD', description: 'BBD' },
  { value: 'BDT', description: 'BDT' },
  { value: 'BGN', description: 'BGN' },
  { value: 'BHD', description: 'BHD' },
  { value: 'BIF', description: 'BIF' },
  { value: 'BMD', description: 'BMD' },
  { value: 'BND', description: 'BND' },
  { value: 'BOB', description: 'BOB' },
  { value: 'BOV', description: 'BOV' },
  { value: 'BRL', description: 'BRL' },
  { value: 'BSD', description: 'BSD' },
  { value: 'BTN', description: 'BTN' },
  { value: 'BWP', description: 'BWP' },
  { value: 'BYN', description: 'BYN' },
  { value: 'BZD', description: 'BZD' },
  { value: 'CAD', description: 'CAD' },
  { value: 'CDF', description: 'CDF' },
  { value: 'CHE', description: 'CHE' },
  { value: 'CHF', description: 'CHF' },
  { value: 'CHW', description: 'CHW' },
  { value: 'CLF', description: 'CLF' },
  { value: 'CLP', description: 'CLP' },
  { value: 'CNY', description: 'CNY' },
  { value: 'COP', description: 'COP' },
  { value: 'COU', description: 'COU' },
  { value: 'CRC', description: 'CRC' },
  { value: 'CUP', description: 'CUP' },
  { value: 'CVE', description: 'CVE' },
  { value: 'CZK', description: 'CZK' },
  { value: 'DJF', description: 'DJF' },
  { value: 'DKK', description: 'DKK' },
  { value: 'DOP', description: 'DOP' },
  { value: 'DZD', description: 'DZD' },
  { value: 'EGP', description: 'EGP' },
  { value: 'ERN', description: 'ERN' },
  { value: 'ETB', description: 'ETB' },
  { value: 'EUR', description: 'EUR' },
  { value: 'FJD', description: 'FJD' },
  { value: 'FKP', description: 'FKP' },
  { value: 'GBP', description: 'GBP' },
  { value: 'GEL', description: 'GEL' },
  { value: 'GHS', description: 'GHS' },
  { value: 'GIP', description: 'GIP' },
  { value: 'GMD', description: 'GMD' },
  { value: 'GNF', description: 'GNF' },
  { value: 'GTQ', description: 'GTQ' },
  { value: 'GYD', description: 'GYD' },
  { value: 'HKD', description: 'HKD' },
  { value: 'HNL', description: 'HNL' },
  { value: 'HTG', description: 'HTG' },
  { value: 'HUF', description: 'HUF' },
  { value: 'IDR', description: 'IDR' },
  { value: 'ILS', description: 'ILS' },
  { value: 'INR', description: 'INR' },
  { value: 'IQD', description: 'IQD' },
  { value: 'IRR', description: 'IRR' },
  { value: 'ISK', description: 'ISK' },
  { value: 'JMD', description: 'JMD' },
  { value: 'JOD', description: 'JOD' },
  { value: 'JPY', description: 'JPY' },
  { value: 'KES', description: 'KES' },
  { value: 'KGS', description: 'KGS' },
  { value: 'KHR', description: 'KHR' },
  { value: 'KMF', description: 'KMF' },
  { value: 'KPW', description: 'KPW' },
  { value: 'KRW', description: 'KRW' },
  { value: 'KWD', description: 'KWD' },
  { value: 'KYD', description: 'KYD' },
  { value: 'KZT', description: 'KZT' },
  { value: 'LAK', description: 'LAK' },
  { value: 'LBP', description: 'LBP' },
  { value: 'LKR', description: 'LKR' },
  { value: 'LRD', description: 'LRD' },
  { value: 'LSL', description: 'LSL' },
  { value: 'LYD', description: 'LYD' },
  { value: 'MAD', description: 'MAD' },
  { value: 'MDL', description: 'MDL' },
  { value: 'MGA', description: 'MGA' },
  { value: 'MKD', description: 'MKD' },
  { value: 'MMK', description: 'MMK' },
  { value: 'MNT', description: 'MNT' },
  { value: 'MOP', description: 'MOP' },
  { value: 'MRU', description: 'MRU' },
  { value: 'MUR', description: 'MUR' },
  { value: 'MVR', description: 'MVR' },
  { value: 'MWK', description: 'MWK' },
  { value: 'MXN', description: 'MXN' },
  { value: 'MXV', description: 'MXV' },
  { value: 'MYR', description: 'MYR' },
  { value: 'MZN', description: 'MZN' },
  { value: 'NAD', description: 'NAD' },
  { value: 'NGN', description: 'NGN' },
  { value: 'NIO', description: 'NIO' },
  { value: 'NOK', description: 'NOK' },
  { value: 'NPR', description: 'NPR' },
  { value: 'NZD', description: 'NZD' },
  { value: 'OMR', description: 'OMR' },
  { value: 'PAB', description: 'PAB' },
  { value: 'PEN', description: 'PEN' },
  { value: 'PGK', description: 'PGK' },
  { value: 'PHP', description: 'PHP' },
  { value: 'PKR', description: 'PKR' },
  { value: 'PLN', description: 'PLN' },
  { value: 'PYG', description: 'PYG' },
  { value: 'QAR', description: 'QAR' },
  { value: 'RON', description: 'RON' },
  { value: 'RSD', description: 'RSD' },
  { value: 'RUB', description: 'RUB' },
  { value: 'RWF', description: 'RWF' },
  { value: 'SAR', description: 'SAR' },
  { value: 'SBD', description: 'SBD' },
  { value: 'SCR', description: 'SCR' },
  { value: 'SDG', description: 'SDG' },
  { value: 'SEK', description: 'SEK' },
  { value: 'SGD', description: 'SGD' },
  { value: 'SHP', description: 'SHP' },
  { value: 'SLE', description: 'SLE' },
  { value: 'SOS', description: 'SOS' },
  { value: 'SRD', description: 'SRD' },
  { value: 'SSP', description: 'SSP' },
  { value: 'STN', description: 'STN' },
  { value: 'SVC', description: 'SVC' },
  { value: 'SYP', description: 'SYP' },
  { value: 'SZL', description: 'SZL' },
  { value: 'THB', description: 'THB' },
  { value: 'TJS', description: 'TJS' },
  { value: 'TMT', description: 'TMT' },
  { value: 'TND', description: 'TND' },
  { value: 'TOP', description: 'TOP' },
  { value: 'TRY', description: 'TRY' },
  { value: 'TTD', description: 'TTD' },
  { value: 'TWD', description: 'TWD' },
  { value: 'TZS', description: 'TZS' },
  { value: 'UAH', description: 'UAH' },
  { value: 'UGX', description: 'UGX' },
  { value: 'USD', description: 'USD' },
  { value: 'USN', description: 'USN' },
  { value: 'UYI', description: 'UYI' },
  { value: 'UYU', description: 'UYU' },
  { value: 'UYW', description: 'UYW' },
  { value: 'UZS', description: 'UZS' },
  { value: 'VED', description: 'VED' },
  { value: 'VES', description: 'VES' },
  { value: 'VND', description: 'VND' },
  { value: 'VUV', description: 'VUV' },
  { value: 'WST', description: 'WST' },
  { value: 'XAF', description: 'XAF' },
  { value: 'XAG', description: 'XAG' },
  { value: 'XAU', description: 'XAU' },
  { value: 'XBA', description: 'XBA' },
  { value: 'XBB', description: 'XBB' },
  { value: 'XBC', description: 'XBC' },
  { value: 'XBD', description: 'XBD' },
  { value: 'XCD', description: 'XCD' },
  { value: 'XDR', description: 'XDR' },
  { value: 'XOF', description: 'XOF' },
  { value: 'XPD', description: 'XPD' },
  { value: 'XPF', description: 'XPF' },
  { value: 'XPT', description: 'XPT' },
  { value: 'XSU', description: 'XSU' },
  { value: 'XTS', description: 'XTS' },
  { value: 'XUA', description: 'XUA' },
  { value: 'XXX', description: 'XXX' },
  { value: 'YER', description: 'YER' },
  { value: 'ZAR', description: 'ZAR' },
  { value: 'ZMW', description: 'ZMW' },
  { value: 'ZWG', description: 'ZWG' },
];
