import { memo } from 'react';

import { Profile, ProfileProps } from '@/components/ui';
import { toUserId, useGetUser } from '@/hooks/api';

export type UserProps = ProfileProps & {
  userId?: string | null;
};

const IUser = ({ userId, ...rest }: UserProps) => {
  const { data: user } = useGetUser(toUserId(userId));
  return <Profile profileId={user?.profileId} {...rest} />;
};

export const User = memo(IUser) as typeof IUser;
