import { arrayMove } from '@dnd-kit/sortable';
import { parseDate } from '@internationalized/date';
import { ProjectRunStatusValues } from '@lucidtech/las-sdk-browser';
import { ColumnSort } from '@tanstack/react-table';
import { createContext } from 'react';
import { DateRange, DateValue } from 'react-aria-components';
import superjson from 'superjson';
import { createStore } from 'zustand';
import { devtools, persist, PersistStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { generateId } from '@/utils';

export type SortingFilter = ColumnSort & {
  hidden?: boolean;
};

export type ColumnFilter = {
  id: string;
  columnName?: string;
};

export const DateRangeConditions = ['between'] as const;
export type DateRangeColumnFilter = ColumnFilter & {
  type?: 'date-range';
  condition?: (typeof DateRangeConditions)[number];
  value?: DateRange | null;
};

export const DateConditions = ['after', 'before'] as const;
export type DateColumnFilter = ColumnFilter & {
  type?: 'date';
  condition?: (typeof DateConditions)[number];
  value?: DateValue | null;
};

export const StringConditions = ['is'] as const;
export type StringColumnFilter = ColumnFilter & {
  type?: 'string';
  condition?: (typeof StringConditions)[number];
  value?: string[];
};

export type FilterType = DateRangeColumnFilter | DateColumnFilter | StringColumnFilter;
export type FilterGroup = {
  name: string;
  filters: FilterType[];
};

export interface InboxProps {
  sorting: SortingFilter[];
  filters: FilterType[];
  selectedRunIds: Set<string>;
  search: string | undefined;
  filterGroups: FilterGroup[];
}

superjson.registerCustom<DateValue, string>(
  {
    isApplicable: (v): v is DateValue => typeof v === 'object' && 'calendar' in v,
    serialize: (v) => v.toString(),
    deserialize: (v) => parseDate(v),
  },
  'DateValue'
);

const storage: PersistStorage<InboxProps> = {
  getItem: (name) => {
    const str = localStorage.getItem(name);
    if (!str) return null;
    return superjson.parse(str);
  },
  setItem: (name, value) => {
    localStorage.setItem(name, superjson.stringify(value));
  },
  removeItem: (name) => localStorage.removeItem(name),
};

export type SortingUpdates = {
  [key: string]: Partial<SortingFilter>;
};

export interface InboxState extends InboxProps {
  setSorting: (sorting: SortingFilter[]) => void;
  updateSorting: (updates: SortingUpdates) => void;
  moveSorting: (fromIndex: number, toIndex: number) => void;
  addFilter: (filter?: Partial<FilterType>) => void;
  updateFilter: (id: string, updates: Partial<FilterType>) => void;
  deleteFilter: (id: string) => void;
  clearFilters: () => void;
  addSelectedRunId: (id: string) => void;
  removeSelectedRunId: (id: string) => void;
  setSelectedRunIds: (selectedRunIds: string[] | Set<string>) => void;
  setSearch: (search: string | undefined) => void;
}

export const createInboxStore = (projectId: string, initProps?: Partial<InboxProps>) => {
  const props: InboxProps = {
    sorting: [
      { id: 'status', desc: false, hidden: true },
      { id: 'createdBy', desc: false, hidden: true },
      { id: 'createdTime', desc: true, hidden: false },
      { id: 'updatedTime', desc: false, hidden: true },
      { id: 'updatedBy', desc: false, hidden: true },
    ],
    filters: [],
    filterGroups: [
      {
        name: 'All',
        filters: [
          {
            id: generateId(),
            type: 'string',
            condition: 'is',
            value: [...ProjectRunStatusValues.filter((s) => s !== 'Archived')],
            columnName: 'status',
          },
        ],
      },
      {
        name: 'Review',
        filters: [
          { id: generateId(), type: 'string', condition: 'is', value: ['Ready for review'], columnName: 'status' },
        ],
      },
      {
        name: 'Exported',
        filters: [{ id: generateId(), type: 'string', condition: 'is', value: ['Exported'], columnName: 'status' }],
      },
      {
        name: 'Archived',
        filters: [{ id: generateId(), type: 'string', condition: 'is', value: ['Archived'], columnName: 'status' }],
      },
    ],
    search: undefined,
    selectedRunIds: new Set(),
    ...initProps,
  };

  return createStore<InboxState>()(
    devtools(
      persist(
        immer((set) => ({
          ...props,
          setSorting: (sorting: SortingFilter[]) =>
            set((state) => {
              state.sorting = sorting;
            }),
          updateSorting: (updates: SortingUpdates) =>
            set((state) => {
              for (const [id, opts] of Object.entries(updates)) {
                const item = state.sorting.find((s) => s.id === id);
                if (item) {
                  Object.assign(item, opts);
                }
              }
            }),
          moveSorting: (fromIndex: number, toIndex: number) =>
            set((state) => {
              state.sorting = arrayMove(state.sorting, fromIndex, toIndex);
            }),
          addFilter: (filter?: Partial<FilterType>) =>
            set((state) => {
              state.filters.push({ id: generateId(), ...filter });
            }),
          updateFilter: (id: string, updates: Partial<FilterType>) =>
            set((state) => {
              const filter = state.filters.find((f) => f.id === id);
              if (filter) {
                Object.assign(filter, updates);
              }
            }),
          deleteFilter: (id: string) =>
            set((state) => {
              const index = state.filters.findIndex((f) => f.id === id);
              if (index > -1) {
                state.filters.splice(index, 1);
              }
            }),
          clearFilters: () =>
            set((state) => {
              state.filters = [];
            }),
          addSelectedRunId: (id: string) =>
            set((state) => {
              state.selectedRunIds.add(id);
            }),
          removeSelectedRunId: (id: string) =>
            set((state) => {
              state.selectedRunIds.delete(id);
            }),
          setSelectedRunIds: (selectedRunIds: string[] | Set<string>) =>
            set((state) => {
              state.selectedRunIds = new Set([...selectedRunIds]);
            }),
          setSearch: (search: string | undefined) =>
            set((state) => {
              state.search = search;
            }),
        })),
        { name: projectId, storage }
      )
    )
  );
};

export type InboxStore = ReturnType<typeof createInboxStore>;

export const InboxContext = createContext<InboxStore | null>(null);
