import type { ProjectRun } from '@lucidtech/las-sdk-browser';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { DateTime, Header, Loading } from '@/components/core';
import { AppClient, ProjectRunDocumentCard, User } from '@/components/ui';
import { SampleDocumentTooltip } from '@/features/projects';
import { useGetProjectFromPath } from '@/hooks';
import { InboxProvider } from '@/store';

import { FilterGroupsBar } from './FilterGroupsBar.tsx';
import { InboxSelectedToolbar } from './InboxSelectedToolbar';
import { ProjectRunsTable } from './ProjectRunsTable';
import { SetupAutomaticExportBanner } from './SetupAutomaticExportBanner.tsx';
import { Status } from './Status.tsx';

export type InboxProps = object;

// eslint-disable-next-line no-empty-pattern
const IInbox = ({}: InboxProps) => {
  const columns = useMemo<AccessorKeyColumnDef<ProjectRun>[]>(() => {
    return [
      {
        accessorKey: 'file',
        header: 'File',
        cell: ({ row }) => <ProjectRunDocumentCard projectId={row.original.projectId} runId={row.original.runId} />,
        size: 500,
      },
      {
        accessorKey: 'createdTime',
        header: 'Created',
        cell: ({ row }) => <DateTime date={row.original.createdTime} />,
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        cell: ({ row }) => {
          const createdBy = row.original.createdBy;
          if (createdBy.startsWith('las:user:')) {
            return <User userId={createdBy} />;
          } else {
            return <AppClient appClientId={createdBy} />;
          }
        },
      },
      {
        // Setting resourceIds as accessorKey will cause lag?
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => <Status projectId={row.original.projectId} runId={row.original.runId} />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sortingFn: (rowA, rowB, _) => {
          const statusA = rowA.original.status;
          const statusB = rowB.original.status;
          const statusOrder = ['Pending predictions', 'Ready for review', 'Exported', 'Archived'];
          return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
        },
      },
    ];
  }, []);

  const runsProps = useMemo(() => ({ outerContainer: { className: 'h-[80vh]' } }), []);

  const { data: project } = useGetProjectFromPath();

  if (!project) {
    return <Loading label="Loading runs ..." />;
  }

  return (
    <div className="w-full">
      <div className="flex h-full w-full flex-col items-center justify-center px-12">
        <InboxProvider projectId={project?.projectId}>
          <Header className="w-full" size="xl">
            Inbox
          </Header>
          <FilterGroupsBar />
          <SetupAutomaticExportBanner projectId={project.projectId} />
          <SampleDocumentTooltip className="translate-x-16" offset={225} projectId={project.projectId} />
          <ProjectRunsTable projectId={project.projectId} columns={columns} props={runsProps} />
          <InboxSelectedToolbar projectId={project.projectId} />
        </InboxProvider>
      </div>
    </div>
  );
};

export const Inbox = memo(IInbox) as typeof IInbox;
