import { arrayMove } from '@dnd-kit/sortable';
import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Label, Table, Text } from '@/components/core';
import { AddFieldButton, FieldLogo } from '@/components/ui';
import { FieldState, useModelConfigStore } from '@/store';

export type OnFieldItemClick = (field: FieldState) => void;

export type FieldCellProps = {
  field: FieldState;
  onFieldItemClick?: OnFieldItemClick;
};

const IFieldCell = ({ field, onFieldItemClick }: FieldCellProps) => {
  const setFields = useModelConfigStore((state) => state.setFields);
  const deleteField = useModelConfigStore((state) => state.deleteField);
  const duplicateField = useModelConfigStore((state) => state.duplicateField);

  const columns = useMemo<ColumnDef<FieldState>[]>(
    () => [
      {
        accessorKey: 'id',
        cell: ({ row }) => <FieldCell field={row.original} onFieldItemClick={onFieldItemClick} />,
        size: Number.MAX_SAFE_INTEGER,
      },
    ],
    [onFieldItemClick]
  );

  const fieldName = useMemo(() => field.name ?? field.id, [field.id, field.name]);

  if (field.type === 'table' && field.fields) {
    return (
      <div className="rounded-lg border border-gray-200 bg-white py-2 pr-2">
        <Label className="pl-2">Table: {fieldName}</Label>
        <Table
          className="border-separate border-spacing-y-2 pr-4"
          data={field.fields}
          columns={columns}
          isInnerTable={true}
          onRowIndexChange={(oldIndex, newIndex) =>
            setFields(arrayMove(field.fields ?? [], oldIndex, newIndex), field.id)
          }
          hideHeader
          duplicateRowAction={(row) => duplicateField(row.original.id, field.id)}
          deleteRowAction={(row) => deleteField(row.original.id, field.id)}
        />
        <AddFieldButton className="ml-2 hover:bg-gray-50" parentId={field.id} variant="plain">
          <PlusIcon className="stroke-gray-500" />
          <Text size="md">Add column</Text>
        </AddFieldButton>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => onFieldItemClick?.(field)}
        className="flex cursor-pointer items-center gap-2 rounded-lg border border-gray-200 bg-white p-2 hover:bg-gray-50"
      >
        <FieldLogo field={field} size="lg" />
        <Text size="md">{fieldName}</Text>
      </div>
    );
  }
};

export const FieldCell = memo(IFieldCell) as typeof IFieldCell;
