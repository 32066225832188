import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location }) => {
    const { auth } = context;
    if (!(await auth.ensureLoggedIn())) {
      throw redirect({
        to: '/organizations',
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
