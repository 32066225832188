import { memo } from 'react';

import TriggerIcon from '@/assets/trigger-icon.svg?react';
import { EmailLogo, Logo, LogoProps } from '@/components/core';
import { PowerAutomateLogo, ZapierLogo } from '@/components/flow';
import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
} from '@/constants';

export type TriggerLogoProps = LogoProps & {
  functionId?: string;
};

const ITriggerLogo = ({ functionId, ...rest }: TriggerLogoProps) => {
  switch (functionId) {
    case CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID:
      return <EmailLogo {...rest} />;
    case CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID:
      return <PowerAutomateLogo {...rest} />;
    case CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID:
      return <ZapierLogo {...rest} />;
  }

  return (
    <Logo className="bg-teal-400" {...rest}>
      <TriggerIcon className="size-4 fill-white" />
    </Logo>
  );
};

export const TriggerLogo = memo(ITriggerLogo) as typeof ITriggerLogo;
