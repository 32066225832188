import { memo } from 'react';
import { Focusable } from 'react-aria-components';

import CloseIcon from '@/assets/close-icon.svg?react';
import InfoIcon from '@/assets/info-icon.svg?react';
import { Text, Tooltip, TooltipProps } from '@/components/core';
import { FormatterError, FormatterWarning } from '@/utils';

export type ErrorPredictionTooltipProps = Omit<TooltipProps, 'tooltip'> & {
  errors: FormatterError[];
  warnings: FormatterWarning[];
};

const IErrorPredictionTooltip = ({
  errors,
  warnings,
  placement = 'left top',
  ...rest
}: ErrorPredictionTooltipProps) => {
  return (
    <Tooltip
      className="bg-white text-white shadow-lg"
      placement={placement}
      tooltip={
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 border-b border-gray-200 pb-2">
            <CloseIcon className="!rotate-0 stroke-red-500" />
            <Text className="font-semibold">Failed validation</Text>
          </div>
          {errors.map((e) => (
            <div key={e.id} className="flex items-center gap-2">
              <InfoIcon className="size-4 !rotate-0 fill-red-500 stroke-white" />
              <Text size="sm">{e.errors.join(', ')}</Text>
            </div>
          ))}
          {warnings.map((w) => (
            <div key={w.id} className="flex items-center gap-2">
              <InfoIcon className="size-4 !rotate-0 fill-amber-500 stroke-white" />
              <Text size="sm">{w.warnings.join(', ')}</Text>
            </div>
          ))}
        </div>
      }
      {...rest}
    >
      <Focusable>
        <CloseIcon className="fill-red-50 stroke-red-300" />
      </Focusable>
    </Tooltip>
  );
};

export const ErrorPredictionTooltip = memo(IErrorPredictionTooltip) as typeof IErrorPredictionTooltip;
