import { RefAttributes } from 'react';
import { Menu as Menu_, MenuProps as MenuProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type MenuProps<T extends object> = MenuProps_<T> & RefAttributes<HTMLDivElement>;

export const Menu = <T extends object>({ className, children, ...rest }: MenuProps<T>) => {
  return (
    <Menu_ className={merge('outline-none', className)} {...rest}>
      {children}
    </Menu_>
  );
};
