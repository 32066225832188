import DeleteIcon from '@/assets/delete-icon.svg?react';
import { merge } from '@/components';
import { MenuItem, MenuItemProps, Text } from '@/components/core';
import { OmitChildren } from '@/utils';

export type MenuItemDeleteProps = OmitChildren<MenuItemProps>;

export const MenuItemDelete = ({ className, onAction, ...rest }: MenuItemDeleteProps) => {
  return (
    <MenuItem className={merge('flex items-center p-3', className)} onAction={onAction} {...rest}>
      <DeleteIcon className="size-4 stroke-red-400" />
      <Text className="text-red-500" size="sm">
        Delete
      </Text>
    </MenuItem>
  );
};
