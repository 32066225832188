import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';

export type UseSearchProps = {
  onSearch?: (search: string) => void;
  delay?: number;
};

export const useSearch = ({ onSearch, delay }: UseSearchProps = {}) => {
  const [search, setSearch] = useState<string | undefined>();
  const debouncedSearch = useDebounce(search, delay ?? 400);

  useEffect(() => {
    if (debouncedSearch) {
      onSearch?.(debouncedSearch);
    }
  }, [onSearch, debouncedSearch]);

  return { debouncedSearch, search, setSearch };
};
