import { memo } from 'react';

import RegexIcon from '@/assets/regex-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type RegexLogoProps = VariantLogoProps;

const IRegexLogo = ({ ...rest }: RegexLogoProps) => {
  return <FormatterValidatorLogo Icon={RegexIcon} iconColoringType="fill" {...rest} />;
};

export const RegexLogo = memo(IRegexLogo) as typeof IRegexLogo;
