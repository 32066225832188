import { memo } from 'react';

import { merge } from '@/components';
import { Text, TextProps } from '@/components/core';

export type TagProps = TextProps;

const ITag = ({ className, children, ...rest }: TagProps) => {
  return (
    <Text className={merge('rounded-lg bg-gray-100 px-2 py-1 text-gray-600', className)} size="xs" {...rest}>
      {children}
    </Text>
  );
};

export const Tag = memo(ITag) as typeof ITag;
