import { memo } from 'react';

import DateIcon from '@/assets/date-change-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type DateLogoProps = VariantLogoProps;

const IDateLogo = ({ ...rest }: DateLogoProps) => {
  return <FormatterValidatorLogo Icon={DateIcon} iconColoringType="stroke" {...rest} />;
};

export const DateLogo = memo(IDateLogo) as typeof IDateLogo;
