import { MenuItem as MenuItem_, MenuItemProps as MenuItemProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type MenuItemProps = MenuItemProps_;

export const MenuItem = ({ className, children, ...rest }: MenuItemProps) => {
  /* prettier-ignore */
  const styles = [
    'cursor-pointer',
    'data-[disabled]:cursor-not-allowed',
    'gap-2',
    'outline-none',
    'p-3',
    'rounded-lg',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'hover:bg-gray-50',
  ];

  return (
    <MenuItem_ className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </MenuItem_>
  );
};
