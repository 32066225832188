import { useQuery } from '@tanstack/react-query';
import { extract } from 'letterparser';
import { memo, useMemo } from 'react';
import { Letter } from 'react-letter';

import { Loading } from '@/components/core';
import { toDocumentId, toFileId, useGetDocument, useGetFile } from '@/hooks/api';

import { Panel } from './Panel.tsx';

export type DetailsEmailPanelProps = {
  documentId: string;
};

const IDetailsEmailPanel = ({ documentId }: DetailsEmailPanelProps) => {
  const { data: doc } = useGetDocument(toDocumentId(documentId));
  const { data: blob } = useGetFile(toFileId(doc?.fileUrl));

  const { data: emailStr } = useQuery({
    queryKey: [documentId, 'text'],
    queryFn: async () => {
      if (!blob) throw Error('Blob is undefined');
      return await blob.text();
    },
  });

  const email = useMemo(() => (emailStr ? extract(emailStr) : undefined), [emailStr]);

  return (
    <Panel>
      {email?.html && email?.text ? (
        <Letter className="overflow-auto p-4" html={email.html} text={email.text} />
      ) : (
        <Loading label="Loading email..." />
      )}
    </Panel>
  );
};

export const DetailsEmailPanel = memo(IDetailsEmailPanel) as typeof IDetailsEmailPanel;
