import { ReactNode, useMemo } from 'react';
import { Input, InputProps, Label, LabelProps, SearchField, SearchFieldProps } from 'react-aria-components';

import SearchIcon from '@/assets/search-icon.svg?react';
import { merge } from '@/components';
import { Button, Text } from '@/components/core';
import { generateId } from '@/utils';

export type SearchProps = {
  autocompleteOptions?: string[];
  children?: ReactNode;
  clear?: () => void;
  props?: {
    input?: InputProps;
    label?: LabelProps;
    searchField?: SearchFieldProps;
  };
};

export const Search = ({ autocompleteOptions, children, clear, props }: SearchProps) => {
  const autocompleteId = useMemo(() => {
    return generateId();
  }, []);

  const { className: inputClassName, ...inputProps } = props?.input ?? {};
  const { className: labelClassName, ...labelProps } = props?.label ?? {};
  const { className: searchFieldClassName, ...searchFieldProps } = props?.searchField ?? {};

  return (
    <SearchField
      className={merge(
        'focus-within:ring-brand-400 flex flex-row items-center gap-2 rounded-lg border border-gray-200 px-2 text-sm font-normal text-gray-400 focus-within:ring-2',
        searchFieldClassName
      )}
      {...searchFieldProps}
    >
      <Label className={labelClassName} {...labelProps}>
        <SearchIcon />
      </Label>
      <Input
        list={autocompleteId}
        className={merge('outline-none [&::-webkit-search-cancel-button]:hidden', inputClassName)}
        {...inputProps}
      >
        {children}
      </Input>
      {clear && (!inputProps || inputProps.value) ? (
        <Button onPress={clear} variant="plain">
          <Text>✕</Text>
        </Button>
      ) : null}
      <datalist id={autocompleteId}>{autocompleteOptions?.map((a, i) => <option key={i} value={a} />)}</datalist>
    </SearchField>
  );
};
