import type { ProjectRunStatus } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { merge } from '@/components';
import { DivProps } from '@/utils';

export type ProjectRunStatusBadgeProps = DivProps & {
  status?: ProjectRunStatus;
};

const IProjectRunStatusBadge = ({ status, className, children, ...rest }: ProjectRunStatusBadgeProps) => {
  const statusStyles = [
    status === 'Pending predictions' && 'border-indigo-200 bg-indigo-50 text-indigo-600',
    status === 'Ready for review' && 'border-sky-200 bg-sky-50 text-sky-600',
    status === 'Exported' && 'border-green-200 bg-green-50 text-green-600',
  ];
  return (
    <div
      className={merge(
        'flex cursor-default items-center gap-2 rounded-full border px-2 py-1 text-xs',
        statusStyles,
        className
      )}
      {...rest}
    >
      {status}
      {children}
    </div>
  );
};

export const ProjectRunStatusBadge = memo(IProjectRunStatusBadge) as typeof IProjectRunStatusBadge;
