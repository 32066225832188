import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-aria-components';

import { merge } from '@/components';
import { Label, Text, TextArea } from '@/components/core';
import { useFormatter } from '@/hooks';
import { useModelConfigStore } from '@/store';
import { FieldContextType, FieldStateValueType } from '@/utils';

import { findField } from './utils';

export type FormatterTestProps = {
  fieldId: string;
  index: number;
} & HTMLAttributes<HTMLDivElement>;

const IFormatterTest = ({ fieldId, index, className, ...rest }: FormatterTestProps) => {
  const [rawValue, setRawValue] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState<string | undefined>(undefined);
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const formatter = field?.formatters?.[index];

  const { run } = useFormatter({ fieldId, index });

  const onInputChanged = useCallback(async (value: string | undefined) => {
    setErrorMessage('');
    setRawValue(value);

    if (!value) {
      setValue(' ');
    }
  }, []);

  useEffect(() => {
    const wrapper = async () => {
      if (!formatter || !rawValue) return;

      const fieldValue: FieldStateValueType = { rawValue, confidence: 1.0 };

      const context: FieldContextType = {
        name: formatter.name,
        fields: { fieldId: fieldValue },
        config: formatter.config,
      };

      try {
        const formatted = await run(fieldValue, context);
        if (formatted.value !== null) {
          setValue(formatted.value);
        }
      } catch (error) {
        if (error instanceof Error) {
          setValue(' ');
          setErrorMessage(error.message);
        }
      }
    };

    wrapper();
  }, [formatter, rawValue, run]);

  return (
    <div className={merge('flex flex-col gap-4', className)} {...rest}>
      <Text>Test formatter</Text>

      <div className="flex flex-col gap-4">
        <Text>Input</Text>
        <TextArea delay={500} onChange={onInputChanged} />

        <TextField className="flex w-full flex-col">
          <Label>Output</Label>
          <TextArea value={value} disabled />
          <Text slot="description" className="text-red-600">
            {errorMessage}
          </Text>
        </TextField>
      </div>
    </div>
  );
};

export const FormatterTest = memo(IFormatterTest) as typeof IFormatterTest;
