import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { InboxContext, InboxState } from './inboxStore';

export function useInboxStore<T>(selector: (state: InboxState) => T): T {
  const store = useContext(InboxContext);
  if (!store) throw new Error('Missing InboxContext.Provider in the tree');
  return useStore(store, selector);
}
