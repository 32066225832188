import { memo, ReactNode, useContext } from 'react';
import { DropZone as DropZone_, DropZoneProps as DropZoneProps_, FileTrigger } from 'react-aria-components';

import DropZoneIcon from '@/assets/drop-zone-icon.svg?react';
import { merge } from '@/components';
import { Button, DropZoneContext, Text } from '@/components/core';
import { formatFileSize } from '@/utils';

export const DropZoneButton = () => {
  const { acceptedMimeTypes, maxFileSize, onDropFiles } = useContext(DropZoneContext);

  return (
    <FileTrigger
      allowsMultiple
      acceptedFileTypes={acceptedMimeTypes}
      onSelect={(e) => {
        if (e) {
          let files = Array.from(e);
          if (acceptedMimeTypes) {
            files = files.filter((file) => acceptedMimeTypes.includes(file.type));
          }
          if (maxFileSize) {
            files = files.filter((file) => file.size <= maxFileSize);
          }
          onDropFiles?.(files);
        }
      }}
    >
      <Button className="flex h-full w-full flex-col" variant="plain">
        <DropZoneIcon className="size-16" />
        <Text>Click to upload or drag and drop</Text>
        {acceptedMimeTypes ? <Text size="xs">{acceptedMimeTypes.join(', ')}</Text> : null}
        {maxFileSize ? <Text size="xs">Max size: {formatFileSize(maxFileSize)}</Text> : null}
      </Button>
    </FileTrigger>
  );
};

export type DropZoneProps = Omit<DropZoneProps_, 'children'> & {
  acceptedMimeTypes?: string[];
  maxFileSize?: number;
  onDropFiles?: (files: File[]) => void;
  children?: ReactNode;
};

const IDropZone = ({
  acceptedMimeTypes,
  maxFileSize,
  onDropFiles,
  isDisabled,
  className,
  children,
  ...rest
}: DropZoneProps) => {
  return (
    <DropZone_
      className={merge(
        'flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200',
        className
      )}
      isDisabled={isDisabled}
      getDropOperation={(types) => {
        if (acceptedMimeTypes) {
          let hasSomeValidFiles = false;
          for (const acceptedMimeType of acceptedMimeTypes) {
            if (types.has(acceptedMimeType)) {
              hasSomeValidFiles = true;
            }
          }
          return hasSomeValidFiles ? 'copy' : 'cancel';
        }
        return 'copy';
      }}
      onDrop={async (e) => {
        let fileDropItems = e.items.filter((di) => di.kind === 'file');
        if (acceptedMimeTypes) {
          fileDropItems = fileDropItems.filter((fdi) => acceptedMimeTypes.includes(fdi.type));
        }
        let files = await Promise.all(fileDropItems.map((fdi) => fdi.getFile()));
        if (maxFileSize) {
          files = files.filter((file) => file.size <= maxFileSize);
        }
        onDropFiles?.(files);
      }}
      {...rest}
    >
      <DropZoneContext value={{ maxFileSize, onDropFiles, acceptedMimeTypes }}>{children}</DropZoneContext>
    </DropZone_>
  );
};

export const DropZone = memo(IDropZone) as typeof IDropZone;
