import type { CreateOrganizationOptions, Organization } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type OrganizationId = { organizationId: string };

export const {
  getOptions: getOrganizationOptions,
  listOptions: listOrganizationOptions,
  useAutoUpdate: useAutoUpdateOrganization,
  useCreate: useCreateOrganization,
  useGet: useGetOrganization,
  useList: useListOrganizations,
  useSuspenseGet: useSuspenseGetOrganization,
} = create<Organization, OrganizationId, CreateOrganizationOptions>({
  cacheKey: 'organizations',
  equal: (organization, id) =>
    Boolean(organization && id?.organizationId && organization.organizationId === id.organizationId),
  createId: (organization) => (organization ? { organizationId: organization.organizationId } : null),
  create: (client, organization) => client.createOrganization(organization),
  get: (client, id) => client.getOrganization(id.organizationId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .makeGetRequest('/organizations')
        .then((response: JSONType) => {
          resolve({ data: response.organizations, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateOrganization(id.organizationId, updates),
});

export const toOrganizationId = (organizationId?: string | null): OrganizationId | null => {
  return organizationId ? { organizationId } : null;
};
