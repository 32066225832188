import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { useModelConfigStore } from '@/store';

export const useFormatterMutation = (fieldId: string, index: number, parentId?: string | null) => {
  const updateFormatter = useModelConfigStore((state) => state.updateFormatter);

  return useCallback(
    (mutateFn: (v: FieldValidator) => void) => {
      updateFormatter(
        fieldId,
        index,
        (formatter) => {
          mutateFn(formatter);
          return formatter;
        },
        parentId
      );
    },
    [fieldId, index, parentId, updateFormatter]
  );
};

export const useValidatorMutation = (fieldId: string, index: number, parentId?: string | null) => {
  const updateValidator = useModelConfigStore((state) => state.updateValidator);

  return useCallback(
    (mutateFn: (v: FieldValidator) => void) => {
      updateValidator(
        fieldId,
        index,
        (validator) => {
          mutateFn(validator);
          return validator;
        },
        parentId
      );
    },
    [fieldId, index, parentId, updateValidator]
  );
};
