import { memo } from 'react';

import CradlIcon from '@/assets/cradl-logo.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type CradlLogoProps = LogoProps;

const ICradlLogo = ({ className, ...rest }: CradlLogoProps) => {
  return (
    <Logo className={merge('rounded-lg border border-gray-200 bg-white', className)} {...rest}>
      <CradlIcon className="size-min-5" />
    </Logo>
  );
};

export const CradlLogo = memo(ICradlLogo) as typeof ICradlLogo;
