import { useAuth } from './useAuth.ts';
import { useGlobalAuth } from './useGlobalAuth.ts';

export type UseClientOpts = object;

// eslint-disable-next-line no-empty-pattern
export const useClient = ({}: UseClientOpts = {}) => {
  const auth = useAuth();
  const globalAuth = useGlobalAuth();
  const client = auth.client ?? globalAuth.client;
  return { client };
};
