import { memo, useMemo } from 'react';

import { Text } from '@/components/core';
import { ValidationLogo } from '@/components/flow';
import { MultiProfileBadge } from '@/components/ui';
import { toValidationId, useGetValidation, useListUsers } from '@/hooks/api';

export type ValidationNodeProps = {
  validationId?: string;
};

export const IValidationNode = ({ validationId }: ValidationNodeProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: validation } = useGetValidation(toValidationId(validationId));
  const { data: users } = useListUsers();
  const profileIds = useMemo(() => users?.data.map((u) => u.profileId), [users]);

  return (
    <div className="w-72">
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <ValidationLogo size="lg" />
        <Text className="font-semibold" size="md">
          Review by human
        </Text>
      </div>
      <div className="gap-2 pt-2">
        <Text className="flex flex-row flex-wrap items-center gap-2 text-gray-600" size="xs">
          If flagged send to: <MultiProfileBadge profileIds={profileIds} />
        </Text>
      </div>
    </div>
  );
};

export const ValidationNode = memo(IValidationNode) as typeof IValidationNode;
