import { useLocation, useNavigate } from '@tanstack/react-router';

import AIModelIcon from '@/assets/ai-model-icon.svg?react';
import AnalyticsIcon from '@/assets/analytics-icon.svg?react';
import ChatIcon from '@/assets/chat-icon.svg?react';
import DocsIcon from '@/assets/docs-icon.svg?react';
import DotsIcon from '@/assets/dots-icon.svg?react';
import InboxIcon from '@/assets/inbox-icon.svg?react';
import { Hr, Loading, Text, WarningSymbol } from '@/components/core';
import { NavigationBar as NavBar, NavigationBarItem as Item } from '@/components/ui';
import { useGetNotConfiguredExports, useGetProjectFromPath, useTour } from '@/hooks';
import { toModelId, useGetModel } from '@/hooks/api';
import { findModelId } from '@/utils';

import { ProjectsMenu } from './ProjectsMenu';
import { UpgradeCard } from './UpgradeCard';
import { WorkspaceCard } from './WorkspaceCard';

export const NavigationBar = () => {
  const { data: project } = useGetProjectFromPath();
  const navigate = useNavigate();
  const location = useLocation();
  const { actions } = useGetNotConfiguredExports({ projectId: project?.projectId });
  const { data: model } = useGetModel(toModelId(findModelId(project)));
  const { showReviewPredictionsTooltip } = useTour();

  if (!project) {
    return <Loading label="Loading ..." />;
  }

  const flowSetupRequired =
    !showReviewPredictionsTooltip && (actions.length || Object.entries(model?.fieldConfig ?? {}).length < 1);

  return (
    <NavBar>
      <WorkspaceCard className="h-14 border-b border-gray-200" organizationId="me" />
      <div className="space-y-2 px-2">
        <div className="flex">
          <Text size="md">{project?.name}</Text>
          <div className="grow" />
          <ProjectsMenu>
            <DotsIcon className="fill-gray-400" />
          </ProjectsMenu>
        </div>
        <ul className="px-2">
          <Item
            selected={location.pathname.endsWith('inbox')}
            onClick={async () =>
              await navigate({ to: '/projects/$projectId/inbox', params: { projectId: project.projectId } })
            }
            label="Inbox"
          >
            <InboxIcon className="fill-gray-400" />
          </Item>
          <Item
            selected={location.pathname.endsWith('integration')}
            onClick={async () =>
              await navigate({ to: '/projects/$projectId/integration', params: { projectId: project.projectId } })
            }
            label="Flow"
            rightSection={flowSetupRequired ? <WarningSymbol /> : null}
          >
            <AIModelIcon />
          </Item>
          <Item
            selected={location.pathname.endsWith('analytics')}
            onClick={async () =>
              await navigate({ to: '/projects/$projectId/analytics', params: { projectId: project.projectId } })
            }
            label="Insights"
          >
            <AnalyticsIcon />
          </Item>
        </ul>
      </div>
      <div className="flex-grow" />
      <div className="space-y-2 px-2">
        <ul className="px-2">
          <Hr className="my-4" />
          <Item label="Documentation" onClick={() => window.open('https://docs.cradl.ai', '_blank')}>
            <DocsIcon />
          </Item>
          <Item label="Chat with an expert" onClick={() => window._chatlio?.open()}>
            <ChatIcon />
          </Item>
        </ul>
        <Text className="px-2 text-end text-gray-300" size="xs">
          app version: {(import.meta.env.VITE_CURRENT_COMMIT_SHA ?? '').substring(0, 7) || 'dev'}
        </Text>
      </div>
      <Hr className="my-4" />
      <UpgradeCard organizationId="me" />
    </NavBar>
  );
};
