import { useCallback } from 'react';

import { JSONType } from '@/utils';

import { useCreateExport, UseCreateExportOpts } from './useCreateExport';

export const useCreateJSONExport = ({ projectId, runIds }: UseCreateExportOpts) => {
  const exports = useCreateExport({ projectId, runIds });

  const create = useCallback(async () => {
    const json: JSONType = exports.filter((e) => e);

    return new Blob([JSON.stringify(json, null, 2)], {
      type: 'application/json;charset=utf-8',
    });
  }, [exports]);

  return { create };
};
