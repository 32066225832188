import { memo } from 'react';

import ValidationIcon from '@/assets/validation-fill-icon.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type ValidationLogoProps = LogoProps;

const IValidationLogo = ({ className, ...rest }: ValidationLogoProps) => {
  return (
    <Logo className={merge('bg-amber-400', className)} {...rest}>
      <ValidationIcon className="size-4 fill-white" />
    </Logo>
  );
};

export const ValidationLogo = memo(IValidationLogo) as typeof IValidationLogo;
