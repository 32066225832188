import type { CreateFunctionOptions, Function } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type FunctionId = { functionId: string };

export const { useBatchGet: useBatchGetFunctions, useGet: useGetFunction } = create<
  Function,
  FunctionId,
  CreateFunctionOptions
>({
  cacheKey: 'functions',
  equal: (fn, id) => Boolean(fn && id?.functionId && fn.functionId === id.functionId),
  createId: (fn) => (fn ? { functionId: fn.functionId } : null),
  create: (client, fn) => client.createFunction(fn),
  delete: (client, id) => client.deleteFunction(id.functionId),
  get: (client, id) => client.getFunction(id.functionId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listFunctions()
        .then((response: JSONType) => {
          resolve({ data: response.functions, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateFunction(id.functionId, updates),
});

export const toFunctionId = (functionId?: string | null): FunctionId | null => {
  return functionId ? { functionId } : null;
};
