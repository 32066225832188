import { memo } from 'react';

import { merge } from '@/components';
import { Text } from '@/components/core';
import { useImageSrc } from '@/hooks';
import { toProfileId, useGetProfile } from '@/hooks/api';
import { DivProps, OmitChildren } from '@/utils';

export type ProfileBadgeProps = OmitChildren<DivProps> & {
  profileId: string;
};

const IProfileBadge = ({ profileId, className, ...rest }: ProfileBadgeProps) => {
  const { data: profile } = useGetProfile(toProfileId(profileId));
  const { src } = useImageSrc({ source: profile?.pictureUrl });

  if (!profile) return null;

  return (
    <div className={merge('flex gap-1 rounded-lg border border-gray-300 bg-gray-50 p-1', className)} {...rest}>
      <img className="size-4 rounded-full" alt={profile.profileId} src={src} />
      <Text>{profile.givenName}</Text>
    </div>
  );
};

export const ProfileBadge = memo(IProfileBadge) as typeof IProfileBadge;
