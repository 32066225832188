import { memo } from 'react';

import ExportIcon from '@/assets/export-icon.svg?react';
import { Logo, LogoProps } from '@/components/core';
import { ExcelLogo, PowerAutomateLogo, WebhookLogo, ZapierLogo } from '@/components/flow';
import {
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
} from '@/constants';

export type ExportLogoProps = LogoProps & {
  functionId?: string;
};

const IExportLogo = ({ functionId, ...rest }: ExportLogoProps) => {
  switch (functionId) {
    case EXPORT_TO_EXCEL_FUNCTION_ID:
      return <ExcelLogo {...rest} />;
    case EXPORT_TO_WEBHOOK_FUNCTION_ID:
      return <WebhookLogo {...rest} />;
    case EXPORT_TO_ZAPIER_FUNCTION_ID:
      return <ZapierLogo {...rest} />;
    case EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID:
      return <PowerAutomateLogo {...rest} />;
  }

  return (
    <Logo className="bg-purple-400" {...rest}>
      <ExportIcon className="size-4 fill-white" />
    </Logo>
  );
};

export const ExportLogo = memo(IExportLogo) as typeof IExportLogo;
