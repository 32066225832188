import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { Login } from '@/features/auth';

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  component: Login,
});
