import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';

import { Slider, SliderProps } from '@/components/core';

export type DebouncedSliderProps = Omit<SliderProps, 'onChange' | 'value'> & {
  value: number | number[];
  delay?: number;
  onChange?: (value: number | number[]) => void;
};

export const DebouncedSlider = ({ id, className, value, delay = 1000, onChange, ...rest }: DebouncedSliderProps) => {
  const [value_, setValue_] = useState<number | number[]>(value);
  const debouncedValue = useDebounce(value_, delay);

  useEffect(() => {
    setValue_(value);
  }, [id, value]);

  useEffect(() => {
    onChange?.(debouncedValue);
    // Adding onChange to deps will cause infinite recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return <Slider id={id} className={className} onChange={(newValue) => setValue_(newValue)} value={value_} {...rest} />;
};
