import type { FieldFormatter, Function } from '@lucidtech/las-sdk-browser';
import { useCallback, useMemo } from 'react';

import { findField } from '@/components/ui';
import { toFileId, toFunctionId, useGetFile, useGetFunction } from '@/hooks/api';
import { useModelConfigStore } from '@/store';
import { FieldContextType, FieldStateValueType, FormatterFnType, importJS } from '@/utils';

type FormatterResolved = FieldFormatter & {
  function?: Function;
  transform?: FormatterFnType;
};

export type UseFormatterOpts = {
  fieldId: string;
  index: number;
};

export const useFormatter = ({ fieldId, index }: UseFormatterOpts) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const { data: formatterFn } = useGetFunction(toFunctionId(field?.formatters?.[index]?.functionId));
  const { data: formatterBlob } = useGetFile(toFileId(formatterFn?.fileUrl));

  const formatter = useMemo(async () => {
    if (!field?.formatters) return;

    const fmt: FormatterResolved = { ...field.formatters[index] };

    if (fmt && formatterBlob) {
      const codeModule = await importJS(formatterBlob);
      fmt.transform = codeModule.default;
    }

    return fmt;
  }, [field?.formatters, index, formatterBlob]);

  const run = useCallback(
    async (state: FieldStateValueType, context: FieldContextType): Promise<FieldStateValueType> => {
      const fmt = await formatter;
      if (fmt && fmt.transform) {
        const formatterContext = structuredClone(context);
        formatterContext.config = structuredClone(fmt.config);
        const { result } = fmt.transform(structuredClone(state), formatterContext);

        if (result) {
          state = result;
        }
      }

      return state;
    },
    [formatter]
  );

  return { run };
};
