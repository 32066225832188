import { memo, useCallback } from 'react';
import { FileTrigger } from 'react-aria-components';

import ImportIcon from '@/assets/import-icon.svg?react';
import { Button, DebouncedInput, Label, Text, TextField } from '@/components/core';
import { PROFILE_PICTURE_MIME_TYPES } from '@/constants';
import { useImageSrc } from '@/hooks';
import { useOrganizationStore } from '@/store';

export type WorkspaceDetailsProps = object;

// eslint-disable-next-line no-empty-pattern
const IWorkspaceDetails = ({}: WorkspaceDetailsProps) => {
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const name = useOrganizationStore((state) => state.name);
  const pictureUrl = useOrganizationStore((state) => state.pictureUrl);
  const picture = useOrganizationStore((state) => state.picture);
  const { src } = useImageSrc({ source: pictureUrl ?? picture });
  const update = useOrganizationStore((state) => state.update);

  const onSelect = useCallback(
    (fileList: FileList | null) => {
      if (!fileList) return;
      const files = Array.from(fileList);
      if (!files) return;
      update({ picture: files[0] });
    },
    [update]
  );

  return (
    <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
      <div className="flex items-center gap-4">
        <img className="size-16 rounded-lg" alt={organizationId ?? undefined} src={src} />
        <div className="flex flex-col gap-1">
          <Label>Company Logo</Label>
          <FileTrigger allowsMultiple={false} acceptedFileTypes={PROFILE_PICTURE_MIME_TYPES} onSelect={onSelect}>
            <div>
              <Button>
                <ImportIcon className="size-5 stroke-gray-200" />
                Upload
              </Button>
            </div>
          </FileTrigger>
          <Text size="xs">{PROFILE_PICTURE_MIME_TYPES.join(', ')}</Text>
        </div>
      </div>
      <TextField className="flex flex-col gap-1" defaultValue={name ?? undefined}>
        <Label>Workspace name</Label>
        <DebouncedInput id="name" onChange={(e) => update({ name: e.target.value })} />
      </TextField>
    </div>
  );
};

export const WorkspaceDetails = memo(IWorkspaceDetails) as typeof IWorkspaceDetails;
