import { memo, useMemo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import ChevronIcon from '@/assets/chevron-down-icon.svg?react';
import { merge } from '@/components';
import { Button, ComingSoon, Hr, Label, Menu, MenuItem, Popover, Text } from '@/components/core';
import { findField, FormatterValidatorHeader } from '@/components/ui';
import { useFormatterMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';
import { toString } from '@/utils';

import { FormatterTest } from './FormatterTest';

const DAY_MONTH_ORDER = ['MDY', 'DMY'] as const;
type DayMonthOrder = (typeof DAY_MONTH_ORDER)[number];
const SUPPORTED_DAY_MONTH_ORDERS: DayMonthOrder[] = ['MDY', 'DMY'];
const DATE_FORMAT_STRING = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD.MM.YYYY'] as const;
type DateFormatString = (typeof DATE_FORMAT_STRING)[number];
const SUPPORTED_DATE_FORMAT_STRING: DateFormatString[] = ['YYYY-MM-DD'];

export type DateFormatterProps = {
  fieldId: string;
  index: number;
};

const IDateFormatter = ({ fieldId, index }: DateFormatterProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const mutate = useFormatterMutation(fieldId, index, field?.parentId);
  const formatter = field?.formatters?.[index];

  const dayMonthOrder = useMemo(() => {
    let dayMonthOrder: DayMonthOrder = SUPPORTED_DAY_MONTH_ORDERS[0];
    const configDayMonthOrder = toString(formatter?.config.dayMonthOrder) as DayMonthOrder;
    if (configDayMonthOrder && DAY_MONTH_ORDER.includes(configDayMonthOrder)) {
      dayMonthOrder = configDayMonthOrder;
    }
    return dayMonthOrder;
  }, [formatter?.config.dayMonthOrder]);

  const dateFormatString = useMemo(() => {
    let dateFormatString: DateFormatString = SUPPORTED_DATE_FORMAT_STRING[0];
    const configDateFormatString = toString(formatter?.config.dateFormatString) as DateFormatString;
    if (configDateFormatString && DATE_FORMAT_STRING.includes(configDateFormatString)) {
      dateFormatString = configDateFormatString;
    }
    return dateFormatString;
  }, [formatter?.config.dateFormatString]);

  if (!formatter) return null;

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader formatter={formatter} />
      <Hr />

      <div className="flex grow flex-col gap-4">
        <Label>Select day month ordering for ambiguous dates</Label>
        <MenuTrigger>
          <Button variant="secondary">
            <Text className="flex-1 text-left">{dayMonthOrder}</Text>
            <ChevronIcon className="size-5 stroke-gray-400" />
          </Button>
          <Popover>
            <Menu>
              {DAY_MONTH_ORDER.map((order) => {
                const isSupported = SUPPORTED_DAY_MONTH_ORDERS.includes(order);
                return (
                  <MenuItem
                    className={merge('flex items-center gap-4', !isSupported && 'bg-gray-50')}
                    isDisabled={!isSupported}
                    key={order}
                    onAction={() => mutate((f) => (f.config.dayMonthOrder = order))}
                  >
                    <Text size="sm" className="flex-1">
                      {order}
                    </Text>
                    {!isSupported ? <ComingSoon /> : null}
                  </MenuItem>
                );
              })}
            </Menu>
          </Popover>
        </MenuTrigger>
        <Label>Select output date format</Label>
        <MenuTrigger>
          <Button variant="secondary">
            <Text className="flex-1 text-left">{dateFormatString}</Text>
            <ChevronIcon className="size-5 stroke-gray-400" />
          </Button>
          <Popover>
            <Menu>
              {DATE_FORMAT_STRING.map((format) => {
                const isSupported = SUPPORTED_DATE_FORMAT_STRING.includes(format);
                return (
                  <MenuItem
                    className={merge('flex items-center gap-4', !isSupported && 'bg-gray-50')}
                    isDisabled={!isSupported}
                    key={format}
                    onAction={() => mutate((f) => (f.config.dateFormatString = format))}
                  >
                    <Text size="sm" className="flex-1">
                      {format}
                    </Text>
                    {!isSupported ? <ComingSoon /> : null}
                  </MenuItem>
                );
              })}
            </Menu>
          </Popover>
        </MenuTrigger>
      </div>

      <FormatterTest fieldId={fieldId} index={index} />
    </div>
  );
};

export const DateFormatter = memo(IDateFormatter) as typeof IDateFormatter;
