import { ProjectRun } from '@lucidtech/las-sdk-browser';
import { useNavigate } from '@tanstack/react-router';
import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import { memo, useCallback, useMemo } from 'react';

import { merge } from '@/components';
import { TableRowProps } from '@/utils';

type RunsRowProps = {
  measureFn: (node: Element | null | undefined) => void;
  virtualRow: VirtualItem;
  row: Row<ProjectRun>;
  runId: string;
  selected: boolean;
  projectId: string;
  rowProps: TableRowProps | undefined;
};

const IRunsRow = ({ virtualRow, row, runId, selected, rowProps, measureFn, projectId }: RunsRowProps) => {
  const navigate = useNavigate();

  const cells = useMemo(
    () =>
      row.getVisibleCells().map((cell) => (
        <td className="flex" key={cell.id} style={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      )),
    [row]
  );

  const style = useMemo(() => ({ transform: `translateY(${virtualRow.start}px)` }), [virtualRow.start]);

  const goToRun = useCallback(async () => {
    await navigate({
      to: '/details/projects/$projectId/runs/$runId',
      params: { projectId: projectId, runId },
      search: { showPanel: 'project-runs' },
    });
  }, [navigate, projectId, runId]);

  const { className, ...rest } = rowProps ?? {};

  return (
    <tr
      className={merge(
        'absolute flex w-full cursor-pointer items-center border-b border-gray-200 bg-white px-8 py-3 text-sm text-gray-700 hover:bg-gray-50',
        selected && 'bg-gray-100',
        className
      )}
      data-index={virtualRow.index}
      ref={measureFn}
      key={virtualRow.index}
      style={style}
      onClick={goToRun}
      {...rest}
    >
      {cells}
    </tr>
  );
};

export const RunsRow = memo(IRunsRow) as typeof IRunsRow;
