import { PropsWithChildren, useRef } from 'react';

import { createInboxStore, InboxContext, InboxProps, InboxStore } from './inboxStore';

type InboxProviderProps = PropsWithChildren<Partial<InboxProps>> & {
  projectId: string;
};

export const InboxProvider = ({ children, projectId, ...props }: InboxProviderProps) => {
  const storeRef = useRef<InboxStore>(null);

  if (!storeRef.current) {
    storeRef.current = createInboxStore(projectId, props);
  }

  return <InboxContext.Provider value={storeRef.current}>{children}</InboxContext.Provider>;
};
