import type { Invoice, PaymentMethod } from '@lucidtech/las-sdk-browser';
import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import DownloadIcon from '@/assets/download-icon.svg?react';
import { Badge, Button, Header, Label, Table, Text } from '@/components/core';
import { CreditCard } from '@/components/ui';
import { useDeletePaymentMethod, useListInvoices, useListPaymentMethods } from '@/hooks/api';
import { useOrganizationStore } from '@/store';
import { formatDate, Id } from '@/utils';

import { UpgradeCard } from '../projects/UpgradeCard.tsx';
import { AddPaymentMethod } from './AddPaymentMethod.tsx';

export type BillingDetailsProps = {
  organizationId: string;
};

const IBillingDetails = ({ organizationId }: BillingDetailsProps) => {
  const paymentMethodId = useOrganizationStore((state) => state.paymentMethodId);
  const update = useOrganizationStore((state) => state.update);
  const { data: paymentMethods } = useListPaymentMethods();
  const { mutate: deletePaymentMethod } = useDeletePaymentMethod();
  const { data: invoices } = useListInvoices();

  const paymentMethodsColumns = useMemo<ColumnDef<PaymentMethod & Id>[]>(() => {
    return [
      {
        accessorKey: 'details',
        cell: ({ row }) => {
          return <CreditCard details={row.original.details} />;
        },
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        accessorKey: 'paymentMethodId',
        cell: ({ row }) => {
          if (row.original.paymentMethodId === paymentMethodId) {
            return <Badge variant="info" label="active" />;
          }
          const details = row.original.details;
          if (!details) return null;
          const expires = new Date(details.expYear, details.expMonth);
          if (expires < new Date()) {
            return <Badge variant="error" label="expired" />;
          }
        },
        size: 0,
      },
    ];
  }, [paymentMethodId]);

  const paymentMethodsData = useMemo(() => {
    return paymentMethods?.data.map((p) => ({ ...p, id: p.paymentMethodId })) ?? [];
  }, [paymentMethods]);

  const invoicesColumns = useMemo<ColumnDef<Invoice & Id>[]>(() => {
    return [
      {
        accessorKey: 'invoiceId',
        header: 'ID',
      },
      {
        accessorKey: 'amount',
        header: 'Billed amount',
        cell: ({ row }) => {
          return <Text>${row.original.amount}</Text>;
        },
      },
      {
        accessorKey: 'createdTime',
        header: 'Date',
        cell: ({ row }) => {
          return (
            <Text className="py-2" size="sm">
              {formatDate(row.original.createdTime)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          switch (row.original.status) {
            case 'paid':
              return <Badge variant="success" label={row.original.status} />;
            case 'failed':
              return <Badge variant="error" label={row.original.status} />;
            case 'pending':
              return <Badge variant="info" label={row.original.status} />;
          }
        },
        size: 0,
      },
    ];
  }, []);

  const invoicesData = useMemo(() => {
    return invoices?.data.map((i) => ({ ...i, id: i.invoiceId })) ?? [];
  }, [invoices]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <div className="flex grow flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
          <Label>Free plan</Label>
          <Text size="sm">$0 per month</Text>
          <div className="grow" />
          <Button>Explore plans</Button>
        </div>
        <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4 pb-2">
          <UpgradeCard className="w-[16rem]" organizationId={organizationId} />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col">
          <Header size="xl">Payment methods</Header>
          <Text size="md">Update your payment method</Text>
        </div>
        <div className="grow" />
      </div>

      <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
        <Table
          columns={paymentMethodsColumns}
          data={paymentMethodsData}
          hideDragHandle
          hideHeader
          options={[
            {
              onAction: (row) => update({ paymentMethodId: row.original.paymentMethodId }),
              children: <Text size="sm">Set as active</Text>,
            },
          ]}
          deleteRowAction={(row) => deletePaymentMethod({ paymentMethodId: row.original.paymentMethodId })}
          props={{ row: { className: 'hover:bg-gray-50 rounded-lg' } }}
        />
      </div>

      <div>
        <AddPaymentMethod />
      </div>

      <div className="flex items-center">
        <div className="flex flex-col">
          <Header size="xl">History</Header>
          <Text size="md">View your past invoices and payment history</Text>
        </div>
        <div className="grow" />
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
        <Table
          columns={invoicesColumns}
          data={invoicesData}
          hideDragHandle
          options={[
            {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onAction: (_) => {
                // TODO: Implement file download
                console.log('Download invoice file');
              },
              children: (
                <div className="flex gap-2">
                  <DownloadIcon className="stroke-gray-400" />
                  <Text size="sm">Download</Text>
                </div>
              ),
            },
          ]}
          props={{ row: { className: 'hover:bg-gray-50 rounded-lg' } }}
        />
      </div>
    </div>
  );
};

export const BillingDetails = memo(IBillingDetails) as typeof IBillingDetails;
