import { memo } from 'react';

import VerticalBarChartIcon from '@/assets/vertical-bar-chart-icon.svg?react';
import { Header, Logo, Text } from '@/components/core';

export type InsightsProps = object;

// eslint-disable-next-line no-empty-pattern
const IInsights = ({}: InsightsProps) => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
      <Logo className="border border-gray-200 bg-white" size="xl">
        <VerticalBarChartIcon className="stroke-gray-500" />
      </Logo>
      <Header size="xl">Insights are coming soon</Header>
      <Text className="w-96">
        Insights is currently only available on our enterprise plan, but we are working on a version for everybody
      </Text>
      <Text>- stay tuned.</Text>
    </div>
  );
};

export const Insights = memo(IInsights) as typeof IInsights;
