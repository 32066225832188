import { memo } from 'react';
import { Focusable } from 'react-aria-components';

import InfoIcon from '@/assets/info-icon.svg?react';
import { Text, Tooltip, TooltipProps } from '@/components/core';
import { FormatterWarning } from '@/utils';

export type WarningPredictionTooltipProps = Omit<TooltipProps, 'tooltip'> & {
  formattedValue?: string | null;
  confidence?: number | null;
  warnings: FormatterWarning[];
};

const IWarningPredictionTooltip = ({
  formattedValue,
  warnings,
  placement = 'left top',
  ...rest
}: WarningPredictionTooltipProps) => {
  return (
    <Tooltip
      className="bg-white text-white shadow-lg"
      placement={placement}
      tooltip={
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 border-b border-gray-200 pb-2">
            <InfoIcon className="!rotate-0 stroke-amber-500" />
            <Text className="font-semibold">Failed validation</Text>
          </div>
          {warnings.map((w) => (
            <div key={w.id} className="flex items-center gap-2">
              <InfoIcon className="size-4 !rotate-0 fill-amber-500 stroke-white" />
              <Text size="sm">{w.warnings.join(', ')}</Text>
            </div>
          ))}
          <Text size="sm">If you choose to ignore these warnings, the following value will be exported:</Text>
          <Text className="w-fit rounded-lg border border-gray-200 px-1">{formattedValue}</Text>
        </div>
      }
      {...rest}
    >
      <Focusable>
        <InfoIcon className="fill-amber-50 stroke-amber-300" />
      </Focusable>
    </Tooltip>
  );
};

export const WarningPredictionTooltip = memo(IWarningPredictionTooltip) as typeof IWarningPredictionTooltip;
