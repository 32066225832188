import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { OrganizationContext, OrganizationState } from './organizationStore';

export function useOrganizationStore<T>(selector: (state: OrganizationState) => T): T {
  const store = useContext(OrganizationContext);
  if (!store) throw new Error('Missing OrganizationContext.Provider in the tree');
  return useStore(store, selector);
}
