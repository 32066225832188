import { useReactFlow } from '@xyflow/react';
import { memo, useCallback, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Text } from '@/components/core';
import { ExportOption, NewExportModal } from '@/components/flow';
import { useAddExport } from '@/hooks';

export type EmptyExportNodeContentProps = {
  projectId: string;
};

const IEmptyExportNodeContent = ({ projectId }: EmptyExportNodeContentProps) => {
  const [newExportModelOpened, setNewExportModalOpened] = useState(false);
  const builder = useReactFlow();
  const onSuccess = useCallback(() => {
    builder.setNodes((nodes) => nodes);
    setNewExportModalOpened(false);
  }, [builder]);
  const { addExport, isPending: isAddingExport } = useAddExport({ onSuccess });

  const onExportOptionSelected = useCallback(
    async (exportOption: ExportOption) => {
      await addExport({ projectId, exportOption });
    },
    [addExport, projectId]
  );

  return (
    <DialogTrigger>
      <Button
        variant="plain"
        className="flex h-24 w-full items-center justify-center gap-2 rounded-lg border-2 border-dashed"
        onPress={() => setNewExportModalOpened(true)}
      >
        <PlusIcon className="stroke-gray-500" />
        <Text>Add export</Text>
      </Button>
      <NewExportModal
        isAddingExport={isAddingExport}
        isOpen={newExportModelOpened}
        onExportOptionSelected={onExportOptionSelected}
        onOpenChange={() => setNewExportModalOpened(false)}
      />
    </DialogTrigger>
  );
};

export const EmptyExportNodeContent = memo(IEmptyExportNodeContent) as typeof IEmptyExportNodeContent;
