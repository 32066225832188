import type { Action, FieldConfig, Hook, Model, Project } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { CREATE_PREDICTION_FUNCTION_ID, HOOK_EVALUATE_DOCUMENT } from '@/constants';
import { toProjectId, useCreateAction, useCreateHook, useCreateModel, useUpdateProject } from '@/hooks/api';

export type UseAddModelOnSuccess = ({
  model,
  action,
  hook,
  project,
}: {
  model: Model;
  action: Action;
  hook: Hook;
  project: Project;
}) => void;

export type UseAddModelOnError = ({ error }: { error: Error }) => void;

export type UseAddModelOpts = {
  onSuccess?: UseAddModelOnSuccess;
  onError?: UseAddModelOnError;
};

export const useAddModel = (opts?: UseAddModelOpts) => {
  const { onSuccess: onSuccessFnBase, onError: onErrorFnBase } = opts ?? {};

  const {
    mutateAsync: updateProject,
    isPending: isPendingUpdateProject,
    isSuccess: isSuccessUpdateProject,
    error: errorUpdateProject,
  } = useUpdateProject();

  const {
    mutateAsync: createAction,
    isPending: isPendingCreateAction,
    isSuccess: isSuccessCreateAction,
    error: errorCreateAction,
  } = useCreateAction();

  const {
    mutateAsync: createModel,
    isPending: isPendingCreateModel,
    isSuccess: isSuccessCreateModel,
    error: errorCreateModel,
  } = useCreateModel();

  const {
    mutateAsync: createHook,
    isPending: isPendingCreateHook,
    isSuccess: isSuccessCreateHook,
    error: errorCreateHook,
  } = useCreateHook();

  const addModel = useCallback(
    async ({
      projectId,
      name,
      fieldConfig,
      onSuccess: onSuccessFn,
      onError: onErrorFn,
    }: {
      projectId: string;
      name?: string;
      fieldConfig: FieldConfig;
      onSuccess?: UseAddModelOnSuccess;
      onError?: UseAddModelOnError;
    }) => {
      const onSuccess: UseAddModelOnSuccess = (...args) => {
        onSuccessFnBase?.(...args);
        onSuccessFn?.(...args);
      };
      const onError: UseAddModelOnError = (...args) => {
        onErrorFnBase?.(...args);
        onErrorFn?.(...args);
      };

      try {
        const model = await createModel({
          fieldConfig: fieldConfig,
          name: name,
        });

        const action = await createAction({
          config: { modelId: model.modelId },
          functionId: CREATE_PREDICTION_FUNCTION_ID,
          projectId: projectId,
        });

        const hook = await createHook({
          functionId: HOOK_EVALUATE_DOCUMENT,
          config: {},
          projectId: projectId,
          trigger: 'Document is Created',
          trueActionId: action.actionId,
        });

        const project = await updateProject({
          id: toProjectId(projectId),
          updates: (project: Project) => ({
            resourceIds: [...project.resourceIds, model.modelId, action.actionId, hook.hookId],
          }),
        });

        onSuccess?.({ model, action, hook, project });

        return model;
      } catch (error) {
        if (error instanceof Error) {
          onError?.({ error });
        }
      }
    },
    [createAction, createHook, createModel, onErrorFnBase, onSuccessFnBase, updateProject]
  );

  return {
    addModel,
    isPending: isPendingCreateModel || isPendingCreateAction || isPendingCreateHook || isPendingUpdateProject,
    isSuccess: isSuccessCreateModel && isSuccessCreateAction && isSuccessCreateHook && isSuccessUpdateProject,
    errors: [errorCreateModel, errorCreateAction, errorCreateHook, errorUpdateProject].filter((e) => !!e),
  };
};
