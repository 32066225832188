import { useReactFlow } from '@xyflow/react';
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { ButtonContext, MenuTrigger } from 'react-aria-components';

import DotsIcon from '@/assets/dots-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Menu, MenuItem, MenuItemDelete, Popover, Save, Text } from '@/components/core';
import { NewTriggerModal, TriggerOption } from '@/components/flow';
import { useAddTrigger } from '@/hooks';
import { toProjectId, useDeleteAction, useSuspenseGetProject, useUpdateProject } from '@/hooks/api';

export type TriggerHeaderProps = {
  projectId: string;
  actionId: string;
  children?: ReactNode;
};

const ITriggerHeader = ({ projectId, actionId, children }: TriggerHeaderProps) => {
  const [newTriggerModelOpened, setNewTriggerModalOpened] = useState(false);
  const { data: project } = useSuspenseGetProject(toProjectId(projectId));
  const builder = useReactFlow();
  const onSuccess = useCallback(() => {
    builder.setNodes((nodes) => nodes);
    setNewTriggerModalOpened(false);
  }, [builder]);
  const { addTrigger, isPending: isAddingTrigger } = useAddTrigger({ onSuccess });

  const { mutate: updateProject, isPending: isPendingUpdateProject } = useUpdateProject(toProjectId(projectId));
  const { mutate: deleteAction, isPending: isPendingDeleteAction } = useDeleteAction();

  const deleteTrigger = useCallback(() => {
    const updates = { resourceIds: project.resourceIds.filter((r) => ![actionId].includes(r)) };
    updateProject(
      { id: toProjectId(projectId), updates },
      {
        onSuccess: () => {
          // TODO: Need to also delete hooks
          deleteAction(
            { actionId },
            {
              onSuccess: () => {
                // TODO: Avoid force-triggering rerender of flow
                builder.setNodes((nodes) => nodes);
              },
            }
          );
        },
      }
    );
  }, [project.resourceIds, updateProject, projectId, actionId, deleteAction, builder]);

  const onTriggerOptionSelected = useCallback(
    async (triggerOption: TriggerOption) => {
      await addTrigger({ projectId, triggerOption });
    },
    [addTrigger, projectId]
  );

  const isDeleting = useMemo(
    () => isPendingDeleteAction || isPendingUpdateProject,
    [isPendingDeleteAction, isPendingUpdateProject]
  );

  // TODO: Figure out why uncontrolled doesn't work here
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {children}
      {isDeleting ? (
        <Save message="Deleting" />
      ) : (
        <MenuTrigger>
          <Button className="hover:bg-gray-100" variant="plain" onPress={() => setIsOpen(true)}>
            <DotsIcon className="size-4 rotate-90 fill-gray-400" />
          </Button>

          <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
            <Menu>
              <MenuItemDelete onAction={deleteTrigger} />
              <MenuItem className="flex items-center gap-2" onAction={() => setNewTriggerModalOpened(true)}>
                <PlusIcon className="size-4 stroke-gray-500" />
                <Text size="sm">New trigger</Text>
              </MenuItem>
            </Menu>
          </Popover>
        </MenuTrigger>
      )}
      <NewTriggerModal
        isAddingTrigger={isAddingTrigger}
        isOpen={newTriggerModelOpened}
        onOpenChange={() => setNewTriggerModalOpened(false)}
        onTriggerOptionSelected={onTriggerOptionSelected}
      />
    </>
  );
};

export const TriggerHeader = memo(ITriggerHeader) as typeof ITriggerHeader;
