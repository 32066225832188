import { memo } from 'react';
import { Tab as Tab_, TabProps as TabProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type TabProps = TabProps_;

const ITab = ({ className, children, ...rest }: TabProps) => {
  return (
    <Tab_
      className={merge(
        'flex cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-2 py-1 outline-none hover:bg-gray-50 data-[selected]:bg-gray-50',
        className
      )}
      {...rest}
    >
      {children}
    </Tab_>
  );
};

export const Tab = memo(ITab) as typeof ITab;
