import './index.css';

import { enableMapSet } from 'immer';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';

import { AuthProvider } from '@/features/auth';
import { GlobalAuthProvider } from '@/features/auth';

import { App } from './App';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
enableMapSet();

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <GlobalAuthProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </GlobalAuthProvider>
    </StrictMode>
  );
}
