const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const withRetry = async <T extends object>(
  fn: () => T,
  delay: number,
  errorCondition?: (error: Error) => boolean,
  numRetries: number = 4
): Promise<T> => {
  const cond = errorCondition ?? ((e: Error) => true);
  let error;

  for (let i = 0; i < numRetries + 1; ++i) {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof Error && cond(e)) {
        error = e;
        await sleep(delay * Math.pow(2, i));
      } else {
        throw e;
      }
    }
  }

  throw error;
};
