import { FieldApi } from '@tanstack/react-form';
import { memo, useCallback, useMemo } from 'react';

import { merge } from '@/components';
import { Input, Loading } from '@/components/core';
import {
  EnumMenu,
  ErrorPredictionTooltip,
  FieldInfo,
  FieldLabel,
  useValidation,
  ValidatedPredictionTooltip,
  WarningPredictionTooltip,
} from '@/components/ui';
import { FORMATTER_CLASSIFICATION_FUNCTION_ID } from '@/constants';
import { useFormatters, useValidators } from '@/hooks';
import { FieldValueState, FormValue, FormValueRow, FormValues, selectFromFieldConfig, useDocumentStore } from '@/store';
import {
  attentionMapToBlocks,
  FieldContextType,
  FieldMetaWithWarnings,
  FieldStateArrayType,
  FieldStateType,
  FieldStateValueType,
  FormatterError,
  FormatterWarning,
  toString,
} from '@/utils';

const toFieldState = (formValue: FormValue): FieldStateValueType => {
  return {
    rawValue: formValue.current.rawValue,
    value: formValue.current.value,
    confidence: formValue.current.confidence,
  };
};

type OnChangeAsync = {
  value: FormValue | FormValueRow[];
  fieldApi: FieldApi<FormValues, string, undefined, undefined, FormValue | FormValueRow[]>;
  signal: AbortSignal;
};

export type FieldProps = {
  hideLabel?: boolean;
  name: string;
  setSearch: (search?: string) => void;
  rowId: string;
};

const IField = ({ hideLabel = false, name, setSearch, rowId }: FieldProps) => {
  const FieldComponent = useDocumentStore((state) => state.form.Field);
  const formState = useDocumentStore((state) => state.form.state);
  const config = useDocumentStore(selectFromFieldConfig(name));
  const setFocusedField = useDocumentStore((state) => state.setFocusedField);
  const setAttention = useDocumentStore((state) => state.setAttention);
  const status = useDocumentStore((state) => state.status);

  const { run: runFormatters } = useFormatters({ name });
  const { run: runValidators } = useValidators({ name });

  const firstFormatter = useMemo(() => config?.formatters?.[0], [config]);
  const isEnum = useMemo(() => firstFormatter?.functionId === FORMATTER_CLASSIFICATION_FUNCTION_ID, [firstFormatter]);
  const enumValues = useMemo(() => firstFormatter?.config.labels, [firstFormatter]);

  const createContext = useCallback(() => {
    const fields: FieldStateType = {};

    for (const [name_, formValue_] of Object.entries(formState.values)) {
      if (Array.isArray(formValue_)) {
        const rows: FieldStateArrayType = [];
        for (const formRows of formValue_) {
          const row: Record<string, FieldStateValueType> = {};
          for (const [subName, subFormValue] of Object.entries(formRows.columns)) {
            row[subName] = toFieldState(subFormValue);
          }
          rows.push(row);
        }
        fields[name_] = rows;
      } else {
        fields[name_] = toFieldState(formValue_);
      }
    }
    const context: FieldContextType = {
      name: name,
      fields: fields,
      config: config,
    };

    return context;
  }, [config, formState.values, name]);

  const onChangeAsync = useCallback(
    async (field: OnChangeAsync) => {
      // TODO: Get rid of type assertion
      const formValue = field.value as FormValue;
      if (formValue.current.suppressFormattersAndValidators) return;

      const initialState = toFieldState(formValue);
      initialState.isTouched = field.fieldApi.getMeta().isTouched;
      initialState.value = undefined;

      const context = createContext();

      const {
        result: formattedState,
        errors: formattingErrors,
        warnings: formattingWarnings,
      } = await runFormatters(initialState, context);

      context.fields[name] = formattedState ?? {};

      const {
        result: validatedState,
        errors: validationErrors,
        warnings: validationWarnings,
      } = await runValidators(formattedState, context);

      const errors = [...(formattingErrors ?? []), ...(validationErrors ?? [])].map(
        (e, i) => ({ id: String(i), errors: [e] }) as FormatterError
      );
      const warnings = [...(formattingWarnings ?? []), ...(validationWarnings ?? [])].map(
        (w, i) => ({ id: String(i), warnings: [w] }) as FormatterWarning
      );

      field.fieldApi.setMeta((meta: FieldMetaWithWarnings) => ({
        ...meta,
        warnings,
        errors,
      }));

      if (errors.length > 0) {
        return errors;
      }

      field.fieldApi.setValue((current: FormValue) => ({
        ...current,
        ...formattedState,
        ...validatedState,
        suppressFormattersAndValidators: true,
      }));
    },
    [createContext, name, runFormatters, runValidators]
  );

  const { isTableView, hideTableView, scrollToPage, setDetachTable } = useValidation();

  return (
    <FieldComponent
      key={rowId}
      name={name}
      validators={{
        onChangeAsyncDebounceMs: 400,
        onChangeAsync: onChangeAsync,
      }}
      children={(field) => {
        // TODO: Get rid of this type assertion
        const formValue = field.state.value as FormValue;
        if (!formValue) {
          return <Loading label="field" />;
        }
        const errors = ((field.state.meta.errors ?? []) as FormatterError[][]).flat();
        const warnings = ((field.getMeta() as FieldMetaWithWarnings).warnings ?? []) as FormatterWarning[];

        return (
          <div className="flex flex-col gap-1">
            <FieldLabel name={config?.name || name} description={toString(config?.description)} hideLabel={hideLabel} />
            <div className="flex flex-col gap-1">
              {/* autoComplete="off": Disable autocomplete for plugins such as LastPass */}
              <Input
                autoComplete="off"
                disabled={status !== 'Ready for review'}
                props={{
                  container: { className: merge('gap-1', errors.length && 'border-red-500') },
                }}
                list={isEnum ? undefined : field.name}
                id={field.name}
                name={field.name}
                type="text"
                value={formValue.current?.rawValue ?? undefined}
                onBlur={field.handleBlur}
                onFocus={(e) => {
                  setAttention(attentionMapToBlocks(formValue.current.attentionMap));
                  setFocusedField(formValue.current);
                  setSearch(e.target.value);

                  // Hide the table view if a field which is not in the table form receives focus
                  if (!isTableView) {
                    hideTableView?.();
                  }

                  setDetachTable?.(false);

                  if (formValue.current?.page != null) {
                    scrollToPage?.(formValue.current.page);
                  }
                }}
                onChange={(e) => {
                  field.setValue({
                    ...formValue,
                    current: {
                      ...formValue.current,
                      rawValue: e.target.value,
                    },
                  });

                  setSearch(e.target.value);
                }}
              >
                {isEnum ? (
                  <EnumMenu
                    formValue={formValue}
                    enumValues={enumValues}
                    onAction={(fieldValue: FieldValueState) => {
                      field.setValue({
                        ...formValue,
                        current: {
                          ...structuredClone(fieldValue),
                          suppressFormattersAndValidators: false,
                        },
                      });
                    }}
                  />
                ) : null}
                {errors.length ? (
                  <ErrorPredictionTooltip warnings={warnings} errors={errors} />
                ) : warnings.length ? (
                  <WarningPredictionTooltip formattedValue={formValue.current.value} warnings={warnings} />
                ) : (
                  <ValidatedPredictionTooltip formattedValue={formValue.current.value} />
                )}
              </Input>
              <FieldInfo warnings={warnings} errors={errors} isValidating={field.state.meta.isValidating} />
            </div>
          </div>
        );
      }}
    />
  );
};

export const Field = memo(IField) as typeof IField;
