import { memo } from 'react';

import { Text } from '@/components/core';
import { TriggerHeader, ZapierLogo } from '@/components/flow';

export type ZapierTriggerNodeContentProps = {
  projectId: string;
  actionId: string;
};

const IZapierTriggerNodeContent = ({ projectId, actionId }: ZapierTriggerNodeContentProps) => {
  return (
    <>
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <TriggerHeader projectId={projectId} actionId={actionId}>
          <ZapierLogo size="lg" />
          <Text className="flex-1 font-semibold" size="md">
            Trigger from Zapier
          </Text>
        </TriggerHeader>
      </div>
      <div className="flex gap-2 pt-2">
        <Text className="flex items-center gap-1 rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
          Zapier
        </Text>
      </div>
    </>
  );
};

export const ZapierTriggerNodeContent = memo(IZapierTriggerNodeContent) as typeof IZapierTriggerNodeContent;
