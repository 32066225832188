import { memo, ReactNode } from 'react';
import {
  ProgressBar as ProgressBar_,
  ProgressBarProps as ProgressBarProps_,
  ProgressBarRenderProps,
} from 'react-aria-components';

import { merge } from '@/components';
import { DivProps, OmitChildren } from '@/utils';

export type ProgressBarProps = OmitChildren<ProgressBarProps_> & {
  children?: (props: ProgressBarRenderProps) => ReactNode;
  props?: {
    barOverlay?: DivProps;
    barBackground?: DivProps;
  };
};

const IProgressBar = ({ props, children, ...rest }: ProgressBarProps) => {
  const { className: barOverlayClassName, ...barOverlayProps } = props?.barOverlay ?? {};
  const { className: barBackgroundClassName, ...barBackgroundProps } = props?.barBackground ?? {};

  return (
    <ProgressBar_ {...rest}>
      {({ percentage, valueText, isIndeterminate }) => (
        <>
          {children?.({ percentage, valueText, isIndeterminate })}
          <div
            className={merge('bg-opacity-40 h-2 w-full transform rounded-full bg-gray-100', barBackgroundClassName)}
            {...barBackgroundProps}
          >
            <div
              className={merge('bg-brand-500 absolute top-0 h-2 transform rounded-full', barOverlayClassName)}
              style={{ width: percentage + '%' }}
              {...barOverlayProps}
            />
          </div>
        </>
      )}
    </ProgressBar_>
  );
};

export const ProgressBar = memo(IProgressBar) as typeof IProgressBar;
