import { memo, PropsWithChildren, ReactNode } from 'react';
import {
  OverlayArrow,
  Tooltip as Tooltip_,
  TooltipProps as TooltipProps_,
  TooltipTrigger,
} from 'react-aria-components';

import { merge } from '@/components';

export type TooltipProps = PropsWithChildren<TooltipProps_> & {
  isDisabled?: boolean;
  tooltip?: ReactNode;
};

const ITooltip = ({ isDisabled, tooltip, placement, className, children, offset = 8, ...rest }: TooltipProps) => {
  /* prettier-ignore */
  const styles  = [
    'px-3',
    'py-2',
    'rounded-lg',
    'max-w-96',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'bg-gray-700',
    'text-gray-700',
  ];

  return (
    <TooltipTrigger isDisabled={isDisabled} {...rest}>
      {children}
      <Tooltip_
        className={merge(
          'data-[placement=bottom]:[&_svg]:rotate-180 data-[placement=left]:[&_svg]:rotate-270 data-[placement=right]:[&_svg]:rotate-90',
          styles,
          lightStyles,
          className
        )}
        placement={placement}
        offset={offset}
      >
        <OverlayArrow
          className={merge(
            /* RAC already sets "top" or "left" so we need to override it */
            ['top left', 'bottom left'].includes(placement as string) && '!left-4',
            ['top right', 'bottom right'].includes(placement as string) && '!left-[100%] -translate-x-4',
            ['left top', 'right top'].includes(placement as string) && '!top-4',
            ['left bottom', 'right bottom'].includes(placement as string) && '!top-[100%] -translate-y-4'
          )}
        >
          <svg className="fill-current" width={8} height={8} viewBox="0 0 8 8">
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
        {tooltip}
      </Tooltip_>
    </TooltipTrigger>
  );
};

export const Tooltip = memo(ITooltip) as typeof ITooltip;
