import type { Action, CreateActionOptions } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type ActionId = { actionId: string };

export const {
  useAutoUpdate: useAutoUpdateAction,
  useBatchGet: useBatchGetActions,
  useCreate: useCreateAction,
  useDelete: useDeleteAction,
  useGet: useGetAction,
  useSuspenseBatchGet: useSuspenseBatchGetActions,
  useSuspenseGet: useSuspenseGetAction,
} = create<Action, ActionId, CreateActionOptions>({
  cacheKey: 'actions',
  equal: (action, id) => Boolean(action && id?.actionId && action.actionId === id.actionId),
  createId: (action) => (action ? { actionId: action.actionId } : null),
  create: (client, action) => client.createAction(action),
  delete: (client, id) => client.deleteAction(id.actionId),
  get: (client, id) => client.getAction(id.actionId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listActions()
        .then((response: JSONType) => {
          resolve({ data: response.actions, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateAction(id.actionId, updates),
});

export const toActionId = (actionId?: string | null): ActionId | null => {
  return actionId ? { actionId } : null;
};
