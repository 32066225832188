import { useNavigate } from '@tanstack/react-router';
import { memo } from 'react';

import { merge } from '@/components';
import { Badge, Button, Label, ProgressBar, Text } from '@/components/core';
import { useRudderAnalytics } from '@/hooks';
import { toOrganizationId, toPlanId, useGetOrganization, useGetPlan } from '@/hooks/api';
import { DivProps } from '@/utils';

export type UpgradeCardProps = DivProps & {
  organizationId: string;
};

const IUpgradeCard = ({ organizationId, className, ...rest }: UpgradeCardProps) => {
  const { data: organization } = useGetOrganization(toOrganizationId(organizationId));
  const { data: plan } = useGetPlan(toPlanId(organization?.planId));
  const navigate = useNavigate();
  const { track } = useRudderAnalytics();

  if (!organization || !plan) return null;

  return (
    <div className={merge('flex flex-col gap-4 p-2', className)} {...rest}>
      <Badge className="mr-auto rounded-lg" variant="info" label={plan.name} />
      <ProgressBar
        aria-label="free-plan-limit"
        className="flex flex-col gap-2"
        value={organization.monthlyNumberOfPagePredictionsUsed}
        maxValue={organization.monthlyNumberOfPagePredictionsAllowed}
      >
        {({ valueText }) => (
          <div className="flex">
            <Label className="flex-1">Predictions this month</Label>
            <Text size="xs">{valueText}</Text>
          </div>
        )}
      </ProgressBar>
      <Button onPress={track('Upgrade Clicked', () => navigate({ to: '/settings/billing' }))} variant="secondary">
        Upgrade
      </Button>
    </div>
  );
};

export const UpgradeCard = memo(IUpgradeCard) as typeof IUpgradeCard;
