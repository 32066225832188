import type { FieldFormatter } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { useComponentStack } from '@/components/core';
import {
  DEFAULT_FORMATTER_CATEGORY,
  findField,
  FormatterConfiguration,
  FormatterMenuItem,
  FORMATTERS,
  NavigationContainer,
  SearchPanel,
  SearchPanelProps,
} from '@/components/ui';
import { useModelConfigStore } from '@/store';

export type NewFormatterProps = Omit<SearchPanelProps<FieldFormatter>, 'renderItem' | 'searchableCategorizedData'> & {
  fieldId: string;
};

const INewFormatter = ({ fieldId, className, ...rest }: NewFormatterProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const addFormatter = useModelConfigStore((state) => state.addFormatter);
  const numFormatters = useModelConfigStore((state) => findField(state.fields, fieldId)?.formatters?.length);
  const componentStack = useComponentStack();

  return (
    <SearchPanel
      className={className}
      defaultCategory={DEFAULT_FORMATTER_CATEGORY}
      renderItem={(item) => (
        <FormatterMenuItem
          isDisabled={item.comingSoon}
          onAction={() => {
            // The index _after_ the new formatter has been added
            const index = numFormatters ?? 0;
            addFormatter(fieldId, item, field?.parentId);

            componentStack?.push(
              <NavigationContainer>
                <FormatterConfiguration fieldId={fieldId} index={index} />
              </NavigationContainer>
            );
          }}
          formatter={item}
        />
      )}
      searchableCategorizedData={FORMATTERS}
      {...rest}
    />
  );
};

export const NewFormatter = memo(INewFormatter) as typeof INewFormatter;
