import { Input as Input_, InputProps as InputProps_ } from 'react-aria-components';

import { merge } from '@/components';
import { DivProps } from '@/utils';

// XXX: defaultValue should be specified on the TextField - not on the Input element
export type InputProps = Omit<InputProps_, 'defaultValue'> & {
  props?: {
    container?: DivProps;
  };
};

export const Input = ({ className, children, props, ...rest }: InputProps) => {
  /* prettier-ignore */
  const containerStyles = [
    'bg-white',
    'border',
    'border-gray-200',
    'flex',
    'has-[:disabled]:bg-gray-100',
    'items-center',
    'outline-none',
    'p-2',
    'rounded-lg',
    'text-md',
    'w-full',
  ];
  /* prettier-ignore */
  const styles = [
    'grow',
    'bg-none',
    'data-[disabled]:cursor-not-allowed',
    'data-[disabled]:bg-gray-100',
    'outline-none',
    'text-md',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'text-gray-900',
  ];

  const { className: containerClassName, ...containerProps } = props?.container ?? {};

  return (
    <div className={merge(containerStyles, containerClassName)} {...containerProps}>
      <Input_ className={merge(styles, lightStyles, className)} {...rest} />
      {children}
    </div>
  );
};
