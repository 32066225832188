import { useLocalStorage } from '@uidotdev/usehooks';

import { createLocalStorageKey } from '@/utils';

export type UseTourOpts = {
  prefixes?: (string | null | undefined)[];
};

export const useTour = ({ prefixes }: UseTourOpts = {}) => {
  let keyPrefix = 'tour:';
  for (const prefix of prefixes ?? []) {
    if (prefix) {
      keyPrefix += `${prefix}:`;
    }
  }

  const [showSampleDocumentTooltip, setShowSampleDocumentTooltip] = useLocalStorage(
    createLocalStorageKey(`${keyPrefix}:showSampleDocumentTooltip`),
    true
  );
  const [showReviewPredictionsTooltip, setShowReviewPredictionsTooltip] = useLocalStorage(
    createLocalStorageKey(`${keyPrefix}:showReviewPredictionsTooltip`),
    true
  );
  const [showSetupAutomaticExportBanner, setShowSetupAutomaticExportBanner] = useLocalStorage(
    createLocalStorageKey(`${keyPrefix}:showSetupAutomaticExportBanner`),
    true
  );

  return {
    setShowReviewPredictionsTooltip,
    setShowSampleDocumentTooltip,
    setShowSetupAutomaticExportBanner,
    showReviewPredictionsTooltip,
    showSampleDocumentTooltip,
    showSetupAutomaticExportBanner,
  };
};
