import { memo } from 'react';

import { Input, InputProps } from '@/components/core';
import { useDebounceCallback } from '@/hooks';

export type DebouncedInputProps = InputProps & {
  delay?: number;
};

const IDebouncedInput = ({ className, delay = 1000, onChange, ...rest }: DebouncedInputProps) => {
  const onChangeDebounce = useDebounceCallback(onChange || (() => {}), delay);
  return <Input className={className} onChange={onChangeDebounce} {...rest} />;
};

export const DebouncedInput = memo(IDebouncedInput) as typeof IDebouncedInput;
