import type { CreateHookOptions, Hook } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type HookId = { hookId: string };

export const {
  useCreate: useCreateHook,
  useGet: useGetHook,
  useSuspenseBatchGet: useSuspenseBatchGetHooks,
  useSuspenseGet: useSuspenseGetHook,
} = create<Hook, HookId, CreateHookOptions>({
  cacheKey: 'hooks',
  equal: (hook, id) => Boolean(hook && id?.hookId && hook.hookId === id.hookId),
  createId: (hook) => (hook ? { hookId: hook.hookId } : null),
  create: (client, hook) => client.createHook(hook),
  delete: (client, id) => client.deleteHook(id.hookId),
  get: (client, id) => client.getHook(id.hookId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listHooks()
        .then((response: JSONType) => {
          resolve({ data: response.hooks, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateHook(id.hookId, updates),
});

export const toHookId = (hookId?: string | null): HookId | null => {
  return hookId ? { hookId } : null;
};
