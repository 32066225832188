import { useNodes } from '@xyflow/react';
import { memo } from 'react';

import { NodeType } from '@/components/flow';

import { ValidationPanelSlideOut } from './ValidationPanelSlideOut.tsx';

export type ValidationPanelWrapperProps = {
  projectId: string;
};

const IValidationPanelWrapper = ({ projectId }: ValidationPanelWrapperProps) => {
  const nodes = useNodes() as NodeType[];
  const selectedNode = nodes.find((node) => node.selected);
  const actionId = selectedNode?.data.resourceIds.find((r) => r.startsWith('las:action'));
  const validationId = selectedNode?.data.resourceIds.find((r) => r.startsWith('las:validation'));

  if (!actionId || !validationId || !selectedNode || selectedNode.data.resourceGroupType !== 'validation') {
    return null;
  }

  return <ValidationPanelSlideOut projectId={projectId} actionId={actionId} validationId={validationId} />;
};

export const ValidationPanelSlideOutWrapper = memo(IValidationPanelWrapper) as typeof IValidationPanelWrapper;
