import { RefObject, useLayoutEffect } from 'react';

import { AttentionBlock } from '@/store';

type Point = {
  x: number;
  y: number;
};

type Patch = {
  imageData: ImageData;
  rotation: number;
} & Point;

const rotatePoint = (point: Point, deg: number, p: Point = { x: 0.5, y: 0.5 }) => {
  const rad = (deg / 360) * 2 * Math.PI;

  const { x: x1, y: y1 } = p;
  const tx = point.x - x1;
  const ty = point.y - y1;

  const rx = tx * Math.cos(rad) - ty * Math.sin(rad);
  const ry = tx * Math.sin(rad) + ty * Math.cos(rad);

  return [rx + x1, ry + y1];
};

const calculateRect = (points: Point[], width: number, height: number) => {
  // Store old patch
  const xs = points.map((p) => p.x);
  const ys = points.map((p) => p.y);

  if (xs.length === 0 || ys.length === 0) return;

  const x = Math.floor(Math.min(...xs) * width);
  const y = Math.floor(Math.min(...ys) * height);
  const w = Math.ceil(Math.max(...xs) * width - x);
  const h = Math.ceil(Math.max(...ys) * height - y);

  return { x, y, w, h };
};

export const useAttentionMap = (
  canvasRef: RefObject<HTMLCanvasElement | null>,
  attention?: AttentionBlock[],
  rotation: number = 0,
  canDraw: boolean = true
) => {
  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });

    if (!attention || !canvas || !canDraw || !context) {
      return;
    }

    const { width, height } = canvas;

    const rotatedAttention = attention.map((block) => ({
      ...block,
      points: block.points.map((p) => {
        const [x, y] = rotatePoint(p, rotation);
        return { ...p, x, y };
      }),
    }));

    context.save();

    const patches: Patch[] = [];
    for (const block of rotatedAttention) {
      // Store old patch
      const rect = calculateRect(block.points, width, height);
      if (!rect) {
        continue;
      }

      const { x, y, w, h } = rect;
      const imageData = context.getImageData(x, y, w, h);
      patches.push({ x, y, imageData, rotation });
    }

    for (const block of rotatedAttention) {
      // Draw attention
      const alpha = Math.floor(128 * Math.min(block.intensity, 1.0)).toString(16);
      context.fillStyle = '#8DFF30' + alpha;
      context.beginPath();

      for (const [i, point] of block.points.entries()) {
        if (i === 0) {
          context.moveTo(point.x * width, point.y * height);
        } else {
          context.lineTo(point.x * width, point.y * height);
        }
      }

      context.closePath();
      context.fill();
    }

    context.restore();

    return () => {
      for (const patch of patches ?? []) {
        context.putImageData(patch.imageData, patch.x, patch.y);
      }
    };
  }, [canDraw, canvasRef, attention, rotation]);
};
