import { memo, ReactNode, RefAttributes } from 'react';
import {
  Checkbox as Checkbox_,
  CheckboxProps as CheckboxProps_,
  CheckboxRenderProps as CheckboxRenderProps_,
} from 'react-aria-components';

import CheckmarkIcon from '@/assets/checkmark-simple-icon.svg?react';
import { merge } from '@/components';

export type CheckboxProps = CheckboxProps_ & RefAttributes<HTMLLabelElement>;

const ICheckbox = ({ className, children, ...rest }: CheckboxProps) => {
  return (
    <Checkbox_ className={merge('group/checkbox flex cursor-pointer gap-3', className)} {...rest}>
      {(props: CheckboxRenderProps_ & { defaultChildren: ReactNode | undefined }) => {
        let renderedChildren: ReactNode;
        if (typeof children === 'function') {
          renderedChildren = children(props);
        } else {
          renderedChildren = children;
        }

        return (
          <>
            <div className="flex size-6 items-center justify-center rounded-lg border border-gray-200 p-1 group-data-[selected]/checkbox:bg-blue-400">
              <CheckmarkIcon aria-hidden className="size-4 group-data-[selected]/checkbox:stroke-white" />
            </div>
            {renderedChildren}
          </>
        );
      }}
    </Checkbox_>
  );
};

export const Checkbox = memo(ICheckbox) as typeof ICheckbox;
