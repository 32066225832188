import { memo, useCallback, useState } from 'react';
import { Group, Key } from 'react-aria-components';

import MinusIcon from '@/assets/minus-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import {
  Button,
  Hr,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  NumberField,
  Popover,
  Select,
  SelectValue,
  Switch,
  Text,
} from '@/components/core';
import { findField, FormatterValidatorHeader } from '@/components/ui';
import { useFormatterMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

import { FormatterTest } from './FormatterTest';

const FLOAT_NUMERIC_TYPE = 'float';
const INTEGER_NUMERIC_TYPE = 'integer';

const NUMERIC_TYPE_OPTIONS = [
  {
    id: FLOAT_NUMERIC_TYPE,
    label: 'Float',
    description: 'Convert to a decimal number',
  },
  {
    id: INTEGER_NUMERIC_TYPE,
    label: 'Integer',
    description: 'Convert to integer',
  },
];

export type NumericFormatterProps = {
  fieldId: string;
  index: number;
};

const INumericFormatter = ({ fieldId, index }: NumericFormatterProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const mutate = useFormatterMutation(fieldId, index, field?.parentId);
  const formatter = field?.formatters?.[index];

  const numDecimals = formatter?.config?.decimals ? Number(formatter?.config?.decimals) : undefined;
  const [showLimitDecimals, setShowLimitDecimals] = useState(!!numDecimals);

  const setNumericType = useCallback((key: Key) => mutate((f) => (f.config.type = key.toString())), [mutate]);

  const onChangePrecision = useCallback(
    (n: number | undefined) => {
      const v = Number.isFinite(n) ? n : undefined;
      mutate((f) => ((f.config.decimals as number | undefined) = v));
    },
    [mutate]
  );

  const numericType = formatter?.config.type ?? FLOAT_NUMERIC_TYPE;

  const toggleLimitDecimals = useCallback(
    (isSelected: boolean) => {
      if (!isSelected) {
        onChangePrecision(undefined);
      }
      setShowLimitDecimals(isSelected);
    },
    [onChangePrecision]
  );

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader formatter={formatter} />

      <Hr />

      <Select onSelectionChange={setNumericType} defaultSelectedKey={FLOAT_NUMERIC_TYPE}>
        <Label>Numeric type</Label>
        <Button variant="secondary">
          <SelectValue />
        </Button>

        <Popover>
          <ListBox>
            {NUMERIC_TYPE_OPTIONS.map((t) => (
              <ListBoxItem id={t.id}>
                <Text slot="label" className="font-bold">
                  {t.label}
                </Text>
                <Text slot="description">{t.description}</Text>
              </ListBoxItem>
            ))}
          </ListBox>
        </Popover>
      </Select>

      <div className="flex grow flex-col gap-4">
        {numericType === FLOAT_NUMERIC_TYPE && (
          <div className="flex h-8 w-full items-center justify-between">
            <div className="grow">
              <Switch onChange={toggleLimitDecimals} defaultSelected={!!numDecimals}>
                <Label>Limit number of decimals</Label>
              </Switch>
            </div>

            {showLimitDecimals && (
              <NumberField minValue={0} className="flex-none" onChange={onChangePrecision}>
                <Group className="flex gap-2">
                  <Button slot="decrement" variant="plain">
                    <MinusIcon stroke="black" className="w-3" />
                  </Button>

                  <div className="w-8">
                    <Input
                      defaultValue={numDecimals}
                      placeholder="2"
                      onInput={(e) => onChangePrecision(Number(e.currentTarget.value))}
                    />
                  </div>

                  <Button slot="increment" variant="plain">
                    <PlusIcon stroke="black" className="w-3" />
                  </Button>
                </Group>
              </NumberField>
            )}
          </div>
        )}
      </div>

      <FormatterTest fieldId={fieldId} index={index} />
    </div>
  );
};

export const NumericFormatter = memo(INumericFormatter) as typeof INumericFormatter;
