import { ReactFlowProvider } from '@xyflow/react';
import { memo } from 'react';

import { Loading } from '@/components/core';
import { useGetProjectFromPath } from '@/hooks';

import { ExportPanelSlideOutWrapper } from './ExportPanelSlideOutWrapper.tsx';
import { FlowDiagram } from './FlowDiagram';
import { ModelPanelSlideOutWrapper } from './ModelPanelSlideOutWrapper.tsx';
import { TriggerPanelSlideOutWrapper } from './TriggerPanelSlideOutWrapper.tsx';
import { ValidationPanelSlideOutWrapper } from './ValidationPanelSlideOutWrapper.tsx';

export type IntegrationProps = object;

// eslint-disable-next-line no-empty-pattern
const IIntegration = ({}: IntegrationProps) => {
  const { data: project, isPending: isPendingProject } = useGetProjectFromPath();

  if (isPendingProject) {
    return <Loading label="Loading ..." />;
  }

  if (!project) {
    // TODO: Handle this properly
    return null;
  }

  return (
    <div className="flex h-screen w-full">
      <ReactFlowProvider>
        <FlowDiagram projectId={project.projectId} />
        <TriggerPanelSlideOutWrapper projectId={project.projectId} />
        <ModelPanelSlideOutWrapper projectId={project.projectId} />
        <ValidationPanelSlideOutWrapper projectId={project.projectId} />
        <ExportPanelSlideOutWrapper projectId={project.projectId} />
      </ReactFlowProvider>
    </div>
  );
};

export const Integration = memo(IIntegration) as typeof IIntegration;
