import { useContext } from 'react';
import { useStore } from 'zustand/index';

import { ProfileContext, ProfileState } from './profileStore';

export function useProfileStore<T>(selector: (state: ProfileState) => T): T {
  const store = useContext(ProfileContext);
  if (!store) throw new Error('Missing ProfileContext.Provider in the tree');
  return useStore(store, selector);
}
