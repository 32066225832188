import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { merge } from '@/components';

export type TextProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  size?: 'md' | 'sm' | 'xs';
};

export const Text = ({ className, children, size = 'md', ...rest }: TextProps) => {
  return (
    // TODO: change to span
    <div
      className={merge(
        'text-gray-600',
        {
          'text-md': size === 'md',
          'text-sm': size === 'sm',
          'text-xs text-gray-400': size === 'xs',
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
