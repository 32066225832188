import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import Logo from '@/assets/logo.svg?react';
import { Header } from '@/components/core';
import { useAuth, useGlobalAuth } from '@/hooks';

export const Logout = () => {
  const { logout, isLoggedIn: isLoggedInOrg } = useAuth();
  const { logout: globalLogout, isLoggedIn: isLoggedInGlobal } = useGlobalAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedInOrg) {
      logout();
    } else if (isLoggedInGlobal) {
      globalLogout();
    } else {
      navigate({ to: '/login' });
    }
  }, [globalLogout, isLoggedInGlobal, isLoggedInOrg, logout, navigate]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-gradient-to-b from-white to-slate-200">
      <Logo />
      <Header size="xl">Logging out...</Header>
    </div>
  );
};
