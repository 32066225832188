import {
  AuthorizationCodeCredentials,
  type CreateProjectRunOptions,
  type ProjectRun,
  type ProjectRunStatus,
  ProjectRunStatusValues,
  type SortParam,
} from '@lucidtech/las-sdk-browser';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { useMemo } from 'react';

import { useClient } from '@/hooks';
import { create } from '@/hooks/api';
import { DateColumnFilter, DateRangeColumnFilter, useInboxStore } from '@/store';
import { isArray, JSONType } from '@/utils';

export type ProjectRunId = { projectId: string; runId: string };

export const {
  getOptions: getProjectRunOptions,
  useCreate: useCreateProjectRun,
  useGet: useGetProjectRun,
  useBatchGet: useBatchGetProjectRun,
  useUpdate: useUpdateProjectRun,
  useDelete: useDeleteProjectRun,
} = create<ProjectRun, ProjectRunId, CreateProjectRunOptions & { projectId: string }>({
  cacheKey: 'projectRuns',
  equal: (projectRun, id) =>
    Boolean(
      projectRun && id?.projectId && id?.runId && projectRun.projectId === id.projectId && projectRun.runId === id.runId
    ),
  createId: (projectRun) => (projectRun ? { projectId: projectRun.projectId, runId: projectRun.runId } : null),
  create: (client, run) => client.createProjectRun(run.projectId, { ...run, projectId: undefined }),
  get: (client, id) => client.getProjectRun(id.projectId, id.runId),
  delete: (client, id) => client.deleteProjectRun(id.projectId, id.runId),
  list: (client, options) => {
    return new Promise((resolve, reject) => {
      client
        .listProjectRuns(options)
        .then((response: JSONType) => {
          resolve({ data: response.runs, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateProjectRun(id.projectId, id.runId, updates),
});

export const useRunsCacheKey = (projectId: string) => {
  const sorting = useInboxStore((state) => state.sorting);
  const filters = useInboxStore((state) => state.filters);
  const search = useInboxStore((state) => state.search);

  const activeFilters = useMemo(() => filters.filter((f) => f.columnName && f.type && f.value), [filters]);
  const { client } = useClient();

  return [
    (client.credentials as AuthorizationCodeCredentials).clientId,
    'projectRuns',
    'list',
    projectId,
    sorting,
    search,
    activeFilters,
  ];
};

export const toProjectRunId = (projectId?: string | null, runId?: string | null): ProjectRunId | null => {
  return projectId && runId ? { projectId, runId } : null;
};

export type UseFilteredProjectRunsQueryOpts = {
  projectId: string;
};

export const useFilteredProjectRunsQuery = ({ projectId }: UseFilteredProjectRunsQueryOpts) => {
  const sorting = useInboxStore((state) => state.sorting);
  const filters = useInboxStore((state) => state.filters);
  const activeFilters = useMemo(() => filters.filter((f) => f.columnName && f.type && f.value), [filters]);
  const { client } = useClient();

  const search = useDebounce(
    useInboxStore((state) => state.search),
    400
  );

  const cacheKey = useRunsCacheKey(projectId);

  return useInfiniteQuery({
    queryKey: cacheKey,
    queryFn: async ({ pageParam }) => {
      const sort: SortParam<ProjectRun>[] = [];
      for (const sortOption of sorting) {
        if (!sortOption.hidden) {
          const { id, desc } = sortOption as { id: keyof ProjectRun; desc: boolean };
          sort.push({ column: id, order: desc ? 'desc' : 'asc' });
        }
      }

      const isProjectRunStatus = (s: string): s is ProjectRunStatus => {
        const statusValues: string[] = [...ProjectRunStatusValues];
        return statusValues.includes(s);
      };
      const status: ProjectRunStatus[] = [];
      for (const activeFilter of activeFilters.filter((f) => f.columnName === 'status' && f.condition === 'is')) {
        if (isArray(activeFilter.value)) {
          status.push(...activeFilter.value.filter(isProjectRunStatus));
        }
      }

      const dateFilters: Record<string, Record<string, Date | undefined>> = {
        createdTime: { after: undefined, before: undefined },
        updatedTime: { after: undefined, before: undefined },
      };

      const compareDates = (condition: 'after' | 'before', newDate: Date, oldDate?: Date) => {
        switch (condition) {
          case 'after':
            return newDate >= (oldDate ?? newDate) ? newDate : oldDate;
          case 'before':
            return newDate >= (oldDate ?? newDate) ? newDate : oldDate;
        }
      };

      for (const activeFilter of activeFilters.filter((f) => f.type === 'date')) {
        const filter = activeFilter as DateColumnFilter;
        if (!filter.columnName || !filter.condition || !filter.value) continue;
        const date = filter.value.toDate('UTC');
        dateFilters[filter.columnName][filter.condition] = compareDates(
          filter.condition,
          date,
          dateFilters[filter.columnName][filter.condition]
        );
      }

      for (const activeFilter of activeFilters.filter((f) => f.type === 'date-range')) {
        const filter = activeFilter as DateRangeColumnFilter;
        if (!filter.columnName || !filter.condition || !filter.value) continue;
        const fromDate = filter.value.start.toDate('UTC');
        dateFilters[filter.columnName]['after'] = compareDates(
          'after',
          fromDate,
          dateFilters[filter.columnName]['after']
        );
        const toDate = filter.value.end.toDate('UTC');
        dateFilters[filter.columnName]['before'] = compareDates(
          'before',
          toDate,
          dateFilters[filter.columnName]['before']
        );
      }

      const apiFilters: Record<string, Date> = {};
      for (const [column, dateRange] of Object.entries(dateFilters)) {
        for (const [range, date] of Object.entries(dateRange)) {
          if (date) {
            const when = range.charAt(0).toUpperCase() + range.slice(1);
            apiFilters[`${column}${when}`] = date;
          }
        }
      }

      const runs = await client.listProjectRuns(projectId, {
        nextToken: pageParam,
        maxResults: 50,
        history: search,
        status,
        sort,
        ...apiFilters,
      });
      return { data: runs.runs, nextToken: runs.nextToken };
    },
    // TODO replace '' with undefined: Breaks TS type inference
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.nextToken,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });
};
