import { JSONValue } from '@lucidtech/las-sdk-browser';
import { memo, useCallback } from 'react';
import { DialogTrigger, TextField } from 'react-aria-components';
import { PillListActions } from 'src/components/core/PillsInput';

import { Button, Hr, Input, Label, Pills, Popover, Text } from '@/components/core';
import { findField, FormatterValidatorHeader } from '@/components/ui';
import { useFormatterMutation } from '@/hooks';
import { useModelConfigStore } from '@/store';

import { FormatterTest } from './FormatterTest';

export type ClassificationLabel = {
  value: string;
  description: string;
};

export type ClassificationFormatterProps = {
  fieldId: string;
  index: number;
};

const IClassificationFormatter = ({ fieldId, index }: ClassificationFormatterProps) => {
  const field = useModelConfigStore((state) => findField(state.fields, fieldId));
  const mutate = useFormatterMutation(fieldId, index, field?.parentId);
  const formatter = field?.formatters?.[index];

  const onItemsChange = useCallback(
    (items: Pills.Item[]) => {
      return mutate(
        (f) =>
          (f.config.labels = items.map((it) => ({
            value: it.value,
            description: it.label ?? it.value,
          })))
      );
    },
    [mutate]
  );

  const onSetCatchAll = useCallback(
    (value?: string) => {
      mutate((f) => (f.config.catchAll = value as JSONValue));
    },
    [mutate]
  );

  const initialValues: Pills.Item[] = formatter?.config?.labels?.map(({ value, description }: ClassificationLabel) => ({
    value: value,
    label: description,
  }));

  return (
    <div className="flex h-full flex-col gap-4 p-4">
      <FormatterValidatorHeader formatter={formatter} />

      <Hr />
      <div className="flex grow flex-col gap-4">
        <div>
          <Text>Categories</Text>
          <Text size="xs">An exhaustive list of all the values this field can have.</Text>
        </div>

        <Pills.Input>
          <Pills.List
            className="flex grow"
            autoSeparate={['+', '-', '*', '/']}
            onItemsChange={onItemsChange}
            initialValues={initialValues}
          >
            {(pill: Pills.Item, handle: PillListActions) => (
              <DialogTrigger>
                <Button variant="secondary" className="flex gap-1 py-0">
                  {pill.value}
                  <span className="ml-2 cursor-pointer" onClick={() => handle.removeItem(pill)}>
                    x
                  </span>
                </Button>

                <Popover className="p-2">
                  <TextField
                    defaultValue={pill.label}
                    onChange={(desc: string) => handle.updateItem({ label: desc, value: pill.value })}
                  >
                    <Label>Description</Label>
                    <Input onClick={(e) => e.stopPropagation()} />
                  </TextField>
                </Popover>
              </DialogTrigger>
            )}
          </Pills.List>
        </Pills.Input>

        <TextField
          onInput={(e) => onSetCatchAll(e.currentTarget.value)}
          defaultValue={formatter?.config?.catchAll as string | undefined}
        >
          <Label>Catch-all value</Label>
          <Input placeholder="Unknown" />
          <Text slot="description">If set, this value will be used if no other categories are found.</Text>
        </TextField>
      </div>

      <FormatterTest fieldId={fieldId} index={index} />
    </div>
  );
};

export const ClassificationFormatter = memo(IClassificationFormatter) as typeof IClassificationFormatter;
