import { ComponentType, memo } from 'react';

import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';
import { SVGProps } from '@/utils';

export type FormatterValidatorLogoProps = LogoProps & {
  variant: 'formatter' | 'validator' | 'field-type';
  Icon: ComponentType<SVGProps>;
  iconProps?: SVGProps;
  iconColoringType?: 'fill' | 'stroke';
};

const IFormatterValidatorLogo = ({
  variant,
  Icon,
  iconProps,
  iconColoringType,
  className,
  ...rest
}: FormatterValidatorLogoProps) => {
  const { className: iconClassName, ...restIconProps } = iconProps ?? {};
  return (
    <Logo
      className={merge(
        'bg-purple-50',
        variant === 'formatter' && 'bg-purple-50',
        variant === 'validator' && 'bg-green-50',
        variant === 'field-type' && 'bg-cyan-50',
        className
      )}
      {...rest}
    >
      <Icon
        className={merge(
          'size-5',
          variant === 'formatter' && iconColoringType === 'fill' && 'fill-purple-700',
          variant === 'formatter' && iconColoringType === 'stroke' && 'stroke-purple-700',
          variant === 'validator' && iconColoringType === 'fill' && 'fill-green-700',
          variant === 'validator' && iconColoringType === 'stroke' && 'stroke-green-700',
          variant === 'field-type' && iconColoringType === 'fill' && 'fill-cyan-700',
          variant === 'field-type' && iconColoringType === 'stroke' && 'stroke-cyan-700',
          iconClassName
        )}
        {...restIconProps}
      />
    </Logo>
  );
};

export const FormatterValidatorLogo = memo(IFormatterValidatorLogo) as typeof IFormatterValidatorLogo;
