import type { User } from '@lucidtech/las-sdk-browser';
import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { Badge, BadgeProps, Table } from '@/components/core';
import { toRoleId, useDeleteUser, useGetRole, useListUsers } from '@/hooks/api';
import { Id } from '@/utils';

import { Profile } from './Profile';

type RoleBadgeProps = {
  roleId: string;
};

const IRoleBadge = ({ roleId }: RoleBadgeProps) => {
  const { data: role } = useGetRole(toRoleId(roleId));
  if (!role) return null;
  let variant: BadgeProps['variant'];
  switch (role.roleId) {
    case 'las:role:administrator':
      variant = 'success';
      break;
    case 'las:role:developer':
      variant = 'info';
      break;
    case 'las:role:reviewer':
      variant = 'warning';
      break;
    default:
      variant = 'info';
      break;
  }
  return <Badge variant={variant} label={role.name ?? role.roleId} />;
};

const RoleBadge = memo(IRoleBadge) as typeof IRoleBadge;

export type MembersTableProps = object;

// eslint-disable-next-line no-empty-pattern
const IMembersTable = ({}: MembersTableProps) => {
  const { data: users } = useListUsers();
  const { mutate: deleteUser } = useDeleteUser();

  const columns = useMemo<ColumnDef<User & Id>[]>(() => {
    return [
      {
        accessorKey: 'userId',
        cell: ({ row }) => <Profile className="py-1" profileId={row.original.profileId} showEmail />,
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        accessorKey: 'roleIds',
        cell: ({ row }) => {
          return (
            <div className="flex flex-row justify-end gap-1">
              {row.original.roleIds.map((roleId) => (
                <RoleBadge key={roleId} roleId={roleId} />
              ))}
            </div>
          );
        },
        size: 0,
      },
    ];
  }, []);

  const data = useMemo(() => users?.data.map((u) => ({ ...u, id: u.userId })) ?? [], [users]);

  return (
    <Table
      columns={columns}
      data={data}
      hideDragHandle
      hideHeader
      deleteRowAction={(row) => deleteUser({ userId: row.original.userId })}
    />
  );
};

export const MembersTable = memo(IMembersTable) as typeof IMembersTable;
