import type { CreateModelOptions, Model } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type ModelId = { modelId: string };

export const {
  useAutoUpdate: useAutoUpdateModel,
  useCreate: useCreateModel,
  useGet: useGetModel,
  useSuspenseBatchGet: useSuspenseBatchGetModels,
  useSuspenseGet: useSuspenseGetModel,
} = create<Model, ModelId, CreateModelOptions>({
  cacheKey: 'models',
  createId: (model) => (model ? { modelId: model.modelId } : null),
  equal: (model, id) => Boolean(model && id?.modelId && model.modelId === id.modelId),
  create: (client, model) => client.createModel(model),
  delete: (client, id) => client.deleteModel(id.modelId),
  get: (client, id) => client.getModel(id.modelId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listModels()
        .then((response: JSONType) => {
          resolve({ data: response.models, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateModel(id.modelId, updates),
});

export const toModelId = (modelId?: string | null): ModelId | null => {
  return modelId ? { modelId } : null;
};
