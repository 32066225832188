import { useNavigate } from '@tanstack/react-router';
import { memo, useCallback } from 'react';

import LinkIcon from '@/assets/link-icon.svg?react';
import { Button, Text } from '@/components/core';
import { ExportLogo } from '@/components/flow';
import { CradlLogo } from '@/components/ui';
import { useGetNotConfiguredExports, useTour } from '@/hooks';

export type SetupAutomaticExportBannerProps = {
  projectId: string;
};

const ISetupAutomaticExportBanner = ({ projectId }: SetupAutomaticExportBannerProps) => {
  const navigate = useNavigate();
  const goToSetup = useCallback(
    () => navigate({ to: '/projects/$projectId/integration', params: { projectId } }),
    [navigate, projectId]
  );
  const { actions } = useGetNotConfiguredExports({ projectId });
  const action = actions[0];
  const { showSetupAutomaticExportBanner, setShowSetupAutomaticExportBanner } = useTour({
    prefixes: [projectId, action?.actionId],
  });
  const { showReviewPredictionsTooltip } = useTour();
  const dismiss = useCallback(() => setShowSetupAutomaticExportBanner(false), [setShowSetupAutomaticExportBanner]);

  if (!action || !showSetupAutomaticExportBanner || showReviewPredictionsTooltip) return;

  return (
    <div className="my-2 flex w-full items-center gap-4 rounded-lg border-2 border-sky-200 bg-sky-50 p-4">
      <div className="flex items-center">
        <CradlLogo className="translate-x-2" size="4xl" />
        <div className="z-10 rounded-full border border-gray-200 bg-white p-1">
          <LinkIcon className="size-4 stroke-gray-500" />
        </div>
        <ExportLogo className="-translate-x-2" size="4xl" functionId={action.functionId} />
      </div>
      <div className="flex flex-col gap-2">
        <Text className="font-semibold">Setup automatic export</Text>
        <Text>
          To automatically export your parsed document data, you need to complete the setup of your integration in the
          workflow.
        </Text>
        <div className="flex gap-2">
          <Button variant="secondary" onPress={goToSetup}>
            Go to setup
          </Button>
          <Button variant="plain" onPress={dismiss}>
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SetupAutomaticExportBanner = memo(ISetupAutomaticExportBanner) as typeof ISetupAutomaticExportBanner;
