import { memo } from 'react';

import AIModelIcon from '@/assets/ai-model-icon.svg?react';
import { Logo, Text } from '@/components/core';

export type DefaultNodeProps = {
  resourceIds: string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IDefaultNode = ({ resourceIds }: DefaultNodeProps) => {
  return (
    <div className="w-72">
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <Logo className="bg-blue-500" size="lg">
          <AIModelIcon className="size-4 text-white" />
        </Logo>
        <Text className="font-semibold" size="md">
          Default
        </Text>
      </div>
      <div className="gap-2 pt-2">
        <Text className="flex flex-row flex-wrap items-center gap-2 text-gray-600" size="xs"></Text>
      </div>
    </div>
  );
};

export const DefaultNode = memo(IDefaultNode) as typeof IDefaultNode;
