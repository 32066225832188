import { createFileRoute } from '@tanstack/react-router';

import { New } from '@/features/organizations';
import { OrganizationProvider } from '@/store';

export const Route = createFileRoute('/_globalAuth/organizations/new')({
  component: () => (
    <OrganizationProvider>
      <New />
    </OrganizationProvider>
  ),
});
