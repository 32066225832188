import type { Action } from '@lucidtech/las-sdk-browser';

import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
  CREATE_PREDICTION_FUNCTION_ID,
  CREATE_VALIDATION_TASK_FUNCTION_ID,
} from '@/constants';
import {
  useSuspenseBatchGetActions,
  useSuspenseBatchGetHooks,
  useSuspenseBatchGetModels,
  useSuspenseBatchGetValidations,
  useSuspenseGetProject,
} from '@/hooks/api';

const findIds = (str: string, resourceName: string) => {
  const regExp = new RegExp(`las:${resourceName}:[a-zA-Z0-9_-]+`, 'g');
  return [...str.matchAll(regExp)].map((reg) => reg.toString());
};

export type UseProjectTreeOpts = {
  projectId: string;
};

export const useProjectTree = ({ projectId }: UseProjectTreeOpts) => {
  const { data: project } = useSuspenseGetProject({ projectId });
  const { data: hooks } = useSuspenseBatchGetHooks(
    project.resourceIds.filter((r) => r.startsWith('las:hook')).map((hookId) => ({ hookId }))
  );
  const { data: actions } = useSuspenseBatchGetActions(
    project.resourceIds.filter((r) => r.startsWith('las:action')).map((actionId) => ({ actionId }))
  );
  const { data: models } = useSuspenseBatchGetModels(
    project.resourceIds.filter((r) => r.startsWith('las:model')).map((modelId) => ({ modelId }))
  );
  const { data: validations } = useSuspenseBatchGetValidations(
    project.resourceIds.filter((r) => r.startsWith('las:validation')).map((validationId) => ({ validationId }))
  );

  const knownDependencies: Record<string, string[]> = {
    'Document is Created': [
      CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
      CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
      CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
    ],
    'Prediction is Created': [CREATE_PREDICTION_FUNCTION_ID],
    'ValidationTask has Completed': [CREATE_VALIDATION_TASK_FUNCTION_ID],
    'ValidationTask is Created': [CREATE_VALIDATION_TASK_FUNCTION_ID],
  };
  const nodeResourceIds: Record<string, Set<string>> = {};
  for (const action of actions) {
    nodeResourceIds[action.actionId] = new Set([action.actionId]);
    if (action.functionId === CREATE_PREDICTION_FUNCTION_ID) {
      const configStr = JSON.stringify(action.config);
      for (const modelId of findIds(configStr, 'model')) {
        if (models.find((m) => m.modelId === modelId)) {
          nodeResourceIds[action.actionId].add(modelId);
        }
      }
    }
    if (action.functionId === CREATE_VALIDATION_TASK_FUNCTION_ID) {
      const configStr = JSON.stringify(action.config);
      for (const validationId of findIds(configStr, 'validation')) {
        if (validations.find((m) => m.validationId === validationId)) {
          nodeResourceIds[action.actionId].add(validationId);
        }
      }
    }
  }

  const isConnected = (from_action: Action, to_action: Action) => {
    for (const hook of hooks) {
      const deps = knownDependencies[hook.trigger] ?? [];
      if (
        (hook.trueActionId === to_action.actionId || hook.falseActionId === to_action.actionId) &&
        deps.includes(from_action.functionId)
      ) {
        nodeResourceIds[to_action.actionId].add(hook.hookId);
        return true;
      }
    }
    return false;
  };

  const length = actions.length;
  const connections = Array.from({ length }, () => Array.from({ length }, () => false));
  for (const [i, from_action] of actions.entries()) {
    for (const [j, to_action] of actions.entries()) {
      if (isConnected(from_action, to_action)) {
        connections[i][j] = true;
      }
    }
  }

  const resources: string[][] = [];
  for (const action of actions) {
    resources.push([...nodeResourceIds[action.actionId]]);
  }

  return { connections, resources, hooks, actions, models, validations, hash: JSON.stringify(project) };
};
