import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { memo, useEffect } from 'react';

import { ErrorCard, Loading } from '@/components/core';
import { DEFAULT_REDIRECT_URL } from '@/constants';
import { useAuth } from '@/hooks';

const routeApi = getRouteApi('/authCallback');

export type AuthCallbackProps = object;

// eslint-disable-next-line no-empty-pattern
const IAuthCallback = ({}: AuthCallbackProps) => {
  const search = routeApi.useSearch();
  const navigate = useNavigate();
  const { ensureLoggedIn, updateCredentials, redirectUrl } = useAuth();

  useEffect(() => {
    if (search.code) {
      updateCredentials(search.code);
    }
    ensureLoggedIn().then((value) => {
      if (value) {
        navigate({ to: redirectUrl ?? DEFAULT_REDIRECT_URL }).catch(console.error);
      }
    });
  }, [ensureLoggedIn, navigate, redirectUrl, search.code, updateCredentials]);

  if (search.error) {
    return <ErrorCard label={search.error} message={search.error_description} />;
  }

  return <Loading label="Checking credentials..." />;
};

export const AuthCallback = memo(IAuthCallback) as typeof IAuthCallback;
