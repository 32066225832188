import { memo, useMemo } from 'react';
import { DialogTrigger } from 'react-aria-components';

import PlusIcon from '@/assets/plus-icon.svg?react';
import { Button, Header, Text } from '@/components/core';
import { InviteMembersModal, MembersTable } from '@/components/ui';
import { toOrganizationId, useSuspenseGetOrganization } from '@/hooks/api';
import { OrganizationProvider } from '@/store';

import { Page } from './Page';
import { WorkspaceDetails } from './WorkspaceDetails.tsx';
import { WorkspaceHeader } from './WorkspaceHeader.tsx';

export type WorkspaceProps = object;

// eslint-disable-next-line no-empty-pattern
const IWorkspace = ({}: WorkspaceProps) => {
  const { data: organization } = useSuspenseGetOrganization(toOrganizationId('me'));
  const key = useMemo(() => organization.organizationId, [organization]);
  organization.organizationId = 'me';

  return (
    // Force re-creation of provider if profileId changes
    <Page key={key}>
      <OrganizationProvider {...organization}>
        <WorkspaceHeader>
          <div className="flex flex-col">
            <Header size="xl">Workspace</Header>
            <Text size="md">Manage your workspace</Text>
          </div>
        </WorkspaceHeader>
        <WorkspaceDetails />
      </OrganizationProvider>
      <div className="flex items-center">
        <div className="flex flex-col">
          <Header size="xl">Members</Header>
          <Text size="md">Manage your team and change roles/permissions</Text>
        </div>
        <div className="grow" />
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-4">
        <MembersTable />
        <div>
          <DialogTrigger>
            <Button variant="secondary">
              <PlusIcon className="stroke-gray-500" />
              Add members
            </Button>
            <InviteMembersModal />
          </DialogTrigger>
        </div>
      </div>
    </Page>
  );
};

export const Workspace = memo(IWorkspace) as typeof IWorkspace;
