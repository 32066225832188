import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Text } from '@/components/core';
import { JSONType, OmitChildren } from '@/utils';

import { TriggerLogo } from '../TriggerLogo.tsx';

export type TriggerOption = {
  functionId: string;
  name: string;
  config: JSONType;
};

export type TriggerCellProps = OmitChildren<ButtonProps> & {
  option: TriggerOption;
};

const ITriggerCell = ({ option, className, ...rest }: TriggerCellProps) => {
  return (
    <Button
      variant="plain"
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-2', className)}
      {...rest}
    >
      <TriggerLogo size="lg" functionId={option.functionId} />
      <Text>{option.name}</Text>
    </Button>
  );
};

export const TriggerCell = memo(ITriggerCell) as typeof ITriggerCell;
