import type { FieldValidator } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { merge } from '@/components';
import { Button, ButtonProps, Text } from '@/components/core';
import { OmitChildren } from '@/utils';

import { ValidatorLogo } from '../NewValidatorModal';

export type ValidatorCellProps = OmitChildren<ButtonProps> & {
  validator: FieldValidator;
};

const IValidatorCell = ({ validator, className, ...rest }: ValidatorCellProps) => {
  return (
    <Button
      variant="plain"
      className={merge('flex w-full items-center justify-start gap-2 rounded-lg border border-gray-200 p-1', className)}
      {...rest}
    >
      <ValidatorLogo validator={validator} size="md" />
      <Text size="sm">{validator.name}</Text>
    </Button>
  );
};

export const ValidatorCell = memo(IValidatorCell) as typeof IValidatorCell;
