import { useLocation, useNavigate } from '@tanstack/react-router';

import BillingIcon from '@/assets/billing-icon.svg?react';
import ChevronDown from '@/assets/chevron-down-icon.svg?react';
import UserIcon from '@/assets/user-icon.svg?react';
import UsersIcon from '@/assets/users-icon.svg?react';
import { Button, Header, Hr } from '@/components/core';
import { NavigationBar as NavBar, NavigationBarItem as Item } from '@/components/ui';

import { UpgradeCard } from '../projects/UpgradeCard.tsx';

export const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <NavBar>
      <div className="flex flex-row items-center gap-2 px-2 pt-2">
        <Button
          variant="plain"
          onPress={async () => {
            await navigate({ to: '/projects' });
          }}
        >
          <ChevronDown className="rotate-90 stroke-gray-700" />
        </Button>
        <Header className="text-md font-semibold" size="lg">
          Account settings
        </Header>
      </div>
      <Hr className="my-4" />
      <div className="space-y-2 px-2">
        <ul className="px-2">
          <Item
            selected={location.pathname.endsWith('profile')}
            onClick={async () => await navigate({ to: '/settings/profile' })}
            label="Profile"
          >
            <UserIcon className="size-5 fill-gray-500" />
          </Item>
          <Item
            selected={location.pathname.endsWith('workspace')}
            onClick={async () => await navigate({ to: '/settings/workspace' })}
            label="Workspace & Members"
          >
            <UsersIcon className="size-5 fill-gray-500" />
          </Item>
          <Item
            selected={location.pathname.endsWith('billing')}
            onClick={async () => await navigate({ to: '/settings/billing' })}
            label="Billing"
          >
            <BillingIcon className="size-5 fill-gray-500" />
          </Item>
        </ul>
      </div>
      <div className="flex-grow" />
      <Hr className="my-4" />
      <UpgradeCard organizationId="me" />
    </NavBar>
  );
};
