import { DetailedHTMLProps, FormHTMLAttributes, memo, useMemo } from 'react';

import { Loading } from '@/components/core';
import { TableField, useValidation } from '@/components/ui';
import { useDocumentStore } from '@/store';

export type DocumentTableFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  setSearch: (search?: string) => void;
};

const IDocumentTableForm = ({ setSearch }: DocumentTableFormProps) => {
  const fieldConfig = useDocumentStore((state) => state.fieldConfig);
  const status = useDocumentStore((state) => state.status);

  const { currentTable } = useValidation();

  const currentLabel = useMemo(() => {
    return Object.keys(fieldConfig ?? {}).find((label) => fieldConfig?.[label] === currentTable?.field);
  }, [currentTable?.field, fieldConfig]);

  if (status === 'Pending predictions') {
    return <Loading label="AI is working" message="The AI model is parsing your document" />;
  }

  if (!currentLabel) {
    return null;
  }

  return <TableField key={currentLabel} rowId={currentLabel} name={currentLabel} setSearch={setSearch} />;
};

export const DocumentTableForm = memo(IDocumentTableForm) as typeof IDocumentTableForm;
