import { ExportOption } from '@/components/flow';
import {
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
} from '@/constants';

export const EXPORTS: ExportOption[] = [
  {
    functionId: EXPORT_TO_WEBHOOK_FUNCTION_ID,
    config: { httpMethod: 'POST' },
    name: 'Export to Webhook',
  },
  {
    functionId: EXPORT_TO_EXCEL_FUNCTION_ID,
    config: {},
    name: 'Export to Excel',
  },
  {
    functionId: EXPORT_TO_ZAPIER_FUNCTION_ID,
    config: { credentials: '********************' },
    name: 'Export to Zapier',
  },
  {
    functionId: EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
    config: { credentials: '********************' },
    name: 'Export to Power Automate',
  },
];
