import { memo, useMemo } from 'react';

import { toProfileId, useSuspenseGetProfile } from '@/hooks/api';
import { ProfileProvider } from '@/store';

import { Page } from './Page';
import { ProfileDetails } from './ProfileDetails.tsx';

export type ProfileProps = object;

// eslint-disable-next-line no-empty-pattern
const IProfile = ({}: ProfileProps) => {
  const { data: profile } = useSuspenseGetProfile(toProfileId('me'));
  const key = useMemo(() => profile.profileId, [profile]);
  profile.profileId = 'me';

  return (
    // Force re-creation of provider if profileId changes
    <Page key={key}>
      <ProfileProvider {...profile}>
        <ProfileDetails profileId={profile.profileId} />
      </ProfileProvider>
    </Page>
  );
};

export const Profile = memo(IProfile) as typeof IProfile;
