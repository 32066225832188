import { memo } from 'react';

import IBANNumberIcon from '@/assets/iban-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type IBANNumberLogoProps = VariantLogoProps;

const IIBANNumberLogo = ({ ...rest }: IBANNumberLogoProps) => {
  return <FormatterValidatorLogo Icon={IBANNumberIcon} iconColoringType="stroke" {...rest} />;
};

export const IBANNumberLogo = memo(IIBANNumberLogo) as typeof IIBANNumberLogo;
