import { Label as Label_, LabelProps as LabelProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type LabelProps = LabelProps_;

export const Label = ({ className, children, ...rest }: LabelProps) => {
  /* prettier-ignore */
  const styles = [
    'font-semibold',
    'text-sm',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'text-gray-400',
  ];

  return (
    <Label_ className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </Label_>
  );
};
