import type { Action, Field, FieldConfig, JSONValue, Project, ProjectRun } from '@lucidtech/las-sdk-browser';
import moment from 'moment/moment';

import {
  EXPORT_TO_EXCEL_FUNCTION_ID,
  EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID,
  EXPORT_TO_WEBHOOK_FUNCTION_ID,
  EXPORT_TO_ZAPIER_FUNCTION_ID,
  MULTI_VALUE_COLUMN_NAME,
} from '@/constants';
import { AttentionBlock, FieldState, FormValue, FormValueRow, FormValues, Point } from '@/store';

import {
  Annotation,
  Annotations,
  FormatterError,
  FormatterWarning,
  HeaderAnnotations,
  isArray,
  JSONType,
  TableAnnotations,
} from './types.ts';

export const createId = (resourceName: string, resourceId: number | string) => {
  return `las:${resourceName}:${resourceId}`;
};

export const generateId = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

export const convertToState = (fieldConfig: FieldConfig, parentId?: string) => {
  const fields: FieldState[] = [];
  for (const [fieldId, config] of Object.entries(fieldConfig)) {
    const fieldState: FieldState = {
      id: fieldId,
      description: config.description,
      formatters: config.formatters,
      isNullable: config.isNullable,
      name: config.name,
      parentId,
      promptHint: config.promptHint,
      type: config.type,
      validators: config.validators,
    };
    if (config.type === 'table' && config.fields) {
      fieldState.fields = convertToState(config.fields, fieldState.id);
    }
    fields.push(fieldState);
  }
  return fields;
};

export const convertToFieldConfig = (fields: FieldState[]) => {
  const fieldConfig: FieldConfig = {};
  for (const field of fields) {
    fieldConfig[field.id] = {
      description: field.description,
      formatters: field.formatters,
      isNullable: field.isNullable,
      name: field.name,
      promptHint: field.promptHint,
      type: field.type,
      validators: field.validators,
    };
    if (field.type === 'table' && field.fields) {
      fieldConfig[field.id].fields = convertToFieldConfig(field.fields);
    }
  }
  return fieldConfig;
};

export const listAllFields = (fieldConfig: FieldConfig, parentName?: string) => {
  const fields: { name: string; field: Field }[] = [];
  for (const [fieldId, config] of Object.entries(fieldConfig)) {
    const fieldName = config.name ?? fieldId;
    if (config.type === 'table' && config.fields) {
      fields.push(...listAllFields(config.fields, fieldName));
    } else {
      fields.push({ name: parentName ? `${parentName}/${fieldName}` : fieldName, field: config });
    }
  }
  return fields;
};

export const addUniqueValue = <T>(array: T[], value: T) => {
  if (array.indexOf(value) === -1) {
    return [...array, value];
  }
  return array;
};

export const removeUniqueValue = <T>(array: T[], value: T) => {
  const index = array.indexOf(value);
  if (index !== -1) {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }
  return array;
};

export const attentionMapToBlocks = (attentionMap?: number[][]): AttentionBlock[] => {
  if (!attentionMap) return [];

  const blocks: AttentionBlock[] = [];
  for (const attention of attentionMap) {
    const copy = structuredClone(attention);
    const coords = copy.splice(1);
    const intensity = copy.pop() ?? 0.0;
    const xs = coords.filter((_, i) => i % 2 === 0);
    const ys = coords.filter((_, i) => i % 2 !== 0);
    const points: Point[] = [];
    for (let i = 0; i < Math.min(xs.length, ys.length); i++) {
      points.push({ x: xs[i], y: ys[i] });
    }
    blocks.push({ intensity, points });
  }
  return blocks;
};

export const formatDate = (date?: Date) => {
  if (!date) return '';
  return moment(date).fromNow();
};

export const formatDateFull = (date?: Date) => {
  if (!date) return '';
  return moment(date).format('LLLL');
};

export const formatFileSize = (sizeInBytes: number) => {
  const sizeInKilobytes = sizeInBytes / 1000;
  if (sizeInKilobytes < 1) {
    return `${sizeInBytes.toPrecision(2)} B`;
  }
  const sizeInMegabytes = sizeInKilobytes / 1000;
  if (sizeInMegabytes < 1) {
    return `${sizeInKilobytes.toPrecision(2)} kB`;
  }
  const sizeInGigabytes = sizeInMegabytes / 1000;
  if (sizeInGigabytes < 1) {
    return `${sizeInMegabytes.toPrecision(2)} MB`;
  }
  return `${sizeInGigabytes.toPrecision(2)} GB`;
};

export const importJS = async (blob: Blob) => {
  const code = await blob.text();
  return import(/* @vite-ignore */ `data:text/javascript,${code}; export default transform;`);
};

export const searchMatch = (value?: JSONType, search?: string) => {
  if (!search) return true;
  if (!value) return false;
  return JSON.stringify(value).toLowerCase().includes(search.toLowerCase());
};

export const objectEqual = (a: JSONType, b: JSONType) => {
  // TODO: Sort keys
  const aStr = JSON.stringify(a);
  const bStr = JSON.stringify(b);
  return aStr === bStr;
};

export const isDocumentId = (id?: string | null) => {
  if (!id) return false;
  return id.startsWith('las:document:');
};

export const isActionId = (id?: string | null) => {
  if (!id) return false;
  return id.startsWith('las:action:');
};

export const findModelId = (project?: Project | null) => {
  return project?.resourceIds.findLast((r) => r.startsWith('las:model:'));
};

export const findValidationId = (project?: Project | null) => {
  return project?.resourceIds.findLast((r) => r.startsWith('las:validation:'));
};

export const findPredictionId = (projectRun?: ProjectRun | null) => {
  return projectRun?.resourceIds.findLast((r) => r.startsWith('las:prediction:'));
};

export const findDocumentId = (projectRun?: ProjectRun | null) => {
  return projectRun?.resourceIds.findLast((r) => r.startsWith('las:document:'));
};

export const findValidationTaskId = (projectRun?: ProjectRun | null) => {
  return projectRun?.resourceIds.findLast((r) => r.startsWith('las:validation-task:'));
};

export const findActionIds = (project?: Project | null) => {
  return project?.resourceIds.filter(isActionId) ?? [];
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const parseNumber = (value: any, predicate?: (value: number) => boolean) => {
  if (typeof value === 'string') value = Number.parseFloat(value);
  if (typeof value !== 'number') return;
  if (isNaN(value)) return;
  if (!predicate || predicate?.(value)) return value;
};

export const toDataUrl = (file: File | Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const toAnnotation = (formValue: FormValue): Annotation => {
  return {
    rawValue: formValue.current.rawValue,
    value: formValue.current.value,
    confidence: formValue.current.confidence,
  };
};

export const toAnnotations = (fieldConfig: FieldConfig, formValues: FormValues): Annotations => {
  const annotations: Annotations = {};

  for (const [id, config] of Object.entries(fieldConfig)) {
    const values = formValues[id];
    if (!values) continue;
    if (isArray(values)) {
      /* Convert table form values to line annotations for field */
      if (config.type === 'table') {
        const rows: TableAnnotations = [];
        for (const row of values) {
          const rowAnnotations: Annotations = {};
          for (const [subId, subValue] of Object.entries(row.columns)) {
            rowAnnotations[subId] = [toAnnotation(subValue)];
          }
          rows.push(rowAnnotations);
        }
        annotations[id] = rows;

        /* Convert table form values to multi value annotations for field */
      } else if (config.type === 'multi-value') {
        annotations[id] = values.map((v) => toAnnotation(v.columns[MULTI_VALUE_COLUMN_NAME]));
      }

      /* Convert form values to single value annotations for field */
    } else {
      annotations[id] = [toAnnotation(values)];
    }
  }

  return annotations;
};

const findAnnotations = (id: string, ...annotationsArgs: Annotations[]) => {
  for (const annotations of annotationsArgs) {
    const headerOrTableAnnotations = annotations[id];
    if (headerOrTableAnnotations) return headerOrTableAnnotations;
  }
};

const createWarningsAndErrors = (warnings?: string[] | null, errors?: string[] | null) => {
  return {
    errorMap: {
      onMount: (errors ?? []).map((e, i) => ({ id: String(i), errors: [e] }) as FormatterError),
    },
    warnings: (warnings ?? []).map((w, i) => ({ id: String(i), warnings: [w] }) as FormatterWarning),
  };
};

export const toFormValues = (fieldConfig: FieldConfig, ...annotationsArgs: Annotations[]): FormValues => {
  const formValues: FormValues = {};
  const formMeta: Record<string, any> = {};
  const createDefaultValue = () => ({ current: { value: '' }, candidates: [] });

  for (const [id, config] of Object.entries(fieldConfig)) {
    const values = structuredClone(findAnnotations(id, ...annotationsArgs));

    /* Cannot find any annotations for field */
    if (!values) {
      formValues[id] = config.type === 'table' || config.type === 'multi-value' ? [] : createDefaultValue();
      continue;
    }

    /* Convert line annotations to table form values for field */
    if (config.type === 'table') {
      if (!config.fields) continue;
      const rowValues: FormValueRow[] = [];

      for (const [i, row] of (values as TableAnnotations).entries()) {
        const rowId = generateId();
        const rowValue: FormValueRow = { id: rowId, columns: {} };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [subId, _] of Object.entries(config.fields)) {
          const value = row[subId];

          if (value && value.length > 0) {
            const current = value[0];

            rowValue.columns[subId] = {
              current: current,
              candidates: value,
            };
            const metaId = `${id}[${i}].columns.${subId}`;
            formMeta[metaId] = createWarningsAndErrors(current.warnings, current.errors);
          } else {
            rowValue.columns[subId] = createDefaultValue();
          }
        }
        rowValues.push(rowValue);
      }

      formValues[id] = rowValues;

      /* Convert multi value annotations to table form values for field */
    } else if (config.type === 'multi-value') {
      const multiValues: FormValueRow[] = [];

      for (const [i, multiValue] of (values as HeaderAnnotations).entries()) {
        const multiId = generateId();

        multiValues.push({
          id: multiId,
          columns: {
            [MULTI_VALUE_COLUMN_NAME]: {
              current: multiValue,
              candidates: [multiValue],
            },
          },
        });

        const metaId = `${id}[${i}].columns.${MULTI_VALUE_COLUMN_NAME}`;
        formMeta[metaId] = createWarningsAndErrors(multiValue.warnings, multiValue.errors);
      }

      formValues[id] = multiValues;

      /* Convert single value annotations to form values for field */
    } else {
      const value = values as HeaderAnnotations;

      if (value.length > 0) {
        const current = value[0];
        formValues[id] = {
          current: current,
          candidates: value,
        };
        formMeta[id] = createWarningsAndErrors(current.warnings, current.errors);
      } else {
        formValues[id] = createDefaultValue();
      }
    }
  }

  return { values: formValues, meta: formMeta };
};

export const getScopes = (jwt?: JSONType) => {
  const scopes = jwt?.scope || '';
  if (typeof scopes !== 'string') {
    return [];
  } else {
    return scopes.split(' ').map((scope) => (scope.includes('/') ? scope.split('/')[1] : scope)) || [];
  }
};

export const jsonToList = <T extends object>(obj?: JSONValue): T[] => {
  if (!obj) return [];

  if (Array.isArray(obj)) {
    return obj as T[];
  }

  throw new Error('Object is not a list');
};

export const createLocalStorageKey = (key: string) => {
  return `cradl:${key}`;
};

export const isNotConfiguredExport = (action?: Action) => {
  if (!action) return false;

  switch (action.functionId) {
    case EXPORT_TO_EXCEL_FUNCTION_ID:
      if (!('documentId' in action.config)) return true;
      if (!('sheetId' in action.config)) return true;
      break;
    case EXPORT_TO_POWER_AUTOMATE_FUNCTION_ID:
      if (!('url' in action.config)) return true;
      break;
    case EXPORT_TO_WEBHOOK_FUNCTION_ID:
      if (!('url' in action.config)) return true;
      break;
    case EXPORT_TO_ZAPIER_FUNCTION_ID:
      if (!('url' in action.config)) return true;
      break;
  }
  return false;
};
