import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import PlusIcon from '@/assets/plus-icon.svg?react';
import SortIcon from '@/assets/sort-icon.svg?react';
import { merge } from '@/components';
import { Button, Label, Menu, MenuItem, Popover, Table, Text } from '@/components/core';
import { SortingFilter, useInboxStore } from '@/store';

import { AscendingCard } from './AscendingCard.tsx';
import { DescendingCard } from './DescendingCard.tsx';
import { SortColumnCard } from './SortColumnCard.tsx';

export type InboxSortProps = object;

// eslint-disable-next-line no-empty-pattern
const IInboxSort = ({}: InboxSortProps) => {
  const sorting = useInboxStore((state) => state.sorting);
  const updateSorting = useInboxStore((state) => state.updateSorting);
  const moveSorting = useInboxStore((state) => state.moveSorting);
  const columns = useMemo<ColumnDef<SortingFilter>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Sort column',
        cell: ({ row }) => {
          return (
            <div className="rounded-lg border border-gray-200 hover:bg-gray-50">
              <MenuTrigger>
                <Button className="group/order-button w-full" variant="plain">
                  <SortColumnCard columnName={row.original.id}>
                    <div className="grow" />
                    <ChevronDownIcon
                      className={merge('stroke-gray-400 group-data-[pressed]/order-button:rotate-180')}
                    />
                  </SortColumnCard>
                </Button>
                <Popover>
                  <Menu>
                    {sorting
                      .filter((s) => s.hidden)
                      .map((s) => (
                        <MenuItem
                          key={s.id}
                          onAction={() =>
                            updateSorting({
                              [row.original.id]: { hidden: true },
                              [s.id]: { hidden: false, desc: row.original.desc },
                            })
                          }
                        >
                          <SortColumnCard columnName={s.id} />
                        </MenuItem>
                      ))}
                  </Menu>
                </Popover>
              </MenuTrigger>
            </div>
          );
        },
      },
      {
        accessorKey: 'desc',
        header: 'order',
        cell: ({ row }) => {
          return (
            <div className="rounded-lg border border-gray-200 hover:bg-gray-50">
              <MenuTrigger>
                <Button className="group/order-button w-full" variant="plain">
                  {row.original.desc ? (
                    <DescendingCard>
                      <div className="grow" />
                      <ChevronDownIcon
                        className={merge('stroke-gray-400 group-data-[pressed]/order-button:rotate-180')}
                      />
                    </DescendingCard>
                  ) : (
                    <AscendingCard>
                      <div className="grow" />
                      <ChevronDownIcon
                        className={merge('stroke-gray-400 group-data-[pressed]/order-button:rotate-180')}
                      />
                    </AscendingCard>
                  )}
                </Button>
                <Popover>
                  <Menu>
                    <MenuItem onAction={() => updateSorting({ [row.original.id]: { desc: false } })}>
                      <AscendingCard />
                    </MenuItem>
                    <MenuItem onAction={() => updateSorting({ [row.original.id]: { desc: true } })}>
                      <DescendingCard />
                    </MenuItem>
                  </Menu>
                </Popover>
              </MenuTrigger>
            </div>
          );
        },
      },
    ],
    [sorting, updateSorting]
  );

  const numSortedColumns = sorting.filter((s) => !s.hidden).length;

  return (
    <MenuTrigger>
      <Button className="text-gray-400" variant="secondary">
        <SortIcon className="size-5 stroke-gray-400" />
        Sort{numSortedColumns ? ` / ${numSortedColumns}` : null}
      </Button>
      <Popover className="flex flex-col gap-2 p-2">
        <Text size="sm">Sort by</Text>
        <Table
          columns={columns}
          data={sorting}
          deleteRowAction={(row) => updateSorting({ [row.original.id]: { hidden: true } })}
          onRowIndexChange={(oldIndex, newIndex) => moveSorting(oldIndex, newIndex)}
          hideHeader
        />
        <MenuTrigger>
          {/* TODO: Button uses full width. Remove unnecessary div when possible */}
          <div>
            <Button className="group/order-button hover:bg-gray-50" variant="plain">
              <PlusIcon className="size-4 stroke-gray-400" />
              <Text size="sm">New sort</Text>
            </Button>
          </div>
          <Popover>
            <Menu>
              {sorting
                .filter((s) => s.hidden)
                .map((s) => (
                  <MenuItem key={s.id} onAction={() => updateSorting({ [s.id]: { hidden: false } })}>
                    <SortColumnCard columnName={s.id} />
                  </MenuItem>
                ))}
            </Menu>
          </Popover>
        </MenuTrigger>
      </Popover>
    </MenuTrigger>
  );
};

export const InboxSort = memo(IInboxSort) as typeof IInboxSort;
