import { Field } from '@lucidtech/las-sdk-browser';
import React, { useCallback, useContext, useMemo, useState } from 'react';

type TableValidationState = {
  field?: Field;
  label?: string;
  setCurrent?: (label: string, field: Field) => void;
};

type OnPageScrollHandler = (page: number) => void;

type ValidationContextProps = {
  currentTable?: TableValidationState;
  isTableView?: boolean;
  hideTableView?: () => void;
  tableViewIsHidden?: boolean;
  onScrollToPage?: (callback: OnPageScrollHandler) => void;
  scrollToPage?: OnPageScrollHandler;
  detachTable?: boolean;
  setDetachTable?: (v: boolean) => void;
};

export const ValidationContext = React.createContext<ValidationContextProps>({});

export const useCreateValidationContext = () => {
  const [currentTableField, setCurrentTableField] = useState<Field>();
  const [currentLabel, setCurrentLabel] = useState<string>();
  const [tableViewIsHidden, setHideTableView] = useState(true);
  const [scrollToPage, setScrollToPage] = useState<OnPageScrollHandler>();
  const [detachTable, setDetachTable] = useState(false);

  const onScrollToPage = useCallback((cb: OnPageScrollHandler) => setScrollToPage(() => cb), [setScrollToPage]);

  const context = useMemo(() => {
    return {
      currentTable: {
        setCurrent: (label: string, field: Field) => {
          setCurrentLabel(label);
          setCurrentTableField(field);
          setHideTableView(false);
        },
        field: currentTableField,
        label: currentLabel,
      },
      hideTableView: (shouldHide?: boolean) => setHideTableView(shouldHide ?? true),
      tableViewIsHidden,
      detachTable,
      setDetachTable,
      onScrollToPage,
      scrollToPage,
    };
  }, [currentLabel, currentTableField, detachTable, onScrollToPage, scrollToPage, tableViewIsHidden]);

  return context;
};

export const useValidation = () => {
  return useContext(ValidationContext);
};
