import SampleDocument from '@/assets/sampleDocuments/Resume.pdf';
import { TemplateModel } from '@/components/ui';

import { highConfidenceValidator, mediumConfidenceValidator } from './common';

export const RESUME: TemplateModel = {
  fields: [
    {
      id: 'full_name',
      type: 'single-value',
      name: 'Full name',
      promptHint: 'Full name of the person.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'email',
      type: 'single-value',
      name: 'Email address',
      promptHint: 'The email address of the person.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'phone_number',
      type: 'single-value',
      name: 'Phone number',
      promptHint: 'The phone number of the person.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'work_experience',
      type: 'table',
      name: 'Work experience',
      promptHint: 'Previous work experience',
      fields: [
        {
          id: 'employee_name',
          type: 'single-value',
          name: 'Employee name',
          promptHint: 'The name of the employee',
          validators: [mediumConfidenceValidator],
        },
        {
          id: 'from_year',
          type: 'single-value',
          name: 'Start year',
          promptHint: 'The year this employment started',
          validators: [highConfidenceValidator],
        },
        {
          id: 'to_year',
          type: 'single-value',
          name: 'End year',
          promptHint: 'The year this employment ended',
          validators: [highConfidenceValidator],
        },
      ],
    },
    {
      id: 'education',
      type: 'table',
      name: 'Education',
      promptHint: 'Education',
      fields: [
        {
          id: 'educational_institution',
          type: 'single-value',
          name: 'Institution',
          promptHint: 'The name of the educational institution',
          validators: [mediumConfidenceValidator],
        },
        {
          id: 'from_year',
          type: 'single-value',
          name: 'Start year',
          promptHint: 'The start year.',
          validators: [highConfidenceValidator],
        },
        {
          id: 'to_year',
          type: 'single-value',
          name: 'End year',
          promptHint: 'The end year.',
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
