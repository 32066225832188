import type { FieldFormatter, FieldValidator } from '@lucidtech/las-sdk-browser';

import {
  FORMATTER_CLASSIFICATION_FUNCTION_ID,
  FORMATTER_DATE_FUNCTION_ID,
  FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  FORMATTER_NUMERIC_FUNCTION_ID,
  VALIDATOR_CONFIDENCE_FUNCTION_ID,
} from '@/constants';

import country_codes from './country_codes.ts'
import currencies from './currencies.ts';

export const mediumConfidenceValidator: FieldValidator = {
  functionId: VALIDATOR_CONFIDENCE_FUNCTION_ID,
  name: 'Confidence over 80%',
  description: 'Automatically validate all AI predictions where the confidence is over 80%',
  config: {
    thresholds: { automate: 0.8 },
  },
};

export const highConfidenceValidator: FieldValidator = {
  functionId: VALIDATOR_CONFIDENCE_FUNCTION_ID,
  name: 'Confidence over 90%',
  description: 'Automatically validate all AI predictions where the confidence is over 90%',
  config: {
    thresholds: { automate: 0.9 },
  },
};

export const amountFormatter: FieldFormatter = {
  functionId: FORMATTER_NUMERIC_FUNCTION_ID,
  name: 'Amount',
  description: 'Removes thousand separators and enforces two decimals',
  config: {},
};

export const currencyFormatter: FieldFormatter = {
  functionId: FORMATTER_CLASSIFICATION_FUNCTION_ID,
  name: 'Currency',
  description: 'Enforce a valid ISO currency',
  config: {
    labels: currencies,
    catchAll: 'Unknown',
  },
};

export const genderFormatter: FieldFormatter = {
  functionId: FORMATTER_CLASSIFICATION_FUNCTION_ID,
  name: 'Gender',
  description: 'Enforce a gender category',
  config: {
    labels: [
      { value: 'MALE', description: 'Male' },
      { value: 'FEMALE', description: 'Female' },
      { value: 'OTHER', description: 'Other' },
    ],
    catchAll: 'Unknown',
  },
};

export const countryCodeFormatter: FieldFormatter = {
  functionId: FORMATTER_CLASSIFICATION_FUNCTION_ID,
  name: 'ISO Alpha-2 country code',
  description: 'Enforce a valid ISO alpha-2 country code',
  config: {
    labels: country_codes,
    catchAll: 'Unknown',
  },
};

export const dateFormatter: FieldFormatter = {
  functionId: FORMATTER_DATE_FUNCTION_ID,
  name: 'ISO Date',
  description: 'Convert to an ISO date format (YYYY-MM-DD)',
  config: {},
};

export const ibanFormatter: FieldFormatter = {
  functionId: FORMATTER_IBAN_NUMBER_FUNCTION_ID,
  name: 'Parse as IBAN',
  description: 'Removes separators such as whitespaces and punctuation',
  config: {},
};

export const numericFormatter: FieldFormatter = {
  functionId: FORMATTER_NUMERIC_FUNCTION_ID,
  name: 'Numeric',
  description: 'Removes thousand separators',
  config: {},
};
