import { toActionId, toProjectId, useBatchGetActions, useGetProject } from '@/hooks/api';
import { findActionIds, isNotConfiguredExport } from '@/utils';

export type UseGetNotConfiguredExportsOps = {
  projectId?: string | null;
};

export const useGetNotConfiguredExports = ({ projectId }: UseGetNotConfiguredExportsOps) => {
  const { data: project } = useGetProject(toProjectId(projectId));
  const actionIds = findActionIds(project);
  const { data: actions } = useBatchGetActions(actionIds.map(toActionId));
  return { actions: actions?.filter(isNotConfiguredExport) };
};
