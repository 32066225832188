import { createFileRoute, notFound } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { Billing } from '@/features/settings';

const BillingSearch = z
  .object({
    redirect_status: fallback(z.optional(z.string()), undefined),
    setup_intent_client_secret: fallback(z.optional(z.string()), undefined),
  })
  .transform((data) => ({
    redirectStatus: data.redirect_status,
    setupIntentSecret: data.setup_intent_client_secret,
  }));

export const Route = createFileRoute('/_auth/settings/billing')({
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth.client) throw notFound();
  },
  validateSearch: zodValidator(BillingSearch),
  component: Billing,
  notFoundComponent: () => {
    return <p>Workspace not found!</p>;
  },
});
