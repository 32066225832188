import { FieldValidatorWithComingSoon } from '@/components/ui';
import {
  VALIDATOR_CONFIDENCE_FUNCTION_ID,
  VALIDATOR_CUSTOM_CODE_FUNCTION_ID,
  VALIDATOR_DATABASE_MATCH_FUNCTION_ID,
  VALIDATOR_LOGEX_FUNCTION_ID,
  VALIDATOR_REGEX_FUNCTION_ID,
} from '@/constants';

const VALIDATOR_CONFIDENCE: FieldValidatorWithComingSoon = {
  functionId: VALIDATOR_CONFIDENCE_FUNCTION_ID,
  name: 'Confidence threshold',
  description: 'Check that field has confidence above threshold',
  config: { thresholds: { automate: 0.9 } },
};
const VALIDATOR_LOGEX: FieldValidatorWithComingSoon = {
  functionId: VALIDATOR_LOGEX_FUNCTION_ID,
  name: 'Logical expression',
  description: 'Simple arithmetic and/or logical operations',
  config: {},
};
const VALIDATOR_REGEX: FieldValidatorWithComingSoon = {
  functionId: VALIDATOR_REGEX_FUNCTION_ID,
  name: 'RegEx',
  description: 'String match with RegEx',
  config: {},
};
const VALIDATOR_DATABASE_MATCH: FieldValidatorWithComingSoon = {
  functionId: VALIDATOR_DATABASE_MATCH_FUNCTION_ID,
  name: 'Database match',
  description: 'Verify that value exists in database',
  config: {},
  comingSoon: true,
};
const VALIDATOR_CUSTOM_CODE: FieldValidatorWithComingSoon = {
  functionId: VALIDATOR_CUSTOM_CODE_FUNCTION_ID,
  name: 'Custom JS',
  description: 'Write your own JS validator',
  config: {},
  comingSoon: true,
};

const POPULAR_VALIDATORS = [VALIDATOR_REGEX, VALIDATOR_CONFIDENCE, VALIDATOR_LOGEX];
const STRING_VALIDATORS = [VALIDATOR_REGEX, VALIDATOR_DATABASE_MATCH];
const DATE_TIME_VALIDATORS = [VALIDATOR_LOGEX];
const NUMBER_VALIDATORS = [VALIDATOR_CONFIDENCE];
const CUSTOM_VALIDATORS = [VALIDATOR_CUSTOM_CODE];

export type ValidatorsType = Record<string, FieldValidatorWithComingSoon[]>;
export const DEFAULT_VALIDATOR_CATEGORY = 'Popular';
export const VALIDATORS: ValidatorsType = {
  [DEFAULT_VALIDATOR_CATEGORY]: POPULAR_VALIDATORS,
  Strings: STRING_VALIDATORS,
  Numbers: NUMBER_VALIDATORS,
  'Date & time': DATE_TIME_VALIDATORS,
  Custom: CUSTOM_VALIDATORS,
};
