import { memo } from 'react';

import CountryCodeIcon from '@/assets/country-code-icon.svg?react';
import { FormatterValidatorLogo, VariantLogoProps } from '@/components/ui';

export type CountryCodeLogoProps = VariantLogoProps;

const ICountryCodeLogo = ({ ...rest }: CountryCodeLogoProps) => {
  return <FormatterValidatorLogo Icon={CountryCodeIcon} iconColoringType="stroke" {...rest} />;
};

export const CountryCodeLogo = memo(ICountryCodeLogo) as typeof ICountryCodeLogo;
