import { PropsWithChildren, useRef } from 'react';

import { createProfileStore, ProfileContext, ProfileProps, ProfileStore } from './profileStore';

type ProfileProviderProps = PropsWithChildren<ProfileProps> & {
  profileId: string;
};

export const ProfileProvider = ({ children, ...props }: ProfileProviderProps) => {
  const storeRef = useRef<ProfileStore>(null);

  if (!storeRef.current) {
    storeRef.current = createProfileStore(props);
  }

  return <ProfileContext.Provider value={storeRef.current}>{children}</ProfileContext.Provider>;
};
