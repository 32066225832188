import type { EnumValue } from '@lucidtech/las-sdk-browser';
import { memo, useMemo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import { Badge, Button, Menu, MenuItem, Popover, Text } from '@/components/core';
import { FieldValueState, FormValue } from '@/store';

type EnumCandidate = FieldValueState & {
  description?: string;
};

export type EnumMenuProps = {
  formValue: FormValue;
  enumValues?: EnumValue[];
  onAction: (fieldValue: FieldValueState) => void;
};

const IEnumMenu = ({ formValue, enumValues, onAction }: EnumMenuProps) => {
  const candidates: EnumCandidate[] | undefined = useMemo(() => {
    const getConfidence = (value: string) => {
      return formValue.candidates.find((c) => c.value === value)?.confidence;
    };
    return enumValues
      ?.map((ev) => ({ ...ev, rawValue: ev.value, confidence: getConfidence(ev.value) }))
      .sort((a, b) => (b.confidence ?? 0) - (a.confidence ?? 0));
  }, [enumValues, formValue.candidates]);

  if (!candidates) return null;

  return (
    <MenuTrigger>
      <Button excludeFromTabOrder variant="plain">
        <ChevronDownIcon className="size-4 stroke-gray-400" />
      </Button>
      <Popover>
        <Menu className="flex h-[50vh] w-64 flex-col overflow-auto bg-white">
          {candidates.map((candidate, i) => {
            return (
              <MenuItem onAction={() => onAction(candidate)} key={i} className="flex cursor-pointer items-center">
                <Text className="font-semibold" size="md">
                  {candidate.value}
                </Text>
                {candidate.description && <Text size="sm">{candidate.description}</Text>}
                <div className="min-w-16 grow" />
                {candidate.confidence && <Badge variant="success" label={`${candidate.confidence * 100}%`} />}
              </MenuItem>
            );
          })}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

export const EnumMenu = memo(IEnumMenu) as typeof IEnumMenu;
