import { memo } from 'react';

import { Badge, BadgeProps } from '@/components/core';

export type ComingSoonProps = Omit<BadgeProps, 'variant' | 'label'>;

const IComingSoon = ({ className, ...rest }: ComingSoonProps) => {
  return <Badge className={className} variant="success" label="Coming soon" {...rest} />;
};

export const ComingSoon = memo(IComingSoon) as typeof IComingSoon;
