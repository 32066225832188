import type { ProjectRun } from '@lucidtech/las-sdk-browser';
import { useMatchRoute } from '@tanstack/react-router';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { memo, useMemo } from 'react';

import { ProjectRunDocumentCard } from '@/components/ui';

import { ProjectRunsTable } from '../inbox/ProjectRunsTable.tsx';
import { StatusCompact } from '../StatusCompact.tsx';
import { Panel } from './Panel.tsx';

export type DetailsProjectRunsPanelProps = {
  projectId: string;
};

const IDetailsProjectRunsPanel = ({ projectId }: DetailsProjectRunsPanelProps) => {
  const matchRoute = useMatchRoute();

  const columns = useMemo<AccessorKeyColumnDef<ProjectRun>[]>(() => {
    return [
      {
        // Setting resourceIds will cause lag?
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          const matchedRoute = matchRoute({ to: '/details/projects/$projectId/runs/$runId' });
          const selected = matchedRoute && matchedRoute.runId === row.original.runId;
          if (selected) return <div className="border-brand-500 bg-brand-25 size-4 rounded-full border-4" />;
          return <StatusCompact projectId={row.original.projectId} runId={row.original.runId} />;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sortingFn: (rowA, rowB, _) => {
          const statusA = rowA.original.status;
          const statusB = rowB.original.status;
          const statusOrder = ['Pending predictions', 'Ready for review', 'Exported', 'Archived'];
          return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
        },
        size: 40,
      },
      {
        accessorKey: 'file',
        header: 'File',
        cell: ({ row }) => <ProjectRunDocumentCard projectId={row.original.projectId} runId={row.original.runId} />,
        size: 300,
      },
      {
        accessorKey: 'createdBy',
        cell: () => null,
        size: 0,
      },
      {
        accessorKey: 'createdTime',
        cell: () => null,
        size: 0,
      },
    ];
  }, [matchRoute]);

  return (
    <Panel>
      <ProjectRunsTable
        projectId={projectId}
        columns={columns}
        hideHeaders
        hideSelectionColumn
        props={{
          outerContainer: { className: 'border-none h-[calc(100vh-theme(height.14))]' },
          row: { className: 'border-none' },
        }}
      />
    </Panel>
  );
};

export const DetailsProjectRunsPanel = memo(IDetailsProjectRunsPanel) as typeof IDetailsProjectRunsPanel;
