import { memo } from 'react';
import { MenuTrigger } from 'react-aria-components';

import CalendarIcon from '@/assets/calendar-icon.svg?react';
import { merge } from '@/components';
import { Button, DatePicker, Menu, MenuItem, Popover, Text } from '@/components/core';
import { DateColumnFilter, DateConditions, useInboxStore } from '@/store';
import { generateId } from '@/utils';

import { FilterCard, FilterCardSubType } from './FilterCard.tsx';

export type DateFilterCardProps = FilterCardSubType & {
  columns: string[];
  filter: DateColumnFilter;
};

const IDateFilterCard = ({ columns, filter, className, ...rest }: DateFilterCardProps) => {
  const updateFilter = useInboxStore((state) => state.updateFilter);
  const deleteFilter = useInboxStore((state) => state.deleteFilter);

  return (
    <FilterCard onDelete={() => deleteFilter(filter.id)} className={merge('', className)} {...rest}>
      <MenuTrigger>
        <Button className="p-1 hover:bg-gray-100" variant="plain">
          {filter.columnName ? (
            <>
              <CalendarIcon className="size-5 stroke-gray-500" />
              <Text className="text-gray-500" size="sm">
                {filter.columnName}
              </Text>
            </>
          ) : (
            <Text className="pl-1 text-gray-300" size="sm">
              Select column
            </Text>
          )}
        </Button>
        <Popover>
          <Menu>
            {columns
              .filter((c) => c !== filter.columnName)
              .filter((c) => ['createdTime', 'updatedTime'].includes(c))
              .map((c) => (
                <MenuItem onAction={() => updateFilter(filter.id, { columnName: c })}>
                  <Text size="sm">{c}</Text>
                </MenuItem>
              ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      <MenuTrigger>
        <Button className="p-1 hover:bg-gray-100" variant="plain">
          {filter.condition ? (
            <Text size="sm">{filter.condition}</Text>
          ) : (
            <Text className="text-gray-300" size="sm">
              Select condition
            </Text>
          )}
        </Button>
        <Popover>
          <Menu>
            {DateConditions.map((condition) => (
              <MenuItem onAction={() => updateFilter(filter.id, { condition })}>
                <Text size="sm">{condition}</Text>
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      <DatePicker
        label={filter.columnName ?? generateId()}
        date={filter.value}
        onDateChange={(value) => updateFilter(filter.id, { value })}
      />
    </FilterCard>
  );
};

export const DateFilterCard = memo(IDateFilterCard) as typeof IDateFilterCard;
