import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { Organizations } from '@/features/organizations';
import { listOrganizationOptions } from '@/hooks/api';

const OrganizationsSearchSchema = z.object({
  redirect: z.optional(z.string()),
});

export const Route = createFileRoute('/_globalAuth/organizations/')({
  beforeLoad: async ({ context: { globalAuth, queryClient } }) => {
    if (!globalAuth.client) throw notFound();
    const { data: organizations } = await queryClient.ensureQueryData(listOrganizationOptions(globalAuth.client));
    if (organizations && organizations.length < 1) {
      throw redirect({ to: '/onboarding' });
    }
  },
  validateSearch: zodValidator(OrganizationsSearchSchema),
  component: Organizations,
});
