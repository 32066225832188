import { Popover as Popover_, PopoverProps as PopoverProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type PopoverProps = PopoverProps_;

export const Popover = ({ className, children, ...rest }: PopoverProps) => {
  /* prettier-ignore */
  const styles = [
    'border',
    'border-gray-200',
    'outline-none',
    'rounded-lg',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'bg-white',
  ];

  return (
    <Popover_ className={merge(styles, lightStyles, className)} {...rest}>
      {children}
    </Popover_>
  );
};
