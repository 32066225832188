import { FieldMeta } from '@tanstack/react-form';
import { JwtPayload } from 'jwt-decode';
import { boolean, number, string } from 'mathjs';
import {
  DetailedHTMLProps,
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  SVGProps as SVGProps_,
  TableHTMLAttributes,
} from 'react';
// @ts-expect-error cannot find module
import { JSONObject, JSONValue } from 'superjson/dist/types';

export type JSONType = JSONObject;

export type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export type SVGProps = SVGProps_<SVGSVGElement>;
export type TableProps = DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;
export type TableRowProps = DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
export type HrProps = DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement>;

export type OmitChildren<P = unknown> = Omit<P, 'children'>;

export const keys = <TObject extends object>(value: TObject) => {
  return Object.keys(value) as (keyof typeof value)[];
};

export const isNumber = (value: unknown, predicate?: (value: number) => boolean): value is number => {
  if (typeof value !== 'number') return false;
  if (predicate) return predicate?.(value);
  return true;
};

export const isArray = (value: unknown) => {
  return Array.isArray(value);
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isInArray = <TArrayData>(array: readonly TArrayData[], value: any): value is TArrayData => {
  return array.includes(value);
};

export const toString = (value?: JSONValue | null, defaultValue: string = ''): string => {
  if (value == null) return defaultValue;
  return string(value);
};

export const toNumber = (value?: JSONValue | null, defaultValue: number = 0): number => {
  if (value == null) return defaultValue;
  return number(value);
};

export const toBoolean = (value?: JSONValue, defaultValue: boolean = false): boolean => {
  if (value == null) return defaultValue;
  return boolean(value);
};

export type Id = { id: string; hidden?: boolean };

export type FieldStateValueType = {
  confidence?: number;
  value?: string | null;
  rawValue?: string | null;
  isTouched?: boolean;
};
export type FieldStateArrayType = Record<string, FieldStateValueType>[];
export type FieldStateType = Record<string, FieldStateValueType | FieldStateArrayType>;

export type FieldContextType = {
  name: string;
  fields: FieldStateType;
  config: JSONType;
};

export type FormatterError = {
  id: string;
  errors: string[];
};

export type FormatterWarning = {
  id: string;
  warnings: string[];
};

export type FormatterFnReturnType = {
  result?: FieldStateValueType;
  errors?: string[];
  warnings?: string[];
};

export type ValidatorFnReturnType = FormatterFnReturnType;

export type FormatterFnType = (state: FieldStateValueType, context: FieldContextType) => FormatterFnReturnType;
export type ValidatorFnType = FormatterFnType;

export type SetState<TState> = Dispatch<SetStateAction<TState>>;

export type AttentionMap = number[][];

export type Annotation = {
  rawValue?: string | null;
  confidence?: number;
  value?: string | null;
  attentionMap?: AttentionMap;
  page?: number;
  errors?: string[] | null;
  warnings?: string[] | null;
};

export type HeaderAnnotations = Annotation[];
export type TableAnnotations = Record<string, Annotation[]>[];
export type Annotations = Record<string, HeaderAnnotations | TableAnnotations>;

export interface FieldMetaWithWarnings extends FieldMeta {
  warnings?: FormatterWarning[];
  errors?: FormatterError[];
}

export type JWT = JwtPayload & { scope: string };
