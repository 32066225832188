import { createContext } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface OrganizationProps {
  organizationId?: string | null;
  name?: string | null;
  planId?: string | null;
  paymentMethodId?: string | null;
  pictureUrl?: string | null;
  picture?: Blob | null;
  url?: string | null;
}

export interface OrganizationState extends OrganizationProps {
  update: (updates: Partial<OrganizationProps>) => void;
}

export const createOrganizationStore = (initProps?: Partial<OrganizationProps>) => {
  return createStore<OrganizationState>()(
    devtools(
      immer((set) => ({
        ...initProps,
        update: (updates) =>
          set((state) => {
            if (updates.name) {
              state.name = updates.name;
            }
            if (updates.planId) {
              state.planId = updates.planId;
            }
            if (updates.paymentMethodId) {
              state.paymentMethodId = updates.paymentMethodId;
            }
            if (updates.picture) {
              state.picture = updates.picture;
            }
            if (updates.url) {
              state.url = updates.url;
            }
          }),
      }))
    )
  );
};

export type OrganizationStore = ReturnType<typeof createOrganizationStore>;

export const OrganizationContext = createContext<OrganizationStore | null>(null);
