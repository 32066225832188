import { memo } from 'react';

import {
  TriggerEmailConfiguration,
  TriggerPanelHeader,
  TriggerPowerAutomateConfiguration,
  TriggerZapierConfiguration,
} from '@/components/flow';
import {
  CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID,
  CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID,
} from '@/constants';
import { useSuspenseGetAction } from '@/hooks/api';
import { TriggerProvider } from '@/store';

export type TriggerPanelProps = {
  projectId: string;
  actionId: string;
};

const ITriggerPanel = ({ projectId, actionId }: TriggerPanelProps) => {
  const { data: action } = useSuspenseGetAction({ actionId });

  return (
    /* Force remount of component when projectId or actionId changes */
    <div key={`${projectId}/${actionId}`}>
      <TriggerProvider action={action}>
        <TriggerPanelHeader className="border-b border-gray-200 p-4" actionId={actionId} />
        {action.functionId === CREATE_DOCUMENT_FROM_EMAIL_FUNCTION_ID ? (
          <TriggerEmailConfiguration />
        ) : action.functionId === CREATE_DOCUMENT_FROM_POWER_AUTOMATE_FUNCTION_ID ? (
          <TriggerPowerAutomateConfiguration />
        ) : action.functionId === CREATE_DOCUMENT_FROM_ZAPIER_FUNCTION_ID ? (
          <TriggerZapierConfiguration />
        ) : null}
      </TriggerProvider>
    </div>
  );
};

export const TriggerPanel = memo(ITriggerPanel) as typeof ITriggerPanel;
