import { ApiObject, RudderAnalytics } from '@rudderstack/analytics-js';
import { useCallback, useEffect, useState } from 'react';

export const useRudderAnalytics = () => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>();

  useEffect(() => {
    if (!analytics) {
      const writeKey = import.meta.env.VITE_RUDDER_WRITE_KEY;
      const analyticsInstance = new RudderAnalytics();
      analyticsInstance.load(writeKey, import.meta.env.VITE_RUDDER_DATAPLANE_URL);
      setAnalytics(analyticsInstance);
    }
  }, [analytics]);

  const track = useCallback(
    <T extends (...args: unknown[]) => unknown>(event: string, fn: T, opts?: ApiObject): T => {
      return ((...args: Parameters<T>): ReturnType<T> => {
        const result = fn(...args) as ReturnType<T>;

        if (result instanceof Promise) {
          return result.then((res) => {
            analytics?.track(event, opts);
            return res;
          }) as ReturnType<T>;
        } else {
          analytics?.track(event, opts);
        }

        return result;
      }) as T;
    },
    [analytics]
  );
  return { analytics, track };
};
