import { memo } from 'react';

import EmailIcon from '@/assets/email-fill-icon.svg?react';
import { merge } from '@/components';
import { Logo, LogoProps } from '@/components/core';

export type EmailLogoProps = LogoProps;

const IEmailLogo = ({ className, ...rest }: EmailLogoProps) => {
  return (
    <Logo className={merge('bg-teal-400', className)} {...rest}>
      <EmailIcon className="size-4 fill-white" />
    </Logo>
  );
};

export const EmailLogo = memo(IEmailLogo) as typeof IEmailLogo;
