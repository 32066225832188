import {
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRange,
  DateRangePicker as DateRangePicker_,
  DateRangePickerProps as DateRangePickerProps_,
  DateSegment,
  DateValue,
  Dialog,
  Group,
  Heading,
  RangeCalendar,
} from 'react-aria-components';

import ChevronDownIcon from '@/assets/chevron-down-icon.svg?react';
import { merge } from '@/components';
import { Button, Popover, Text } from '@/components/core';

export type DateRangePickerProps = DateRangePickerProps_<DateValue> & {
  label: string;
  dateRange?: DateRange | null;
  onDateRangeChange?: (value: DateRange | null) => void;
};

export const DateRangePicker = ({ label, dateRange, onDateRangeChange, className, ...rest }: DateRangePickerProps) => {
  return (
    <DateRangePicker_ aria-label={label} className={merge('', className)} defaultValue={dateRange} {...rest}>
      <Group className="flex items-center">
        <Button className="gap-1 hover:bg-gray-50" variant="plain">
          <DateInput className="pointer-events-none flex text-xs text-gray-500" slot="start">
            {(segment) => {
              segment.isEditable = false;
              return <DateSegment segment={segment} />;
            }}
          </DateInput>
          <Text size="xs">–</Text>
          <DateInput className="pointer-events-none flex text-xs text-gray-500" slot="end">
            {(segment) => {
              segment.isEditable = false;
              return <DateSegment segment={segment} />;
            }}
          </DateInput>
        </Button>
      </Group>
      <Popover>
        <Dialog>
          <RangeCalendar onChange={onDateRangeChange}>
            <header className="flex items-center justify-center gap-4 p-2">
              <Button variant="plain" slot="previous">
                <ChevronDownIcon className="rotate-90 stroke-gray-400" />
              </Button>
              <Heading className="font-semibold text-gray-600" />
              <Button variant="plain" slot="next">
                <ChevronDownIcon className="-rotate-90 stroke-gray-400" />
              </Button>
            </header>
            <CalendarGrid>
              {(date) => (
                <CalendarCell
                  className="data-[selected]:bg-brand-100 data-[selection-end]:bg-brand-300 data-[selection-start]:bg-brand-300 rounded-lg p-3 data-[disabled]:bg-gray-100 data-[disabled]:text-gray-400 data-[selection-end]:outline-none data-[selection-start]:outline-none"
                  date={date}
                />
              )}
            </CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </DateRangePicker_>
  );
};
