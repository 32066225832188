import type { FieldFormatter, FieldValidator } from '@lucidtech/las-sdk-browser';
import { memo } from 'react';

import { Badge, Text } from '@/components/core';
import { FormatterLogo, ValidatorLogo } from '@/components/ui';

export type FormatterValidatorHeaderProps = {
  formatter?: FieldFormatter;
  validator?: FieldValidator;
};

const IFormatterValidatorHeader = ({ formatter, validator }: FormatterValidatorHeaderProps) => {
  return (
    <div className="flex items-center gap-4">
      {formatter ? (
        <FormatterLogo size="xl" formatter={formatter} />
      ) : validator ? (
        <ValidatorLogo size="xl" validator={validator} />
      ) : null}
      <div className="flex flex-col">
        <div className="flex shrink">
          <Badge
            className="rounded-lg"
            variant="default"
            label={formatter ? 'formatter' : validator ? 'validator' : 'unknown'}
          />
        </div>
        <Text>{formatter ? formatter.name : validator ? validator.name : null}</Text>
      </div>
    </div>
  );
};

export const FormatterValidatorHeader = memo(IFormatterValidatorHeader) as typeof IFormatterValidatorHeader;
