import SampleDocument from '@/assets/sampleDocuments/Bank Statement.pdf';
import { TemplateModel } from '@/components/ui';

import { amountFormatter, currencyFormatter, dateFormatter, highConfidenceValidator } from './common';

export const BANK_STATEMENT: TemplateModel = {
  fields: [
    {
      id: 'bank_name',
      type: 'single-value',
      name: 'Bank',
      promptHint: 'The name of the bank which issued the statement',
      validators: [highConfidenceValidator],
    },
    {
      id: 'customer_name',
      type: 'single-value',
      name: 'Customer',
      promptHint: 'The person or company which this bank statement belongs to',
      validators: [highConfidenceValidator],
    },
    {
      id: 'issue_date',
      type: 'single-value',
      name: 'Issue Date',
      promptHint: 'The issue date',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'account_number',
      type: 'single-value',
      name: 'Account Number',
      promptHint: 'The account number that this statement was issued for',
      validators: [highConfidenceValidator],
    },
    {
      id: 'balance',
      type: 'single-value',
      name: 'Balance',
      promptHint: 'The balance of the account at the time of issue.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'currency',
      type: 'single-value',
      name: 'Currency',
      promptHint: 'The ISO currency of the balance',
      formatters: [currencyFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'transactions',
      type: 'table',
      name: 'Transactions',
      promptHint: 'The transactions.',
      fields: [
        {
          id: 'transaction_date',
          type: 'single-value',
          name: 'Transaction Date',
          promptHint: 'The date of this transaction',
          formatters: [dateFormatter],
          validators: [highConfidenceValidator],
        },
        {
          id: 'withdrawal_amount',
          type: 'single-value',
          name: 'Withdrawal amount',
          promptHint: 'The withdrawal/credit or deduction amount.',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
        {
          id: 'deposit_amount',
          type: 'single-value',
          name: 'Deposit amount',
          promptHint: 'The deposit amount.',
          formatters: [amountFormatter],
          validators: [highConfidenceValidator],
        },
      ],
    },
  ],
  sampleDocuments: [SampleDocument],
};
