import { PropsWithChildren, useRef } from 'react';

import { createDocumentStore, CreateDocumentStoreProps, DocumentContext, DocumentStore } from './documentStore';

type DocumentProviderProps = PropsWithChildren<CreateDocumentStoreProps>;

export const DocumentProvider = ({ children, ...props }: DocumentProviderProps) => {
  const storeRef = useRef<DocumentStore>(null);

  if (!storeRef.current) {
    storeRef.current = createDocumentStore(props);
  }

  return <DocumentContext.Provider value={storeRef.current}>{children}</DocumentContext.Provider>;
};
