import { useMemo } from 'react';

import { toModelId, useGetModel } from '@/hooks/api';
import { JSONType } from '@/utils';

export type UsePowerAutomateJSONSchemaOpts = {
  modelId: string;
};

export const usePowerAutomateJSONSchema = ({ modelId }: UsePowerAutomateJSONSchemaOpts) => {
  const { data: model } = useGetModel(toModelId(modelId));

  const schema = useMemo(() => {
    // TODO: Look at formatter to give better field description
    const typeToDescription = {
      'single-value': 'A single value',
      'multi-value': 'Multiple values',
      table: 'array (Each element represents a row with predictions)',
    };
    const confidenceDescription =
      'number (between 0 and 1, representing how confident the AI is that the prediction is correct';
    const isEditedDescription = 'boolean (true if the value was corrected by a human validator)';

    const jsonSchema: JSONType = {
      type: 'object',
      properties: {
        documentId: {
          type: 'string',
          title: 'Document ID in Cradl',
        },
        datasetId: {
          type: 'string',
          title: 'Dataset ID in Cradl',
        },
        validatedPredictions: {
          type: 'object',
          properties: {},
        },
      },
    };

    const singlePrediction = (fieldName: string, type: 'single-value' | 'multi-value' | 'table') => {
      return {
        value: {
          type: 'string',
          title: fieldName,
          description: typeToDescription[type] ?? '',
        },
        confidence: {
          type: 'number',
          title: `${fieldName} confidence`,
          description: confidenceDescription,
        },
        isEdited: {
          type: 'boolean',
          title: `${fieldName} isEdited`,
          description: isEditedDescription,
        },
      };
    };

    const toArray = (properties: JSONType) => {
      return {
        type: 'array',
        title: '',
        description: 'multivalue',
        items: {
          type: 'object',
          properties: properties,
        },
      };
    };

    for (const [fieldName, config] of Object.entries(model?.fieldConfig ?? {})) {
      if (config.type === 'table') {
        const items: JSONType = {
          type: 'object',
          properties: {},
        };
        for (const [subFieldName, subConfig] of Object.entries(config.fields ?? {})) {
          const properties = singlePrediction(subFieldName, subConfig.type);
          if (subConfig.type === 'multi-value') {
            items.properties[subFieldName] = toArray(properties);
          } else {
            items.properties[subFieldName] = {
              type: 'object',
              properties: properties,
            };
          }
        }
        jsonSchema.properties.validatedPredictions.properties[fieldName] = {
          type: 'array',
          title: `${fieldName} array`,
          description: typeToDescription[config.type] ?? '',
          items: items,
        };
      } else {
        const properties = singlePrediction(fieldName, config.type);
        if (config.type === 'multi-value') {
          jsonSchema.properties.validatedPredictions.properties[fieldName] = toArray(properties);
        } else {
          jsonSchema.properties.validatedPredictions.properties[fieldName] = {
            type: 'object',
            properties: properties,
          };
        }
      }
    }
    return jsonSchema;
  }, [model?.fieldConfig]);

  return { schema };
};
