import { memo } from 'react';
import { Dialog as Dialog_, DialogProps as DialogProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type DialogProps = DialogProps_;

const IDialog = ({ className, children, ...rest }: DialogProps) => {
  return (
    <Dialog_ className={merge('outline-none', className)} {...rest}>
      {children}
    </Dialog_>
  );
};

export const Dialog = memo(IDialog) as typeof IDialog;
