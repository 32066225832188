import type { CreateDocumentOptions, Document } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type DocumentId = { documentId: string };

export const {
  useCreate: useCreateDocument,
  useBatchGet: useBatchGetDocument,
  useGet: useGetDocument,
  useUpdate: useUpdateDocument,
} = create<Document, DocumentId, CreateDocumentOptions>({
  cacheKey: 'documents',
  createId: (doc) => (doc ? { documentId: doc.documentId } : null),
  equal: (doc, id) => Boolean(doc && id?.documentId && doc.documentId === id.documentId),
  create: (client, doc) => client.createDocument(doc),
  delete: (client, id) => client.deleteDocument(id.documentId),
  get: (client, id) => client.getDocument(id.documentId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listDocuments()
        .then((response: JSONType) => {
          resolve({ data: response.documents, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateDocument(id.documentId, updates),
});

export const toDocumentId = (documentId?: string | null): DocumentId | null => {
  return documentId ? { documentId } : null;
};
