import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { merge } from '@/components';
import { Text } from '@/components/core';

export type NavigationBarItemProps = DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
  label: string;
  selected?: boolean;
  rightSection?: ReactNode;
};

export const NavigationBarItem = ({
  label,
  selected,
  rightSection,
  className,
  children,
  ...rest
}: NavigationBarItemProps) => {
  /* prettier-ignore */
  const styles = [
    'cursor-pointer',
    'flex',
    'gap-2',
    'group/li',
    'items-center',
    'p-2',
    'rounded-lg',
  ];
  /* prettier-ignore */
  const lightStyles = [
    'hover:bg-gray-100',
    'hover:text-gray-600',
    'text-gray-400',
    selected && 'bg-gray-100',
    selected && 'text-gray-600',
    selected && 'fill-gray-600',
  ];

  return (
    <li className={merge(styles, lightStyles, className)} {...rest}>
      {children}
      <Text className="group-hover/li:text-gray-800" size="md">
        {label}
      </Text>
      <div className="grow" />
      {rightSection}
    </li>
  );
};
