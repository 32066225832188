import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { memo, useEffect } from 'react';

import { ErrorCard, Loading } from '@/components/core';
import { useGlobalAuth } from '@/hooks';

const routeApi = getRouteApi('/globalAuthCallback');

export type GlobalAuthCallbackProps = object;

// eslint-disable-next-line no-empty-pattern
const IGlobalAuthCallback = ({}: GlobalAuthCallbackProps) => {
  const search = routeApi.useSearch();
  const navigate = useNavigate();
  const { ensureLoggedIn, updateCredentials } = useGlobalAuth();

  useEffect(() => {
    if (search.code) {
      updateCredentials(search.code);
    }
    ensureLoggedIn().then((value) => {
      if (value) {
        navigate({ to: '/organizations' }).catch(console.error);
      }
    });
  }, [ensureLoggedIn, navigate, search.code, updateCredentials]);

  if (search.error) {
    return <ErrorCard label={search.error} message={search.error_description} />;
  }

  return <Loading label="Checking credentials..." />;
};

export const GlobalAuthCallback = memo(IGlobalAuthCallback) as typeof IGlobalAuthCallback;
