import type { CreateProjectOptions, Project } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type ProjectId = { projectId: string };

export const {
  getOptions: getProjectOptions,
  listOptions: listProjectsOptions,
  useCreate: useCreateProject,
  useGet: useGetProject,
  useList: useListProjects,
  useSuspenseGet: useSuspenseGetProject,
  useUpdate: useUpdateProject,
} = create<Project, ProjectId, CreateProjectOptions>({
  cacheKey: 'projects',
  equal: (project, id) => Boolean(project && id?.projectId && project.projectId === id.projectId),
  createId: (project) => (project ? { projectId: project.projectId } : null),
  create: (client, project) => client.createProject(project),
  delete: (client, id) => client.deleteProject(id.projectId),
  get: (client, id) => client.getProject(id.projectId),
  list: (client) => {
    return new Promise((resolve, reject) => {
      client
        .listProjects()
        .then((response: JSONType) => {
          resolve({ data: response.projects, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateProject(id.projectId, updates),
});

export const toProjectId = (projectId?: string | null): ProjectId | null => {
  return projectId ? { projectId } : null;
};
