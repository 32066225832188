import { memo } from 'react';

import EmailDocumentIcon from '@/assets/email-document-icon.svg?react';
import ImageIcon from '@/assets/image-icon.svg?react';
import PdfIcon from '@/assets/pdf-icon.svg?react';
import { Text } from '@/components/core';
import { useProjectRunTree } from '@/hooks';

export type ProjectRunDocumentCardProps = {
  projectId: string;
  runId: string;
};

const IProjectRunDocumentCard = ({ projectId, runId }: ProjectRunDocumentCardProps) => {
  const { emailDocument, taskDocument } = useProjectRunTree({ projectId, runId });

  if (!taskDocument) {
    return <Text size="sm">Loading...</Text>;
  }

  return (
    <div className="flex items-center gap-2">
      {taskDocument.contentType === 'application/pdf' ? (
        <PdfIcon className="size-7" />
      ) : (
        <ImageIcon className="size-7" />
      )}
      {emailDocument ? <EmailDocumentIcon /> : null}
      <Text>{taskDocument.name}</Text>
    </div>
  );
};

export const ProjectRunDocumentCard = memo(IProjectRunDocumentCard) as typeof IProjectRunDocumentCard;
