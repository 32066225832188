import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { GlobalAuthCallback } from '@/features/auth';

const GlobalAuthCallbackSearchSchema = z.object({
  code: z.optional(z.string()),
  error: z.optional(z.string()),
  error_description: z.optional(z.string()),
});

export const Route = createFileRoute('/globalAuthCallback')({
  component: GlobalAuthCallback,
  validateSearch: zodValidator(GlobalAuthCallbackSearchSchema),
});
