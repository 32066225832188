import type { Model } from '@lucidtech/las-sdk-browser';
import { useCallback } from 'react';

import { toModelId, toProjectId, useGetModel, useGetProject } from '@/hooks/api';
import { findModelId, JSONType } from '@/utils';

import { useCreateExport } from './useCreateExport';

const getHeaders = function* (model: Model) {
  for (const [id, config] of Object.entries(model.fieldConfig)) {
    if (config.type === 'table' && config.fields) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [subId, _] of Object.entries(config.fields)) {
        yield `${id}/${subId}`;
      }
    } else {
      yield id;
    }
  }
};

type CSVValue = string | undefined | null;

const getLineValues = (model: Model, prediction: JSONType) => {
  const lines: Record<string, Record<string, CSVValue>[]> = {};
  for (const [id, config] of Object.entries(model.fieldConfig)) {
    if (config.type === 'table' && config.fields) {
      lines[id] = [];
      for (const row of prediction?.[id] ?? []) {
        const line: Record<string, CSVValue> = {};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [subId, _] of Object.entries(config.fields)) {
          line[`${id}/${subId}`] = row?.[subId]?.[0]?.value;
        }
        lines[id].push(line);
      }
    }
  }
  return lines;
};

const getMultiValues = (model: Model, prediction: JSONType) => {
  const multiValues: Record<string, CSVValue[]> = {};
  for (const [id, config] of Object.entries(model.fieldConfig)) {
    if (config.type === 'multi-value') {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      multiValues[id] = (prediction?.[id] ?? []).map((candidate: any) => candidate.value);
    }
  }
  return multiValues;
};

const getKeyValues = (model: Model, prediction: JSONType) => {
  const keyValues: Record<string, CSVValue> = {};
  for (const [id, config] of Object.entries(model.fieldConfig)) {
    if (config.type !== 'table' && !(config.type === 'multi-value')) {
      keyValues[id] = prediction?.[id]?.[0]?.value;
    }
  }
  return keyValues;
};

const getValues = (model: Model, prediction: JSONType) => {
  return {
    keyValues: getKeyValues(model, prediction),
    lines: getLineValues(model, prediction),
    multiValues: getMultiValues(model, prediction),
  };
};

const createCSVRows = (
  headers: string[],
  documentId: string,
  keyValues: Record<string, CSVValue>,
  lineValues: Record<string, CSVValue>[]
) => {
  const escape = (s: string) => s.replace(/"/g, '\\"');
  const rows: string[] = [];
  for (const lineValue of lineValues) {
    const row: string[] = [];
    for (const header of headers) {
      let value = '';
      if (header === 'documentId') {
        value = documentId;
      } else if (header in lineValue) {
        value = lineValue[header] ?? '';
      } else if (header in keyValues) {
        value = keyValues[header] ?? '';
      }
      row.push(`"${escape(value)}"`);
    }
    rows.push(row.join(','));
  }
  return rows;
};

export type UseCreateCSVExportOpts = {
  projectId: string;
  runIds?: string[] | null;
};

export const useCreateCSVExport = ({ projectId, runIds }: UseCreateCSVExportOpts) => {
  const { data: project } = useGetProject(toProjectId(projectId));
  const { data: model } = useGetModel(toModelId(findModelId(project)));

  const exports = useCreateExport({ projectId, runIds });

  const create = useCallback(async () => {
    if (!model) throw Error('Model does not exist');
    const headers = ['documentId', ...getHeaders(model)];
    /* Add CSV headers */
    const rows = [headers.join(',')];

    for (const e of exports) {
      const { keyValues, lines, multiValues } = getValues(model, e.result.predictions);

      /* Add key-value fields */
      rows.push(...createCSVRows(headers, e.result.taskId, keyValues, [{ '/headers': undefined }]));

      /* Add multi-value fields */
      for (const [fieldId, values] of Object.entries(multiValues)) {
        const lineValues = values.map((value) => ({ [fieldId]: value }));
        rows.push(...createCSVRows(headers, e.result.taskId, keyValues, lineValues));
      }

      /* Add line items fields */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_, lineValues] of Object.entries(lines)) {
        rows.push(...createCSVRows(headers, e.result.taskId, keyValues, lineValues));
      }
    }
    return new Blob([rows.join('\n')], {
      type: 'text/csv;charset=utf-8',
    });
  }, [exports, model]);

  return { create };
};
