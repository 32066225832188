import type { CreateValidationTaskOptions, ValidationTask } from '@lucidtech/las-sdk-browser';

import { create } from '@/hooks/api';
import { JSONType } from '@/utils';

export type ValidationTaskId = { validationId: string; taskId: string };

export const toValidationTaskId = (validationId?: string | null, taskId?: string | null): ValidationTaskId | null => {
  return validationId && taskId ? { validationId, taskId } : null;
};

export const {
  useGet: useGetValidationTask,
  useUpdate: useUpdateValidationTask,
  useBatchGet: useBatchGetValidationTask,
} = create<ValidationTask, ValidationTaskId, CreateValidationTaskOptions & { validationId: string }>({
  cacheKey: 'validationTasks',
  equal: (task, id) =>
    Boolean(
      task && id?.validationId && id?.taskId && task.validationId === id.validationId && task.taskId === id.taskId
    ),
  createId: (task) => toValidationTaskId(task?.validationId, task?.taskId),
  create: (client, task) => client.createValidationTask(task.validationId, task),
  delete: (client, id) => client.deleteValidationTask(id.validationId, id.taskId),
  get: (client, id) => client.getValidationTask(id.validationId, id.taskId),
  list: (client, options) => {
    return new Promise((resolve, reject) => {
      client
        .listValidationTasks(options)
        .then((response: JSONType) => {
          resolve({ data: response.tasks, nextToken: response.nextToken });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: (client, id, updates) => client.updateValidationTask(id.validationId, id.taskId, updates),
});
