import { useNavigate } from '@tanstack/react-router';
import { useCallback, useRef } from 'react';
import { MenuTrigger, Separator, Toolbar } from 'react-aria-components';

import ArrowRightIcon from '@/assets/arrow-right-icon.svg?react';
import AutomaticImportIcon from '@/assets/automatic-import-icon.svg?react';
import CloseIcon from '@/assets/close-icon.svg?react';
import DeleteIcon from '@/assets/delete-icon.svg?react';
import DownloadCSVIcon from '@/assets/download-csv-icon.svg?react';
import DownloadExcelIcon from '@/assets/download-excel-icon.svg?react';
import DownloadIcon from '@/assets/download-icon.svg?react';
import ValidationIcon from '@/assets/validation-icon.svg?react';
import { Button, Checkbox, Menu, MenuItem, Popover, Text } from '@/components/core';
import { useCreateCSVExport, useCreateJSONExport, useDownloadFile } from '@/hooks';
import { toProjectRunId, useDeleteProjectRun } from '@/hooks/api';
import { useInboxStore } from '@/store';

export type InboxSelectedToolbarProps = {
  projectId: string;
};

export const InboxSelectedToolbar = ({ projectId }: InboxSelectedToolbarProps) => {
  const selectedRunIds = useInboxStore((state) => state.selectedRunIds);
  const setSelectedRunIds = useInboxStore((state) => state.setSelectedRunIds);
  const addFilter = useInboxStore((state) => state.addFilter);
  const triggerRef = useRef(null);
  const navigate = useNavigate();
  const { create: createCSV } = useCreateCSVExport({ projectId, runIds: [...selectedRunIds] });
  const { create: createJSON } = useCreateJSONExport({ projectId, runIds: [...selectedRunIds] });
  const { download: downloadCSV } = useDownloadFile({ fetchData: createCSV, downloadedFileName: 'export.csv' });
  const { download: downloadJSON } = useDownloadFile({ fetchData: createJSON, downloadedFileName: 'export.json' });

  const { mutateAsync: deleteProjectRun } = useDeleteProjectRun();

  const onDeleteProjectRun = useCallback(() => {
    for (const runId of selectedRunIds) {
      deleteProjectRun(toProjectRunId(projectId, runId)).catch(console.log);
    }
  }, [deleteProjectRun, projectId, selectedRunIds]);

  return (
    <>
      <div ref={triggerRef} />
      <Popover
        /* Override zIndex to make sure toolbar stays below modals */
        className="!z-10"
        triggerRef={triggerRef}
        isOpen={selectedRunIds.size > 0}
        placement="top"
        isNonModal={true}
      >
        <Toolbar orientation="horizontal" className="flex h-14 items-center gap-2 px-5 py-4">
          <Checkbox isDisabled={true} isSelected={true} />
          <Text>{selectedRunIds.size} runs</Text>
          <Separator className="mx-4 h-full w-[1px] bg-gray-200" orientation="vertical" />
          <Button
            className="hover:bg-gray-50"
            variant="plain"
            onPress={async () => {
              addFilter({
                type: 'string',
                columnName: 'runId',
                condition: 'is',
                value: Array.from(selectedRunIds),
              });
              return navigate({
                to: '/details/projects/$projectId/runs/$runId',
                params: { projectId: projectId, runId: Array.from(selectedRunIds)[0] },
              });
            }}
          >
            <ValidationIcon className="size-5 stroke-gray-500" />
            <Text>Review</Text>
          </Button>
          <Separator className="mx-4 h-full w-[1px] bg-gray-200" orientation="vertical" />
          <MenuTrigger>
            <Button className="hover:bg-gray-50" variant="plain">
              <DownloadIcon className="size-5 stroke-gray-500" />
              <Text>Export</Text>
            </Button>
            <Popover placement="top">
              <Menu>
                <MenuItem className="flex items-center" onAction={downloadCSV}>
                  <DownloadCSVIcon className="size-6 fill-gray-500" />
                  <Text>Export as CSV</Text>
                </MenuItem>
                <MenuItem className="flex items-center" onAction={downloadCSV}>
                  <DownloadExcelIcon className="size-6 fill-gray-500" />
                  <Text>Export as Excel</Text>
                </MenuItem>
                <MenuItem className="flex items-center" onAction={downloadJSON}>
                  <DownloadExcelIcon className="size-6 fill-gray-500" />
                  <Text>Export as JSON</Text>
                </MenuItem>
                <Separator className="h-[1px] w-full bg-gray-200" orientation="horizontal" />
                <MenuItem
                  className="flex items-center"
                  onAction={() => navigate({ to: '/projects/$projectId/integration', params: { projectId } })}
                >
                  <AutomaticImportIcon className="size-5 stroke-gray-500" />
                  <Text>Automatic export</Text>
                  <div className="flex items-center gap-1 rounded-lg border border-gray-200 p-1">
                    <Text className="text-gray-600" size="xs">
                      Setup
                    </Text>
                    <ArrowRightIcon className="size-4 stroke-gray-400" />
                  </div>
                </MenuItem>
              </Menu>
            </Popover>
          </MenuTrigger>
          <Separator className="mx-4 h-full w-[1px] bg-gray-200" orientation="vertical" />
          <Button className="hover:bg-gray-50" variant="plain" onPress={onDeleteProjectRun}>
            <DeleteIcon className="size-5 stroke-gray-500" />
            <Text>Delete</Text>
          </Button>
          <Separator className="mx-4 h-full w-[1px] bg-gray-200" orientation="vertical" />
          <Button className="hover:bg-gray-50" variant="plain" onPress={() => setSelectedRunIds([])}>
            <CloseIcon className="size-5 stroke-gray-500" />
          </Button>
        </Toolbar>
      </Popover>
    </>
  );
};
