import React, { memo, useCallback, useEffect } from 'react';
import { HTMLAttributes, useContext, useImperativeHandle, useRef, useState } from 'react';
import { Key, SelectContext } from 'react-aria-components';

import { merge } from '../utils';
import { InputContext } from './PillsInputContext';

export type Item = {
  value: string;
  label?: string;
};

export type PillListActions = {
  addItem: (pill: Item) => void;
  getItems: () => Item[];
  removeItem: (pill: Item) => void;
  focus: () => void;
  updateItem: (item: Item) => void;
};

export type ListProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  children: (pill: Item, handle: PillListActions) => React.ReactNode;
  autoSeparate?: string[];
  onItemsChange?: (items: Item[]) => void;
  initialValues?: Item[];
  isInputDisabled?: boolean;
};

export const IList = ({
  children,
  autoSeparate,
  onItemsChange,
  initialValues,
  isInputDisabled,
  className,
  ...rest
}: ListProps) => {
  const { pillsRef } = useContext(InputContext);

  const [pills, setPills] = useState<Item[]>([...(initialValues ?? [])]);
  const [cursor, setCursor] = useState<number>(0);
  const inputRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (onItemsChange) onItemsChange(pills);
  }, [pills, onItemsChange]);

  useImperativeHandle(
    pillsRef,
    () => ({
      addItem: (pill: Item) => {
        pills.splice(cursor, 0, pill);
        setPills([...pills]);
        setCursor(cursor + 1);
      },
      updateItem: (pill: Item) => setPills((pills) => pills.map((p) => (p.value === pill.value ? pill : p))),
      getItems: () => pills,
      removeItem: (pill: Item) => setPills((pills) => pills.filter((p) => p !== pill)),
      focus: () => {
        setCursor(pills.length);
        inputRef.current?.focus();
      },
    }),
    [pills, cursor, setPills]
  );

  const saveAsPill = useCallback(() => {
    if (!inputRef.current) return;

    const text = inputRef.current.textContent;
    if (text) {
      const parts = text.trim().split(/\s/);
      for (const value of parts) {
        pillsRef?.current?.addItem({ value });
      }

      inputRef.current.textContent = '';
    }
  }, [pillsRef]);

  const onInput = useCallback(() => {
    const text = inputRef?.current?.textContent;

    if (text) {
      if (autoSeparate?.includes(text) || text.match(/\s$/)) {
        saveAsPill();
      }
    }
  }, [autoSeparate, saveAsPill]);

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if (!inputRef.current?.textContent) {
        switch (e.key) {
          case 'Backspace':
            setPills([...pills.slice(0, cursor - 1), ...pills.slice(cursor, pills.length)]);
            setCursor(cursor - 1);
            break;
          case 'Delete':
            setPills([...pills.slice(0, cursor), ...pills.slice(cursor + 1, pills.length)]);
            break;
          case 'ArrowLeft':
            setCursor(Math.max(cursor - 1, 0));
            break;
          case 'ArrowRight':
            setCursor(Math.min(cursor + 1, pills.length));
            break;
        }
      }
    },
    [cursor, pills]
  );

  return (
    <div
      className={merge('flex grow flex-wrap items-center gap-1', className)}
      onClick={pillsRef?.current?.focus}
      onBlur={saveAsPill}
      {...rest}
    >
      {pillsRef?.current && pills.slice(0, cursor).map((p) => children(p, pillsRef.current!))}
      {!isInputDisabled ? (
        <span ref={inputRef} contentEditable onInput={onInput} onKeyDown={handleKeydown} className="px-1 outline-0" />
      ) : null}
      {pillsRef?.current && pills.slice(cursor, pills.length).map((p) => children(p, pillsRef.current!))}
    </div>
  );
};

export const List = memo(IList) as typeof IList;

export type PillsInputProps = {
  pillsRef?: React.RefObject<PillListActions | null>;
  mapValue?: (key: Key) => Item;
} & HTMLAttributes<HTMLDivElement>;

const IInput = ({ children, mapValue, className, ...rest }: PillsInputProps) => {
  const ref = useRef<PillListActions>(null);

  const onSelectionChange = useCallback(
    (key: Key) => {
      const val = mapValue ? mapValue(key) : { value: key.toString() };
      ref.current?.addItem(val);
    },
    [mapValue]
  );

  const styles = ['border-1 rounded-lg p-1 border-gray-300'];

  return (
    <InputContext.Provider value={{ pillsRef: ref }}>
      <SelectContext.Provider value={{ onSelectionChange }}>
        <div className={merge(styles, className)} {...rest}>
          {children}
        </div>
      </SelectContext.Provider>
    </InputContext.Provider>
  );
};

export const Input = memo(IInput) as typeof IInput;
