import { memo } from 'react';

import AIModelIcon from '@/assets/ai-model-icon.svg?react';
import { Logo, Text } from '@/components/core';
import { SetupRequired } from '@/components/flow';
import { toModelId, useGetModel } from '@/hooks/api';
import { listAllFields } from '@/utils';

export type ModelNodeProps = {
  modelId?: string;
};

const IModelNode = ({ modelId }: ModelNodeProps) => {
  const { data: model } = useGetModel(toModelId(modelId));
  const allFields = listAllFields(model?.fieldConfig ?? {});
  const visibleFieldNames = allFields.slice(0, 6);
  const restFieldNames = allFields.length - visibleFieldNames.length;

  return (
    <div className="w-72">
      <div className="flex items-center gap-2 border-b border-gray-200 pb-2">
        <Logo className="bg-blue-400" size="lg">
          <AIModelIcon className="size-4 text-white" />
        </Logo>
        <Text className="font-semibold" size="md">
          Extract with AI
        </Text>
      </div>
      <div className="gap-2 pt-2">
        {Object.entries(model?.fieldConfig ?? {}).length ? (
          <Text className="flex flex-row flex-wrap items-center gap-2 text-gray-600" size="xs">
            Extract:{' '}
            {visibleFieldNames.map(({ name }) => (
              <Text key={name} className="rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
                {name}
              </Text>
            ))}
            {restFieldNames ? (
              <Text key="rest" className="rounded-lg bg-gray-100 px-2 py-1 text-gray-600" size="xs">
                +{restFieldNames}
              </Text>
            ) : null}
          </Text>
        ) : (
          <SetupRequired />
        )}
      </div>
    </div>
  );
};

export const ModelNode = memo(IModelNode) as typeof IModelNode;
