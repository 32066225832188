import { memo } from 'react';

import { Button, CopyText, Header, Input, Label, Link, Text } from '@/components/core';
import { usePowerAutomateJSONSchema } from '@/hooks';
import { useTriggerStore } from '@/store';
import { toString } from '@/utils';

export type ExportPowerAutomateConfigurationProps = {
  modelId: string;
};

const IExportPowerAutomateConfiguration = ({ modelId }: ExportPowerAutomateConfigurationProps) => {
  const action = useTriggerStore((state) => state.action);
  const updateAction = useTriggerStore((state) => state.updateAction);
  const { schema } = usePowerAutomateJSONSchema({ modelId });

  return (
    <div className="flex flex-col gap-4 p-4">
      <Header size="md">Connect using Power Automate</Header>
      <Text size="sm">
        Read how-to in our <Link href="https://docs.cradl.ai/integrations/powerAutomate">documentation</Link>
      </Text>
      <Button variant="secondary" onPress={() => window.open('https://make.powerautomate.com')}>
        Open Power Automate
      </Button>
      <div className="flex flex-col gap-2">
        <Label>Power Automate Webhook URL</Label>
        <Input
          onChange={(e) => updateAction({ config: { ...action.config, url: e.target.value } })}
          className="text-xs text-gray-600"
          value={toString(action.config?.url)}
          placeholder="https://{tenant}.region.logic.azure.com/abc"
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label>JSON Schema</Label>
        <CopyText value={JSON.stringify(schema)} />
      </div>
    </div>
  );
};

export const ExportPowerAutomateConfiguration = memo(
  IExportPowerAutomateConfiguration
) as typeof IExportPowerAutomateConfiguration;
