import { memo } from 'react';

import { merge } from '@/components';
import { Text } from '@/components/core';
import { DivProps } from '@/utils';

const ISortColumnCard = ({ columnName, className, children, ...rest }: DivProps & { columnName: string }) => {
  return (
    <div className={merge('flex w-full items-center gap-1', className)} {...rest}>
      <Text size="sm">{columnName}</Text>
      {children}
    </div>
  );
};

export const SortColumnCard = memo(ISortColumnCard) as typeof ISortColumnCard;
