import { TemplateModel } from '@/components/ui';
import { FORMATTER_CLASSIFICATION_FUNCTION_ID } from '@/constants';
import { generateId } from '@/utils';

import {
  currencyFormatter,
  dateFormatter,
  highConfidenceValidator,
  ibanFormatter,
  numericFormatter,
} from './common.ts';

export const GENERIC: TemplateModel = {
  fields: [
    {
      id: generateId(),
      type: 'single-value',
      name: 'Text',
      validators: [highConfidenceValidator],
    },
    {
      id: generateId(),
      type: 'single-value',
      name: 'Numeric',
      formatters: [numericFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: generateId(),
      type: 'single-value',
      name: 'Date',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: generateId(),
      type: 'single-value',
      name: 'Currency',
      formatters: [currencyFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: generateId(),
      type: 'single-value',
      name: 'Classification',
      formatters: [
        {
          functionId: FORMATTER_CLASSIFICATION_FUNCTION_ID,
          name: 'Classification',
          description: 'Enforce one of the defined classes',
          config: {
            labels: [
              { value: 'ExampleClassA', description: 'Example Description A' },
              { value: 'ExampleClassB', description: 'Example Description B' },
              { value: 'ExampleClassC', description: 'Example Description C' },
            ],
            catchAll: 'OtherClass',
          },
        },
      ],
      validators: [highConfidenceValidator],
    },
    {
      id: generateId(),
      type: 'single-value',
      name: 'IBAN',
      formatters: [ibanFormatter],
      validators: [highConfidenceValidator],
      comingSoon: true,
    },
  ],
  sampleDocuments: [],
};
