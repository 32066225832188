import '@xyflow/react/dist/base.css';

import {
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  type Edge as Edge_,
  type Node as Node_,
  type OnEdgesChange,
  type OnNodesChange,
  ReactFlow,
} from '@xyflow/react';
import { memo } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Edge, Node } from '@/components/flow';
import { useNodesAndEdges, useProjectTree } from '@/hooks';

export type FlowDiagramProps = {
  projectId: string;
};

const IFlowDiagram = ({ projectId }: FlowDiagramProps) => {
  const projectTree = useProjectTree({ projectId });
  const { nodes: initialNodes, edges: initialEdges } = useNodesAndEdges(projectTree);
  const [nodes, setNodes] = useState<Node_[]>([]);
  const [edges, setEdges] = useState<Edge_[]>([]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  useEffect(() => {
    setNodes(initialNodes);
    // TODO: Cannot use initialNodes as dep, will cause infinite recursion
  }, [projectTree.hash]);

  useEffect(() => {
    setEdges(initialEdges);
    // TODO: Cannot use initialEdges as dep, will cause infinite recursion
  }, [projectTree.hash]);

  return (
    <div className="h-full w-full">
      <ReactFlow
        colorMode="light"
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={{ node: Node }}
        edgeTypes={{ edge: Edge }}
        fitView
        fitViewOptions={{ maxZoom: 1 }}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export const FlowDiagram = memo(IFlowDiagram) as typeof IFlowDiagram;
