import { memo } from 'react';
import { TabPanel as TabPanel_, TabPanelProps as TabPanelProps_ } from 'react-aria-components';

import { merge } from '@/components';

export type TabPanelProps = TabPanelProps_;

const ITabPanel = ({ className, children, ...rest }: TabPanelProps) => {
  return (
    <TabPanel_ className={merge(className)} {...rest}>
      {children}
    </TabPanel_>
  );
};

export const TabPanel = memo(ITabPanel) as typeof ITabPanel;
