import { memo } from 'react';

import { ModelPanel } from '@/components/flow';

import { SlideOut } from './SlideOut.tsx';

export type ModelPanelProps = {
  projectId: string;
  modelId: string;
};

const IModelPanelSlideOut = ({ projectId, modelId }: ModelPanelProps) => {
  return (
    <SlideOut>
      <ModelPanel projectId={projectId} modelId={modelId} />
    </SlideOut>
  );
};

export const ModelPanelSlideOut = memo(IModelPanelSlideOut) as typeof IModelPanelSlideOut;
