import SampleDocument from '@/assets/sampleDocuments/Payslip.pdf';
import { TemplateModel } from '@/components/ui';

import { amountFormatter, dateFormatter, highConfidenceValidator } from './common';

export const PAYSLIP: TemplateModel = {
  fields: [
    {
      id: 'employee_name',
      type: 'single-value',
      name: 'Employee Name',
      promptHint: 'Employee name.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'employee_number',
      type: 'single-value',
      name: 'Employee Number',
      promptHint: 'Employee number.',
      validators: [highConfidenceValidator],
    },
    {
      id: 'pay_period_from',
      type: 'single-value',
      name: 'Pay Period From',
      promptHint: 'The start date of the time period for which wages are calculated on the payslip.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'pay_period_to',
      type: 'single-value',
      name: 'Pay Period To',
      promptHint: 'The end date of the time period for which wages are calculated on the payslip.',
      formatters: [dateFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'net_pay',
      type: 'single-value',
      name: 'Net Pay',
      promptHint: 'Net pay.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'deductions',
      type: 'single-value',
      name: 'Deductions',
      promptHint: 'Deductions.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
    {
      id: 'gross_pay',
      type: 'single-value',
      name: 'Gross Pay',
      promptHint: 'Gross pay.',
      formatters: [amountFormatter],
      validators: [highConfidenceValidator],
    },
  ],
  sampleDocuments: [SampleDocument],
};
